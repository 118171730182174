import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import GoogleLoader from '../../../Commoncomponet/Loaders/GoogleLoader'
import ButtonTabs from '../../../Commoncomponet/Tabs/ButtonTabs'
import { GetMssqlCardDetailsById } from '../../../Commoncomponet/Utils'
import Credentials from './Credentials'
import IntegrationLog from './IntegrationLog'
import Log from './IntegrationLog'
import Push from './Push'
import ZISU_AMR_READING from './ZISU_AMR_READING'
import SchedulePush from './SchedulePush'
import ManualPush from './ManualPush'

const IntegrationDetailTabs = () => {
    
    const [credentialdetails,setcredentialdetails]=useState({})
    const [Loader,setLoader]=useState(false)

    const params = useParams();

    const getCredential=async()=>{
        setLoader(true)
        const data=await GetMssqlCardDetailsById(params?.MSID)
        if(data.success==true){
            setcredentialdetails(data?.data?.data)
        setLoader(false)

        }else{
            console.log('something Went wrong')
        setLoader(false)

    }
}
    useEffect(()=>{
        getCredential()
    },[])
    return (
        <>
            <div className="main-content">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/all-deshboard">
                                <i className="fas fa-tachometer-alt"></i> Home
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                           Integrations
                        </li>
                        <li className="breadcrumb-item">
                            <Link to='/integration'>Plugin</Link>
                        </li>
                        <li className="breadcrumb-item">
                        {params?.MSID}
                        </li>
                    </ol>
                </nav>
                {Loader==true ? <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                        <GoogleLoader bodyClassName={'tw-h-[70%]'} />
                    </div>:  <section class="section">
                    <div class="section-body">
                        <div class="row">

                            <div class="col-12 col-sm-12 col-lg-12 tw-p-0">
                                <div class="card">
                                    <div className='tw-mt-2 tw-w-full tw-mx-auto tw-px-0'>

                                        <ButtonTabs color="indigo" className={''} defaultTab={'tab2'}>

                                            <div name={'Connection Setup'} value={'tab2'} color='#6366f1'>
                                                <Credentials />

                                            </div>
                                            {/* <div name='Log' value={'tab3'} color='#ef4444'>
                                                <IntegrationLog TYPE={credentialdetails?.INTEGRATION_TYPE}/>
                                            </div> */}
                                            <div name={'Interaction'} value={'tab4'} color='#f59e0b' >
                                                <Push credentialdetails={credentialdetails} getCredential={getCredential}/>
                                            </div>
                                            {credentialdetails?.integration_type=='1'?<div name='Manual Push*' value={'tab5'} color='#fc544b' >
                                                <ManualPush credentialdetails={credentialdetails} getCredential={getCredential}/>
                                            </div>:<></>}
                                            {credentialdetails?.integration_type=='1'?<div name='Schedule Push*' value={'tab6'} color='#3abaf4' >
                                                <SchedulePush credentialdetails={credentialdetails} getCredential={getCredential}/>
                                            </div>:<></>}
                                        </ButtonTabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div></section>}</div>
        </>
    )
}

export default IntegrationDetailTabs