import React, { useEffect } from 'react'
import { useState } from 'react'
// import {
// 	withScriptjs,
// 	withGoogleMap,
// 	GoogleMap,
// 	Marker,
// 	InfoWindow
// } from "react-google-maps"
import { GoogleMap, InfoWindowF, MarkerF, PolylineF, useJsApiLoader } from '@react-google-maps/api';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom'
import mapicon from '../../assets/img/triangle-green.svg'
import { getAccount, getAllMapDataInGSM, getAllMapDataInGSMmapView, getAllMeter, getAllModem, getAreaById, getCircleById, getDivisionById, getGSMDataById, getGaById, getRolesById, getSubdevisionById, getTags, getAllMapDataInGSMmapViewByFilter } from '../../Commoncomponet/Utils'
import './Map.css'
import signal2 from '../../assets/img/secondsignal.png'
import signal3 from '../../assets/img/signal3.png'
import signal4 from '../../assets/img/signal4.png'
import signal5 from '../../assets/img/signal5.png'
import mapRedicon from '../../assets/img/triangle-red.svg'
import moment from 'moment'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import MultiInput from '../../Commoncomponet/MultiInput/MultiInput'
import { DatePicker, Space } from 'antd'
import GoogleLoader from '../../Commoncomponet/Loaders/GoogleLoader';

export default function GSMmap() {
	console.log("Test All Map");
	const [mapInfo, setMapInfo] = useState([])
	const [permision, setpermision] = useState({})
	const userInfo = JSON.parse(window.localStorage.getItem('user_info'))
	const [ModemInfoModem, setModemInfoModem] = useState(false)
	const [isFilter, setIsFilter] = useState(false)
	const [lastseen, setLastSeen] = useState('')
	const [lastseenIndate, setlastseenIndate] = useState('')
	const [GSMID, setGSMID] = useState('')
	const [GSMData, setGSMData] = useState({})
	const params = useLocation()
	const [filterObj, setFilterObj] = useState({})
	const [SubdevisionData, setSubdevisionData] = useState([]);
	const [DivisionData, setDivisionData] = useState([]);
	const [CircleData, setCircleData] = useState([]);
	const [GaData, setGaData] = useState([]);
	const [AccounData, setAccountData] = useState([]);
	const [AccountKey, setAccountKey] = useState([])
	const [AreaData, setAreaData] = useState([]);
	const [ModemData, setModemData] = useState([]);
	const [MeterData, setMeterData] = useState([]);
	const [tagsList, settagsList] = useState([])
	const [FilterLoader, setFilterLoader] = useState(false)
	//update 1.1 use defaultDate 
	const [defaultDate, setDefaultDate] = useState(moment().add(-moment().utcOffset(), "m").format("DD-MM-YYYY HH:mm:ss"))
	const history = useHistory()

	const [Profile, setProfile] = useState({
		accountuser: false,
		gauser: false,
		circleuser: false,
		areauser: false,
		divisionuser: false,
		subDivisionuser: false,
	})

	const [consumer, setConsumer] = useState({
		checktext1: false,
		checktext2: false,
		checktext3: false,
		checktext4: false,
		checktext5: false,
		checktext6: false,
		checktext7: false,
		checktextCS: false,
		checktextMP: false,
		checktextMOP: false,
		checktextTA: false,
		checktextInTS: false,
		checktextLSs: false,
		Consumer_Type: false,
		checktextDRTS: false,
		deviceStatus : false,
	})
	const [dates, setDates] = useState({ start: '', end: '' })
	const [modem, setModem] = useState({
		checktextRSSI: false,
		checktextLS: false,
		checktextBP: false,
	})


	const [modemStart, setModemState] = useState({
		Between: false,
		lessThan: false,
		greaterThan: false
	})

	const [installState, setinstallState] = useState({
		Between: false,
		lessThan: false,
		greaterThan: false
	})
	const [DRState, setDRState] = useState({
		Between: false,
		lessThan: false,
		greaterThan: false
	})
	const [betteryPr, setbetteryPr] = useState({
		Between: false,
		lessThan: false,
		greaterThan: false
	})

	const [last, setlastSeen] = useState({
		LessEqual: false,
		GreaterEqual: false
	})
	useEffect(() => {
		console.log(params)
		if (params.search.split('?')[1]) {
			// console.log(params.search.split('last_seen')[1].split('=')[1]);
			if (params?.search?.split('last_seen')[1]) {
				setLastSeen(parseInt(params?.search?.split('last_seen')[1]?.split('=')[1]) || 24)
			} else if (params?.search?.split('date')[1]) {
				console.log(params?.search?.split('date')[1]?.split('=')[1]?.split('%22').join('"'));
				setlastseenIndate(JSON.parse(params?.search?.split('date')[1]?.split('=')[1]?.split('%22').join('"')))
			} else {
				setGSMID(params.search.split('?')[1])
				onHandalModelData(params.search.split('?')[1])
			}
		}
	}, [params.search])

	const handleChangeS = (e) => {
		if (e.target.checked) {
			if (Profile.hasOwnProperty(e.target.id)) {
				setProfile({ ...Profile, [e.target.id]: true })
				setFilterObj({ ...filterObj, [e.target.name]: [] })

			}
			if (consumer.hasOwnProperty(e.target.id)) {
				setConsumer({ ...consumer, [e.target.id]: true })
				setFilterObj({ ...filterObj, [e.target.name]: '' })

			}
			if (modem.hasOwnProperty(e.target.id)) {
				setModem({ ...modem, [e.target.id]: true })
			}
			if (e.target.id == 'checktextLS') {
				setFilterObj({ ...filterObj, [e.target.name]: {} })
			}
			if (e.target.id == 'checktextRSSI') {
				setFilterObj({ ...filterObj, [e.target.name]: {} })
			}
			if (e.target.id == 'checktextInTS') {
				setFilterObj({ ...filterObj, [e.target.name]: {} })
			}
			if (e.target.id == 'checktextBP') {
				setFilterObj({ ...filterObj, [e.target.name]: {} })
			}
			if (e.target.id == 'checktextDRTS') {
				setFilterObj({ ...filterObj, [e.target.name]: {} })
			}
		} else {
			if (Profile.hasOwnProperty(e.target.id)) {
				setProfile({ ...Profile, [e.target.id]: false })
				delete filterObj[e.target.name]
				// delete showfilterObj[e.target.name]
			}
			if (consumer.hasOwnProperty(e.target.id)) {
				setConsumer({ ...consumer, [e.target.id]: false })
				delete filterObj[e.target.name]
				// delete showfilterObj[e.target.name]
				if (e.target.id == 'checktextDRTS') {
					setDRState({ ...DRState, greaterThan: false, lessThan: false, Between: false })
				}
			}
			if (modem.hasOwnProperty(e.target.id)) {
				setModem({ ...modem, [e.target.id]: false })
				if (e.target.id == 'checktextRSSI') {
					delete filterObj[e.target.name]
					// delete showfilterObj[e.target.name]
					setModemState({ ...modemStart, greaterThan: false, lessThan: false, Between: false })
				}
				if (e.target.id == 'checktextBP') {
					delete filterObj[e.target.name]
					// delete showfilterObj[e.target.name]
					setbetteryPr({ ...betteryPr, greaterThan: false, lessThan: false, Between: false })
				}
				if (e.target.id == 'checktextLS') {
					console.log(e.target.id)
					delete filterObj[e.target.name]
					// delete showfilterObj[e.target.name]
					setlastSeen({ ...last, greaterThan: false, lessThan: false })
				}
			}
		}
	}

	const handleSelectChange = (e) => {
		const stateUpdate = Object.keys(modemStart)
		setModemState(stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, RSSI: stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: "True" }), {}) })
	};

	const handleSelectChangeInstall = (e) => {
		const stateUpdate = Object.keys(modemStart)
		setinstallState(stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, Installation_Date: stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: "True" }), {}) })
	};
	const handleSelectChangeDR = (e) => {
		const stateUpdate = Object.keys(modemStart)
		setDRState(stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, Dataretrival_Date: stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: "True" }), {}) })
	};
	const handleBetteryPr = (e) => {
		const BpUpdate = Object.keys(betteryPr)
		setbetteryPr(BpUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, BAT_PER: BpUpdate.reduce((obj, data) => ({ ...obj, [e.value]: "True" }), {}) })

	};

	const handleLastSeen = (e) => {
		const lastSeenUpdate = Object.keys(last)
		setLastSeen(lastSeenUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, [e.value]: 'True' } })

	};

	const onHandaleRoles = async () => {
		console.log(userInfo)

		const data = await getRolesById(userInfo?.ROLE)
		if (data.success === true) {
			const obj = data.data.data[0]
			const Roles = { ...obj, ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0), ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5), ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2), ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3), ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1), ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4) }
			console.log(params)
			if (params?.state?.detail) {

			} else {
				ongetAllMapDataInGSM(Roles)
			}
			setpermision(Roles)
		} else {
			console.log('something Went wrong')
		}
	}

	const ongetAllMapDataInGSM = async (roles) => {
		const data = await getAllMapDataInGSMmapView(roles)
		if (data.success == true) {
			setMapInfo(data.data.data)
		} else {
			console.log('something Went wrong')
		}
	}

	const ongetAllMapDataInGSMFilter = async (info, data_info) => {
		setFilterLoader(true)
		var perameter_obj = {}
		if (info == 'true') {
			perameter_obj = { ...data_info, CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view, CATEGORY0_view: permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view, CATEGORY2_view: permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view, CATEGORY3_view: permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view, CATEGORY4_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view, CATEGORY5_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY5_view, roles: userInfo?.ROLE }
		} else {
			perameter_obj = { ...filterObj, CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view, CATEGORY0_view: permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view, CATEGORY2_view: permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view, CATEGORY3_view: permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view, CATEGORY4_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view, CATEGORY5_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY5_view, roles: userInfo?.ROLE }
		}
		const data = await getAllMapDataInGSMmapViewByFilter(perameter_obj)
		if (data.success == true) {
			setMapInfo(data.data.data)
			setIsFilter(false)
			setFilterLoader(false)

		} else {
			console.log('something Went wrong')
			setFilterLoader(false)

		}
	}
	useEffect(() => {
		onHandaleRoles()

		if (params?.state?.detail) {
			setFilterObj({ ...params?.state?.detail })
			const lookUpobj = {
				CATEGORY0: "accountuser",
				CATEGORY1: 'gauser',
				CATEGORY2: 'circleuser',
				CATEGORY3: 'divisionuser',
				CATEGORY4: 'subDivisionuser',
				CATEGORY5: 'areauser',
				Installation_Number: 'checktext1',
				Business_Number: 'checktext2', Modem_Serial_No: 'checktext3', Panel_Serial_No: 'checktext4', Unique_ID: 'checktext5', Distirbuter_ID: 'checktext6', IMEI: 'checktext7', Status: 'checktextCS', Meter_Profile: 'checktextMP', Modem_Profile: 'checktextMOP', Tags: 'checktextTA', Installation_Date: 'checktextInTS', Dataretrival_Date: 'checktextDRTS', Consumer_Type: 'Consumer_Type', RSSI: 'checktextRSSI', BAT_PER: 'checktextBP', Last_Seen: "checktextLS"
			}
			var Profile_obj = { ...Profile }
			var consumer_obj = { ...consumer }
			var modem_obj = { ...modem }
			Object.keys(params?.state?.detail).map((item, id) => {
				if (Profile.hasOwnProperty(lookUpobj[item])) {
					Profile_obj[lookUpobj[item]] = true
				}
				if (consumer.hasOwnProperty(lookUpobj[item])) {
					consumer_obj[lookUpobj[item]] = true
				}
				if (modem.hasOwnProperty(lookUpobj[item])) {
					modem_obj[lookUpobj[item]] = true
				}
				if (id + 1 == Object.keys(params?.state?.detail).length) {
					setProfile({ ...Profile_obj })
					setConsumer({ ...consumer_obj })
					setModem({ ...modem_obj })
					ongetAllMapDataInGSMFilter('true', params?.state?.detail)
				}

			})
		} else {
			setFilterObj({ ...filterObj, Status: '1' })
			setConsumer({ ...consumer, checktextCS: true })
		}
	}, []);

	const onHandalModelData = async (id) => {
		if (id && id !== '') {
			const data = await getGSMDataById(id);
			if (data.success === true) {
				const objGSM = data.data.data[0];
				if (data.data.data.length > 0) {
					setGSMData({
						...objGSM,
						ADDRESS: JSON.parse(objGSM.ADDRESS),
						CONTACT_PERSON: JSON.parse(objGSM.CONTACT_PERSON),
						INSTALLATION_DETAILS: JSON.parse(objGSM.INSTALLATION_DETAILS),
						METER_DATA: JSON.parse(objGSM.METER_DATA),
						MODEM_DATA: JSON.parse(objGSM.MODEM_DATA),
						UNIQUE_TABLE_NAME: JSON.parse(objGSM.UNIQUE_TABLE_NAME),
						HEARTBEAT_LAST_DATA: JSON.parse(objGSM.HEARTBEAT_LAST_DATA)
					})
					setModemInfoModem(true)
				}
			} else {
				console.log('something Went wrong')
				setGSMData({})
			}
		} else {
			setGSMData({})
		}
	}

	const ongetGaById = async (id) => {
		const data1 = await getGaById(id);
		if (data1.success === true) {
			const options = []
			const optionArray = data1?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter((items) => (items === data.CATEGORY1_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY1_UNIQUE_ID, label: item?.CATEGORY1_NAME })))
			setGaData(options)
		}
	};

	const ongetCircle = async (id) => {
		const data1 = await getCircleById(id);
		if (data1.success === true) {
			const options = []
			const optionArray = data1?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter((items) => (items === data.CATEGORY2_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY2_UNIQUE_ID, label: item?.CATEGORY2_NAME })))
			setCircleData(options);
		}
	};
	const ongetSubdevisionById = async (id) => {
		const data1 = await getSubdevisionById(id);
		if (data1.success === true) {
			const options = []
			const optionArray = data1?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter((items) => (items === data.CATEGORY4_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY4_UNIQUE_ID, label: item?.CATEGORY4_NAME })))
			setSubdevisionData(options);
		}
	};
	const ongetDivisionById = async (id) => {
		const data3 = await getDivisionById(id);
		if (data3.success === true) {
			const options = []
			const optionArray = data3?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter((items) => (items === data.CATEGORY3_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY3_UNIQUE_ID, label: item?.CATEGORY3_NAME })))
			setDivisionData(options);
		}
	};
	const ongetArea = async (id) => {
		const data3 = await getAreaById(id);
		if (data3.success === true) {
			const options = []
			const optionArray = data3?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'));
			optionArray.map((item) => (options.push({ value: item?.UNIQUE_ID, label: item?.CATEGORY5_NAME })))
			setAreaData(options);
		}
	};

	useEffect(() => {
		if (filterObj?.CATEGORY0?.length > 0) {
			ongetGaById(filterObj?.CATEGORY0);
			setCircleData([]);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([])
		} else {
			setGaData([]);
			setCircleData([]);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([]);
		}
	}, [filterObj.CATEGORY0]);

	useEffect(() => {
		if (filterObj?.CATEGORY1?.length > 0) {
			ongetCircle(filterObj?.CATEGORY1);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([]);

		} else {
			setCircleData([]);
			setDivisionData([]);
			setSubdevisionData([]);
			setAreaData([]);


		}
	}, [filterObj.CATEGORY1]);

	useEffect(() => {
		if (filterObj?.CATEGORY2?.length > 0) {
			ongetDivisionById(filterObj?.CATEGORY2);
			setAreaData([]);
			setSubdevisionData([]);

		} else {
			setAreaData([]);
			setDivisionData([]);
			setSubdevisionData([]);
		}
	}, [filterObj.CATEGORY2]);

	useEffect(() => {
		if (filterObj?.CATEGORY3?.length > 0) {
			ongetSubdevisionById(filterObj?.CATEGORY3);
			setAreaData([]);
		} else {
			setSubdevisionData([]);
			setAreaData([]);
		}
	}, [filterObj.CATEGORY3]);

	useEffect(() => {
		if (filterObj?.CATEGORY4?.length > 0) {
			ongetArea(filterObj?.CATEGORY4);
		} else {
			setAreaData([]);

		}
	}, [filterObj.CATEGORY4]);

	const onHandaleAccount = async () => {
		const data = await getAccount();
		if (data.success === true) {
			const options = []
			// Update 1 : set filter on account data that filter all active data
			let activedata = data?.data?.data.filter(v => v.STATUS === 1)
			const optionArray = activedata?.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'))
			console.log(optionArray);
			optionArray.map((item) => (options.push({ value: item?.UNIQUE_ID, label: item?.CATEGORY0 })))
			setAccountData(options);
			console.log('options = ', options)

			setAccountKey(data?.data?.data)
		} else {
			console.log("somthing Went wrong");
		}
	};

	useEffect(() => {
		onHandaleAccount()

	}, [permision])


	const ongetAllModem = async () => {
		const data6 = await getAllModem(true);
		if (data6.success === true) {
			const options = []
			data6?.data?.data?.map((item) => (options.push({ value: item?.MODEM_UNIQUE_ID, label: item?.MODEM_PROFILE_NAME })))
			setModemData(options);
		} else {
			console.log("somthing Went wrong");
		}
	}
	const ongetAllMeter = async () => {
		const data7 = await getAllMeter(true);
		if (data7.success === true) {
			const options = []
			data7?.data?.data?.map((item) => (options.push({ value: item?.METER_UNIQUE_ID, label: item?.METER_PROFILE_NAME })))
			setMeterData(options);
		} else {
			console.log("somthing Went wrong");
		}
	}

	const onGetTags = async () => {
		const data = await getTags()
		if (data.success === true) {
			settagsList(data.data.data.map((item) => ({ value: item?.UNIQUE_ID, label: item.TAG_NAME })))
		} else {
			console.log('something Went wrong');
		}
	}
	const customStyles = {
		menu: provided => ({ ...provided, zIndex: 9999 }),
		multiValue: (provided, { data }) => {
			return {
				...provided,
				width: 'min-content',
				backgroundColor: "#6777ef",
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "white",
		}),


	}

	const animatedComponents = makeAnimated();

	const options1 = [
		{ value: "account-adani", label: 'Adani Total Gas Limited' },
		{ value: "meMighty", label: 'MEMIGHTY' },
	]

	const consumers = [
		{ value: 'all', label: 'All' },
		{ value: '1', label: 'Active' },
		{ value: '0', label: 'InActive' },
		{ value: '-1', label: 'Pending Configurations' },
		{ value: '2', label: 'Under Maintenance' },
		{ value: '3', label: 'Stand By' }

	]

	const dataWise = [
		{ value: "Between", label: 'Is between' },
		{ value: "LessEqual", label: 'Is Less than Equel to' },
		{ value: "GreaterEqual", label: "Is Greater than Equel to" }
	]
	const lastSeenWise = [
		{ value: "LessEqual", label: 'Less than' },
		{ value: "GreaterEqual", label: 'Greater than' }
	]
	const lastSeenWiseOption = [
		{ value: "Hours", label: 'Hours' },
		{ value: "Minutes", label: 'Minutes' }
	]
	const deviceStatus = [
		{ value: 'all', label: 'All' },
		{ value: 'online', label: 'Online' },
		{ value: 'offline', label: 'Offline' },

	]
	const lastSeenWiseShort = [
		{ value: "desc", label: 'Desc' },
		{ value: "asc", label: 'Asc' }
	]
	const consumerTypeArray = [
		{ value: "Industrial", label: 'Industrial' },
		{ value: "Residential", label: 'Residential' },
		{ value: "Commercial", label: 'Commercial' },
	]

	const onSearchMultiFilterJson = (newSearchTerms, json_name) => {
		setFilterObj({ ...filterObj, [json_name]: newSearchTerms })

	};

	const onItemClearedFilterJson = (clearedIndex, json_name) => {
		// Create a new array without the cleared item
		const newSearchTerms = filterObj[json_name]?.filter((_, index) => index !== clearedIndex);
		// Update the search results based on the new search terms
		onSearchMultiFilterJson(newSearchTerms, json_name);
	};
	const onClearAllItemsFilterJson = (arr, json_name) => {
		// Reset the search results to the original data
		onSearchMultiFilterJson([], json_name);

	};
	return (
		<>
			<div id="mySidenav" class={'sidenav'} style={{ width: "550px", height: "100%", visibility: ModemInfoModem ? 'inherit' : 'hidden' }}>
				<a class="btn btn-danger closebtn tw-text-white" style={{ color: 'white' }} id="" onClick={() => (setModemInfoModem(false))}>×</a>
				<a href={`/view_existing_consumer_gsm/${GSMData?.UNIQUE_ID}`} target='_blank' class="btn btn-light ml-2" id="device_btn_info"><i className='fas fa-external-link' style={{ height: '20px' }} /></a>
				<div class="h-100 bottom-fix">
					<div class=" card-type" id="Device_Info_list">
						<div class="card-header">
							<div class="pull-left">
								<h4 id="c_consumer_name" style={{ color: "#fff" }}>{GSMData?.ADDRESS?.address_compony_name}</h4>
							</div>
							<div class="clearfix"></div>
						</div>
						<div class="card-body">
							<ul class="nav nav-pills" id="myTab3" role="tablist">
								<li class="nav-item">
									<a class="nav-link active" id="home-tab3" data-toggle="tab" href="#home3" role="tab" aria-controls="home" aria-selected="true">Modem Info</a>
								</li>
							</ul>
							<div class="tab-content" id="myTabContent">
								<div class="tab-pane fade show active" id="home3" role="tabpanel" aria-labelledby="home-tab">
									<div class="card">
										<div class="card-header">
											<h4>Consumer Info</h4>
										</div>
										<div class="card-body">
											<div class="form-group mb-0">
												<label class="f-800">Business Partner Number : </label>
												<label id="c_busniess_partner_number">{GSMData?.BUSINESS_PARTNER_NUMBER}</label>
											</div>
											<div class="form-group mb-0">
												<label class="f-800">Installation Number : </label>
												<label id="c_installtion_number">{GSMData?.INSTALLATION_NUMBER}</label>
											</div>
											<div class="form-group mb-0">
												<label class="f-800">Modem Serial Number: </label>
												<label id="c_modem_serial_number">{GSMData?.DEVICE_SERIAL_NO}</label>
											</div>
											<div class="form-group mb-0">
												<label class="f-800">Panel Serial Number : </label>
												<label id="c_panel_serial_number">{GSMData?.PANEL_SERIAL_NO}</label>
											</div>
											<div class="form-group mb-0">
												<label class="f-800">IMEI : </label>
												<label id="c_eui">{GSMData?.IMEI}</label>
											</div>
											<div class="form-group mb-0">
												<label class="f-800">Customer ID : </label>
												<label id="c_customer_id">{GSMData?.CUSTOMER_ID}</label>
											</div>
											<div class="form-group mb-0">
												<label class="f-800">Consumer Unique ID : </label>
												<label id="c_unique_id">{GSMData?.UNIQUE_ID}</label>
											</div>
											<div class="form-group mb-0">
												<label class="f-800">Consumer Status : </label>
												<label id="c_consumer_status">{GSMData?.CUSTOMER_STATUS == 1 ? 'Active' : GSMData?.CUSTOMER_STATUS == -1 ? 'Pending Configration' : GSMData?.CUSTOMER_STATUS == 0 ? 'Inactive' : GSMData?.CUSTOMER_STATUS == 2 ? 'Under Maintenance' : 'Stand By'}</label>
											</div>
										</div>
									</div>
									<div class="card">
										<div class="card-header">
											<h4>Profile Info</h4>
										</div>
										<div class="card-body">
											<div class="form-group mb-0">
												<label class="f-800">Account</label>
												<label id="c_account">{GSMData?.ACCOUNT_NAME}</label>
											</div>
											<div class="form-group mb-0">
												<label class="f-800">GA : </label>
												<label id="c_ga">{GSMData?.GA_NAME}</label>
											</div>
											<div class="form-group mb-0">
												<label class="f-800">Cicle : </label>
												<label id="c_circle">{GSMData?.CIRCLE_NAME}</label>
											</div>
											<div class="form-group mb-0">
												<label class="f-800">Division : </label>
												<label id="c_division">{GSMData?.DIVISION_NAME}</label>
											</div>
											<div class="form-group mb-0">
												<label class="f-800">Sub Division : </label>
												<label id="c_sub_division">{GSMData?.SUBDIVISION_NAME}</label>
											</div>
											<div class="form-group mb-0">
												<label class="f-800">Area : </label>
												<label id="c_area">{GSMData?.AREA_NAME}</label>
											</div>
										</div>
									</div>
									<div class="card">
										<div class="card-header">
											<h4>Modem Info</h4>
										</div>
										<div class="card-body">

											<div class="form-group mb-0">
												<label class="f-800">RSSI: </label>
												{GSMData?.HEARTBEAT_LAST_DATA?.GSM_RSSI >= 2 && GSMData?.HEARTBEAT_LAST_DATA?.GSM_RSSI <= 9 ? (
													<>
														<img src={signal2} style={{ height: '20px', width: "26px", border: '0', boxShadow: 'none', borderRadius: 'none' }} />
														<span className="text-center">{GSMData?.HEARTBEAT_LAST_DATA?.GSM_RSSI} </span>
													</>
												) : GSMData?.HEARTBEAT_LAST_DATA?.GSM_RSSI >= 10 && GSMData?.HEARTBEAT_LAST_DATA?.MMD_INFO_GSM_RSSI <= 14 ? (
													<>
														<img src={signal3} style={{ height: '20px', width: "26px", border: '0', borderRadius: '0', boxShadow: 'none' }} />
														<span className="text-center">{GSMData?.HEARTBEAT_LAST_DATA?.GSM_RSSI} </span>
													</>
												) : GSMData?.HEARTBEAT_LAST_DATA?.GSM_RSSI >= 15 && GSMData?.HEARTBEAT_LAST_DATA?.GSM_RSSI <= 19 ? (
													<>
														<img src={signal4} style={{ height: '20px', width: "26px", border: '0', borderRadius: '0', boxShadow: 'none' }} />
														<span className="text-center">{GSMData?.HEARTBEAT_LAST_DATA?.GSM_RSSI} </span>
													</>
												) : GSMData?.HEARTBEAT_LAST_DATA?.GSM_RSSI >= 20 && GSMData?.HEARTBEAT_LAST_DATA?.GSM_RSSI <= 31 ? (
													<>
														<img src={signal5} style={{ height: '20px', width: "26px", border: '0', borderRadius: '0', boxShadow: 'none' }} />
														<span className="text-center">{GSMData?.HEARTBEAT_LAST_DATA?.GSM_RSSI} </span>
													</>
												) : (
													"-"
												)}
												{/* <label id="c_rssi">24 (Excellent)</label> */}
											</div>
											<div class="form-group mb-0">
												<label class="f-800">Last seen : </label>
												<label id="c_last_seen">{GSMData?.HEARTBEAT_TS ? moment.utc(GSMData.HEARTBEAT_TS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</label>
											</div>
											<div class="form-group mb-0">
												<label class="f-800">Latitude : </label>
												<label id="c_latitude">{GSMData?.LATITUDE}</label>
											</div>
											<div class="form-group mb-0">
												<label class="f-800">Longitude : </label>
												<label id="c_longitude">{GSMData?.LONGITUDE}</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="mySidenav" class={'map_filter'} style={{ width: "760px", height: "100%", visibility: isFilter ? 'inherit' : 'hidden' }}>
				<a class="btn btn-danger closebtn tw-text-white" style={{ color: 'white' }} id="" onClick={() => {
					setIsFilter(false)
				}}>×</a>
				<div class="h-100 bottom-fix">
					<fieldset className="customLegend">
						<legend >Group Wise Filter</legend>
						<div className="row" style={{
							marginLeft: 10,
							marginRight: 10

						}} >
							<div className="col-12">
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="accountuser" name="CATEGORY0" className="custom-control-input " value="account" checked={Profile.accountuser} onChange={handleChangeS} />
										<label className="custom-control-label" for="accountuser">CATEGORY0</label>
									</div>
									{Profile.accountuser && (<div className="form-group col-md-6 accountgrp" style={{ marginTop: -10 }} >
										<Select value={AccounData.filter((item) => filterObj?.CATEGORY0?.includes(item.value)) || []} placeholder='Select account...' styles={customStyles} components={animatedComponents} name='account' isMulti options={AccounData} onChange={(e) => {
											setFilterObj({ ...filterObj, CATEGORY0: e.map((item) => (item.value)) })
										}} />
									</div>)}
								</div>

								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="gauser" name="CATEGORY1" className="custom-control-input" value="ga" checked={Profile.gauser} onChange={handleChangeS} />
										<label className="custom-control-label" for="gauser">CATEGORY1</label>
									</div>
									{Profile.gauser && (<div className="form-group col-md-6 gagrp" style={{ marginTop: -10 }}>
										<Select value={GaData.filter((item) => filterObj?.CATEGORY1?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={GaData} onChange={(e) => {
											setFilterObj({ ...filterObj, CATEGORY1: e.map((item) => (item.value)) })
										}} />
									</div>)}
								</div>

								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="circleuser" name="CATEGORY2" className="custom-control-input" checked={Profile.circleuser} value="circle" onChange={handleChangeS} />
										<label className="custom-control-label" for="circleuser">CATEGORY2</label>
									</div>
									{Profile.circleuser && (<div className="form-group col-md-6 circlegrp" style={{ marginTop: -10 }}>
										<Select value={CircleData.filter((item) => filterObj?.CATEGORY2?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={CircleData} onChange={(e) => {
											setFilterObj({ ...filterObj, CATEGORY2: e.map((item) => (item.value)) })
										}} />
									</div>)}
								</div>

								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="divisionuser" name="CATEGORY3" className="custom-control-input" checked={Profile.divisionuser} value="division" onChange={handleChangeS} />
										<label className="custom-control-label" for="divisionuser">CATEGORY3</label>
									</div>
									{Profile.divisionuser && (<div className="form-group col-md-6 divisiongrp" style={{ marginTop: -10 }}>
										<Select value={DivisionData.filter((item) => filterObj?.CATEGORY3?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={DivisionData} onChange={(e) => {
											setFilterObj({ ...filterObj, CATEGORY3: e.map((item) => (item.value)) })
										}} />

									</div>)}
								</div>

								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="subDivisionuser" name="CATEGORY4" className="custom-control-input" checked={Profile.subDivisionuser} value="subdivision" onChange={handleChangeS} />
										<label className="custom-control-label " for="subDivisionuser">CATEGORY4</label>
									</div>
									{Profile.subDivisionuser && (<div className="form-group col-md-6 subdivisiongrp" style={{ marginTop: -10 }}>
										<Select value={SubdevisionData.filter((item) => filterObj?.CATEGORY4?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={SubdevisionData} onChange={(e) => {
											setFilterObj({ ...filterObj, CATEGORY4: e.map((item) => (item.value)) })
										}} />

									</div>)}
								</div>

								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="areauser" name="CATEGORY5" className="custom-control-input" checked={Profile.areauser} value="area" onChange={handleChangeS} />
										<label className="custom-control-label" for="areauser">CATEGORY5</label>
									</div>
									{Profile.areauser && (<div className="form-group col-md-6 areagrp" style={{ marginTop: -10 }}>
										<Select value={AreaData.filter((item) => filterObj?.CATEGORY5?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={AreaData} onChange={(e) => {
											setFilterObj({ ...filterObj, CATEGORY5: e.map((item) => (item.value)) })
										}} />

									</div>)}
								</div>
							</div>

						</div>
					</fieldset>
					<fieldset className="customLegend ">
						<legend >Consumer Info Wise Filter</legend>
						<div className="row" style={{ marginLeft: 10 }}>
							<div className="col-12">
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="checktext1" name="Installation_Number" className="custom-control-input" checked={consumer.checktext1} value="installnumber" onChange={handleChangeS} />
										<label className="custom-control-label" for="checktext1">Installation Number</label>
									</div>
									{consumer.checktext1 && <MultiInput
										placeholder="search and enter..."
										onChange={(arr) => onSearchMultiFilterJson(arr, "Installation_Number")}
										maxItems={null} // Allow any number of items
										onClear={(arr) => onItemClearedFilterJson(arr, "Installation_Number")}
										onClearAll={(arr) => onClearAllItemsFilterJson(arr, "Installation_Number")}
									/>}

								</div>
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="checktext2" name="Business_Number" className="custom-control-input" value="businessnu" checked={consumer.checktext2} onChange={handleChangeS} />
										<label className="custom-control-label" for="checktext2">Business Partner Number</label>
									</div>
									{consumer.checktext2 && <MultiInput
										placeholder="search and enter..."
										onChange={(arr) => onSearchMultiFilterJson(arr, "Business_Number")}
										maxItems={null} // Allow any number of items
										onClear={(arr) => onItemClearedFilterJson(arr, "Business_Number")}
										onClearAll={(arr) => onClearAllItemsFilterJson(arr, "Business_Number")}
									/>}

								</div>
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="checktext3" name="Modem_Serial_No" className="custom-control-input" value="modemserial" checked={consumer.checktext3} onChange={handleChangeS} />
										<label className="custom-control-label" for="checktext3">Modem Serial No</label>
									</div>
									{consumer.checktext3 && <MultiInput
										placeholder="search and enter..."
										onChange={(arr) => onSearchMultiFilterJson(arr, "Modem_Serial_No")}
										maxItems={null} // Allow any number of items
										onClear={(arr) => onItemClearedFilterJson(arr, "Modem_Serial_No")}
										onClearAll={(arr) => onClearAllItemsFilterJson(arr, "Modem_Serial_No")}
									/>}

								</div>
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="checktext4" name="Panel_Serial_No" className="custom-control-input" value="panelserno" checked={consumer.checktext4} onChange={handleChangeS} />
										<label className="custom-control-label" for="checktext4">Panel Serial No</label>
									</div>
									{consumer.checktext4 && <MultiInput
										placeholder="search and enter..."
										onChange={(arr) => onSearchMultiFilterJson(arr, "Panel_Serial_No")}
										maxItems={null} // Allow any number of items
										onClear={(arr) => onItemClearedFilterJson(arr, "Panel_Serial_No")}
										onClearAll={(arr) => onClearAllItemsFilterJson(arr, "Panel_Serial_No")}
									/>}

								</div>
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="checktext5" name="Unique_ID" className="custom-control-input" value="cnuniqueid" checked={consumer.checktext5} onChange={handleChangeS} />
										<label className="custom-control-label" for="checktext5">Consumer Unique Id</label>
									</div>
									{consumer.checktext5 && <MultiInput
										placeholder="search and enter..."
										onChange={(arr) => onSearchMultiFilterJson(arr, "Unique_ID")}
										maxItems={null} // Allow any number of items
										onClear={(arr) => onItemClearedFilterJson(arr, "Unique_ID")}
										onClearAll={(arr) => onClearAllItemsFilterJson(arr, "Unique_ID")}
										value={filterObj?.Unique_ID}
									/>}
								</div>
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="checktext6" name="Distirbuter_ID" className="custom-control-input" value="distid" checked={consumer.checktext6} onChange={handleChangeS} />
										<label className="custom-control-label" for="checktext6">Customer Id</label>
									</div>
									{consumer.checktext6 && <MultiInput
										placeholder="search and enter..."
										onChange={(arr) => onSearchMultiFilterJson(arr, "Distirbuter_ID")}
										maxItems={null} // Allow any number of items
										onClear={(arr) => onItemClearedFilterJson(arr, "Distirbuter_ID")}
										onClearAll={(arr) => onClearAllItemsFilterJson(arr, "Distirbuter_ID")}
									/>}

								</div>
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="checktext7" name="IMEI" className="custom-control-input" value="allimei" checked={consumer.checktext7} onChange={handleChangeS} />
										<label className="custom-control-label" for="checktext7">imei</label>
									</div>
									{consumer.checktext7 && <MultiInput
										placeholder="search and enter..."
										onChange={(arr) => onSearchMultiFilterJson(arr, "IMEI")}
										maxItems={null} // Allow any number of items
										onClear={(arr) => onItemClearedFilterJson(arr, "IMEI")}
										onClearAll={(arr) => onClearAllItemsFilterJson(arr, "IMEI")}
									/>}

								</div>
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="checktextCS" name="Status" className="custom-control-input" value="filterstatus" checked={consumer.checktextCS} onChange={handleChangeS} />
										<label className="custom-control-label" for="checktextCS">Consumer Status</label>
									</div>
									{consumer.checktextCS && (<div className="form-group col-md-3 checktextCS filterstatus" style={{ marginTop: -10 }}>
										<Select styles={customStyles} components={animatedComponents} name='Status' options={consumers} onChange={(e) => {
											if (!e && e?.value != 1 && e?.value != 0) {
												delete filterObj["SubStatus"]
											}
											setFilterObj({ ...filterObj, Status: e?.value || '' })
										}} isClearable value={[filterObj?.Status ? consumers.filter((item) => (item?.value == filterObj?.Status))[0] : []]} />

									</div>)}
									{consumer.checktextCS && filterObj?.Status && filterObj?.Status == 1 && <div className="form-group col-md-3 checktextCS filterstatus" style={{ marginTop: -10 }}>
										<Select styles={customStyles} components={animatedComponents} name='Status' options={[{ value: '1', label: 'Live' }, { value: '2', label: 'Temporary Disconnection' }]} onChange={(e) => {
											setFilterObj({ ...filterObj, SubStatus: e?.value || '' })
										}} isClearable />
									</div>}
									{consumer.checktextCS && filterObj?.Status && filterObj?.Status == 0 && <div className="form-group col-md-3 checktextCS filterstatus" style={{ marginTop: -10 }}>
										<Select styles={customStyles} components={animatedComponents} name='Status' options={[{ value: '3', label: 'Permanent Disconnection' }]} onChange={(e) => {
											setFilterObj({ ...filterObj, SubStatus: e?.value || '' })
										}} isClearable />
									</div>}
								</div>
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="checktextMP" name="Meter_Profile" className="custom-control-input" value="meterprofile" checked={consumer.checktextMP} onChange={handleChangeS} />
										<label className="custom-control-label" for="checktextMP">EVC Profile</label>
									</div>
									{consumer.checktextMP && (<div className="form-group col-md-6 checktextMP meterprofile" style={{ marginTop: -10 }}>
										<Select styles={customStyles} components={animatedComponents} name='Meter_Profile' options={MeterData} isMulti onChange={(e) => {
											setFilterObj({ ...filterObj, Meter_Profile: e.map((item) => (item.value)) })
										}} />

									</div>)}
								</div>
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="checktextMOP" name="Modem_Profile" className="custom-control-input" value="modemprofile" checked={consumer.checktextMOP} onChange={handleChangeS} />
										<label className="custom-control-label" for="checktextMOP">Modem Profile</label>
									</div>
									{consumer.checktextMOP && (<div className="form-group col-md-6 checktextMOP modemprofile" style={{ marginTop: -10 }}>
										<Select styles={customStyles} components={animatedComponents} name='Modem_Profile' options={ModemData} isMulti onChange={(e) => {
											setFilterObj({ ...filterObj, Modem_Profile: e.map((item) => (item.value)) })
										}} />

									</div>)}
								</div>
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="checktextTA" name="Tags" className="custom-control-input" value="tagsvalue" checked={consumer.checktextTA} onChange={handleChangeS} />
										<label className="custom-control-label" for="checktextTA">Tags</label>
									</div>
									{consumer.checktextTA && (<div className="form-group col-md-6 checktextTA tagsvalue" style={{ marginTop: -10 }}>
										<Select styles={customStyles} name='Tags' isMulti options={tagsList} onChange={(e) => {
											setFilterObj({ ...filterObj, Tags: e.map((item) => (item.value)) })
										}
										} />

									</div>)}

								</div>
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="checktextInTS" name="Installation_Date" className="custom-control-input" value="tagsvalue" checked={consumer.checktextInTS} onChange={handleChangeS} />
										<label className="custom-control-label" for="checktextInTS">Installation Date</label>
									</div>
									{consumer.checktextInTS && (<div className="form-group col-md-3 checktextRSSI length rssival" style={{ marginTop: -10 }}>
										<Select components={animatedComponents} placeholder="Select Condition..." name='RSSI' options={dataWise}
											styles={customStyles} onChange={handleSelectChangeInstall} />

									</div>)}
									{consumer.checktextInTS && installState?.Between && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
										<Space direction="horizontal">
											<div className="d-flex flex-column">Start In</div>
											<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Installation_Date: { ...filterObj.Installation_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
												disabledDate={(current) => {
													return current.isAfter(moment())
												}} />
											<div className="d-flex flex-column" >End In</div>
											<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Installation_Date: { ...filterObj.Installation_Date, End_Date: dateString } })} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
												return current.isAfter(moment())
											}} />
										</Space>

									</div>)}
									{consumer.checktextInTS && installState?.LessEqual && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
										<Space direction="horizontal">
											<div className="d-flex flex-column">Date</div>
											<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Installation_Date: { ...filterObj.Installation_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
												disabledDate={(current) => {
													return current.isAfter(moment())
												}} />
										</Space>

									</div>)}
									{consumer.checktextInTS && installState?.GreaterEqual && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
										<Space direction="horizontal">
											<div className="d-flex flex-column">Date</div>
											<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Installation_Date: { ...filterObj.Installation_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
												disabledDate={(current) => {
													return current.isAfter(moment())
												}} />
										</Space>

									</div>)}
								</div>
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="checktextDRTS" name="Dataretrival_Date" className="custom-control-input" value="tagsvalue" checked={consumer.checktextDRTS} onChange={handleChangeS} />
										<label className="custom-control-label" for="checktextDRTS">Data Retrival Date</label>
									</div>
									{consumer.checktextDRTS && (<div className="form-group col-md-3 checktextRSSI length rssival" style={{ marginTop: -10 }}>
										<Select components={animatedComponents} placeholder="Select Condition..." name='RSSI' options={dataWise}
											styles={customStyles} onChange={handleSelectChangeDR} />

									</div>)}
									{consumer.checktextDRTS && DRState?.Between && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
										<Space direction="horizontal">
											<div className="d-flex flex-column">Start In</div>
											<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Dataretrival_Date: { ...filterObj.Dataretrival_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
												disabledDate={(current) => {
													return current.isAfter(moment())
												}} />
											<div className="d-flex flex-column" >End In</div>
											<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Dataretrival_Date: { ...filterObj.Dataretrival_Date, End_Date: dateString } })} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
												return current.isAfter(moment())
											}} />
										</Space>

									</div>)}
									{consumer.checktextDRTS && DRState?.LessEqual && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
										<Space direction="horizontal">
											<div className="d-flex flex-column">Date</div>
											<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Dataretrival_Date: { ...filterObj.Dataretrival_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
												disabledDate={(current) => {
													return current.isAfter(moment())
												}} />
										</Space>

									</div>)}
									{consumer.checktextDRTS && DRState?.GreaterEqual && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
										<Space direction="horizontal">
											<div className="d-flex flex-column">Date</div>
											<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Dataretrival_Date: { ...filterObj.Dataretrival_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
												disabledDate={(current) => {
													return current.isAfter(moment())
												}} />
										</Space>

									</div>)}
								</div>
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="Consumer_Type" name="Consumer_Type" className="custom-control-input" value="area" checked={consumer.Consumer_Type} onChange={handleChangeS} />
										<label className="custom-control-label" for="Consumer_Type">Consumer Type</label>
									</div>
									{consumer?.Consumer_Type && <div class="form-group col-md-3">
										<Select styles={customStyles} components={animatedComponents} name='Consumer_Type' isMulti options={consumerTypeArray} onChange={(e) => {
											setFilterObj({ ...filterObj, Consumer_Type: e.map((item) => (item.value)) })
										}} />
										{/* <select
															class="form-control form-select modemtypename anothermodemname"
															name="cust_sal"
															id="filter_course"
															required=""
															onChange={(e) => (setFilterObj({ ...filterObj, Consumertype: e.target.value }))}
															value={filterObj.Consumertype}
														>
															<option value="">Select Consumer Type </option>
															<option value="Residential">Residential</option>
															<option value="Industrial">Industrial</option>
															<option value="Commercial">Commercial</option>
														</select> */}
									</div>}
								</div>
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="deviceStatus" name="deviceStatus" className="custom-control-input" value="deviceStatus" checked={consumer.deviceStatus} onChange={handleChangeS} />
										<label className="custom-control-label" for="deviceStatus">Device Status</label>
									</div>
									{consumer.deviceStatus && (<div className="form-group col-md-3 deviceStatus filterstatus" style={{ marginTop: -10 }}>
										<Select styles={customStyles} components={animatedComponents} name='filterstatus' options={deviceStatus} onChange={(e) => {
											if (!e && e?.value != 1 && e?.value != 0) {
												delete filterObj["deviceStatus"]
											}
											setFilterObj({ ...filterObj, deviceStatus: e?.value || '' })
											// setShowFilterObj({ ...showfilterObj, deviceStatus: e?.label || '' })
										}} isClearable value={[filterObj?.deviceStatus ? deviceStatus.filter((item) => (item?.value == filterObj?.deviceStatus))[0] : []]} />

									</div>)}
								</div>
							</div>
						</div>
					</fieldset>

					<fieldset className="customLegend ">
						<legend >Modem Data Wise Filter</legend>
						<div className="row" style={{ marginLeft: 10 }}>
							<div className="col-12">
								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="checktextRSSI" name="RSSI" className="custom-control-input" value="rssival" checked={modem.checktextRSSI} onChange={handleChangeS} />
										<label className="custom-control-label" for="checktextRSSI">RSSI</label>
									</div>
									{modem.checktextRSSI && (<div className="form-group col-md-3 checktextRSSI length rssival" style={{ marginTop: -10 }}>
										<Select components={animatedComponents} placeholder="Select Condition..." name='RSSI' options={dataWise}
											styles={customStyles} onChange={handleSelectChange} />

									</div>)}
									{modemStart.Between && modem.checktextRSSI && (<div className="form-group col-md-3 checktext14" style={{ marginTop: -10 }}>
										<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, From: e.target.value } }) }} />
									</div>)}
									{modemStart.Between && modem.checktextRSSI && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
										<input type="text" className="form-control" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, To: e.target.value } }) }} />
									</div>)}
									{modemStart.LessEqual && modem.checktextRSSI && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
										<input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, Value: e.target.value } }) }} />
									</div>)}
									{modemStart.GreaterEqual && modem.checktextRSSI && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
										<input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, Value: e.target.value } }) }} />
									</div>)}
								</div>

								<div className="form-row">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" id="checktextLS" name="Last_Seen" className="custom-control-input" value="lastssen" checked={modem.checktextLS} onChange={handleChangeS} />
										<label className="custom-control-label" for="checktextLS">Last seen</label>
									</div>
									{modem.checktextLS && (<div className="form-group col-md-3 checktextLS lastssen" style={{ marginTop: -10 }}>
										<Select components={animatedComponents} styles={customStyles} name='Last_Seen' options={lastSeenWiseOption} placeholder="Select Condition..."
											onChange={(e) => { setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, Last_Seen: e.value } }) }} />
									</div>)}
									{modem.checktextLS && (<div className="form-group col-md-3 checktextLS lastssen" style={{ marginTop: -10 }}>
										<Select components={animatedComponents} styles={customStyles} name='Last_Seen' options={lastSeenWise} placeholder="Select Condition..."
											onChange={handleLastSeen} />
									</div>)}

									{last.LessEqual && modem.checktextLS && (<div className="form-group col-md-3 checktext31" style={{ marginTop: -10 }}>
										<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="" onChange={(e) => { setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, Value: e.target.value } }) }} />
									</div>)}

									{last.GreaterEqual && modem.checktextLS && (<div className="form-group col-md-3 checktext31" style={{ marginTop: -10 }}>
										<input type="text" className="form-control" name="filter_hours" id="filter_greater_hours" placeholder="" onChange={(e) => { setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, Value: e.target.value } }) }} />
									</div>)}
								</div>



							</div>
						</div>
					</fieldset>
					<div className="text-left mt-2 ml-2">
						<input type="submit" className={`btn btn-danger mr-2 ${FilterLoader == true ? 'btn-progress disabled' : ''}`} value="Filter" name="filter_customer_form" onClick={ongetAllMapDataInGSMFilter} />
						<Link className="btn btn-light text-dark" onClick={() => { history.replace({ pathname: '/gsm-map-view', state: {} }) }}>Reset</Link>
					</div>
				</div>
			</div>
			<div><div className='btn btn-primary filter-btn' onClick={() => {
				setIsFilter(true)
				ongetAllMeter()
				ongetAllModem()
				onGetTags()
			}}><i className="fa fa-filter text-white"></i></div></div>
			<div class="map-inner" style={{ height: '100vh', width: "100%" }}>
				{/*dev- AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8 */}
				{/*server- AIzaSyDG0jH5rr6z6KU0Zo_ilALl5ZXUP5NSPSs */}
				{/* <ArticlesMap
					googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDG0jH5rr6z6KU0Zo_ilALl5ZXUP5NSPSs&v=3.exp&libraries=geometry,drawing,places"
					loadingElement={<div style={{ height: '100%', width: '100%' }} />}
					containerElement={<div style={{ height: '100%', width: '100%' }} />}
					mapElement={<div style={{ height: '100%', width: '100%' }} />}
					onClick={(ev) => { console.log(ev) }}
					mapInfo={mapInfo}
					GSMID={GSMID}
					//update 1.2 pass defaultDate
					defaultDate={defaultDate}
					lastseen={lastseen}
					GSMData={GSMData}
					lastseenIndate={lastseenIndate}
					setModemInfoModem={(i) => onHandalModelData(i)}
					ModemInfoModem={(i) => setModemInfoModem(i)}
					curentpos={{ lat: 21.727293496271507, lng: 75.5823933220894 }}
				/> */}
				{/* // change map */}
				<ArticlesMap
					mapInfo={mapInfo}
					GSMID={GSMID}
					lastseen={lastseen}
					GSMData={GSMData}
					defaultDate={defaultDate}
					lastseenIndate={lastseenIndate}
					setModemInfoModem={(i) => onHandalModelData(i)}
					ModemInfoModem={(i) => setModemInfoModem(i)}
					curentpos={{ lat: 21.727293496271507, lng: 75.5823933220894 }}
					containerElement={<div style={{ height: '100%', width: '100%' }} />}
					loadingElement={<div style={{ height: '100%', width: '100%' }} />}
					mapElement={<div style={{ height: '100%', width: '100%' }} />}
				/>
				{/* <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319" width="100%" height="300" frameborder="0"></iframe> */}
			</div>
		</>
	)

	//       </div>
	//    </div>
	// </div>
	//         <div class="map-inner" style={{ height: '100vh', width: "100%" }}>
	// 												{/*dev- AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8 */}
	// 												{/*server- AIzaSyDG0jH5rr6z6KU0Zo_ilALl5ZXUP5NSPSs */}
	// 												<ArticlesMap
	// 													googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8&v=3.exp&libraries=geometry,drawing,places"
	// 													loadingElement={<div style={{ height: '100%', width: '100%' }} />}
	// 													containerElement={<div style={{ height: '100%', width: '100%' }} />}
	// 													mapElement={<div style={{ height: '100%', width: '100%' }} />}
	// 													onClick={(ev) => { console.log(ev) }}
	// 													mapInfo={mapInfo}
	//                                                     GSMID={GSMID}
	//                                                     setModemInfoModem={(i)=>onHandalModelData(i)}
	//                                                     ModemInfoModem={(i)=>setModemInfoModem(i)}
	// 													curentpos={{ lat: 21.727293496271507, lng: 75.5823933220894 }}
	// 												/>
	// 												{/* <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319" width="100%" height="300" frameborder="0"></iframe> */}
	// 											</div>
	//     </>
	//   )

}

// change map
export const ArticlesMap = (props) => {

	{/*dev- AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8 */ }
	{/*server- AIzaSyDG0jH5rr6z6KU0Zo_ilALl5ZXUP5NSPSs */ }
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyDG0jH5rr6z6KU0Zo_ilALl5ZXUP5NSPSs',
		libraries: ['geometry', 'drawing', 'places'],
	});

	const [jobDetail, setJobDetail] = useState({ activeMarker: {}, isOpen: false, Info: {} });
	const [center, setcenterPoint] = useState({ lat: props.curentpos.lat, lng: props.curentpos.lng })

	const onMarkerClicked = (data, marker) => {
		setcenterPoint({ lat: data.latLng.lat(), lng: data.latLng.lng() })
		setJobDetail({
			activeMarker: { lat: data.latLng.lat(), lng: data.latLng.lng() },
			isOpen: true,
			Info: marker,
		});
	};

	useEffect(() => {
		if (props.GSMData) {
			const Infoarray = props?.mapInfo?.filter((item) => (item?.UNIQUE_ID == props?.GSMData?.UNIQUE_ID));
			const data = Infoarray[0]
			setJobDetail({
				activeMarker: { lat: parseFloat(props?.GSMData?.LATITUDE), lng: parseFloat(props?.GSMData?.LONGITUDE) },
				isOpen: true,
				Info: props?.GSMData,
			});
		}
	}, [props.GSMData]);

	const onToggleOpen = () => {
		setJobDetail({ ...jobDetail, isOpen: false });
		props.ModemInfoModem(false);
	};

	const onOpenInfoModel = (id) => {
		props.setModemInfoModem(id);
	};

	function getSecondsTodayBydate(ts) {
		//update 1.3 use defaultDate
		var now = props.defaultDate;
		var then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD-MM-YYYY HH:mm:ss");
		console.log(moment(now, "DD-MM-YYYY HH:mm:ss").diff(moment(then, "DD-MM-YYYY HH:mm:ss"), 'hours'));
		return moment(now, "DD-MM-YYYY HH:mm:ss").diff(moment(then, "DD-MM-YYYY HH:mm:ss"), 'hours');
	}

	if (!isLoaded) return <GoogleLoader bodyClassName={'tw-h-[100dvh]'} />;

	return (
		<GoogleMap
			mapContainerStyle={props.containerElement.props.style}
			zoom={5}
			center={center}
			onClick={(e) => { console.log("e.latLng.lat()", e.latLng.lat(), "e.latLng.lng()", e.latLng.lng()) }}
		>
			{props?.mapInfo?.map((item, id) => (
				<React.Fragment key={id}>
					<MarkerF
						icon={{
							url: props?.lastseen ? getSecondsTodayBydate(item?.HEARTBEAT_TS) < props?.lastseen ? mapicon : mapRedicon : props?.lastseenIndate.start && props?.lastseenIndate.end ? moment(props?.lastseenIndate.start, 'DD-MM-YYYY').format('DD-MM-YYYY') <= moment.utc(item.HEARTBEAT_TS).add(-moment().utcOffset(), "m").format('DD-MM-YYYY') && moment(props?.lastseenIndate.end, 'DD-MM-YYYY').format('DD-MM-YYYY') >= moment.utc(item.HEARTBEAT_TS).add(-moment().utcOffset(), "m").format('DD-MM-YYYY') ? mapicon : mapRedicon : getSecondsTodayBydate(item?.HEARTBEAT_TS) < 24 ? mapicon : mapRedicon,
							scaledSize: new window.google.maps.Size(25, 25)
						}}
						options={{ optimized: false }}
						onMouseOver={(data) => onMarkerClicked(data, item)}
						onClick={() => (onOpenInfoModel(item?.UNIQUE_ID))}
						position={{ lat: item?.LATITUDE ? parseFloat(item?.LATITUDE) : null, lng: item?.LONGITUDE ? parseFloat(item?.LONGITUDE) : null }}
					/>

					

					{/* {JSON.parse(item?.assets || '[]').length > 0 && Object.entries(JSON.parse(item?.assets || '[]')[0])?.every(([key, value]) => (value != null)) && JSON.parse(item?.assets || '[]')?.map((data, idx) => (
						<MarkerF
							key={`asset-${id}-${idx}`}
							icon={{
								url: mapCircle,
								scaledSize: new window.google.maps.Size(25, 25)
							}}
							options={{ optimized: false }}
							position={{ lat: data?.latitude ? parseFloat(data?.latitude) : "", lng: data?.longitude ? parseFloat(data?.longitude) : "" }}
						/>
					))} */}
				</React.Fragment>
			))}

			{jobDetail?.activeMarker?.lat && jobDetail?.isOpen && (
				<InfoWindowF
					position={{ lat: jobDetail?.activeMarker?.lat, lng: jobDetail?.activeMarker?.lng }}
					onCloseClick={onToggleOpen}
					options={{
						pixelOffset: new window.google.maps.Size(0, -15)
					}}
				>
					<div className="detailCard">
						<div className="d-flex main-div">
							<div className="d-inline-block">
								<h1 className="font-20">{jobDetail?.Info?.address_compony_name || jobDetail?.Info?.ADDRESS?.address_compony_name}</h1>
								<div className="form-group mb-0"><label className="f-800">Business Partner Number : </label><label id="">{jobDetail?.Info?.BUSINESS_PARTNER_NUMBER}</label></div>
								<div className="form-group mb-0"><label className="f-800">Installation Number : </label><label id="">{jobDetail?.Info?.INSTALLATION_NUMBER}</label></div>
								<div className="form-group mb-0"><label className="f-800">Customer ID : </label><label id="">{jobDetail?.Info?.CUSTOMER_ID}</label></div>
								<div className="form-group mb-0"><label className="f-800">Modem Serial Number : </label><label id="">{jobDetail?.Info?.DEVICE_SERIAL_NO}</label></div>
							</div>
						</div>
					</div>
				</InfoWindowF>
			)}
		</GoogleMap>
	);
};

// export const ArticlesMap = withScriptjs(withGoogleMap((props) => {
// 	const [jobDetail, setJobDetail] = useState({ activeMarker: {}, isOpen: false, Info: {} })
// 	const onMarkerClicked = (data, marker) => {
// 		setJobDetail({
// 			activeMarker: { lat: data?.latLng?.lat(), lng: data?.latLng?.lng() },
// 			isOpen: true,
// 			Info: marker
// 		})
// 	}
// 	useEffect(() => {
// 		if (props.GSMData) {
// 			const Infoarray = props?.mapInfo?.filter((item) => (item?.UNIQUE_ID == props?.GSMData?.UNIQUE_ID))
// 			console.log('Infoarray, props?.mapInfo', Infoarray, props?.GSMData);

// 			const data = Infoarray[0]
// 			setJobDetail({
// 				activeMarker: { lat: parseFloat(props?.GSMData?.LATITUDE), lng: parseFloat(props?.GSMData?.LONGITUDE) },
// 				isOpen: true,
// 				Info: props?.GSMData
// 			})

// 		}
// 	}, [props.GSMData])


// 	const onToggleOpen = () => {
// 		setJobDetail({ ...jobDetail, isOpen: false })
// 		props.ModemInfoModem(false)
// 	}
// 	const onOpenInfoModel = (id) => {
// 		props.setModemInfoModem(id)
// 	}
// 	function getSecondsTodayBydate(ts) {

// 		//update 1.3 use defaultDate
// 		var now = props.defaultDate;
// 		var then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
// 		return moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"), 'hours')
// 	}

// 	return (<>
// 		<GoogleMap
// 			defaultZoom={5}
// 			defaultCenter={{ lat: props.curentpos.lat, lng: props.curentpos.lng }}
// 			onClick={(e) => { console.log("e.latLng.lat()", e.latLng.lat(), "e.latLng.lng()", e.latLng.lng()) }}
// 		>
// 			{props?.mapInfo?.map((item, id) => (<><Marker
// 				defaultZoomOnClick={4}
// 				icon={{
// 					url: props?.lastseen ? getSecondsTodayBydate(item?.HEARTBEAT_TS) < props?.lastseen ? mapicon : mapRedicon : props?.lastseenIndate.start && props?.lastseenIndate.end ? moment(props?.lastseenIndate.start, 'DD-MM-YYYY').format('DD-MM-YYYY') <= moment.utc(item.HEARTBEAT_TS).add(-moment().utcOffset(), "m").format('DD-MM-YYYY') && moment(props?.lastseenIndate.end, 'DD-MM-YYYY').format('DD-MM-YYYY') >= moment.utc(item.HEARTBEAT_TS).add(-moment().utcOffset(), "m").format('DD-MM-YYYY') ? mapicon : mapRedicon : getSecondsTodayBydate(item?.HEARTBEAT_TS) < 24 ? mapicon : mapRedicon,
// 					scaledSize: new window.google.maps.Size(25, 25)
// 				}}
// 				defaultOptions={{ optimized: false }}
// 				onMouseOver={(data) => onMarkerClicked(data, item)}
// 				key={id}
// 				onClick={() => (onOpenInfoModel(item?.UNIQUE_ID))}
// 				position={{ lat: item?.LATITUDE ? parseFloat(item?.LATITUDE) : '', lng: item?.LONGITUDE ? parseFloat(item?.LONGITUDE) : '' }}
// 			/></>))}
// 		</GoogleMap>
// 		{jobDetail?.activeMarker?.lat && jobDetail?.isOpen &&
// 			<InfoWindow position={{ lat: jobDetail?.activeMarker?.lat, lng: jobDetail?.activeMarker?.lng }} onCloseClick={onToggleOpen} options={{
// 				pixelOffset: new window.google.maps.Size(
// 					0, -15
// 				)
// 			}} >
// 				<div className="detailCard">
// 					<div className="d-flex main-div">
// 						<div className="d-inline-block">
// 							<h1 className="font-20">{jobDetail?.Info?.address_compony_name || jobDetail?.Info?.ADDRESS?.address_compony_name}
// 							</h1>
// 							<div class="form-group mb-0"><label class="f-800">Business Partner Number : </label><label id="">{jobDetail?.Info?.BUSINESS_PARTNER_NUMBER}</label></div>
// 							<div class="form-group mb-0"><label class="f-800">Installation Number : </label><label id="">{jobDetail?.Info?.INSTALLATION_NUMBER}</label></div>
// 							<div class="form-group mb-0"><label class="f-800">Customer ID : </label><label id="">{jobDetail?.Info?.CUSTOMER_ID}</label></div>
// 							<div class="form-group mb-0"><label class="f-800">Modem Serial Numbe : </label><label id="">{jobDetail?.Info?.DEVICE_SERIAL_NO}</label></div>
// 						</div>
// 					</div>
// 				</div>
// 			</InfoWindow>}
// 	</>
// 	);
// }
// ))