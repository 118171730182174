import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DropdownItem = ({ option, onSelect, isSelected, isdisabled }) => {
    const itemClasses = classNames('my-dropdown__option', {
        'my-dropdown__option--selected': isSelected,
        'tw-cursor-not-allowed': isdisabled,
    });

    const handleClick = () => {
        onSelect(option);
    };

    return (
        <div className='my-dropdown__option-container'>
            <div aria-disabled={isdisabled} className={itemClasses} onClick={handleClick}>
                {option?.color && <div className="my-dropdown__option-circle" style={{ backgroundColor: option?.color }}></div>}
                {option.label}
            </div>
        </div>
    );
};

DropdownItem.propTypes = {
    option: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
    }).isRequired,
    onSelect: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
};

export default DropdownItem;