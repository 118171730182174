import React, { useEffect, useRef, useState } from 'react';
import './downloadBtn.css';
import classNames from "classnames";
import { Dropdown } from 'react-bootstrap';

export default function ChartDownloadBtn({ selectedValues, captureImage, isForDownload, ...dropdownProps }) {

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef2 = useRef(null);
    const [selected, setSelected] = useState(selectedValues);
    const [position, setPosition] = useState("right");
    const options = [
        { value: 'png', label: 'Download in PNG' },
        { value: 'jpeg', label: 'Download in JPG' }
    ];

    const handleSelect = (option) => {
        setIsOpen(false);
        setSelected(option);
        captureImage(option?.value);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            const rect = dropdownRef2.current.getBoundingClientRect();
            const screenWidth = window.innerWidth || document.documentElement.clientWidth;
            setPosition(screenWidth - rect.right < 169 ? "left" : "right");
        }
    }, [isOpen]);

    return (
        <Dropdown
            className="d-inline"
            show={isOpen}
            onToggle={() => setIsOpen(!isOpen)}
            ref={dropdownRef2}
            {...dropdownProps}
        >
            <Dropdown.Toggle 
                variant="info" 
                className="!tw-py-0.5 !tw-px-3.5 tw-rounded-[30px]" 
                id="dropdown-basic"
            >
                <i className="fa fa-download" aria-hidden="true"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu className={`my-dd-options !tw-min-w-[130px] my-dd-options-${position}`}>
                {options.map((option) => (
                    <Dropdown.Item
                        key={option.value}
                        onClick={() => handleSelect(option)}
                        className={classNames("my-dd-option !tw-py-2 !tw-px-[6px]")}
                    >
                        {option.label}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
