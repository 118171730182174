
import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Lock, Users } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import { BootstrapTooltip } from '../../Commoncomponet/common'
import * as $ from "jquery";
import * as XLSX from 'xlsx';
import dt from "datatables.net-dt";
import jsPDF from "jspdf";
import { getAllTPMeter, getAllModem, syncDatabaseTabledata } from '../../Commoncomponet/Utils'
import { Modal } from 'antd'
import moment from 'moment'
import { toast } from 'react-toastify'
$.fn.DataTable = dt;
export default function All() {
	const [ModemData, setModemData] = useState([])
	const [filterrolesData, setfilterRolesData] = useState([])
	const [roleFilter, setRoleFilter] = useState({ name: '', status: '' })
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [loader, setLoader] = useState(false)
	const history = useHistory()
	const [csvData, setCsvData] = useState([])

	const ongetRolesUserData = async () => {
		setLoader(true);
		$(".loader").fadeOut("slow");
		const data = await getAllTPMeter()
		if (data.success === true) {
			setModemData(data.data.data)
			setfilterRolesData(data.data.data)
		} else {
			console.log('somthing Went wrong')
			setLoader(false)
		}
	}
	const csvLink = useRef();

	const downloadReport = async (isCsv) => {
		if (ModemData.length > 0) {
			const finalCsvData = await ModemData.map((item) => ({ 'SRNO': item.SRNO, 'Unique ID': item.METER_UNIQUE_ID, 'Created Time': moment(item.METER_CREATED_TIME).format('DD-MM-YYYY'), 'Created By': item.METER_CREATED_BY, 'Name': item.METER_PROFILE_NAME, 'Protocol': item.METER_PROTOCOL, 'Status': (item.METER_STATUS === 1 ? 'Active' : 'InActive') }))
			setCsvData(finalCsvData)
			if (isCsv === true) {
				if (finalCsvData.length > 0) {
					csvLink.current.link.click();
				}
			}
			else {
				if (finalCsvData.length > 0) {
					const worksheet = XLSX.utils.json_to_sheet(finalCsvData);
					const workbook = XLSX.utils.book_new();
					XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
					XLSX.writeFile(workbook, `All-meter-data.xlsx`);
				}
			}
		}
	}
	useEffect(() => {
		ongetRolesUserData().then(() =>
			setTimeout(function () {
				$("#table-1").DataTable({ "pageLength": 50, order: [[0, 'desc']] });
				setLoader(false);

			}, 700)
		);
	}, []);


	const UpdateRolesUser = (item) => {
		history.push({ pathname: `/AllThirdPartyMeter`, state: item })
	}
	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const onhandleOk = () => {
		setIsModalVisible(false)
		if (roleFilter.name === '' && roleFilter.status === '') {
			return setModemData(filterrolesData)
		}
		const filteData = filterrolesData && filterrolesData.filter((item) => (roleFilter.name !== '' ? item?.METER_PROFILE_NAME?.includes(roleFilter.name) : []) && (roleFilter.status !== '' ? item?.METER_STATUS == roleFilter.status : []))
		setModemData(filteData)
	}
	const syncList = async () => {
		setLoader(true);
		$(".loader").fadeOut("slow");
		const userInfo = JSON.parse(window.localStorage.getItem("user_info") || {})
		const data = await syncDatabaseTabledata({ createdBy: userInfo?.UNIQUE_ID, tableName: 'pcmt_eums_third_party_manufacturer_brand_meter_index', synctableName: 'pcst_eums_third_party_manufacturer_brand_meter_index' })
		if (data.success === true) {
			toast.success("Synced successfully !");
			ongetRolesUserData()
			setLoader(false)
		}
		else {
			setLoader(false)
			console.log("Error while getting all table info = ", data.err)
		}
	}
	return (
		<>
			<Modal title="Filter Meter" visible={isModalVisible} footer="" onCancel={handleCancel}>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="inputState">Meter Name:</label>
						<input type="text" class="form-control" id="" onChange={(e) => (setRoleFilter({ ...roleFilter, name: e.target.value }))} value={roleFilter.name} name="FirstName" placeholder="Enter Meter Name" />
					</div>
					<div class="form-group col-md-6">
						<label for="inputPassword4">Meter Status:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="modem_name"
							onChange={(e) => (setRoleFilter({ ...roleFilter, status: e.target.value }))}
							value={roleFilter.status}
						>
							<option value="">Select Status</option>
							<option value={1}>Active</option>
							<option value={0}>InActive</option>
						</select>
					</div>
				</div>
				<div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Filter" name="filter_customer_form" onClick={onhandleOk} />
					<Link class="btn btn-light text-dark ml-2" to={`/allmeter`}>
						Reset
					</Link>
				</div>
			</Modal>
			{/* <div className="main-content"> */}
			{loader ? (
				<div className="main-content">
					<div class="loader"></div>
				</div>
			) : (
				""
			)}
			{/* <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/all-deshboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								<Lock style={{ height: "16px" }} />Admin
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								Meter Driver
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
							Manufacturer Brand Meter
							</Link>
						</li>
					</ol>
				</nav> */}
			<section className="section">
				<div className="section-body">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h4><span className='mr-2'>Manufacturer Brand Meter</span><BootstrapTooltip title={`Table Name is pcst_eums_third_party_manufacturer_brand_meter_index`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
									<div className="card-header-action">
										<button className="btn btn-primary mr-2" onClick={() => syncList()}>Sync Data</button>
										{/* <Link to="/AllThirdPartyMeter" className="btn btn-info text-white mr-2">
											<BootstrapTooltip title="Add">
												<span>
													<i
														className="fas fa-plus text-white"
														style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
													></i>
												</span>
											</BootstrapTooltip>
										</Link> */}
										<button type="button" className="btn btn-info mr-2" onClick={() => (setIsModalVisible(true))}>
											<BootstrapTooltip title="Filter">
												<span>
													<i className="fa fa-filter text-white"></i>
												</span>
											</BootstrapTooltip>
										</button>
										<div className="dropdown d-inline mr-2">
											<button
												className="btn btn-info dropdown-toggle mr-2"
												type="button"
												id="dropdownMenuButton3"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
												data-placement="bottom"
												title="Download"
											>
												<i className="fa fa-download mr-1"></i>
											</button>
											<div className="dropdown-menu">
												<a className="dropdown-item" >
													PDF
												</a>
												<a onClick={() => downloadReport(false)} className="dropdown-item" >
													Excel
												</a>
												<a onClick={() => downloadReport(true)} className="dropdown-item">
													CSV
												</a>
												<CSVLink data={csvData} filename={"All-meter-data.csv"} ref={csvLink} />
											</div>
										</div>
									</div>
								</div>
								<div className="card-body">
									<div className="table-responsive">
										<table className="table border stripe" id="table-1">
											<thead>
												<tr>
													<th className="!tw-font-semibold" style={{ maxWidth: "100px" }}>Unique No</th>
													<th className='!tw-font-semibold'>Name</th>
													<th className='!tw-font-semibold'>Type</th>
													<th className='!tw-font-semibold'>Unique Id</th>
													<th className='!tw-font-semibold' style={{ maxWidth: "100px" }}>Status</th>
												</tr>
											</thead>
											<tbody>
												{ModemData &&
													ModemData.map((item, id) => (
														<tr style={{ height: "4rem" }} className="trheigth hover:tw-bg-[#D8D8D8]" key={id} onClick={() => (UpdateRolesUser(item))}>
															<td className="">
																<div key={item?.srno} className={'tw-flex tw-items-center tw-text-blue-600 tw-cursor-pointer tw-group tw-gap-2'}>
																	<span className='tw-whitespace-nowrap text-center'>{item?.srno}</span>
																	<div className="tw-opacity-0 tw-transition-all group-hover:tw-opacity-100" >
																		<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" id="right-arrow"><g data-name="Layer 2"><g data-name="arrowhead-right"><path fill='currentColor' d="m18.78 11.37-4.78-6a1 1 0 0 0-1.41-.15 1 1 0 0 0-.15 1.41L16.71 12l-4.48 5.37a1 1 0 0 0 .13 1.41A1 1 0 0 0 13 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 .01-1.27z"></path><path fill='currentColor' d="M7 5.37a1 1 0 0 0-1.61 1.26L9.71 12l-4.48 5.36a1 1 0 0 0 .13 1.41A1 1 0 0 0 6 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 0-1.27z"></path></g></g></svg>
																	</div>
																</div>
															</td>
															<td>{item?.meter_profile_name}</td>
															<td>{item?.METER_TYPE_NAME}</td>
															<td>{item?.meter_unique_id}</td>
															<td className="">
																<div className={`badge ${item?.meter_status === 1 ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{item?.meter_status === 1 ? 'Active' : 'Inactive'}</div>
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* </div> */}
		</>
	)
}
