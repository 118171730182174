import React from 'react';
import './ButtonLoader.css';

function ButtonLoader({ isLoading, children, ...props }) {
    return (
        <button type='button' className={`ButtonLoader ${isLoading ? 'isLoading' : ''}`} {...props}>
            {isLoading ? <div className="DottedSpinner"><div className="Dot"></div><div className="Dot"></div><div className="Dot"></div></div> : <spn>{children}</spn>}
        </button>
    );
}

export default ButtonLoader;
