import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Lock } from 'react-feather'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { activeandinactive, getmeter_category, getmeter_class, getmeter_firmware, getmeter_hardware_protocol, getmeter_make, getmeter_model_number, getmeter_software_protocol, getmeter_type, getmeter_update_data, getModemFirmware, getModemHardwareVersion, getModemMake, getModemModelNumber, getModemProductCode, getModemType, geturls3, Insertmeter_index, Insertmodem_index, MeterFileuploads, Updatemeter_index, Updatemeter_index_attachments, Updatemodem_index, uploadsFiles } from '../../../Commoncomponet/Utils'
import { toast } from 'react-toastify'
import swal from "sweetalert2";
import moment from 'moment'
import { Modal } from 'antd'
window.Swal = swal;
export default function AddAll() {

    const [type,setType]=useState([])
    const [make,setMake]=useState([])
    const [ModelNumber,setModelNumber]=useState([])
    const [Firmware,setFirmware]=useState([])
    const [Category,setCategory]=useState([])
    const [Software,setSoftware]=useState([])
    const [meterModemType,setmeterModemType]=useState([])
    const [Hardware,setHardware]=useState([])
const [MeterClass,setmeterClass]=useState([])
const [loginErrors, setValidation] = useState({});
const [flagBtn, setflegBtn] = useState(false);
const [ModemId,setModemId]=useState('')
const [disable,setDisable]=useState(true)
const [status,setStatus]=useState(null)
const [UrlImg,setUrlImg]=useState('')
const [inputs,setInputs]=useState({Name:'',Type:'',Make:'',ModelNumber:'',Firmware:'',Category:'',Hardware:'',memoryDay:'',Software :'',note:'',meterModemType:'',classs:'',Name_plate:'',Meter_Baudrate:'', CLIENT_ADDR:'',SERVER_ADDR:'',LOGICAL_NAME_USE:'',AUTH_TYPE:'',PASSWORD:''})
const history = useHistory();
const userInfo=JSON.parse(window.localStorage.getItem("user_info")||{})
const [Attachment, setAttachment] = useState([]);
const [isModalImage,setisModalImage]=useState(false)
   const [imageName,setImageName]=useState('')
const locations=useLocation()
      useEffect(() => {
          if (locations?.state) {
             setInputs({Name:locations?.state?.METER_PROFILE_NAME,Type:locations?.state?.METER_TYPE,Make:locations?.state?.METER_MAKE,ModelNumber:locations?.state?.METER_MODEL,Firmware:locations?.state?.METER_FIRMWARE,Category:locations?.state?.METER_CATEGORY,Hardware:locations?.state?.METER_HARDWARE_PROTOCOL,memoryDay:locations?.state?.MEMORY_STORAGE_IN_DAYS,Software:locations?.state?.METER_SOFTWARE_PROTOCOL,note:locations?.state?.METER_NOTES,meterModemType:locations?.state?.METER_MODEM,classs:locations?.state?.METER_CLASS,Name_plate:locations?.state?.NAME_PLATE_DATA,Meter_Baudrate:locations?.state?.METER_BAUDRATE, CLIENT_ADDR:locations?.state?.CLIENT_ADDR,SERVER_ADDR:locations?.state?.SERVER_ADDR,LOGICAL_NAME_USE:locations?.state?.LOGICAL_NAME_USE,AUTH_TYPE:locations?.state?.AUTH_TYPE,PASSWORD:locations?.state?.PASSWORD})
             setModemId(locations?.state?.METER_UNIQUE_ID)
             setDisable(false)
             setStatus(locations?.state?.METER_STATUS)
             setAttachment(JSON.parse(locations?.state?.METER_ATTECHMENT||'[]'))
          }
       }, [locations]);
    const onHandaleSelection=async()=>{
        const data=await getmeter_type()
        if(data.success===true){
            setType(data.data.data)
            const data1=await getmeter_make()
        if(data1.success===true){
            setMake(data1.data.data)
            const data2=await getmeter_firmware()
        if(data2.success===true){
            setFirmware(data2.data.data)
            const data3=await getmeter_category()
        if(data3.success===true){
            setCategory(data3.data.data)
            const data4=await getmeter_hardware_protocol()
        if(data4.success===true){
            setHardware(data4.data.data)
        }
        const data5=await getmeter_model_number()
        if(data5.success===true){
            setModelNumber(data5.data.data)
        }
        const data6=await getmeter_software_protocol()
        if(data6.success===true){
            setSoftware(data6.data.data)
        }
        const data7=await getModemType()
        if(data7.success===true){
            setmeterModemType(data7.data.data)
        }
        const data8=await getmeter_class()
        if(data8.success===true){
            setmeterClass(data8.data.data)
        }
        }
        }
        }
        }else{
          console.log('somthing Went wrong')
        }
      }

      const onAddAttachment = () => {
         const initial = {SRNO: Attachment.length+1,title: "", description: "", files: "",create_at:moment().format(),update_at:'' };
         // if(contactDetails.length>0){
         const task = Attachment[Attachment.length - 1];
         Attachment[Attachment.length] = initial;
         setAttachment([...Attachment]);
         // }
         };
      const onDeleteAttachment = (id) => {
         swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Card!",
            icon: "warning",
            showCancelButton: true,
         }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
         const test = [...Attachment];
         const data=test.filter((item,ids)=>(ids!==id))
         setAttachment([...data]);
         await Updatemeter_index_attachments(ModemId,{Attachment:data})
            }
            })
      };
      const handleChangeAttachment = async (e) => {
         const { name, value, id } = e.target;
         let updatedTask = [...Attachment];
         if(name==='file'){
            var totalSizeMB = value.target.files[0].size / Math.pow(1024, 2)
            if (totalSizeMB >= 50) {
               swal.fire('', 'File size should be under 50 MB', 'error');
       }else{
            updatedTask[id][name] = value.target.files[0];
       }
         }else{
            updatedTask[id][name] = value.target.value.replace(/[\/\\"`\t\n]/g, '');
         }
         setAttachment([...updatedTask]);
      };
      useEffect(() => {
        onHandaleSelection()
      }, []);

      const validation = (name, value) => {
         switch (name) {
             case 'Name':
                 if (!value) {
                     return 'Please input Meter Name!'
                 } else {
                     return '';
                   }
                //    case 'Type':
                //  if (!value) {
                //      return 'Please input Meter Type!'
                //  } else {
                //      return '';
                //    }
                //    case 'Make':
                //  if (!value) {
                //      return 'Please input Meter Make!'
                //  } else {
                //      return '';
                //    }
                //    case 'ModelNumber':
                //  if (!value) {
                //      return 'Please input Meter Model Number!'
                //  } else {
                //      return '';
                //    }
                //    case 'Firmware':
                //  if (!value) {
                //      return 'Please input Meter Firmware!'
                //  } else {
                //      return '';
                //    }
                //    case 'Category':
                //  if (!value) {
                //      return 'Please input Meter Category!'
                //  } else {
                //      return '';
                //    }
                //    case 'Software':
                //  if (!value) {
                //      return 'Please input Meter Software!'
                //  } else {
                //      return '';
                //    }
                //    case 'Hardware':
                //  if (!value) {
                //      return 'Please input Meter Hardware!'
                //  } else {
                //      return '';
                //    }
                //    case 'meterModemType':
                //  if (!value) {
                //      return 'Please input Meter  Meter Modem Type!'
                //  } else {
                //      return '';
                //    }
                   
                   default: {
                     return null;
                 }
             }
             }

             const onUploadFile=async(id)=>{
               var attechmentsobj=[]
                     const formData = new FormData()
                     formData.append('Images', Attachment[id].file)
                     const uploadImges=await MeterFileuploads(formData)
                     if(uploadImges.success===true){
                        Attachment[id].file=uploadImges?.data?.originalname
                        Attachment[id]["update_at"]=moment().format()

                        const data=await Updatemeter_index_attachments(ModemId,{Attachment:Attachment})
                        if(data.success==true) {
                           ongetmeter_update_data()
                           toast.success('File Successfully Upload')
                        }
                     }else{
                        toast.error('File Not Uploaded')
                        // attechmentsobj.push({...item,file:''})
                     }
                }

                const ongetmeter_update_data=async()=>{
                  const data=await getmeter_update_data(ModemId)
                  if(data.success==true){
                     const inFometer=data.data.data
                     setInputs({Name:inFometer?.METER_PROFILE_NAME,Type:inFometer?.METER_TYPE,Make:inFometer?.METER_MAKE,ModelNumber:inFometer?.METER_MODEL,Firmware:inFometer?.METER_FIRMWARE,Category:inFometer?.METER_CATEGORY,Hardware:inFometer?.METER_HARDWARE_PROTOCOL,memoryDay:inFometer?.MEMORY_STORAGE_IN_DAYS,Software:inFometer?.METER_SOFTWARE_PROTOCOL,note:inFometer?.METER_NOTES,meterModemType:inFometer?.METER_MODEM,classs:inFometer?.METER_CLASS,Name_plate:inFometer?.NAME_PLATE_DATA,Meter_Baudrate:inFometer?.METER_BAUDRATE, CLIENT_ADDR:inFometer?.CLIENT_ADDR,SERVER_ADDR:inFometer?.SERVER_ADDR,LOGICAL_NAME_USE:inFometer?.LOGICAL_NAME_USE,AUTH_TYPE:inFometer?.AUTH_TYPE,PASSWORD:inFometer?.PASSWORD})
                     setModemId(inFometer?.METER_UNIQUE_ID)
                     setDisable(false)
                     setStatus(inFometer?.METER_STATUS)
                     setAttachment(JSON.parse(inFometer?.METER_ATTECHMENT||'[]'))
                  }else{
                     console.log('something Went wrong')
                  }
                }

      const onHandaleSubmitData=async(e)=>{
         e.preventDefault()
         setflegBtn(true)
         let allErrors = {};
      Object.keys(inputs).forEach(key => {
        const error = validation(key, inputs[key]);
        if (error && error.length) {
            allErrors[key] = error
        }
    });
  
   
    if (Object.keys(allErrors).length) {
      setflegBtn(false)
     return setValidation(allErrors)
       }else{
         if(ModemId===''){
            const attechmentsobj=[]
           
        
         const data=await Insertmeter_index({...inputs,add_by:userInfo?.UNIQUE_ID})
         if(data.success===true){
             toast.success('Add Meter Success')
         setflegBtn(false)
         history.push('/Admin/CommonTables')
         }else{
             toast.error('Something Went Wrong')
         setflegBtn(false)
         }
      
     }else{
      const attechmentsobj=[]
      
      
     const data2=await Updatemeter_index({...inputs},ModemId)
     if(data2.success===true){
         toast.success('Update Meter Success')
     setflegBtn(false)
     history.push('/Admin/CommonTables')
     }else{
         toast.error('Something Went Wrong')
     setflegBtn(false)
 
     }
  
     }
       }
     }

     const oninactive=async()=>{
      swal.fire({
			title: "Are You Sure InActive Meter?",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'meter_index',colName:'METER_STATUS',value:'0',matchId:'METER_UNIQUE_ID'},ModemId)
      if(data.success===true){
         toast.success('Success Inactive Meter')
         history.push('/Admin/CommonTables')
      }else{
         toast.error('Something Went Wrong')
      }
   }
})
     }
     const onactive=async()=>{
      swal.fire({
			title: "Are You Sure Meter?",
			// text: "Once deleted, you will not be able to recover this User Details!",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'meter_index',colName:'METER_STATUS',value:'1',matchId:'METER_UNIQUE_ID'},ModemId)
      if(data.success===true){
         toast.success('Success Active Meter')
         history.push('/Admin/CommonTables')
      }else{
         toast.error('Something Went Wrong')
      }
   }
})
     }
     const onDownloadFiles=async(name)=>{
         // e.preventDefault()
         const data1=await geturls3({bucketName:'eums.memighty.com',objectKey:`Meter_Driver_Attachments/${name}`, expiration:60})
         console.log(data1);
         var link = document.createElement("a");
         link.href = data1.data.data;
         link.download = name;
         link.click();
     }

     const onViewAttachments=async(e,name)=>{
      // e.preventDefault()

      setisModalImage(true) 
      const data1=await geturls3({bucketName:'eums.memighty.com',objectKey:`Meter_Driver_Attachments/${name}`, expiration:60})
      setImageName(name)
      setUrlImg(data1.data.data)

     }
     var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
     var imageExtensions=/(\.mp4|\.webm|\.ogg|\.mkv|\.mpg|\.m4v|\.m4p |\.mpv|\.vob|\.mpeg)$/i;
  return (
    <>
   <Modal title="Image" visible={isModalImage} footer='' onCancel={()=>(setisModalImage(false))} width={800}>
       {allowedExtensions.exec(imageName)?
         <img src={`${UrlImg}`} height={500} width={"100%"}></img>:imageExtensions.exec(imageName)?<video height={500} width={"100%"} controls><source src={`${UrlImg}`} type="video/mp4"/><source src={`${UrlImg}`} type="video/ogg"/></video>:''
       }
       </Modal>
    <div className="main-content">
    <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/all-deshboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
                            <Lock className='header-logo' style={{ height: "16px" }} /> Admin
							</Link>
						</li>
						<li className="breadcrumb-item">
						<Link to='/Admin/CommonTables'>
							 Meter Driver
						</Link>
					</li>
						<li className="breadcrumb-item">
							<Link >{ModemId===''?'Add Meter':'Update Meter'}</Link>
						</li>
					</ol>
				</nav>
                <section class="section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                        <div class="card-header">
                           <h4>{ModemId===''?'Add Meter':'Update Meter'}</h4>
                           <div class="card-header-action">  
                           {/* {ModemId!==''?<div  class="btn btn-info text-white btnedit mr-2" onClick={()=>(setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:''}
										{ModemId!==''&&status===1?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2"  onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:''}
										{ModemId!==''&&status===0?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:''} */}
                              <Link to="/Admin/CommonTables" class="btn btn-info text-white align-left">
                              Back
                              </Link>                        
                           </div>
                        </div>
                        <div class="card-body">
                           <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" onSubmit={onHandaleSubmitData}>
                              <div class="form-row" style={{marginLeft:"10px"}}>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Meter Profile Name: <span class="text-danger">*</span></label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Modem Profile Name" required="" onChange={(e)=>(setInputs({...inputs,Name:e.target.value}))} value={inputs.Name} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.Name}</span>    
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Meter Type</label>
                                    <select class="form-control form-select" name="modemtype" id="modemtype" onChange={(e)=>(setInputs({...inputs,Type:e.target.value}))} value={inputs.Type} disabled={!disable}>
                                       <option value="">Select Meter Type</option>
                                                           {type&&type.map((item)=>(<option value={item.UNIQUE_ID}>{item?.METER_TYPE_NAME}</option>))}  
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Name}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Meter Make</label>
                                    <select class="form-control form-select" name="modemmake" id="modemmake" onChange={(e)=>(setInputs({...inputs,Make:e.target.value}))} value={inputs.Make} disabled={!disable}>
                                       <option value="">Select Meter Make</option>
                                       {make&&make.map((item)=>(<option value={item.METER_MAKE_UNIQUE_ID}>{item?.METER_MAKE_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Make}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Meter Model Number</label>
                                    <select class="form-control form-select " name="modemmodalversion" id="modemmodalversion" onChange={(e)=>(setInputs({...inputs,ModelNumber:e.target.value}))} value={inputs.ModelNumber} disabled={!disable}>
                                       <option value="">Select Meter Model Number</option>
                                       {ModelNumber&&ModelNumber.map((item)=>(<option value={item.METER_MODEL_NUMBER_UNIQUE_ID}>{item?.METER_MODEL_NUMBER_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.ModelNumber}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Meter Firmware</label>
                                    <select class="form-control form-select select2-hidden-accessible" name="modemfirmware[]" id="modemfirmware" multiple="" tabindex="-1" aria-hidden="true" onChange={(e)=>(setInputs({...inputs,Firmware:e.target.value}))} value={inputs.Firmware} disabled={!disable}>
                                       <option value="">Select Meter Firmware</option>
                                       {Firmware&&Firmware.map((item)=>(<option value={item.METER_FIRMWARE_UNIQUE_ID}>{item?.METER_FIRMWARE_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Firmware}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Meter Software</label>
                                    <select class="form-control form-select " name="modemproductcode" id="modemproductcode" onChange={(e)=>(setInputs({...inputs,Software:e.target.value}))} value={inputs.Software} disabled={!disable}>
                                       <option value="">Select Meter Software</option>
                                       {Software&&Software.map((item)=>(<option value={item.METER_SOFTWARE_PROTOCOL_UNIQUE_ID}>{item?.METER_SOFTWARE_PROTOCOL_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Software}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Meter Hardware Version</label>
                                    <select class="form-control form-select select2-hidden-accessible" name="modemhardware[]" id="modemhardware" multiple="" tabindex="-1" aria-hidden="true" onChange={(e)=>(setInputs({...inputs,Hardware:e.target.value}))} value={inputs.Hardware} disabled={!disable}>
                                       <option value="">Select Meter Hardware Version</option>
                                       {Hardware&&Hardware.map((item)=>(<option value={item.METER_HARDWARE_PROTOCOL_UNIQUE_ID}>{item?.METER_HARDWARE_PROTOCOL_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Hardware}</span>   
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Meter Category</label>
                                    <select class="form-control form-select select2-hidden-accessible" name="modemhardware[]" id="modemhardware" multiple="" tabindex="-1" aria-hidden="true" onChange={(e)=>(setInputs({...inputs,Category:e.target.value}))} value={inputs.Category} disabled={!disable}>
                                       <option value="">Select Meter Category</option>
                                       {Category&&Category.map((item)=>(<option value={item.UNIQUE_ID}>{item?.METER_CATEGORY_NAME}</option>))}
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Category}</span>   
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Meter Modem Type</label>
                                    <select class="form-control form-select select2-hidden-accessible" name="modemhardware[]" id="modemhardware" multiple="" tabindex="-1" aria-hidden="true" onChange={(e)=>(setInputs({...inputs,meterModemType:e.target.value}))} value={inputs.meterModemType} disabled={!disable}>
                                       <option value="">Select Meter Modem Type</option>
                                       {meterModemType&&meterModemType.map((item)=>(<option value={item.UNIQUE_ID}>{item?.NAME}</option>))}
                                    </select>
                                    <span className='text-danger'>{loginErrors?.meterModemType}</span>   
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Meter class</label>
                                    <select class="form-control form-select select2-hidden-accessible" name="modemfirmware[]" id="modemfirmware" multiple="" tabindex="-1" aria-hidden="true" onChange={(e)=>(setInputs({...inputs,classs:e.target.value}))} value={inputs.classs} disabled={!disable}>
                                       <option value="">Select Meter Class</option>
                                       {MeterClass&&MeterClass.map((item)=>(<option value={item.METER_CLASS_UNIQUE_ID}>{item?.METER_CLASS_NAME}</option>))}
                                    </select>
                                    <span className='text-danger'>{loginErrors?.classs}</span>    

                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Memory Storage In days: <span class="text-danger">*</span></label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,memoryDay:e.target.value}))} value={inputs.memoryDay} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.memoryDay}</span>    
                                 </div>
                              </div>
                              <div class="form-row" style={{marginLeft:"10px"}}>
                                 <div div="" class="form-group col-md-3">
                                    <label for="inputPassword4">Modem Notes: </label>
                                    <textarea class="form-control" name="modemnotes" id="modemnotes" type="text" placeholder="Enter Modem Notes" onChange={(e)=>(setInputs({...inputs,note:e.target.value}))} value={inputs.note} disabled={!disable}></textarea>
                                 </div>
                                 <div div="" class="form-group col-md-3">
                                    <label for="inputPassword4">Name Plate Data: </label>
                                    <textarea class="form-control" name="modemnotes" id="modemnotes" type="text" placeholder="Enter Name Plate Data" onChange={(e)=>(setInputs({...inputs,Name_plate:e.target.value}))} value={inputs.Name_plate} disabled={!disable}></textarea>
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Meter Baudrate: </label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,Meter_Baudrate:e.target.value}))} value={inputs.Meter_Baudrate} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.memoryDay}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Client Address:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,CLIENT_ADDR:e.target.value}))} value={inputs.CLIENT_ADDR} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.memoryDay}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Server Addr:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,SERVER_ADDR:e.target.value}))} value={inputs.SERVER_ADDR} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.memoryDay}</span>    
                                 </div>
                                  <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Logical Name Use: </label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,LOGICAL_NAME_USE:e.target.value}))} value={inputs.LOGICAL_NAME_USE} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.memoryDay}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Auth Type:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,AUTH_TYPE:e.target.value}))} value={inputs.AUTH_TYPE} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.memoryDay}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Password:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,PASSWORD:e.target.value}))} value={inputs.PASSWORD} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.memoryDay}</span>    
                                 </div>
                              </div>
                              
                              <div class="row">
															<div class="col-12">
																<div class="row" id="dynamic_attech">
																	{Attachment &&
																		Attachment.map((item, id) => (
																			<>
																				<div class="col-6" id="colls1">
																					<div class="card card-primary">
																						<div class="card-header">
																							<h4>{id+1}</h4>
                                                                     {!disable?<div class="card-header-action ">
                                                <div class="btn btn-warning text-white mr-2" onClick={(e)=>{onDownloadFiles(e,item.file)}}><i class="fa fa-download"></i></div>
                                                <a onClick={(e)=>{onViewAttachments(e,item.file)
                                                }} class="btn btn-info"><i class="fa fa-eye" ></i></a>
                                             </div>:
																							<div class="card-header-action">
                                                                    {item.file!==''&& typeof item.file==='string'? <><div class="btn btn-warning text-white mr-2" onClick={(e)=>{onDownloadFiles(e,item.file)}}><i class="fa fa-download" ></i></div>
                                                <a  onClick={(e)=>{onViewAttachments(e,item.file)}} class="btn btn-info mr-2 text-white"><i class="fa fa-eye"></i></a></>:''}
																								{disable?<><a
																									class="btn btn-danger text-white"
																									id="1"
                                                                           onClick={()=>(onDeleteAttachment(id))}
																								>
																									<i class='fas fa-xmark'></i>
																								</a></>:''} 
																							</div>}
																							{/* <div class="card-header-action">
																								<button
																									type="button"
																									class="btn btn-danger text-white btn_removes"
																									id="1"
                                                                           onClick={()=>(onDeleteAttachment(id))}
																								>
																									<i class="fa fa-times" aria-hidden="true"></i>
																								</button>
																							</div> */}
																						</div>
																						<div class="card-body">
																							<div class="form-group">
																								<div
																									class="form-group row"
																									style={{ marginTop: "-20px" }}
																								>
																									<label
																										for="attechmenttitle1"
																										class="col-sm-3 col-form-label"
																									>
																										Title
																									</label>
																									<div class="col-sm-9">
																										<input
																											type="text"
																											class="form-control"
																											placeholder="Title"
																											onChange={(value) =>
                                                                                    handleChangeAttachment({
																												target: {
																													name: "title",
																													value,
																													id,
																												},
																											})
																										}
                                                                              value={Attachment[id].title}
                                                                              disabled={!disable}
																										/>
																									</div>
																								</div>
																								<div
																									class="form-group row"
																									style={{ marginTop: "-20px" }}
																								>
																									<label
																										for="lastcontectname1"
																										class="col-sm-3 col-form-label"
																									>
																										Description
																									</label>
																									<div class="col-sm-9">
																										<textarea
																											class="form-control"
																											id="attechmentdescr1"
																											onChange={(value) =>
                                                                                    handleChangeAttachment({
																												target: {
																													name: "description",
																													value,
																													id,
																												},
																											})
																										}
                                                                              disabled={!disable}

                                                                              value={Attachment[id].description}
																										></textarea>
																									</div>
																								</div>
																								<div
																									class="form-group row"
																									style={{ marginTop: "-20px" }}
																								>
																									<label
																										for="lastcontectname1"
																										class="col-sm-3 col-form-label"
																									>
																										Attachment
																									</label>
																									<div class="col-sm-9">
                                                                           {disable===true?<input
																											type="file"
																											class="form-control"
																											accept="application/msword,image/gif,image/jpeg,application/pdf,image/png,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,.doc,.gif,.jpeg,.jpg,.pdf,.png,.xls,.xlsx,.zip"
                                                                                 onChange={(value) =>
                                                                                    handleChangeAttachment({
																												target: {
																													name: "file",
																													value,
																													id,
																												},
																											})
                                                                                 }
                                                                              disabled={!disable}
																										/>:<input
                                                                              type="text"
                                                                              class="form-control"
                                                                              value={item?.file}
                                                                              disabled={!disable}
                                                                           />}
																									</div>
																								</div>
                                                                        {ModemId!==''&&<spam>Created At: {item?.create_at!==''?moment(item?.create_at).format('DD-MM-YYYY hh:mm:ss A'):''}</spam>}<br/>
                                                                        {ModemId!==''&&<spam>Update At: {item?.update_at!==''?moment(item?.update_at).format('DD-MM-YYYY hh:mm:ss A'):''}</spam>}
																							</div>
                                                                     {item.file!==''&& typeof item.file!=='string'?  <div className='btn btn-primary' onClick={()=>onUploadFile(id)}>Upload</div>:''}
																						</div>
																					</div>
																				</div>
																			</>
																		))}
																</div>
															</div>
														</div>
                              <div class="col-md-12 p-4">
															<button
																type="button"
																name="add"
																id="addatt"
																class={`btn btn-info ${!disable?'disabled':""}`}
																onClick={onAddAttachment}
															>
																+Add Attachment
															</button>
														</div>
                              {/* <p style={{marginLeft:"25px"}} class="text-danger">Note : * Indicates Mandatory Fields</p>
                              <div class="text-left">
                                 <input type="submit" name="submit" class={`btn btn-danger ${flagBtn?'btn-progress disabled':''} ml-4 mr-3`} value={ModemId===''?'Submit':'Update'} id="submitvn"/>
                                 <Link class="btn btn-light text-dark" to="/Admin/CommonTables">Cancel</Link>  
                              </div> */}
                           </form>
                        </div>
                     </div>
                  </div>
                </div>
            </div>
         </section>
      </div>
    </>
  )
}
