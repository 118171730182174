import { DatePicker, Modal, Space, Popover } from 'antd'
import React, { useState } from 'react'
import './ConsumerTask.css'
import Tasks from '../../../Tasks/Tasks'


export default function ConsumerTask(props) {
    const [storedFilter, setStoredFilter] = useState()
    const [storedShowFilter, setStoredShowFilter] = useState()
    const [storedFilterType, setstoredFilterType] = useState()
    const [storedFilterS, setstoredFilterS] = useState()
    return (
        <>
          
            <div >

                <section className="section">
                    <div className="card !tw-rounded-none">
                        <Tasks consumerId={props?.GSMdata?.UNIQUE_ID} GSMdata={props.GSMdata} ConsumerType={'GSM'} isConsumer={true} storedFilter={storedFilter}
                                        onChangeStoredFilter={(newFilter) => {
                                            console.log("StoredFilter = ", newFilter)
                                            setStoredFilter(newFilter)
                                        }}
                                        storedShowFilter={storedShowFilter}
                                        onChangeShowFilter={(newFilter) => {
                                            setStoredShowFilter(newFilter)
                                        }}
                                        storedFilterType={storedFilterType}
                                        setstoredFilterType={setstoredFilterType}
                                        setstoredFilterS={setstoredFilterS}
                                        storedFilterS={storedFilterS}/>
                    </div>
                </section>
            </div>
        </>
    )
}
