import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const BasicSelect = ({ name, label, options, value, disabled, onChange, ...rest }) => {
    return (
        <div className={classnames("tw-flex tw-justify-center tw-items-center")}>
            {String(label).length > 0 && <label className="tw-text-gray-700" htmlFor={name}>
                {label}
            </label>}
            <div className="tw-relative">
                <select
                    className={classnames("tw-block tw-appearance-none tw-w-full tw-bg-indigo-50 tw-border tw-border-indigo-200 tw-text-gray-700 tw-py-2 tw-px-2 tw-pr-8 tw-rounded tw-leading-tight focus:tw-outline-none focus:tw-bg-white focus:tw-border-gray-500", {
                        "tw-cursor-not-allowed": disabled,
                    })}
                    id={name}
                    name={name}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    {...rest}
                >
                    {options.map((option, index) => (
                        <option key={index} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </select>
                <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-px-2 tw-text-gray-700">
                    <svg
                        className="tw-fill-current tw-h-4 tw-w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

BasicSelect.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

BasicSelect.defaultProps = {
    value: "",
    disabled: false,
    onChange: () => { },
};

export default BasicSelect;

