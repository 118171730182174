import React, { useEffect, useState } from 'react'
import { BootstrapTooltip } from '../../../Commoncomponet/common'
import { Link, useHistory } from "react-router-dom";
import { getThirdPartyUnknownMeterData } from '../../../Commoncomponet/Utils';
import chroma from 'chroma-js';
import Pagination from '../../../Commoncomponet/Pagination';
import moment from 'moment';
import { Modal } from 'antd';

export default function KnownMeterData() {
    const [Devicedata, setDeviceData] = useState([])
    const [PageSize, setPageSize] = useState(50)
    const [numberofPage, setnumberofPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory()
    const [isModalrow,setIsModalRow]=useState(false)
    const [rowData,setRowData]=useState('{}')

    const onGetRemoteMobileDevice = async () => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const data = await getThirdPartyUnknownMeterData({ start: firstPageIndex, end: PageSize }, { data_status: 1 })
        if (data.success == true) {
            setDeviceData(data.data.data)
            setnumberofPage(data.data.total)
        } else {
            console.log('Server Issue')
        }
    }

    useEffect(() => {
        onGetRemoteMobileDevice()
    }, [])
    const onInfoClick=(payload)=>{
        setIsModalRow(true)
        setRowData(payload)
    }

    return (
        <>
          <Modal title="Payload" visible={isModalrow} footer="" onCancel={() => setIsModalRow(false)} width={1000}>
                <div className="tw-h-[800px] tw-overflow-scroll">
				<pre>{JSON.stringify(JSON.parse(rowData||'[]'),null,2)}</pre>
                </div>
			</Modal>
                <div className="table-responsive">
                    <div id="table-1_wrapper" className="dataTables_wrapper no-footer">
                        <div className="dataTables_length" id="table_id_length">
                            <label>
                                Show{" "}
                                <select name="table_id_length" aria-controls="table_id" className="">
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>{" "}
                                entries
                            </label>
                        </div>
                        <table id="table_id" className="tableCustom table table-striped">
                            <thead>
                                <tr>
                                    <th class="GSM_Link">Unique Id</th>
                                    <th>imei</th>
                                    <th class="" data-mui-internal-clone-element="true">
                                        topic
                                    </th>
                                    <th>emt_id_asn</th>
                                    <th>payload</th>
                                    <th>created_at</th>
                                    <th>requested_by</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Devicedata.length > 0 && Devicedata?.map((item) => (
                                    <tr>
                                        <td><span>{item?.u_sr_no}</span></td>
                                        <td>{item?.imei}</td>
                                        <td>{item?.topic}</td>
                                        <td>{item?.emt_id_asn}</td>
                                         <td><button className='btn btn-info btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onInfoClick(item?.payload)}><i className="fa-solid fa-info"></i></button></td>
                                        <td>{moment.utc(item?.created_at).format('DD-MM-YYYY hh:mm:ss A')}</td>
                                        <td>{item?.requested_by}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <><div className='d-flex justify-content-between'>
                        <div>Total Number Of Row {numberofPage}</div><Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={numberofPage || 1}
                            pageSize={PageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                        /></div></>
                </div>
        </>
    )
}
