import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { BootstrapTooltip } from "../../Commoncomponet/common";
import { getRolesById, getNotificationList } from "../../Commoncomponet/Utils";
import chroma from 'chroma-js';
import * as $ from "jquery";
import dt from "datatables.net-dt";
import Pagination from "../../Commoncomponet/Pagination";
import EventTemplate from "./EventTemplate";
import moment from 'moment'
import Notify_Receiver from "./Notify_Receiver";
import NotificationScript from "./NotificationScript";

$.fn.DataTable = dt;
export default function Notify() {

  const [tagsData,setTagsData]=useState([])
const [filterrolesData,setfilterRolesData]=useState([])
const [loader,setLoader]=useState(false)
const [permision,setpermision]=useState({})
const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
const [activeTab, setActiveTab] = useState("Notification");
const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
const [PageSize, setPageSize] = useState(50);
const [numberofPage, setnumberofPage] = useState(1);
const [currentPage, setCurrentPage] = useState(1);
const history=useHistory()
const onHandaleRoles = async () => {
	const data = await getRolesById(userInfo?.ROLE);
	if (data.success === true) {
		const obj = data.data.data[0];
		setpermision({...obj,ROLES_OTHER_PERMISSIONS:JSON.parse(obj.ROLES_OTHER_PERMISSIONS)});
	}else{
		console.log('Something Went Wrong')
	}
}

  const ongetTagsData=async()=>{
    setLoader(true);
	const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		$(".loader").fadeOut("slow");
const data=await getNotificationList({ start: firstPageIndex, end: PageSize })
if(data.success===true){
  setTagsData(data.data.data)
  setnumberofPage(data.data.total)
data.data.data.map((item)=>{
	console.log(item)
	console.log(item?.response&&item?.response?.replaceAll('\\"',''))
})
  setLoader(false)

}else{
  console.log('somthing Went wrong')
  setLoader(false)
}
  }

  useEffect(() => {
	onHandaleRoles()
    ongetTagsData()
  }, []);
  const UpdateRolesUser=(item)=>{
		history.push({ pathname: `/add-tags`, state: {...item,permision} })
	}
	return (
		<div>
    {loader ? (
						<div className="main-content">
							<div class="loader"></div>
						</div>
					) : (
						""
					)}
			<div class="main-content">
            
				<div class="col-12 d-flex justify-content-between align-items-center pb-3 px-0">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb p-0 mb-0">
							<li class="breadcrumb-item">
								<a href="/">
									<i class="fa fa-dashboard fa-lg"></i> Home
								</a>
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								<a href="/" class="open_rightsidebar">
									<i class="fas fa-list"></i> Notification{" "}
								</a>
							</li>
						</ol>
					</nav>
				</div>
				<section class="section">
                <div class="row">
							<div class="col-12 col-sm-12 col-lg-12 tw-p-0">
								<div class="card">
									<div class="card-header d-flex tw-border-b-0 " style={{ justifyContent: "space-between" }}>
										<ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "20px", alignItems: "center" }}>
											<li>
												<button
													style={{
														backgroundColor: activeTab === "Notification" ? chroma(btnColor[0]).alpha(0.4).css() : '',
														color:activeTab === "Notification" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
														borderBottom: `1px solid ${btnColor[0]}`,
														boxShadow: activeTab === "Notification" ? `0 2px 8px -3px ${btnColor[0]}` : '',
													}}
														className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Notification" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
													data-toggle="tab"
													href="#home"
													role="tab"
													aria-controls="home"
													aria-selected="true"
													onClick={() => setActiveTab("Notification")}
												>
													Notification Execution List
												</button>
											</li>
											<li>
											<button
													style={{
														backgroundColor: activeTab === "Event Template" ? chroma(btnColor[1]).alpha(0.4).css() : '',
														color:activeTab === "Event Template" ? chroma(btnColor[1]).darken(1.5).css() : btnColor[1],
														borderBottom: `1px solid ${btnColor[1]}`,
														boxShadow: activeTab === "Event Template" ? `0 2px 8px -3px ${btnColor[1]}` : '',
													}}
														className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Event Template" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
													id="profile-tab"
													data-toggle="tab"
													href="#profile"
													role="tab"
													aria-controls="profile"
													aria-selected="false"
													onClick={() => setActiveTab("Event Template")}
												>
													Event Template
												</button>
											</li>
                                            <li>
											<button
													style={{
														backgroundColor: activeTab === "Alert Receiver" ? chroma(btnColor[2]).alpha(0.4).css() : '',
														color:activeTab === "Alert Receiver" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
														borderBottom: `1px solid ${btnColor[2]}`,
														boxShadow: activeTab === "Alert Receiver" ? `0 2px 8px -3px ${btnColor[2]}` : '',
													}}
														className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Alert Receiver" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
													id="profile-tab"
													data-toggle="tab"
													href="#profile"
													role="tab"
													aria-controls="profile"
													aria-selected="false"
													onClick={() => setActiveTab("Alert Receiver")}
												>
													Notification Receiver
												</button>
											</li>
											<li>
											<button
													style={{
														backgroundColor: activeTab === "Notification Script List" ? chroma(btnColor[1]).alpha(0.4).css() : '',
														color:activeTab === "Notification Script List" ? chroma(btnColor[1]).darken(1.5).css() : btnColor[1],
														borderBottom: `1px solid ${btnColor[1]}`,
														boxShadow: activeTab === "Notification Script List" ? `0 2px 8px -3px ${btnColor[1]}` : '',
													}}
														className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Notification Script List" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
													id="profile-tab"
													data-toggle="tab"
													href="#profile"
													role="tab"
													aria-controls="profile"
													aria-selected="false"
													onClick={() => setActiveTab("Notification Script List")}
												>
													Notification Script List
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					{activeTab=='Notification'&&<div class="section-body">
						<div class="row">
							<div class="col-12">
								<div class="card">
									<div class="card-header">
										<h4><span className="mr-2">Notification Execution List</span><BootstrapTooltip title='Table Name is notification_list' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											
										</div>
									</div>
									<div class="card-body">
                                    <div className="table-responsive">
												
												<table id="table_id" className="table border table-borderless text-center table-striped font-12">
													<thead>
											
												<tr>
													<th className="text-center" style={{ maxWidth: "100px" }}>sr_no</th>
													<th className="text-center"  style={{ minWidth: "130px" }}>created_at</th>
													<th className="text-center" style={{ maxWidth: "100px" }}>created_by</th>
													<th className="text-center">generated_by_id</th>
													<th className="text-center">notification_type_id</th>
													<th className="text-center">event_parameters</th>
													<th className="text-center">sent_payload</th>
													<th className="text-center">response</th>
													<th className="text-center">status</th>
													
												</tr>
											</thead>
											<tbody>
												{tagsData &&
													tagsData.map((item, id) => (
														<tr style={{ height: "4rem" }} className="trheigth" key={id} >
															<td className="text-center">{item.sr_no}</td>
															<td className="text-center">{moment.utc(item.created_at).format('DD-MM-YYYY hh:mm:ss A')}</td>
															<td className="text-center">{item.created_by}</td>
															<td className="text-center"><BootstrapTooltip title={item.generated_by_id}><span>{item.consumer}</span></BootstrapTooltip></td>
															<td className="text-center"><BootstrapTooltip title={item.notification_type_id}><span>{item.alarm_type}</span></BootstrapTooltip></td>
															<td className="text-center">{item.event_parameters}</td>
															<td className="text-center">{item.sent_payload}</td>
															<td className="text-center">{item.response}</td>
															
															{item.status===1?<td className="text-center">
																			<div className="badge badge-success badge-shadow text-white">success</div>
																		</td>:item.status===-1?<td className="text-center">
																			<div className="badge badge-warning badge-shadow text-white">pending</div>
																		</td>:<td className="text-center">
																			<div className="badge badge-danger badge-shadow text-white">cancel</div>
																		</td>}
														</tr>
													))}
											</tbody>
										</table>
										<>
													<div className="d-flex justify-content-between">
														<div>Total Number Of Row {numberofPage}</div>
														<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select
															name="table_id_length"
															aria-controls="table_id"
															class=""
															onChange={(e) => setPageSize(e.target.value)}
															value={PageSize}
														>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
														<Pagination
															className="pagination-bar"
															currentPage={currentPage}
															totalCount={numberofPage || 1}
															pageSize={PageSize}
															onPageChange={(page) => setCurrentPage(page)}
														/>
													</div>
												</>
									</div>
                  </div>
								</div>
							</div>
						</div>
					</div>}
					{activeTab=='Event Template'&&<EventTemplate/>}
					{activeTab=='Alert Receiver'&&<Notify_Receiver/>}
					{activeTab=='Notification Script List'&&<NotificationScript/>}

				</section>
			</div>
		</div>
	);
}
