import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { getGsmCustomDashboadList, getAllCategoryTableData, getRolesById } from '../../../Commoncomponet/Utils';
import chroma from 'chroma-js';
import GSM_Custom_Dashboard from './GSM_Custom_Dashboard';
import GSM_Dashboard from './GSM_Dashboard';
import { Card, CardHeader } from '../common/Card';

// Reusable Tab component
const Tab = ({ active, label, color, onClick }) => {
    return (
        <button
            style={{
                backgroundColor: active ? chroma(color).alpha(0.4).css() : '',
                color: active ? chroma(color).darken(1.5).css() : color,
                borderBottom: `1px solid ${color}`,
                boxShadow: active ? `0 2px 8px -3px ${color}` : '',
            }}
            className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${active ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
            onClick={onClick}
        >
            {label}
        </button>
    );
};

const GSM_Main_Dashboard = () => {

    const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece'];
    const [activeTab, setActiveTab] = useState("");
    const [subActiveTab, setSubActiveTab] = useState("");
    const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "", ins_no: [] });
    const [AllCategory, setAllCategory] = useState({});
    const [FilterDay, setFilterDay] = useState([]);
    const [isRefDownload, setisRefDownload] = useState(false);
    const [isDownloadBtnShow, setisDownloadBtnShow] = useState(true);
    const [permision, setpermision] = useState({});
    const userInfo = JSON.parse(window.localStorage.getItem("user_info"));

    useEffect(() => {
        setProfile({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "", ins_no: [] })
        setisDownloadBtnShow(true)
    }, [activeTab, subActiveTab])

    const onGetAllCategoryTableData = async (info) => {
        const data = await getAllCategoryTableData()
        if (data.success === true) {
            setAllCategory(data.data.data)
        } else {
            console.log('something went wrong')
        }
    }

    const onGetFilterDaywise = async () => {
        const data2 = await getGsmCustomDashboadList()
        if (data2.success == true) {
            setFilterDay(data2?.data?.data || [])
        }
    }

    const onHandleRoles = async () => {
        const data = await getRolesById(userInfo?.ROLE);
        if (data.success === true) {
            const obj = data.data.data[0];
            setpermision({
                ...obj,
                ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS)
            });

        }
    }

    useEffect(() => {
        onGetAllCategoryTableData()
        onHandleRoles()
    }, []);

    useEffect(() => {
        if (Object.keys(permision).length && (permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Custom_Dashboard') || userInfo?.ROLE === "ROLE1661856938676")) {
            setActiveTab("Custom Dashboard")
            onGetFilterDaywise()
        } else if (Object.keys(permision).length && (permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Static_Dashboard') || userInfo?.ROLE === "ROLE1661856938676")) {
            setActiveTab("Static Dashboard")
        }
    }, [permision])

    return (
        <div className="main-content">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-data">
                        <Link to="/all-dashboard">
                            <i className="fas fa-tachometer-alt"></i> Home/
                        </Link>
                    </li>
                    <li className="breadcrumb-data">
                        <Link to='/gsm_dashboard'> GSM Dashboard</Link>
                    </li>
                </ol>
            </nav>
            <div className='section' >
                <div className='section-body' >
                <Card className='!tw-border-t   !tw-border-t-slate-100'  >
                        <CardHeader className="card-header d-flex tw-border-b-0" style={{ justifyContent: 'space-between' }}>
                            <ul className="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: '20px', alignItems: 'center' }}>
                                {permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Custom_Dashboard') || userInfo?.ROLE === "ROLE1661856938676" ? (
                                    <li>
                                        <Tab
                                            active={activeTab === "Custom Dashboard"}
                                            label="Custom Dashboard"
                                            color={btnColor[0]}
                                            onClick={() => setActiveTab('Custom Dashboard')}

                                        />
                                    </li>
                                ) : ''}
                                {permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Static_Dashboard') || userInfo?.ROLE === "ROLE1661856938676" ? (
                                    <li>
                                        <Tab active={activeTab === "Static Dashboard"} label="Static Dashboard" color={btnColor[2]} onClick={() => setActiveTab('Static Dashboard')} />
                                    </li>
                                ) : ''}
                                {/* // comments out */}
                                {Object.keys(permision).length&&!permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Custom_Dashboard')&&!permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Static_Dashboard')&&userInfo?.ROLE !== "ROLE1661856938676"?<h4>Don't have permission any dashboard</h4>:''}
                            </ul>
                        </CardHeader>


                    </Card>

                    {activeTab === 'Static Dashboard' && (
                        <div className="row">
                            <div className="col-12 col-sm-12 col-lg-12">
                                <Card className='!tw-border-t   !tw-border-t-slate-100'  >
                                    <CardHeader className="card-header d-flex tw-border-b-0" style={{ justifyContent: 'space-between' }}>
                                        <ul className="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: '20px', alignItems: 'center' }}>

                                            <Tab
                                                active={subActiveTab === "Device Dashboard"}
                                                label="Device Dashboard"
                                                color={btnColor[0]}
                                                onClick={() => setSubActiveTab('Device Dashboard')}
                                            />

                                            <Tab
                                                active={subActiveTab === "Meter Dashboard"}
                                                label="Meter Dashboard"
                                                color={btnColor[2]}
                                                onClick={() => setSubActiveTab('Meter Dashboard')}
                                            />

                                        </ul>
                                    </CardHeader>
                                </Card>
                            </div>

                            {subActiveTab && (
                                <GSM_Dashboard
                                    Profile={Profile}
                                    setProfile={setProfile}
                                    activeTab={subActiveTab}
                                    AccounData={AllCategory?.category0}
                                    GaData={AllCategory?.category1}
                                    CircleData={AllCategory?.category2}
                                    DivisionData={AllCategory?.category3}
                                    SubdevisionData={AllCategory?.category4}
                                    AreaData={AllCategory?.category5}
                                    AllCategoryJson={AllCategory}
                                    setisRefDownload={setisRefDownload}
                                    isRefDownload={isRefDownload}
                                    setisDownloadBtnShow={setisDownloadBtnShow}
                                    isDownloadBtnShow={isDownloadBtnShow}
                                />
                            )
                            }
                        </div>
                    )}

                    {activeTab === 'Custom Dashboard' && (
                        <GSM_Custom_Dashboard
                            Profile={Profile}
                            setProfile={setProfile}
                            AccounData={AllCategory?.category0}
                            GaData={AllCategory?.category1}
                            CircleData={AllCategory?.category2}
                            DivisionData={AllCategory?.category3}
                            SubdevisionData={AllCategory?.category4}
                            AreaData={AllCategory?.category5}
                            AllCategoryJson={AllCategory}
                            FilterDay={FilterDay}
                            onGetFilterDayWise={onGetFilterDaywise}
                            setFilterDay={setFilterDay}
                            setisRefDownload={setisRefDownload}
                            isRefDownload={isRefDownload}
                            setisDownloadBtnShow={setisDownloadBtnShow}
                            isDownloadBtnShow={isDownloadBtnShow}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default GSM_Main_Dashboard;