import React, { useState } from 'react'
import ButtonTabs from '../../../Commoncomponet/Tabs/ButtonTabs'
import All from '../../Meter/All'
import MeterModelIndex from '../../Meter/MeterModelIndex'
import Make from '../../Meter/Make'
import Category from '../../Meter/Category'
import Class from '../../Meter/Class'
import Firmware from '../../Meter/Firmware'
import Hardware from '../../Meter/Hardware'
import MeterNumber from '../../Meter/MeterNumber'
import Software from '../../Meter/Software'
import Type from '../../Meter/Type'
import Obis_List from '../../Meter/Obis_List'

const GSMMeterTables = () => {
    const [DefaultTab,setDefaultTab]=useState("tab1")
    const [IsDefault,setIsDefault]=useState(false)
    return (
        <>
                <section className="section">
                    <div className="">

                        <div className='tw-mt-2 tw-w-full tw-mx-auto tw-px-0'>
                            <ButtonTabs color="indigo" className={''} defaultTab={'tab1'} setIsDefault={setIsDefault} IsDefault={IsDefault}>
                                <div name='pcmt_eums_gsm_manufacturer_brand_meter_index' value={'tab1'} color='#22c55e' onClick={()=>setDefaultTab('tab1')}>
                                   <All/>
                                </div>
                                <div className='border-top' name='pcst_eums_gsm_meter_model_index' value={'tab2'} color='#6366f1' onClick={()=>setDefaultTab('tab2')}>
                                   <MeterModelIndex/>
                                </div>
                                
                                <div className='border-top' name='pcst_eums_gsm_meter_category' value={'tab4'} color='#8b5cf6' onClick={()=>setDefaultTab('tab4')}>
                                   <Category/>
                                </div>
                                <div className='border-top' name='pcst_eums_gsm_meter_class' value={'tab5'} color='#e3bf0e' onClick={()=>setDefaultTab('tab5')}>
                                   <Class/>
                                </div>
                                <div className='border-top' name='pcst_eums_gsm_meter_firmware' value={'tab6'} color='#f43f5e' onClick={()=>setDefaultTab('tab6')}>
                                   <Firmware/>
                                </div>
                                <div className='border-top' name='pcst_eums_gsm_meter_hw_protocol' value={'tab7'} color='#0ea5e9' onClick={()=>setDefaultTab('tab7')}>
                                   <Hardware/>
                                </div>
                                <div name='pcst_eums_gsm_meter_make' value={'tab8'} color='#22c55e' onClick={()=>setDefaultTab('tab8')}>
                                <Make/>
                                   </div>
                                   <div className='border-top' name='pcst_eums_gsm_meter_model_number' value={'tab9'} color='#6366f1' onClick={()=>setDefaultTab('tab9')}>
                                      <MeterNumber/>
                                   </div>
                                   <div className='border-top' name='pcst_eums_gsm_meter_sw_protocol' value={'tab10'} color='#0ea5e9' onClick={()=>setDefaultTab('tab10')}>
                                      <Software/>
                                   </div>
                                   <div className='border-top' name='pcst_eums_gsm_meter_type' value={'tab11'} color='#8b5cf6' onClick={()=>setDefaultTab('tab11')}>
                                      <Type/>
                                   </div>
                                   <div className='border-top' name='pcst_eums_obis_list' value={'tab12'} color='#e3bf0e' onClick={()=>setDefaultTab('tab12')}>
                                      <Obis_List/>
                                   </div>
                            </ButtonTabs>
                        </div>
                    </div>
                </section>
        </>
    )
}

export default GSMMeterTables