import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import $ from "jquery";
import moment from "moment";
import { geModemIdWiseColumnName, GetBuferCountInByStatus, getMobilAppErrorbinAndDuplicatbinCountList, getEventCountList, getGSMBufferInDeshboardAllCountByStatus, getGSMDataById, getHeatbeatStatusWiseCount, getLastInstantaneouseData, GetModemStatusByTable, GetModemStatusByTableAndDate, GetModemStatusDurationByTable, GetModemStatusDurationByTableAndDATE, getProfilNameByAccountId, getRolesById, getRSSITotalGSM, getScriptCodeIdWiseColumnName, getSecondaryConsumerById, getStartAndEndDateByTable, GetMobileAppTableNameWiseChartDataByDate, GetTableNameWiseChartDataByStartDateEndDate, GetTableNameWiseChartInRelayonTime, GetTableNameWiseChartInRelayonTimeByDate, GetTableNameWiseChartRSSIDataByStartDateEndDate, getTags, getTagsInGsm, LetestCountAndLastDateByMobileAppTableName, UpdateTagsInGsm ,GetCDFXmlDataInTable, getBillingReportData, getMobileMeterConsumerById, getPoratbleScriptCodeIdWiseColumnName} from "../../../Commoncomponet/Utils";
import Configuration from "./GsmViewDeshboard/Configration";
import ConsumerInfo from "./GsmViewDeshboard/ConsumerInfo";
import ModemData from "./GsmViewDeshboard/ModemData";
import MeterData from "./GsmViewDeshboard/MeterData";
import Extra from "./GsmViewDeshboard/Extra";
import Chart from "react-apexcharts";
import { DatePicker, Modal, Space } from "antd";
import chroma from 'chroma-js';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import swal from "sweetalert2";
import signal2 from '../../../assets/img/secondsignal.png'
import signal3 from '../../../assets/img/signal3.png'
import signal4 from '../../../assets/img/signal4.png'
import signal5 from '../../../assets/img/signal5.png'
import Buffer from './GsmViewDeshboard/Buffer'
import { BootstrapTooltip } from "../../../Commoncomponet/common";
import { GoogleMap, InfoWindowF, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import mapicon from '../../../assets/img/triangle-green.svg'
import mapRedicon from '../../../assets/img/triangle-red.svg'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	Decimation, TimeScale
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import heartbeat from '../../../assets/img/heartbeat.png'
import hourglass from '../../../assets/img/hourglass.png'
import calendar from '../../../assets/img/calendar.png'
import timetable from '../../../assets/img/timetable.png'
import alert from '../../../assets/img/alert.png'
import server from '../../../assets/img/server.png'
import ConsumerTask from "./GsmViewDeshboard/ConsumerTask/ConsumerTask";
import ChartLoaders from '../../../Commoncomponet/Loaders/ChartLoader'
import { X } from "react-feather";
// import Meter_GSM_dashboard from './Meter_GSM_dashboard'
import ReportJson from './GsmViewDeshboard/reportType.json'
import { toast } from "react-toastify";
import * as XLSX from 'xlsx';
import MobileDetails from "./GsmViewDeshboard/MobileDetails";
import BunchData from "./GsmViewDeshboard/BunchData";
import GoogleLoader from "../../../Commoncomponet/Loaders/GoogleLoader";


window.Swal = swal;

export default function MeterConsumerDeshboard() {
	const colors = ["#67b7dcd9", "#6794dcd9", "#6771dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9", "#dc67ced9", "#6771dcd9"]
	const colors15 = ["#67b7dcd9", "#6794dcd9", "#6771dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9", "#dc67ced9", "#6771dcd9", "#6794dcd9", "#67b7dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9"]
	const buttonArray = ["Dashboard", "Consumer Info", "Configuration*", "Meter Data",'Mobile Details','Bunch Data', "Buffer", "Extra",'Tasks'];
	const btnColor=['#4f5ece','#f59e0b','#fc544b','#54ca68',"#67b7dcd9",'#191d21','#7c5295','#4f5ece']
	const subbuttonArray = ["Meter"];
	const [btnText, setButtontext] = useState("Dashboard");
	const [btnSubText, setbtnSubText] = useState("Meter");
	const [GSMData, setGSMData] = useState({});
	const [InstantaneousChart, setInstantaneousChart] = useState({ total: [], date: [] })
	const [RelayOntimeChart, setRelayOntimeChart] = useState({ load1: [],load2: [],load3: [], date: [] })
	const [RelayOntimeChartBydate, setRelayOntimeChartBydate] = useState({ load1: [],load2: [],load3: [], date: [] })

	const [LoadsurvayChart, setLoadsurvayChart] = useState({ total: [], date: [] })
	const [DailyConsuptionChart,setDailyConsuptionChart]= useState({ total: [], date: [] })
	const [DailyChart, setDailyChart] = useState({ total: [], date: [] })
	const [MonthlyChart, setMonthlyChart] = useState({ total: [], date: [] })
	const [HardbeatChart, setHardbeatChart] = useState({ total: [], date: [] })
	const [RSSIChart, setRSSIChart] = useState({ total: [], date: [] })
	const [Instantaneous15Chart, setInstantaneous15Chart] = useState({ total: [], date: [] })
	const [RSSIChartInDate, setRSSIChartInDate] = useState({ total: [], date: [] })
	const [ModemStatus, setModemStatus] = useState({ online_count: [], offline_count: [], date: [] })
	const [ModemStatusDuration, setModemStatusDuration] = useState({ online_count: [], offline_count: [], date: [] })
	const [ModemStatusChartInDate, setModemStatusChartInDate] = useState({ online_count: [], offline_count: [], date: [] })
	const [isModalVisibleModemStatus, setisModalVisibleModemStatus] = useState(false)
	const [ismodalOpenRelayonTime, setismodalOpenRelayonTime] = useState(false)

	const [ModemSTChartInDate, setModemSTChartInDate] = useState({ online_count: [], offline_count: [], date: [] })

	const [isModalVisibleModemST, setisModalVisibleModemST] = useState(false)
	const [BufferChart, setBufferChart] = useState([])
	const [loader, setLoader] = useState(false);
	const [InstantaneousCount, setInstantaneousCount] = useState({})
	const [MonthlyCount, setMonthlyCount] = useState({})
	const [DailyCount, setDailyCount] = useState({})
	const [LoadsurvayCount, setLoadsurvayCount] = useState({})
	const [HardbeatCount, setHardbeatCount] = useState()
	const [InstantaneousLTData, setInstantaneousLTData] = useState({})
	const [InsLoader,setInsLoader]=useState(false)
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [dates, setDates] = useState({ start: '', end: '' })
	const [ModelHeader, setModelHeader] = useState('')
	const [isModalTags, setisModalTags] = useState(false)
	const [tagsList, settagsList] = useState([])
	const [AllBufferStatus, setAllBufferStatus] = useState([])
	const [tagArray, setTagArray] = useState([])
	const [TagBtn, setTagBtn] = useState([])
	const [ChartLoader, setChartLoader] = useState(false)
	const [expireDate, setExpireDate] = useState({})
	const [isModalVisibleRssi, setisModalVisibleRssi] = useState(false)
	const [isModalVisibleMonth, setisModalVisibleMonth] = useState(false)
	const [btnProccess, setbtnProccess] = useState(false)
	const [EventCount,setEventCount]=useState({})
	const [BinCount,setBinCount]=useState({})
	const [HbdStatus,setHbdStatus]=useState({})
	const [permision, setpermision] = useState({});
	const [ColumnName,setColumnName]=useState({})
	const [SecondaryCons,setSecondaryCons]=useState({})
	const [rolesGSMConfig,setrolesGSMConfig]=useState('false')
	const [isChartLoading, setIsChartLoading] = useState({ Heartbeat: false, GSM_RSSI: true, Battery_Percentage: true, Hourly: false, Dailyload: false, Monthbill: false, instantaneous: false, buffer: false })
	const [BufferModal,setBufferModal]=useState(false)
	const [defaultDate, setDefaultDate] = useState(moment().add(-moment().utcOffset(), "m").format("DD-MM-YYYY HH:mm:ss"))
	const params = useParams();
	const userInfo = JSON.parse(window.localStorage.getItem('user_info'))

	const onHandalebtn = (text) => {
		setButtontext(text);
		if(text!=='Dashboard'){
			setbtnSubText('')
		}else if(text==='Dashboard'){
			setbtnSubText('Modem')
		}
	};
	const handleCancel = () => {
		setIsModalVisible(false);
		setInstantaneous15Chart({ total: [], date: [] })
		setChartLoader(false)
	};
	const handleCancelModalRSSI = () => {
		setisModalVisibleRssi(false);
		setRSSIChartInDate({ total: [], date: [] })
		setChartLoader(false)

	}
	const handleCancelMonth = () => {
		setisModalVisibleMonth(false);
		setChartLoader(false)
		setInstantaneous15Chart({ total: [], date: [] })
	}

	const ongetHeatbeatStatusWiseCount=async(tableName)=>{
		const data=await getHeatbeatStatusWiseCount({ tableName: tableName })
		if(data.success==true){
			setHbdStatus(data.data.data[0])
		}else{
			console.log('Something Went Wrong')
		}
	}

	const onHandaleRoles = async () => {
		setLoader(true);
		$(".loader").fadeOut("slow");
		const data = await getRolesById(userInfo?.ROLE);
		if (data.success === true) {
			const obj = data.data.data[0];
			setpermision({...obj,ROLES_OTHER_PERMISSIONS:JSON.parse(obj.ROLES_OTHER_PERMISSIONS)});
			const permissionViewCard=JSON.parse(obj?.ROLES_OTHER_PERMISSIONS)
		onDataInGsm(permissionViewCard,obj)
		}else{
			console.log('Something Went Wrong')
		}
	}
	
	
	const ongetScriptCodeIdWiseColumnName=async(scriptdecode_id,modem_type,meter_type)=>{
		if(scriptdecode_id&&meter_type){
        const data=await getPoratbleScriptCodeIdWiseColumnName(scriptdecode_id)
        if(data.success===true){
            if(data?.data?.data?.length>0){
            setColumnName(JSON.parse(data?.data?.data[0]?.COLUMN_NAME||'{}'))
            }
        }else{
console.log('Something Went Wrong');
        }
	}else{
		const data=await geModemIdWiseColumnName(modem_type)
        if(data.success===true){
            if(data?.data?.data?.length>0){
            setColumnName(JSON.parse(data?.data?.data[0]?.COLUMN_NAME||'{}'))
            }
        }else{
console.log('Something Went Wrong');
        }
	}
    }

	const onConsumerUpdateDate=async()=>{
		const data = await getMobileMeterConsumerById(params.GSMID);
		if (data.success === true) {
			const objGSM = data.data.data[0];
			if (data.data.data.length > 0) {
				setGSMData({
					...objGSM,
					ADDRESS: JSON.parse(objGSM.address||'{}'),
					CONTACT_PERSON: JSON.parse(objGSM.contact_person||'[]'),
					INSTALLATION_DETAILS: JSON.parse(objGSM.INSTALLATION_DETAILS||'[]'),
					METER_DATA: JSON.parse(objGSM?.meter_data||'{}'),
					MODEM_DATA: JSON.parse(objGSM?.modem_data||'{}'),
					UNIQUE_TABLE_NAME: JSON.parse(objGSM?.unique_table_name_list||'{}'),
					HEARTBEAT_LAST_DATA: JSON.parse(objGSM?.HEARTBEAT_LAST_DATA||'{}')
				});
			}else{

			}
		}else{

		}
	
	}

	const onRefreshData =async()=>{
		const data = await getMobileMeterConsumerById(params.GSMID);
		if (data.success === true) {
			const objGSM = data.data.data[0];
			if (data.data.data.length > 0) {
				setGSMData({
					...objGSM,
					ADDRESS: JSON.parse(objGSM.address||'{}'),
					CONTACT_PERSON: JSON.parse(objGSM.contact_person||'[]'),
					INSTALLATION_DETAILS: JSON.parse(objGSM.INSTALLATION_DETAILS||'[]'),
					METER_DATA: JSON.parse(objGSM?.meter_data||'{}'),
					MODEM_DATA: JSON.parse(objGSM?.modem_data||'{}'),
					UNIQUE_TABLE_NAME: JSON.parse(objGSM?.unique_table_name_list||'{}'),
					HEARTBEAT_LAST_DATA: JSON.parse(objGSM?.HEARTBEAT_LAST_DATA||'{}')
				});
			}
		}
	}

	const onDataInGsm = async (permissionViewCard,permitObj) => {
		
		const data = await getMobileMeterConsumerById(params.GSMID);
		if (data.success === true) {
			const objGSM = data.data.data[0];
			if (data.data.data.length > 0) {
				setGSMData({
					...objGSM,
					ADDRESS: JSON.parse(objGSM.address||'{}'),
					CONTACT_PERSON: JSON.parse(objGSM.contact_person||'[]'),
					INSTALLATION_DETAILS: JSON.parse(objGSM.INSTALLATION_DETAILS||'[]'),
					METER_DATA: JSON.parse(objGSM?.meter_data||'{}'),
					MODEM_DATA: JSON.parse(objGSM?.modem_data||'{}'),
					UNIQUE_TABLE_NAME: JSON.parse(objGSM?.unique_table_name_list||'{}'),
					HEARTBEAT_LAST_DATA: JSON.parse(objGSM?.HEARTBEAT_LAST_DATA||'{}')
				});
				const groupObj={
					CATEGORY0:objGSM?.CAT0_ID,
					CATEGORY1:objGSM?.CAT1_ID,
					CATEGORY2:objGSM?.CAT2_ID,
					CATEGORY3:objGSM?.CAT3_ID,
					CATEGORY4:objGSM?.CAT4_ID,
					CATEGORY5:objGSM?.CAT5_ID
				}
				var flagDul=0
				if(userInfo?.ROLE!='ROLE1661856938676'){

				
				Object.entries(groupObj).map(([key,value],id)=>{
					if(value===''){
						if(flagDul==0){
						console.log('ROLES_PERMISSIONS_'+Object.entries(groupObj)[id-1][0],permitObj,permitObj['ROLES_PERMISSIONS_'+Object.entries(groupObj)[id-1][0]]);
						const permit=JSON.parse(permitObj['ROLES_PERMISSIONS_'+Object.entries(groupObj)[id-1][0]])
						setrolesGSMConfig(permit[Object.entries(groupObj)[id-1][0]+'_config'].filter((item)=>(item==Object.entries(groupObj)[id-1][1])).length>0?'true':'false')
						
						flagDul=1
						}
						return true
					}
				})
			}
				ongetScriptCodeIdWiseColumnName(objGSM?.scriptdecode_id,objGSM?.modem_type,objGSM?.meter_type)
				ongetProfilNameByAccountId(objGSM?.CAT0_ID)
				if(permissionViewCard?.GSM_CONSUMER_DASH?.MODEM?.view?.filter((item)=>(item=='Buffer')).length>0||userInfo?.ROLE=='ROLE1661856938676'){
		// onGetBuferCountInByStatus(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.GSM_BUFFER)
				}
		// onAllBufferdataByStatus(JSON.parse(objGSM?.unique_table_name_list||'{}')?.GSM_BUFFER)
		ongetEventCountList(objGSM?.unique_table_name_list)
		ongetErrorbinAndDuplicatbinCountList(objGSM?.unique_table_name_list)
		if(permissionViewCard?.GSM_CONSUMER_DASH?.MODEM?.view?.filter((item)=>(item=='Instantaneous')).length>0||userInfo?.ROLE=='ROLE1661856938676'){
				onGetTableNameWiseChartDataByDate(JSON.parse(objGSM?.unique_table_name_list||'{}')?.INSTANTANEOUS, objGSM?.scriptdecode_id)
		}
				onLetestCountAndLastDateByTableName(JSON.parse(objGSM?.unique_table_name_list||'{}')?.INSTANTANEOUS)
				if(permissionViewCard?.GSM_CONSUMER_DASH?.MODEM?.view?.filter((item)=>(item=='Dailyload')).length>0||userInfo?.ROLE=='ROLE1661856938676'){
				onGetTabledailyWiseChartDataByDate(JSON.parse(objGSM?.unique_table_name_list||'{}')?.DAILY_LOAD, objGSM?.scriptdecode_id)
				}
				if(permissionViewCard?.GSM_CONSUMER_DASH?.MODEM?.view?.filter((item)=>(item=='Loadsurvey')).length>0||userInfo?.ROLE=='ROLE1661856938676'){
				onGetTableLoadsurvayWiseChartDataByDate(JSON.parse(objGSM?.unique_table_name_list||'{}')?.LOAD_SURVEY, objGSM?.scriptdecode_id)
				}
				if(permissionViewCard?.GSM_CONSUMER_DASH?.MODEM?.view?.filter((item)=>(item=='Monthbill')).length>0||userInfo?.ROLE=='ROLE1661856938676'){
				onGetTablemonthWiseChartDataByDate(JSON.parse(objGSM?.unique_table_name_list||'{}')?.MONTH_BILL, objGSM?.scriptdecode_id)
				}
				onLoadsurvayLetestCountAndLastDateByTableName(JSON.parse(objGSM?.unique_table_name_list||'{}')?.LOAD_SURVEY)
				onDailyLetestCountAndLastDateByTableName(JSON.parse(objGSM?.unique_table_name_list||'{}')?.DAILY_LOAD)
				onMonthlyLetestCountAndLastDateByTableName(JSON.parse(objGSM?.unique_table_name_list||'{}')?.MONTH_BILL)
				
				if (objGSM?.TAGS?.length > 0) {
					ongetTagsInGsm(JSON.parse(objGSM?.TAGS))
				}
				ongetLastInstantaneouseData(JSON.parse(objGSM?.unique_table_name_list||'{}')?.INSTANTANEOUS)
				setLoader(false);
			} else {
				setLoader(false);

			}
		} else {
			console.log("Something Went Wrong");
			setLoader(false);
		}
	};

	const onGetSecondaryConsumerById=async()=>{
		const data=await getSecondaryConsumerById(params.GSMID)
		if(data.success==true){
			setSecondaryCons(data?.data?.data||{})
		}else{
			console.log('something Went Wrong')
		}
	}

	// useEffect(()=>{
	// 	onGetTableDailyConsumptionChartDataByDate(GSMData?.UNIQUE_TABLE_NAME?.DAILY_LOAD,GSMData.scriptdecode_id,ColumnName?.DAILY_LOAD?.filter((item)=>(item?.Column=='EMT_PENRG_CUM_IMP_WH'))[0]?.DivisionNumber)
	// },[ColumnName])

	const ongetEventCountList=async(tableName)=>{
		const data=await getEventCountList({UNIQUE_TABLE_NAME:tableName})
		if(data.success===true){
			setEventCount(data.data.data)
		}else{
			console.log('Something Went Wrong')
		}
	}

	const ongetErrorbinAndDuplicatbinCountList=async(tableName)=>{
		const data=await getMobilAppErrorbinAndDuplicatbinCountList({UNIQUE_TABLE_NAME:tableName})
		if(data.success===true){
			setBinCount(data.data.data)
		}else{
			console.log('Something Went Wrong')
		}
	}
	const secondtohours=(totalSecond)=>{
// 		var duration = moment.duration(totalSecond, 'seconds');
// var formatted = duration.format("HH.mm");
		
return moment.utc(1000 * totalSecond).format('HH.mm')
	}

	// const onGetTableNameWiseChartInRelayonTime= async (tableName, scriptdecode_id) => {
	// 	const data = await GetTableNameWiseChartInRelayonTime({ date: 7, tableName: tableName, scriptid: scriptdecode_id, col:'MMD_INFO_RELAY_ON_HIST_DD', fild: 'MMD_PERIOD_RELAY_ON_HIST_LOAD1_S as load1,MMD_PERIOD_RELAY_ON_HIST_LOAD2_S as load2,MMD_PERIOD_RELAY_ON_HIST_LOAD3_S as load3' })
	// 	if (data.success === true) {
	// 		setRelayOntimeChart({ load1: data.data.data.map((item) => (secondtohours(item?.lengths?.load1||0))),load2: data.data.data.map((item) => (secondtohours(item?.lengths?.load2||0))),load3: data.data.data.map((item) => (secondtohours(item?.lengths?.load3||0))), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
	// 	} else {
	// 		console.log('Something Went Wrong')
	// 	}
	// }

	const onGetTableNameWiseChartDataByDate = async (tableName, scriptdecode_id) => {
		setInstantaneousChart({total:[],date:[],res_code:''})
		const data = await GetMobileAppTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: scriptdecode_id, col: 'Clock$0_0_1_0_0_255', fild: 'count(*)' })
		if (data.success === true) {
			setInstantaneousChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('Something Went Wrong')
		setInstantaneousChart({total:[],date:[],res_code:data?.err?.response?.status})

		}
	}
	const onGetTableLoadsurvayWiseChartDataByDate = async (tableName, scriptdecode_id) => {
		setLoadsurvayChart({total:[],date:[],res_code:''})

		const data = await GetMobileAppTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: scriptdecode_id, col: 'Clock$0_0_1_0_0_255', fild: 'count(*)' })
		if (data.success === true) {
			setLoadsurvayChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('Something Went Wrong')
			setLoadsurvayChart({total:[],date:[],res_code:data?.err?.response?.status})


		}
	}
	// const onGetTableDailyConsumptionChartDataByDate = async (tableName, scriptdecode_id,division) => {
	// 	const data = await GetMobileAppTableNameWiseChartDataByDate({ date: 8, tableName: tableName, scriptid: scriptdecode_id, col: 'EMT_TS_DL', fild: 'EMT_PENRG_CUM_IMP_WH/'+ (division||1000) })
	// 	if (data.success === true) {
	// 		const TotalData=data.data.data
	// 		var DateArray=[]
	// 		TotalData.map((item,id) => {
	// 			if(id!=0){DateArray.push(moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))
	// 		}})
	// 		var totalArray=[]
	// 		TotalData.map((item,id) => {
	// 			if(id!=0){
	// 				if(item.lengths!=0){
	// 					if(TotalData[id-1]?.lengths==0){
	// 						if(id==1){
	// 							totalArray.push(0)
	// 						}else{
	// 							const lastIndex=TotalData.findLast((element,ids) => (ids<(id-1)?element?.lengths !=0:''))
	// 							if(lastIndex){
	// 								totalArray.push(0)
	// 							}else{
	// 								totalArray.push((item.lengths-(TotalData.findLast((element,ids) => (ids<(id-1)?element?.lengths !=0:''))?.lengths)).toFixed(3))
	// 							}
	// 						}
	// 					}else{
	// 						totalArray.push((item.lengths-TotalData[id-1]?.lengths).toFixed(3))
	// 					}
	// 				}else{
	// 					totalArray.push(0)
	// 				}
	// 			}
	// 		})
	// 		setDailyConsuptionChart({ total: totalArray, date:DateArray })
	// 	} else {
	// 		console.log('Something Went Wrong')
	// 	}
	// }
	const onGetTablemonthWiseChartDataByDate = async (tableName, scriptdecode_id) => {
		setMonthlyChart({total:[],date:[],res_code:''})

		const data = await GetMobileAppTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: scriptdecode_id, col: 'Register$0_0_0_1_2_255', fild: 'count(*)',TYPE:'Monthly' })
		if (data.success === true) {
			setMonthlyChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (item.date)) })
		} else {
			console.log('Something Went Wrong')
			setMonthlyChart({total:[],date:[],res_code:data?.err?.response?.status})

		}
	}
	const onGetTabledailyWiseChartDataByDate = async (tableName, scriptdecode_id) => {
		setDailyChart({total:[],date:[],res_code:''})

		const data = await GetMobileAppTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: scriptdecode_id, col: 'Clock$0_0_1_0_0_255', fild: 'count(*)' })
		if (data.success === true) {
			setDailyChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
			
		} else {
			console.log('Something Went Wrong')
			setDailyChart({total:[],date:[],res_code:data?.err?.response?.status})

		}
	}

	const onGetTableHardbeatWiseChartDataByDate = async (tableName, scriptdecode_id) => {
		setHardbeatChart({total:[],date:[],res_code:''})
		const data = await GetMobileAppTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: scriptdecode_id, col: 'SCR_TS_ENTRY', fild: 'count(*)' })
		if (data.success === true) {
			setHardbeatChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('Something Went Wrong')
			setHardbeatChart({total:[],date:[],res_code:data?.err?.response?.status})
		}
	}

	const onGetModemStatusByTable = async (tableName) => {
		setModemStatus({online_count:[],offline_count:[],date:[],res_code:''})
		const data = await GetModemStatusByTable({ date: 7, tableName: tableName })
		if (data.success === true) {
			setModemStatus({ online_count: data.data.data.map((item) => (item.online_count)), offline_count: data.data.data.map((item) => (item.offline_count)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('Something Went Wrong')
			setModemStatus({online_count:[],offline_count:[],date:[],res_code:data?.err?.response?.status})
		}
	}

	const onGetModemStatusDurationByTable = async (tableName) => {
		setModemStatusDuration({online_count:[],offline_count:[],date:[],res_code:''})
		const data = await GetModemStatusDurationByTable({ date: 7, tableName: tableName })
		if (data.success === true) {
			setModemStatusDuration({ online_count: data.data.data.map((item) => (item.online_total_duretion)), offline_count: data.data.data.map((item) => (item.offline_total_duretion)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('Something Went Wrong')
			setModemStatusDuration({online_count:[],offline_count:[],date:[],res_code:data?.err?.response?.status})
		}
	}
	const onGetTableRSSIWiseChartDataByDate = async (tableName, scriptdecode_id) => {
		setRSSIChart({total:[],date:[],res_code:''})
		setIsChartLoading({...isChartLoading,GSM_RSSI:true})
		const data = await getRSSITotalGSM({ tableName: tableName, scriptid: scriptdecode_id })
		if (data.success === true) {
			setRSSIChart({ total: data.data.data.map((item) => (item.GSM_RSSI)), date: data.data.data.map((item) => ([moment.utc(item.ENTRYTS).format("DD-MM-YYYY"),moment.utc(item.ENTRYTS).format("hh:mm:ss A (ddd)")])) })
		setIsChartLoading({...isChartLoading,GSM_RSSI:false})

		} else {
			console.log('Something Went Wrong')
		setIsChartLoading({...isChartLoading,GSM_RSSI:false})
			setRSSIChart({total:[],date:[],res_code:data?.err?.response?.status})

		}
	}
	const onGetBuferCountInByStatus = async (tableName) => {
		const data = await GetBuferCountInByStatus(params.GSMID,tableName)
		if (data.success === true) {
			setBufferChart(data.data.data)
		} else {
			console.log('Something Went Wrong')
		}
	}

	const onAllBufferdataByStatus = async (tableName) => {
		const data = await getGSMBufferInDeshboardAllCountByStatus(params.GSMID,tableName)
		if (data.success === true) {
			setAllBufferStatus(data.data.data)
		} else {
			console.log('somethig Went wrong');
		}
	}
	useEffect(() => {
		onHandaleRoles()
		
	}, []);
	const onGetTableNameWiseChartDataBy15Date = async (tableName) => {
		const data = await GetMobileAppTableNameWiseChartDataByDate({ date: 15, tableName: GSMData?.UNIQUE_TABLE_NAME?.INSTANTANEOUS, scriptid: GSMData?.scriptdecode_id, col: 'ENTRYTS', fild: 'count(*)' })
		if (data.success === true) {
			setInstantaneous15Chart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('Something Went Wrong')
		}
	}
	const onLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByMobileAppTableName({ tableName: tableName, col: 'Clock$0_0_1_0_0_255' })
		if (data.success === true) {
			setInstantaneousCount(data.data.data)
		} else {
			console.log('Something Went Wrong')
		}
	}
	const onLoadsurvayLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByMobileAppTableName({ tableName: tableName, col: 'Clock$0_0_1_0_0_255' })
		if (data.success === true) {
			setLoadsurvayCount(data.data.data)
		} else {
			console.log('Something Went Wrong')
		}
	}
	const onDailyLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByMobileAppTableName({ tableName: tableName, col: 'Clock$0_0_1_0_0_255' })
		if (data.success === true) {
			setDailyCount(data.data.data)
		} else {
			console.log('Something Went Wrong')
		}
	}
	const onMonthlyLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByMobileAppTableName({ tableName: tableName, col: 'Register$0_0_0_1_2_255' })
		if (data.success === true) {
			setMonthlyCount(data.data.data)
		} else {
			console.log('Something Went Wrong')
		}
	}

	const onHardbeatLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByMobileAppTableName({ tableName: tableName, col: 'SCR_TS_ENTRY' })
		if (data.success === true) {
			setHardbeatCount(data.data.data)
		} else {
			console.log('Something Went Wrong')
		}
	}

	const ongetLastInstantaneouseData = async (tableName) => {
		setInsLoader(true)
		const data = await getLastInstantaneouseData({ tableName: tableName})
		if (data.success === true) {
			setInstantaneousLTData(data?.data?.data[0])
		setInsLoader(false)
		} else {
			console.log('Something Went Wrong')
		setInsLoader(false)

		}
	}

	

	const Instantaneousstate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: InstantaneousChart.date?.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Instantaneous",
				data: InstantaneousChart.total
			}
		]
	};
	const Loadsurveystate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: LoadsurvayChart.date?.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Loadsurvey",
				data: LoadsurvayChart.total
			}
		]
	};
	const Dailystate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: DailyChart.date?.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Dailyload",
				data: DailyChart.total
			}
		]
	};
	const Monthlytate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: MonthlyChart.date?.map((item)=>([item])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Monthbill",
				data: MonthlyChart.total
			}
		]
	};
	const Hardbeatstate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: HardbeatChart?.date?.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Heartbeat",
				data: HardbeatChart.total
			}
		]
	};
	const RSSIstate = {
		options: {
			chart: {
				id: 'area-datetime',
				toolbar: {
					show: false
				}
			},
			dataLabels: {
				enabled: true,
				offsetY: -10,
				style: {

					// fontSize: "12px",
					fontSize: "10px",
					fontWeight: 100,
					colors: ["#0d94fa"]
				},
				background: {
					enabled: true,

				},
			},
			xaxis: {
				categories: RSSIChart.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 10,
					style: {
						fontSize: "9px"
					}
				},

			},
			yaxis: {
				forceNiceScale: true,
				min: 0,
				max: 32,
			},
			markers: {
				size: 7,
				colors: ["#0d94fa"],
				strokeWidth: 0,
				hover: {
					sizeOffset: 1
				}
			},
			grid: {
				padding: {
					top: 0,
					right: 30,
					bottom: 0,
					left: 30
				},

			},

			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 1,
					opacityFrom: 0.7,
					opacityTo: 0.9,
					stops: [0, 100]
				}
			},
		},

		series: [
			{
				name: "RSSI",
				data: RSSIChart.total
			}
		]
	};


	const Instantaneous15state = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 10,
						fontSize: '10px'
					},
					
				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: Instantaneous15Chart.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: true
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: ModelHeader,
				data: Instantaneous15Chart.total
			}
		]
	};

	const RSSIstateIndate = {
		options: {
			chart: {
				id: 'area-datetime',
				toolbar: {
					show: true
				}
			},
			dataLabels: {
				enabled: true,
				offsetY: 0,
				style: {

					// fontSize: "12px",
					fontSize: "10px",
					fontWeight: 100,
					colors: ["#0d94fa"]
				},
				background: {
					enabled: true,

				},
			},
			xaxis: {
				categories: RSSIChartInDate.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 10,
					style: {
						fontSize: "9px"
					}
				},

			},
			yaxis: {
				forceNiceScale: true,
				min: 0,
				max: 32,
			},
			markers: {
				size: 7,
				colors: ["#0d94fa"],
				strokeWidth: 0,
				hover: {
					sizeOffset: 1
				}
			},
			grid: {
				padding: {
					top: 0,
					right: 30,
					bottom: 0,
					left: 30
				},

			},

			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 1,
					opacityFrom: 0.7,
					opacityTo: 0.9,
					stops: [0, 100]
				}
			},
		},

		series: [
			{
				name: "RSSI",
				data: RSSIChartInDate.total
			}
		]
	};
	const ModemStatusState = {
		series: [{
			name: 'Online',
			data: ModemStatus.online_count
		}, {
			name: 'Offline',
			data: ModemStatus.offline_count
		}],
		options: {
			legend: {
				show: false,

			},
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				toolbar: {
					show: false
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -10,
						offsetY: 0
					}
				}
			}],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					borderRadiusApplication: 'end',
					columnWidth:ModemStatus.date.length<3?10+(60 / (1 + 30*Math.exp(-ModemStatus.date.length /3))):'60%',
					dataLabels: {
						total: {
							style: {
								fontSize: '13px',
								fontWeight: 900
							}
						}
					}
				},
			},
			xaxis: {
				categories: ModemStatus?.date?.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				}
			},
			yaxis: [{
				allowDecimals: false,
				floating: false,
				labels: {
					formatter: (value) => {
						return value.toFixed(0)
					},
				}
			}],
			fill: {
				opacity: 1
			},
			colors: ['#00e396', '#feb019']
		},
	}

	const minutestohours=(totalMinutes)=>{
		var minutes = Math.round(totalMinutes % 60);
var hours = Math.round((totalMinutes-minutes)/60);
return hours + ':' + minutes;
	}
	
	const ModemStatusDurationState = {
		series: [{
			name: 'Online Duration',
			data: ModemStatusDuration.online_count
		}, {
			name: 'Offline Duration',
			data: ModemStatusDuration.offline_count
		}],
		options: {
			legend: {
				show: false,

			},
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				toolbar: {
					show: false
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -10,
						offsetY: 0
					}
				}
			}],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					borderRadiusApplication: 'end',
					dataLabels: {
						total: {
							style: {
								fontSize: '13px',
								fontWeight: 900
							}
						}
					}
				},
			},
			xaxis: {
				categories: ModemStatusDuration?.date?.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				}
			},
			tooltip: {
				shared: true,
				intersect: false,
				custom: function ({ series, seriesIndex, dataPointIndex, w }) {
					console.log(series, seriesIndex, dataPointIndex, w);
					return `<div style={{padding:'5px'}}>` + w.config.xaxis.categories[dataPointIndex] + `<br/>` + 'online:' + series[seriesIndex][dataPointIndex] + ' ' + 'Minutes' + `(${minutestohours(series[seriesIndex][dataPointIndex] )} Hours)` + `<br/>` + 'offline:' + series[seriesIndex + 1][dataPointIndex] + ' ' + 'Minutes' + `(${minutestohours(series[seriesIndex+1][dataPointIndex])} Hours)` + `</div>`
				}
			},
			yaxis: [{
				allowDecimals: false,
				floating: false,
			}],

			fill: {
				opacity: 1
			},
			colors: ['#00e396', '#feb019']
		},
	}
	const ModemStatusDurationStateByDate = {
		series: [{
			name: 'Online Duration',
			data: ModemStatusChartInDate.online_count
		}, {
			name: 'Offline Duration',
			data: ModemStatusChartInDate.offline_count
		}],
		options: {
			legend: {
				show: false,

			},
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				toolbar: {
					show: false
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -10,
						offsetY: 0
					}
				}
			}],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					borderRadiusApplication: 'end',
					dataLabels: {
						total: {
							style: {
								fontSize: '13px',
								fontWeight: 900
							}
						}
					}
				},
			},
			xaxis: {
				categories: ModemStatusChartInDate?.date?.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				}
			},
			tooltip: {
				shared: true,
				intersect: false,
				custom: function ({ series, seriesIndex, dataPointIndex, w }) {
					console.log(series, seriesIndex, dataPointIndex, w);
					return `<div style={{padding:'5px'}}>` + w.config.xaxis.categories[dataPointIndex] + `<br/>` + series[seriesIndex][dataPointIndex] + ' ' + 'Minutes' + `<br/>` + 'offline:' + series[seriesIndex + 1][dataPointIndex] + ' ' + 'Minutes' + `</div>`
				}
			},
			yaxis: [{
				allowDecimals: false,
				floating: false,
			}],

			fill: {
				opacity: 1
			},
			colors: ['#00e396', '#feb019']
		},
	}

	const ModemStatusStatebyDate = {
		series: [{
			name: 'Online',
			data: ModemSTChartInDate.online_count
		}, {
			name: 'Offline',
			data: ModemSTChartInDate.offline_count
		}],
		options: {
			legend: {
				show: false,

			},
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				toolbar: {
					show: false
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -10,
						offsetY: 0
					}
				}
			}],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					borderRadiusApplication: 'end',
					dataLabels: {
						total: {
							style: {
								fontSize: '13px',
								fontWeight: 900
							}
						}
					},
					columnWidth:'60%',
				},
			},
			xaxis: {
				categories: ModemSTChartInDate?.date?.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				}
			},
			yaxis: [{
				allowDecimals: false,
				floating: false,
				labels: {
					formatter: (value) => {
						return value.toFixed(0)
					},
				}
			}],
			fill: {
				opacity: 1
			},
			colors: ['#00e396', '#feb019']
		},
	}



	const onOpenTagsModal = async () => {
		const data = await getTags()
		if (data.success === true) {
			settagsList(data.data.data.map((item) => ({ value: item?.unique_id, label: item.TAG_NAME, color: item?.TAG_COLOR })))
			setisModalTags(true)
		} else {
			console.log('Something Went Wrong');
		}
	}
	const customStyles = {
		menu: provided => ({
			...provided, width: "max-content",
			minWidth: "100%", zIndex: 9999
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			const color = chroma(data.color);
			return {
				...styles,
				backgroundColor: isSelected
					? data.color
					: isFocused
						? color.alpha(0.1).css()
						: undefined,
				color: data.color,

				':active': {
					...styles[':active'],
					backgroundColor: !isDisabled
						? isSelected
							? data.color
							: color.alpha(0.3).css()
						: undefined,
				},
			};
		},
		multiValue: (provided, { data }) => {
			console.log("Colorr ====== ", data)
			const color = chroma(data.color);
			return {
				...provided,
				width: 'min-content',
				backgroundColor: color.alpha(0.2).css(),
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: data.color,
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: data.color,
			':hover': {
				backgroundColor: data.color,
				color: 'white',
			},
		}),


	}
	const ongetTagsInGsm = async (item) => {
		const data = await getTagsInGsm({ tags: item })
		if (data.success === true) {
			setTagBtn(data.data.data)
			setTagArray(data.data.data.map((items) => (items?.unique_id)))
		} else {
			console.log('Something Went Wrong')
		}
	}

	const onSubmitTags = async () => {
		const data = await UpdateTagsInGsm({ tags: tagArray }, params.GSMID)
		if (data.success === true) {
			ongetTagsInGsm(tagArray)
			swal.fire('Success', 'Update Tag Successfuly', 'success');
			setisModalTags(false)
		} else {
			console.log('Something Went Wrong')
		}
	}
	const colorBuffer = ["#191d21", "#54ca68", "#6777ef", "#3abaf4", "#fc544b", "#ffa426"]

	const series = [
		{
			name: "Total",
			type: "column",
			data: BufferChart?.map((item) => (item?.total_length))
		}, {
			name: "Success",
			type: "column",
			data: BufferChart?.map((item) => (item?.success))
		}, {
			name: "Waiting for downlink",
			type: "column",
			data: BufferChart?.map((item) => (item?.wait_down))
		}, {
			name: "Cancelled by user",
			type: "column",
			data: BufferChart?.map((item) => (item?.cancel))
		},
		{
			name: "Timeout",
			type: "column",
			data: BufferChart?.map((item) => (item?.timeout))
		}, {
			name: "Waiting for Uplink",
			type: "column",
			data: BufferChart?.map((item) => (item?.wait_up))
		}

	];

	// const seriesRelayTime = [
	// 	{
	// 		name: "Relay 1",
	// 		type: "column",
	// 		data: RelayOntimeChart?.load1
	// 	}, {
	// 		name: "Relay 2",
	// 		type: "column",
	// 		data: RelayOntimeChart?.load2
	// 	}, {
	// 		name: "Relay 3",
	// 		type: "column",
	// 		data: RelayOntimeChart?.load3
	// 	}
	// ];

	// const DailyConsumption = {
	// 	options: {
	// 		legend: {
	// 			show: false,
	// 		},

	// 		plotOptions: {
	// 			bar: {
	// 				distributed: true,
	// 				borderRadius: 5,
	// 				borderRadiusApplication: 'end',
	// 				dataLabels: {
	// 					position: 'top',
	// 					offsetY: 30,
	// 					fontSize: '10px'
	// 				},

	// 			}
	// 		},
	// 		dataLabels: {
	// 			offsetY: 10
	// 		},
	// 		xaxis: {
	// 			floating: false,
	// 			categories: DailyConsuptionChart.date,
	// 			labels: {
	// 				show: true,
	// 				rotate: -90,
	// 				rotateAlways: true,
	// 				offsetX: 0,
	// 				offsetY: 3,
	// 			},
	// 			// tickPlacement: 'on'
	// 		},
	// 		fill: {
	// 			type: 'bar',
	// 			gradient: {
	// 				shade: 'dark',
	// 				type: "vertical",
	// 				shadeIntensity: 0.5,
	// 				gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
	// 				inverseColors: true,
	// 				opacityFrom: 1,
	// 				opacityTo: 1,
	// 				stops: [0, 50, 100],
	// 				colorStops: []
	// 			}
	// 		},
	// 		chart: {
	// 			toolbar: {
	// 				show: false
	// 			}
	// 		},
	// 		noData: {
	// 			text: "Loading...",
	// 			align: 'center',
	// 			verticalAlign: 'middle',
	// 			offsetX: 0,
	// 			offsetY: 0,
	// 			style: {
	// 				color: "#000000",
	// 				fontSize: '14px',
	// 				fontFamily: "Helvetica"
	// 			},
	// 		},
	// 		colors: colors
	// 		// colors: [
	// 		//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
	// 		// ]
	// 	},

	// 	series: [
	// 		{
	// 			name: "Daily Consumption",
	// 			data: DailyConsuptionChart?.total
	// 		}
	// 	]
	// };
	const seriesRelayTimeBydate = {
		labels: RelayOntimeChartBydate?.date,
		datasets: [
			{
				label: "Relay 1",
				data: RelayOntimeChartBydate?.load1,
				barPercentage: 1.0,
				backgroundColor: '#fc544b',
				borderRadius: 5,
			},
			{
				label: "Relay 2",
				data:RelayOntimeChartBydate?.load2,
				barPercentage: 1.0,
				backgroundColor: '#ffa426',
				borderRadius: 5,
			},
			{
				label: "Relay 3",
				data: RelayOntimeChartBydate?.load2,
				barPercentage: 1.0,
				backgroundColor: '#0000ff',
				borderRadius: 5,
			}
		],
	};
	
	const options = {
		chart: {
			id: "line"
		},
		
		plotOptions: {
			bar: {
				borderRadius: 5,
			}
		},
		dataLabels: {
			enabled: false
		},
		xaxis: {
			categories: BufferChart?.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))),
			//will be displayed on the x-asis
		}
		,
		colors:colorBuffer
	};

	// const optionsRelayonTime = {
	// 	chart: {
	// 		id: "line"
	// 	},
	// 	legend: {
	// 		show: false,

	// 	},
	// 	plotOptions: {
	// 		bar: {
	// 			borderRadius: 5,
	// 		}
	// 	},
	// 	dataLabels: {
	// 		enabled: false
	// 	},
	// 	xaxis: {
	// 		categories: BufferChart?.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))),
	// 		labels: {
	// 			show: true,
	// 			rotate: -90,
	// 			rotateAlways: true,
	// 			offsetX: 0,
	// 			offsetY: 3,
	// 		}
	// 		//will be displayed on the x-asis
	// 	}
	// 	,
	// 	colors:['#fc544b','#ffa426','#0000ff']
	// };

	const optionsRelayonTimeBydate = {
		chart: {
			id: "line"
		},
		legend: {
			show: false,

		},
		plotOptions: {
			bar: {
				borderRadius: 5,
			}
		},
		dataLabels: {
			enabled: false
		},
		xaxis: {
			categories: BufferChart?.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))),
			labels: {
				show: true,
				rotate: -90,
				rotateAlways: true,
				offsetX: 0,
				offsetY: 3,
			}
			//will be displayed on the x-asis
		}
		,
		colors:['#fc544b','#ffa426','#0000ff']
	};


	const sum = (arr) => {
		const result = arr.reduce((total, currentValue) => total = total + currentValue, 0);
		return result
	}

	const onOpenModal = (tableName, col, header) => {
		// onGetTableNameWiseChartDataBy15Date()
		ongetStartAndEndDateByTable(tableName, col)
		setModelHeader(header)
	}
	const onOpenModalInRelayontime = async(tableName, col, header) => {
		ongetStartAndEndDateByTable(tableName, col,true)
		setismodalOpenRelayonTime(true)
		
	}
	const onLoadDataRelayontime=async()=>{
		const data = await GetTableNameWiseChartInRelayonTimeByDate({ startDate: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"),col:'MMD_INFO_RELAY_ON_HIST_DD', fild: 'MMD_PERIOD_RELAY_ON_HIST_LOAD1_S as load1,MMD_PERIOD_RELAY_ON_HIST_LOAD2_S as load2,MMD_PERIOD_RELAY_ON_HIST_LOAD3_S as load3', tableName: GSMData.unique_table_name_list.RELAY_ON_TIME })
		if (data.success === true) {
			setRelayOntimeChartBydate({ load1: data.data.data.map((item) => (secondtohours(item?.lengths?.load1||0))),load2: data.data.data.map((item) => (secondtohours(item?.lengths?.load2||0))),load3: data.data.data.map((item) => (secondtohours(item?.lengths?.load3||0))), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('Something Went Wrong')
		}
	}
	const ongetStartAndEndDateByTable = async (tableName, col,modal) => {
		document.body.style.cursor = 'Went'
		const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
		if (data.success === true) {
			setExpireDate(data.data)
			if(!modal){
			setIsModalVisible(true)
			}
			document.body.style.cursor = 'default'
		} else {
			console.log("Something Went Wrong")
			document.body.style.cursor = 'default'
		}
	}

	const ongetInStatusStartAndEndDateByTable = async (tableName, col) => {
		document.body.style.cursor = 'Went'
		const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
		if (data.success === true) {
			setExpireDate(data.data)
			document.body.style.cursor = 'default'
		} else {
			console.log("Something Went Wrong")
			document.body.style.cursor = 'default'
		}
	}
	const onOpenModalRssi = (tableName, col, header) => {
		// onGetTableNameWiseChartDataBy15Date()
		ongetStartAndEndDateInRssiByTable(tableName, col)
		setModelHeader(header)
	}
	const ongetStartAndEndDateInRssiByTable = async (tableName, col) => {
		document.body.style.cursor = 'Went'
		const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
		if (data.success === true) {
			setExpireDate(data.data)
			setisModalVisibleRssi(true)
			document.body.style.cursor = 'default'
		} else {
			console.log("Something Went Wrong")
			document.body.style.cursor = 'default'
		}
	}
	const onChange = (date, dateString) => {
		setDates({ ...dates, start: dateString })
	};
	const onChanges = (date, dateString) => {
		setDates({ ...dates, end: dateString })
	};

	const onGetDateWiseChart = async () => {
		setChartLoader(true)
		var tableName = ''
		var col = ''
		if (ModelHeader == 'Instantaneous') {
			tableName = GSMData.unique_table_name_list.INSTANTANEOUS
			col = 'Clock$0_0_1_0_0_255'
		} else if (ModelHeader == 'Heartbeat') {
			tableName = GSMData.unique_table_name_list.HEARTBEAT
			col = 'SCR_TS_ENTRY'
		} else if (ModelHeader == 'Monthbill') {
			tableName = GSMData.unique_table_name_list.MONTH_BILL
			col = 'Register$0_0_0_1_2_255'
		} else if (ModelHeader == 'Dailyload') {
			tableName = GSMData.unique_table_name_list.DAILY_LOAD
			col = 'Clock$0_0_1_0_0_255'
		} else if (ModelHeader == 'Loadsurvey') {
			tableName = GSMData.unique_table_name_list.LOAD_SURVEY
			col = 'Clock$0_0_1_0_0_255'
		}
		else if (ModelHeader == 'Daily Consumtion History') {
			tableName = GSMData.unique_table_name_list.DAILY_LOAD
			col = 'Clock$0_0_1_0_0_255'
		}
		const data = await GetTableNameWiseChartDataByStartDateEndDate({ startDate: ModelHeader == 'Monthbill' ? dates.start : moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: ModelHeader == 'Monthbill' ? dates.end : moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), fild: ModelHeader == 'Daily Consumtion History'?'EMT_PENRG_CUM_IMP_WH/'+(ColumnName?.DAILY_LOAD?.filter((item)=>(item?.Column=='EMT_PENRG_CUM_IMP_WH'))[0]?.DivisionNumber||1000):'count(*)', col: col, scriptid: GSMData?.scriptdecode_id, tableName: tableName })
		if (data.success === true) {
			if(ModelHeader == 'Daily Consumtion History'){
				const TotalData=data.data.data
			var DateArray=[]
			TotalData.map((item,id) => {
				if(id!=0){DateArray.push(moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))
			}})
			var totalArray=[]
			TotalData.map((item,id) => {
				if(id!=0){
					if(item.lengths!=0){
						if(TotalData[id-1]?.lengths==0){
							if(id==1){
								totalArray.push(0)
							}else{
								const lastIndex=TotalData.findLast((element,ids) => (ids<(id-1)?element?.lengths !=0:''))
								if(lastIndex){
									totalArray.push(0)
								}else{
									totalArray.push((item.lengths-(TotalData.findLast((element,ids) => (ids<(id-1)?element?.lengths !=0:''))?.lengths)).toFixed(3))
								}
							}
						}else{
							totalArray.push((item.lengths-TotalData[id-1]?.lengths).toFixed(3))
						}
					}else{
						totalArray.push(0)
					}
				}
			})
				setInstantaneous15Chart({ total: totalArray, date: DateArray })
			}else{
			setInstantaneous15Chart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (ModelHeader == 'Monthbill' ? moment(item.date, "YYYY-MM").format("MMMM-YYYY") : moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		}
			setChartLoader(false)

		} else {
			console.log('Something Went Wrong')
			setChartLoader(false)
		}
	}

	const onGetDateWiseInRSSIChart = async () => {
		setChartLoader(true)
		var tableName = GSMData.unique_table_name_list.HEARTBEAT
		var col = 'SCR_TS_ENTRY'
		const data = await GetTableNameWiseChartRSSIDataByStartDateEndDate({ startDate: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), fild: 'SCR_TS_ENTRY as ENTRYTS,MMD_INFO_GSM_RSSI as lengths', col: col, scriptid: GSMData?.scriptdecode_id, tableName: tableName })
		if (data.success === true) {
			setRSSIChartInDate({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment.utc(item.ENTRYTS).format("DD-MM-YYYY hh:mm:ss A (ddd)")))})
			setChartLoader(false)
		} else {
			console.log('Something Went Wrong')
			setChartLoader(false)
		}
	}

	const onGetDateWiseInModemStatusChart = async () => {
		setChartLoader(true)
		var tableName = GSMData.unique_table_name_list.ONLINE_OFFLINE
		var col = 'ENTRYTS'
		const data = await GetModemStatusDurationByTableAndDATE({ startDate: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), tableName: tableName })
		if (data.success === true) {
			setModemStatusChartInDate({ online_count: data.data.data.map((item) => (item.online_total_duretion)), offline_count: data.data.data.map((item) => (item.offline_total_duretion)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
			setChartLoader(false)
		} else {
			console.log('Something Went Wrong')
			setChartLoader(false)
		}
	}

	const onGetModemStatusByTableAndDate = async () => {
		setChartLoader(true)
		var tableName = GSMData.unique_table_name_list.ONLINE_OFFLINE
		var col = 'ENTRYTS'
		const data = await GetModemStatusByTableAndDate({ startDate: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), tableName: tableName })
		if (data.success === true) {
			setModemSTChartInDate({ online_count: data.data.data.map((item) => (item.online_count)), offline_count: data.data.data.map((item) => (item.offline_count)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
			setChartLoader(false)
		} else {
			console.log('Something Went Wrong')
			setChartLoader(false)
		}
	}

	const onRefreshBtn = () => {
		setbtnProccess(true)
		onGetTableNameWiseChartDataByDate(GSMData?.UNIQUE_TABLE_NAME.INSTANTANEOUS, GSMData?.scriptdecode_id)
		onLetestCountAndLastDateByTableName(GSMData?.UNIQUE_TABLE_NAME?.INSTANTANEOUS)
		onGetTabledailyWiseChartDataByDate(GSMData?.UNIQUE_TABLE_NAME?.DAILY_LOAD, GSMData?.scriptdecode_id)
		onGetTableLoadsurvayWiseChartDataByDate(GSMData?.UNIQUE_TABLE_NAME?.LOAD_SURVEY, GSMData?.scriptdecode_id)
		onGetTablemonthWiseChartDataByDate(GSMData?.UNIQUE_TABLE_NAME?.MONTH_BILL, GSMData?.scriptdecode_id)
		onLoadsurvayLetestCountAndLastDateByTableName(GSMData?.UNIQUE_TABLE_NAME?.LOAD_SURVEY)
		onDailyLetestCountAndLastDateByTableName(GSMData?.UNIQUE_TABLE_NAME?.DAILY_LOAD)
		onMonthlyLetestCountAndLastDateByTableName(GSMData?.UNIQUE_TABLE_NAME?.MONTH_BILL)
		setbtnProccess(false)

	}
	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Filler,
		Legend,
		zoomPlugin, ChartDataLabels,
		Decimation, TimeScale
	);

	// let range_min = new Date(moment(RSSIChartInDate.date[RSSIChartInDate.date.length - 1], "DD-MM-YYYY hh:mm:ss A").format("MM-DD-YYYY hh:mm:ss A"))  //start date
	// range_min.setHours(range_min.getHours() - 10);

	// let range_max = new Date(moment(RSSIChartInDate.date[RSSIChartInDate.date.length - 1], "DD-MM-YYYY hh:mm:ss A").format("MM-DD-YYYY hh:mm:ss A")) //end date
	// range_max.setHours(range_max.getHours());

	// console.log("Datessss == ", moment(RSSIChartInDate.date[RSSIChartInDate.date.length - 1], "DD-MM-YYYY hh:mm:ss A").format("MM-DD-YYYY hh:mm:ss A"))
	// console.log("Datessss == ", range_min, range_max)

	const optionsRelayontime = {
		// parsing: false,
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				// type: 'time',
				ticks: {

					maxRotation: 90,
					minRotation: 90,
					font: {
						size: 10
					},

				}
			},

			y: {
				beginAtZero: true
			},
		},

		// parsing: false,
		plugins: {
			decimation: {
				enabled: true,
				algorithm: 'lttb',
				samples: 800,
				threshold: 800
			},
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				backgroundColor: function (context) {
					return context.dataset.borderColor;
				},
				borderRadius: 4,
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "white",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		elements: {
			line: {
				fill: true,
				tension: 0.3
			}
		},
		layout: {
			padding: {
				top: 20
			}
		},
	};

	const optionsRSSI = {
		// parsing: false,
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				// type: 'time',
				ticks: {

					maxRotation: 90,
					minRotation: 90,
					font: {
						size: 10
					},

				}
			},

			y: {
				beginAtZero: true
			},
		},

		// parsing: false,
		plugins: {
			decimation: {
				enabled: true,
				algorithm: 'lttb',
				samples: 800,
				threshold: 800
			},
			legend: {
				display: false,
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				backgroundColor: function (context) {
					return context.dataset.borderColor;
				},
				borderRadius: 4,
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "white",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		elements: {
			line: {
				fill: true,
				tension: 0.3
			}
		},
		layout: {
			padding: {
				top: 20
			}
		},
	};

	const dataRSSI = {
		labels: RSSIChartInDate.date,
		datasets: [
			{
				label: "RSSI",
				data: RSSIChartInDate.total,
				fill: true,
				borderColor: 'rgb(53, 162, 235)',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgba(53, 162, 235, 0.8)");
					gradient.addColorStop(1, "rgba(53, 162, 235, 0)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			}
		]
	};
	const chartRef = useRef()
	const myChart = chartRef.current;
	const zoomIn = () => {
		if (myChart != undefined || myChart != null) {
			myChart.zoom(1.2);
		}
	}
	const zoomOut = () => {
		if (myChart != undefined || myChart != null) {
			myChart.zoom(0.8);
		}
	}

	const panLeft = () => {
		if (myChart != undefined || myChart != null) {
			myChart.pan({ x: 400 }, undefined, 'default');
		}
	}
	const panRight = () => {
		if (myChart != undefined || myChart != null) {
			myChart.pan({ x: -400 }, undefined, 'default');
		}
	}
	setTimeout(() => {
		if (myChart != undefined) {
			myChart.zoom(1.69);
			myChart.pan({
				x: 500
			}, undefined, 'default');

			// myChart.pan({
			// 	x: - (Number.MAX_SAFE_INTEGER)
			// }, undefined, 'default');

		}

	}, 10)

	const expandChartoptions = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				// type: 'time',
				ticks: {

					maxRotation: 90,
					minRotation: 90,
					font: {
						size: 10
					},

				}
			},

			y: {
				beginAtZero: true
			},
		},
		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "black",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		layout: {
			padding: {
				left: 50,
				right: 50,
				top: 20
			}
		},
	};

	const expandChartData = {
		labels: Instantaneous15Chart.date?.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
		datasets: [
			{
				label: ModelHeader,
				backgroundColor: ["rgba(103, 183, 220, 0.85)", "rgba(103, 148, 220, 0.85)", "rgba(103, 113, 220, 0.85)", "rgba(128, 103, 220, 0.85)", "rgba(163, 103, 220, 0.85)", "rgba(199, 103, 220, 0.85)", "rgba(220, 103, 206, 0.85)", "rgba(103, 113, 220, 0.85)"],
				data: Instantaneous15Chart.total,
			},
		],
	};
	const [NameObj,setNameObj]=useState({})
	const ongetProfilNameByAccountId=async(id)=>{
		const data=await getProfilNameByAccountId({ID:id})
		if(data.success===true){
		   setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME||'{}'))
		}else{
		   console.log('Something Went Wrong')
		}
	 }

	 const [ismodel, setModel] = useState(false)
	 const [reportType, setReportType] = useState('xml')
	 const [report, setReport] = useState({ value: 'CDF_Report', name: 'CDF Report',start_date:'',end_date:'',Table:[],label:[],generated_type:'date' })
	 const [ReportLoader,setReportLoader]=useState(false)

	 const onHandalReport = (e,name) => {
		setReport({ ...report,value: e.target.value, name: name,generated_type:'date',start_date:'',end_date:'' })
	}

	const handleCancelReport = () => {
		setModel(false);
	};

	const getreportCreacteInTableName=async()=>{
		if(report?.value=='CDF_Report'){
		setReportLoader(true)
		const obj={
			...report,start_date:moment(report.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'),end_date:moment(report.end_date,'DD-MM-YYYY').format('YYYY-MM-DD'),Table:report.Table.map((item)=>(GSMData?.UNIQUE_TABLE_NAME[item])),scriptid:GSMData?.scriptdecode_id,CAPTURE_PERIOD:JSON.parse(GSMData?.LOAD_SURVEY_DATA||'{}')?.CAPTURE_PERIOD,
		}
		if(report?.start_date&&report?.end_date){
			const x = new Date(moment(report.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
const y = new Date(moment(report.end_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
console.log(x>y,x,y);
			if(x>y){
			return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
			}
		}else{
			return	toast.warn('Please Select "Start Date" And "End Date"')
		}
		if(report?.Table?.length){
			return	toast.warn('Please Select Tables')
		}
	
		const data=await GetCDFXmlDataInTable(obj)
		if(data.success==true){
			setReportLoader(false)

			var pom = document.createElement('a');
var bb = new Blob([data.data.data], {type: 'text/plain'});

pom.setAttribute('href', window.URL.createObjectURL(bb));
pom.setAttribute('download', `${GSMData?.meter_serial_number?.trim()}_${moment().format('YYYYMMDD')}_${moment().format('HHmmss')}.xml`);

pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
pom.draggable = true; 
pom.classList.add('dragout');

pom.click();
		}else{
			setReportLoader(false)
		}
	}else{
		if(InstantaneousLTData?.MONTH_BILL_TS){
		setReportLoader(true)
		const obj={
			...report,start_date:report?.generated_type=='date'?moment(report.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'):moment(report.start_date,'MM-YYYY').format('YYYY-MM')+'-'+(InstantaneousLTData?.MONTH_BILL_TS?moment.utc(InstantaneousLTData?.MONTH_BILL_TS).format('DD'):'01'),end_date:report?.generated_type=='date'?moment(report.end_date,'DD-MM-YYYY').format('YYYY-MM-DD'):moment(report.end_date,'MM-YYYY').format('YYYY-MM')+'-'+(InstantaneousLTData?.MONTH_BILL_TS?moment.utc(InstantaneousLTData?.MONTH_BILL_TS).add(-1,'days').format('DD'):'01'),loadsurveytableName:GSMData?.UNIQUE_TABLE_NAME?.LOAD_SURVEY,dailyloadtableName:GSMData?.UNIQUE_TABLE_NAME?.DAILY_LOAD
		}
		if(report?.start_date&&report?.end_date){
			const x = new Date(moment(report.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
const y = new Date(moment(report.end_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
console.log(x>y,x,y);
			if(x>y){
			return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
			}
		}else{
			return	toast.warn('Please Select "Start Date" And "End Date"')
		}
		const data=await getBillingReportData(obj)
		if(data?.success==true){
		setReportLoader(false)
		var objData={IKWH:0,EKWH:0,LIKWH:0,LEKWH:0}
		
		const daily_sum=await data?.data?.daily_data?.map((acc,val)=>{
			objData.IKWH=(objData?.IKWH+(acc?.Register$1_0_1_8_0_255/1000))
			objData.EKWH=(objData?.EKWH+(acc?.Register$1_0_2_8_0_255/1000))
		})
		const lod_sum=await data?.data?.load_data?.map((acc,val)=>{
			objData.LIKWH=(objData?.LIKWH+(acc?.Register$1_0_1_29_0_255/1000))
			objData.LEKWH=(objData?.LEKWH+(acc?.Register$1_0_2_29_0_255/1000))
		})
		console.log(objData)
		const worksheet = XLSX.utils.json_to_sheet([{"Sr no":'',"Meter no":GSMData?.meter_serial_number.trim().replaceAll(/[^A-Z a-z 0-9]/g, ''),"Date":"Total","KWh Import":objData?.IKWH,"KWh Export":objData?.EKWH},...data?.data?.daily_data?.map((item,id)=>({"Sr no":id+1,"Meter no":item?.EMT_ID_ASN,"Date":moment.utc(item?.Clock$0_0_1_0_0_255).format('DD/MM/YYYY'),"KWh Import":item?.Register$1_0_1_8_0_255!=null?item?.Register$1_0_1_8_0_255/1000:'',"KWh Export":item?.Register$1_0_2_8_0_255!=null?item?.Register$1_0_2_8_0_255/1000:"","KWh Net":item?.Register$1_0_1_9_0_255!=null?item?.Register$1_0_1_9_0_255/1000:''}))],{ origin:1});
		XLSX.utils.sheet_add_aoa(
			worksheet,
			[[`Solar hour (7 AM to 6 PM) calculation of ${GSMData?.meter_serial_number.trim().replaceAll(/[^A-Z a-z 0-9]/g, '')} from ${moment(obj.start_date,'YYYY-MM-DD').format('YYYY/MM/DD')} to ${moment(obj.end_date,'YYYY-MM-DD').format('YYYY/MM/DD')}`]],
			{ origin: 0 }
		  )
		const worksheet1 = XLSX.utils.json_to_sheet([{"Sr no":'',"Meter no":GSMData?.meter_serial_number.trim().replaceAll(/[^A-Z a-z 0-9]/g, ''),"Time":"Total","KWh Import":objData?.LIKWH,"KWh Export":objData?.LEKWH},...data?.data?.load_data?.map((item,id)=>({"Sr no":id+1,"Meter no":item?.EMT_ID_ASN,"Time":moment.utc(item?.Clock$0_0_1_0_0_255).format('DD/MM/YYYY HH:mm:ss'),"KWh Import":item?.Register$1_0_1_29_0_255!=null?item?.Register$1_0_1_29_0_255/1000:'',"KWh Export":item?.Register$1_0_2_29_0_255!=null?item?.Register$1_0_2_29_0_255/1000:"","KVAh Import":item?.Register$1_0_9_29_0_255!=null?item?.Register$1_0_9_29_0_255/1000:'',"KVAh Export":item?.Register$1_0_10_29_0_255!=null?item?.Register$1_0_10_29_0_255/1000:'',"Q1":item?.Register$1_0_5_29_0_255!=null?item?.Register$1_0_5_29_0_255/1000:'',"Q2":item?.Register$1_0_6_29_0_255!=null?item?.Register$1_0_6_29_0_255/1000:'',"Q3":item?.Register$1_0_7_29_0_255!=null?item?.Register$1_0_7_29_0_255/1000:'',"Q4":item?.Register$1_0_8_29_0_255!=null?item?.Register$1_0_8_29_0_255/1000:'',"Vol R":item?.Register$1_0_32_27_0_255!=null?item?.Register$1_0_32_27_0_255:'',"Vol Y":item?.Register$1_0_52_27_0_255!=null?item?.Register$1_0_52_27_0_255:'',"Vol B":item?.Register$1_0_72_27_0_255!=null?item?.Register$1_0_72_27_0_255:'',"Cur R":item?.Register$1_0_31_27_0_255!=null?item?.Register$1_0_31_27_0_255:'',"Cur Y":item?.Register$1_0_51_27_0_255!=null?item?.Register$1_0_51_27_0_255:'',"Cur B":item?.Register$1_0_71_27_0_255!=null?item?.Register$1_0_71_27_0_255:'',"Frequency":item?.Register$1_0_14_27_0_255!=null?item?.Register$1_0_14_27_0_255:'',"PF Import":'',"PF Export":''}))],{ origin:1});
		XLSX.utils.sheet_add_aoa(
			worksheet1,
			[[`Load Survey data of ${GSMData?.meter_serial_number.trim().replaceAll(/[^A-Z a-z 0-9]/g, '')} from ${moment(obj.start_date,'YYYY-MM-DD').format('YYYY/MM/DD')} to ${moment(obj.end_date,'YYYY-MM-DD').format('YYYY/MM/DD')}`]],
			{ origin: 0 }
		  )
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, GSMData?.meter_serial_number.trim().replaceAll(/[^A-Z a-z 0-9]/g, '')+" summary");
		XLSX.utils.book_append_sheet(workbook, worksheet1, GSMData?.meter_serial_number.trim().replaceAll(/[^A-Z a-z 0-9]/g, '')+" load data");
		XLSX.writeFile(workbook, GSMData?.meter_serial_number.trim().replaceAll(/[^A-Z a-z 0-9]/g, '')+'_load_'+moment(obj.start_date,'YYYY-MM-DD').format('YYYYMMDD')+'_'+moment(obj.end_date,'YYYY-MM-DD').format('YYYYMMDD')+`.xlsx`);
		
		}else{
			setReportLoader(false)
			return	toast.warn('Server Issue')
		}
	}else{
		return	toast.warn('Month Bill Date Not Found')
	}
	}
	}
	
	const TableArray = [
		{ value: "INSTANTANEOUS", label: 'INSTANTANEOUS' },
		{ value: "MONTH_BILL", label: 'MONTH_BILL' },
		{ value: "LOAD_SURVEY", label: 'LOAD_SURVEY' },
		{ value: "DAILY_LOAD", label: 'DAILY_LOAD' },
		{ value: "CURRENT_EVENT", label: 'CURRENT_EVENT' },
		{ value: "POWER_EVENT", label: 'POWER_EVENT' },
		{ value: "TRANSECTION_EVENT", label: 'TRANSECTION_EVENT' },
		{ value: "OTHER_EVENT", label: 'OTHER_EVENT' },
		{ value: "NONROLLOVER_EVENT", label: 'NONROLLOVER_EVENT' },
		{ value: "CONTROL_EVENT", label: 'CONTROL_EVENT' },
		{ value: "NAME_PLATE", label: 'NAME_PLATE' },
		{ value: "VOLT_EVENT", label: 'VOLT_EVENT' },
	]
	return (
		<>
		<Modal title="Generate Reports" visible={ismodel} footer='' onCancel={handleCancelReport} width={1500}>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
									<div className="form-row" style={{ marginLeft: "10px" }}>
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4 ">Report Type :<span className='text-danger mr-2'>*</span><BootstrapTooltip title={ReportJson?.INDIVIDUAL_REPORT?.filter((item)=>(item?.TYPE==report?.value))[0]?.DESC||''} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip> </label>
											<select className="form-control form-select" name="reporttype" required="required" onChange={(e)=>onHandalReport(e)}>
												{ReportJson?.INDIVIDUAL_REPORT.map((item)=>(<option value={item?.TYPE}>{item?.NAME}</option>))}
											</select>
										</div>
										
										{report?.value=='Billing_Report'&&<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4"><span className='text-danger'></span> </label>
											<select className="form-control form-select" name="reportextension" onChange={(e) => (setReport({...report,generated_type:e.target.value,start_date:'',end_date:''}))} value={report?.generated_type}>
												<option value="date">Date</option>
												<option value="month">Month</option>
											</select>
										</div>}
										{report?.value=='Billing_Report'?report?.generated_type=='date'?<><div className="form-group col-md-3" id="strdate">
											<label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
											<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
												disabledDate={(current) => {
													return current.isAfter(moment())
												}} value={report?.start_date?moment(report?.start_date,'DD-MM-YYYY'):''}/>
										</div>
											<div className="form-group col-md-3" id="enddate" >
												<label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
													disabledDate={(current) => {
														return current.isAfter(moment())
													}} value={report?.end_date?moment(report?.end_date,'DD-MM-YYYY'):''}/>
											</div></>:<><div className="form-group col-md-3" id="strdate">
											<label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
											<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString,end_date:moment(dateString,'MM-YYYY').add(1,'months').format('MM-YYYY') })} format='MM-YYYY' picker="month" name='start' style={{ width: '100%', height: '40px' }}
												disabledDate={(current) => {
													return current.isAfter(moment().add(-1,'months'))
												}} value={report?.start_date?moment(report?.start_date,'MM-YYYY'):''}/>
										</div>
											<div className="form-group col-md-3" id="enddate" >
												<label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='MM-YYYY' picker="month" name='start' style={{ width: '100%', height: '40px' }}
													disabled={true} value={report?.end_date?moment(report?.end_date,'MM-YYYY'):''}/>
											</div></>:<><div className="form-group col-md-3" id="strdate">
											<label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
											<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
												disabledDate={(current) => {
													return current.isAfter(moment())
												}} />
										</div>
											<div className="form-group col-md-3" id="enddate" >
												<label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
													disabledDate={(current) => {
														return current.isAfter(moment())
													}} />
											</div></>}
											{report?.value=='CDF_Report'?<div className="form-group col-md-3" id="enddate" >
												<label for="inputPassword4">Table :<span className='text-danger'>*</span> </label><br></br>
											<Select  name='Table' isMulti options={TableArray} onChange={(e) => {
										setReport({ ...report, Table: e.map((item) => (item.value)),label: e.map((item) => (item.label)) })
									}} />
									</div>:''}
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reportextension" onChange={(e) => (setReportType(e.target.value))} value={reportType}>
												{report?.value=='CDF_Report'?(<option value="xml">xml</option>):(<><option value="xlxs">xlxs</option></>)}
											</select>
										</div>
									</div>
									<p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
									<div className="text-left">
										<input type="submit" name="submit" className={`btn btn-danger mr-2 ${ReportLoader==true?'disabled btn-progress':''}`} value="Generate" id="submitvn"  onClick={()=>getreportCreacteInTableName()}/>
										<Link className="btn btn-light text-dark" onClick={handleCancelReport}>Cancel</Link>
									</div>
							</div>
						</div>
					</div>
				</section>
			</Modal>
<Modal title="Buffer Data" visible={BufferModal} footer='' maskClosable={false} onCancel={() => (setBufferModal(false))} width={800}>
										{BufferChart.length > 0 && BufferChart.length > 0 ? <Chart options={options} type="bar" series={series} height={"300"} /> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
			</Modal>
<Modal title={'Relay On Time'} visible={ismodalOpenRelayonTime} maskClosable={false} footer='' onCancel={()=>setismodalOpenRelayonTime(false)} width={1500}>
				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
						<Space direction="horizontal">
							<div className="d-flex flex-column">Start In
								<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(0, 'days') >= current) || (moment(expireDate.end_date).add(1, 'days') <= current);
								}} /></div>
							<div className="d-flex flex-column" >End In
								<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(0, 'days') >= current) || (moment(expireDate.end_date).add(1, 'days') <= current);
								}} /></div>
						</Space>
						<div>
							<a className={`btn btn-danger text-white ml-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onLoadDataRelayontime())}>Submit</a>
						</div>

					</div>

					 <div>
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using your mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>

					</div> 

				</div>

				{RelayOntimeChart?.load1.length>0||RelayOntimeChart?.load2.length>0||RelayOntimeChart?.load3.length>0?<div className=" tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={chartRef} options={optionsRelayontime} data={seriesRelayTimeBydate} height={"500"}/> </div> :''}

			</Modal>
			<Modal title="Tags" visible={isModalTags} footer='' maskClosable={false} onCancel={() => (setisModalTags(false))} width={500}>
				<div class="form-row">
					<div class="form-group col-md-12">
						<div>
							<label for="accountuser">Tags</label>
						</div>
						<Select placeholder='Select Tags...' defaultValue={TagBtn.map((item) => ({ value: item?.unique_id, label: item.TAG_NAME, color: item?.TAG_COLOR }))} styles={customStyles} name='tags' isMulti options={tagsList} onChange={(e) => { setTagArray(e.map((item) => (item.value))) }} />
					</div>
				</div>
				<div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Submit" name="filter_customer_form" onClick={onSubmitTags} />
					<Link class="btn btn-light text-dark ml-2" onClick={() => (setisModalTags(false))}>
						Close
					</Link>
				</div>
			</Modal>

			
			<Modal title={ModelHeader} visible={isModalVisible} footer='' maskClosable={false} onCancel={handleCancel} width={1000}>
			<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
					<Space direction="horizontal">
						<div className="d-flex flex-column">Start In
							<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
								return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment() <= current);
							}} /></div>
						<div className="d-flex flex-column" >End In
							<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
								return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment() <= current);
							}} /></div>
					</Space>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetDateWiseChart())}>Submit</a>
				</div>
				{Instantaneous15Chart.total.length > 0 && Instantaneous15Chart.date.length > 0 && <div>
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using their mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>

					</div>}
				</div>
				{Instantaneous15Chart.total.length > 0 && Instantaneous15Chart.date.length > 0 ? <div className="tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={chartRef} options={expandChartoptions} data={expandChartData} /> </div> : ''}
					
			</Modal>
			<Modal title={ModelHeader} visible={isModalVisibleRssi} maskClosable={false} footer='' onCancel={handleCancelModalRSSI} width={1500}>
				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
						<Space direction="horizontal">
							<div className="d-flex flex-column">Start In
								<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment() <= current);
								}} /></div>
							<div className="d-flex flex-column" >End In
								<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment()<= current);
								}} /></div>
						</Space>
						<div>
							<a className={`btn btn-danger text-white ml-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetDateWiseInRSSIChart())}>Submit</a>
						</div>

					</div>

					{RSSIChartInDate.total.length > 0 && RSSIChartInDate.date.length > 0 && sum(RSSIChartInDate.total) > 0 ? <div>
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using your mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>

					</div> : ''}

				</div>

				<div className=" tw-h-[560px] tw-max-h-screen tw-px-5"><Line ref={chartRef} options={optionsRSSI} data={dataRSSI} /> </div> 

			</Modal>
			<Modal title={ModelHeader} visible={isModalVisibleMonth} maskClosable={false} footer='' onCancel={handleCancelMonth} width={1000}>
				<div className="d-flex">
					<Space direction="horizontal">
						<div className="d-flex flex-column">Start In
							<DatePicker onChange={onChange} format='MM-YYYY' name='start' picker="month" disabledDate={(current) => current.isAfter(moment())}/></div>
						<div className="d-flex flex-column" >End In
							<DatePicker onChange={onChanges} name='end' format='MM-YYYY' picker="month" disabledDate={(current) => current.isAfter(moment())}/></div>
					</Space>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={onGetDateWiseChart}>Submit</a>
				</div>
				{Instantaneous15Chart.total.length > 0 && Instantaneous15Chart.date.length > 0 && sum(Instantaneous15Chart.total) > 0 ? <Chart options={Instantaneous15state.options} type="bar" series={Instantaneous15state.series}
					height={"500"} /> : ''}
			</Modal>
			<Modal title={ModelHeader} visible={isModalVisibleModemStatus} footer='' maskClosable={false} onCancel={() => setisModalVisibleModemStatus(false)} width={1000}>
				<div className="d-flex">
					<Space direction="horizontal">
						<div className="d-flex flex-column">Start In
							<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start'
							disabledDate={(current) => current.isAfter(moment())}/></div>
						<div className="d-flex flex-column" >End In
							<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => current.isAfter(moment())}/></div>
					</Space>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetDateWiseInModemStatusChart())}>Submit</a>
				</div>
				{ModemStatusChartInDate.online_count.length > 0 && ModemStatusChartInDate.offline_count.length > 0 && ModemStatusChartInDate.date.length > 0 ? <Chart options={ModemStatusDurationStateByDate.options} type="bar" series={ModemStatusDurationStateByDate.series}
					height={"500"} /> : ''}
			</Modal>
			<Modal title={ModelHeader} visible={isModalVisibleModemST} footer='' maskClosable={false} onCancel={() => setisModalVisibleModemST(false)} width={1000}>
				<div className="d-flex">
					<Space direction="horizontal">
						<div className="d-flex flex-column">Start In
							<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' 
							disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment(expireDate.end_date).add(0, 'days') <= current);}}/></div>
						<div className="d-flex flex-column" >End In
							<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment(expireDate.end_date).add(0, 'days') <= current);}}/></div>
					</Space>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetModemStatusByTableAndDate())}>Submit</a>

				</div>

				{ModemSTChartInDate.online_count.length > 0 && ModemSTChartInDate.offline_count.length > 0 && ModemSTChartInDate.date.length > 0 ? <Chart options={ModemStatusStatebyDate.options} type="bar" series={ModemStatusStatebyDate.series}
					height={"500"} /> : ''}
			</Modal>
			<div className="main-content">
				{loader ? (
					<div className="">
						<div class="loader"></div>
					</div>
				) : (
					""
				)}
				<div class="col-12 d-flex justify-content-between align-items-center pb-3 px-0">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb p-0 mb-0">
							<li class="breadcrumb-item">
								<a href="all-deshboard" class="open_rightsidebar">
									<i class="fas fa-tachometer-alt"></i> Home
								</a>
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								<i class="fas fa-user-shield"></i> Consumer
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								<Link to="/mobileapp" class="open_rightsidebar">
									<i class="fas fa-list"></i> Mobile App{" "}
								</Link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								View Meter Consumer{" "}
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								{params.GSMID}
							</li>
						</ol>
					</nav>
					<div>
					{Object.keys(SecondaryCons).length>0?<a href={`/view_existing_consumer_gsm/${SecondaryCons?.unique_id}`} target='_blank' className="btn btn-outline-primary">Move To Secondary Consumer</a>:''}
					</div>
				</div>
				<div class="row">
					<div class="col-xl-3 col-lg-5 col-md-5 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Consumer Info</h4>
								<div class="card-header-action">
								{GSMData?.latitude&&GSMData?.longitude?<a href={GSMData?.latitude&&GSMData?.longitude?`https://map.google.com/?q=${GSMData?.latitude},${GSMData?.longitude}`:'#'} target={'_blank'} class={`btn btn-success btn-sm text-white font-5 mr-2 ${GSMData?.latitude&&GSMData?.longitude?'':''}`}>
										<BootstrapTooltip title='Directions'>
											<a >
												<i class="fas fa-directions"></i>
											</a></BootstrapTooltip></a>:<BootstrapTooltip title='lat-long is not available'>
												<span className='mr-2'><a class={`btn btn-success btn-sm text-white font-5  ${GSMData?.latitude&&GSMData?.longitude?'':'tw-opacity-30'}`}>
											<a >
												<i class="fas fa-directions"></i>
											</a></a></span></BootstrapTooltip>}
											{GSMData?.latitude&&GSMData?.longitude?<a href={GSMData?.latitude&&GSMData?.longitude?`/gsm-map-view?${GSMData?.unique_id}`:'#'} target={'_blank'}
										class={`btn btn-danger btn-sm text-white font-5 ${GSMData?.latitude&&GSMData?.longitude?'':''}`}
									><BootstrapTooltip title='Location'>
											<a>
												<i class="fas fa-map-marker-alt"></i>
											</a>
										</BootstrapTooltip></a>:<BootstrapTooltip title='lat-long is not available'><span><a class={`btn btn-danger btn-sm text-white font-5 ${GSMData?.latitude&&GSMData?.longitude?'':'tw-opacity-30'}`}
									>
											<a
											>
												<i class="fas fa-map-marker-alt"></i>
											</a>
										</a></span></BootstrapTooltip>}
								</div>
							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '152px' }}>
								<div class="row font-10">
									<div class="col-lg-12">
										<b>Company/Client Name : </b>
										{GSMData?.company_name}
										<br />
										<b>Consumer Unique ID : </b>
										{GSMData?.unique_id}
										<br />
										<b>Consumer Status : </b>
										{GSMData?.status === 1 ? (
											<>
												<span>
													<span class='mr-1' style={{color:'green',fontSize:'17px'}}>
														&#9679;
													</span>
													Active
												</span>
												<br />
											</>
										) : GSMData?.status === -1 ? (
											<>
												<span>
												<span class='mr-1' style={{color:'yellow',fontSize:'17px'}}>
														&#9679;
													</span>

													Pending Configuration
												</span>
												<br />
											</>
										) : GSMData?.status === 0?(
											<>
												<span>
												<span class='mr-1' style={{color:'red',fontSize:'17px'}}>
														&#9679;
													</span>
													
													InActive
												</span>
												<br />
											</>
										):GSMData?.status === 2?(
											<>
												<span>
												<span class='mr-1' style={{color:'#90d8f9',fontSize:'17px'}}>
														&#9679;
													</span>
													
													Under Maintenance
												</span>
												<br />
											</>
										):(
											<>
												<span>
												<span class='mr-1' style={{color:'#01010',fontSize:'17px'}}>
														&#9679;
													</span>
													Stand By
												</span>
												<br />
											</>
										)}
										
										<br />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Group Info</h4>
							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '154px' }}>
								<div class="row font-10">
									<div class="col-lg-12">
										<b>Account : </b>
										{GSMData?.ACCOUNT_NAME || "-"}
										<br />
										<b>{NameObj?.CATEGORY1||"CATEGORY1"} : </b>
										{GSMData?.GA_NAME || "-"}
										<br />
										<b>{NameObj?.CATEGORY2||"CATEGORY2"} : </b> {GSMData?.CIRCLE_NAME || "-"} <br />
										<b>{NameObj?.CATEGORY3||"CATEGORY3"} : </b> {GSMData?.DIVISION_NAME || "-"} <br />
										<b>{NameObj?.CATEGORY4||"CATEGORY4"} : </b> {GSMData?.SUB_DIVISION_NAME || "-"} <br />
										<b>{NameObj?.CATEGORY5||"CATEGORY5"} : </b> {GSMData?.AREA_NAME || "-"}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Modem Info</h4>
							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '153px' }}>
							<div class="row font-10">
									<div class="">
										<b>IMEI : </b>
										{GSMData?.imei}
										<br />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Meter Info</h4>
							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '154px' }}>
							<div class=" font-10">
							<b>Meter Serial No : </b> {GSMData?.meter_serial_number}
							</div>
								</div>
						</div>
					</div>
					
					<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Tags</h4>
								<div class="card-header-action">
									<BootstrapTooltip title='Tags'>
										<div>
											<a
												onClick={onOpenTagsModal}
												class="btn btn-primary text-white"
												data-toggle="tooltip"
												data-placement="Top"
												title=""
												data-original-title="Tags"
											>
												<i class="fa fa-tag"></i>
											</a>
										</div>
									</BootstrapTooltip>
								</div>
							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '150px' }}>
								<div class="row" style={{ marginTop: "10px" }}>
									<div class="col-lg-12">
										{TagBtn.length > 0 && TagBtn?.map((item) => (
											<div class={`btn btn-icon icon-left btn-sm  ml-1`} style={{ backgroundColor: item?.TAG_COLOR, color: "white" }}><i class="fa fa-tag fa-lg" style={{ marginRight: '4px' }}></i>{item.TAG_NAME}</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card pb-2">
							<div className="col-12" style={{ marginTop: "15px" }}>
								<div className="buttons">
									{buttonArray.map((item, id) => (
										item=='Configuration*'?rolesGSMConfig==='true'||userInfo?.ROLE=='ROLE1661856938676'?<button
										style={{
											backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
											color: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
											borderBottom: `1px solid ${btnColor[id]}`,
											boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${btnColor[id]}`  : '',
										}}
										className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
										onClick={() => onHandalebtn(item)}>
										{item}
									</button>:'':<button
										style={{
											backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
											color: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
											borderBottom: `1px solid ${btnColor[id]}`,
											boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${btnColor[id]}`  : '',
										}}
										className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
										onClick={() => onHandalebtn(item)}>
										{item}
									</button>
									))}
									<div class="float-right text-right">
										{btnText === "Dashboard" && <a class={`btn btn-info text-white ${btnProccess && 'btn-progress disabled'} align-left`} onClick={onRefreshBtn}>
											<BootstrapTooltip title="Refresh" placement="top"><i className="fas fa-redo"></i></BootstrapTooltip>
										</a>}
										{/* <button class="btn btn-info" onClick={()=>{setModel(true)}}>
                      <i class="fa fa-download" aria-hidden="true"></i>
                      </button> */}
										<Link to="/All-GSM" class="btn btn-info text-white align-left">
											Back
										</Link>
										
									</div>
								</div>
							</div>
							{btnText === "Configuration*" && <Configuration GSMdata={GSMData} onRefreshData={onRefreshData}/>}
							{btnText === "Modem Data" && <ModemData GSMdata={GSMData} />}
							{btnText === "Meter Data" && <MeterData GSMdata={GSMData} />}
							{btnText === "Extra" && <Extra GSMdata={GSMData} />}
							{btnText === "Dashboard" &&<div className="card-body"  style={{ paddingTop: "0px",paddingLeft:'15px',paddingBottom:'0px' }}>
				<div className="d-flex justify-content-between">
					<div className="buttons d-flex">
						{subbuttonArray.map((item, id) => (
							<div
							style={{
								backgroundColor: subbuttonArray.findIndex((data) => data === btnSubText) === id ? chroma(btnColor[0]).alpha(0.4).css() : '',
								color: subbuttonArray.findIndex((data) => data === btnSubText) === id ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
								borderBottom: `1px solid ${btnColor[0]}`,
								boxShadow: subbuttonArray.findIndex((data) => data === btnSubText) === id ? `0 2px 8px -3px ${btnColor[0]}` : '',
							}}
							className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${subbuttonArray.findIndex((data) => data === btnSubText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
								onClick={() => setbtnSubText(item)}
							>
								{item}
							</div>
						))}
						</div>
                    </div>
                    </div> }
						</div>
					</div>
				</div>
				{btnText === "Buffer" && <Buffer GSMdata={GSMData} />}
				{btnText === "Mobile Details" && <MobileDetails GSMdata={GSMData} />}
				{btnText === "Bunch Data" && <BunchData GSMdata={GSMData} />}
				{btnText === "Tasks" && <ConsumerTask GSMdata={GSMData} permision={permision}/>}

				{btnText === "Consumer Info" && <ConsumerInfo GSMdata={GSMData} permision={permision} onConsumerUpdateDate={onConsumerUpdateDate}/>}
				{btnText === "Dashboard" && (
					<>
					{
					btnSubText=='Meter'&&(<>
						<div class="row">
						
<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ marginBottom: "10px" }}>
								<div class="card card-success " style={{height:'95%'}}>
									<div class="card-header">
										<h4>Instantaneous Last Data(<BootstrapTooltip title={moment.utc(InstantaneousLTData?.EMT_TS_INST).format('DD-MM-YYYY hh:mm:ss A')}><span>{InstantaneousLTData?.EMT_TS_INST ? moment.utc(InstantaneousLTData.EMT_TS_INST).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</span></BootstrapTooltip>)</h4>
									</div>
									<div class="card-body" style={{ overflow: 'auto',height: '334px' }}>
									<ul class="list-group">
									<li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{padding: "4px 18px"}}>
					  <BootstrapTooltip title={'Register$0_0_0_1_2_255'}><span>{ColumnName?.METER_DETAILS?.filter((item)=>(item?.Column=='Register$0_0_0_1_2_255'))[0]?.Header}</span></BootstrapTooltip>
					  <div><span>{moment.utc(InstantaneousLTData?.MONTH_BILL_TS).format('DD-MM-YYYY hh:mm:ss A')}</span></div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{padding: "4px 18px"}}>
					  <BootstrapTooltip title={'Register$1_0_1_8_0_255'}><span>{ColumnName?.METER_DETAILS?.filter((item)=>(item?.Column=='Register$1_0_1_8_0_255'))[0]?.Header}</span></BootstrapTooltip>
					  <div><span>{InstantaneousLTData?.EMT_PENRG_CUM_IMP_WH}</span></div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{padding: "4px 18px"}}>
					  <BootstrapTooltip title={'Register$1_0_9_8_0_255'}><span>{ColumnName?.METER_DETAILS?.filter((item)=>(item?.Column=='Register$1_0_9_8_0_255'))[0]?.Header}</span></BootstrapTooltip>
					  <div><span>{InstantaneousLTData?.EMT_QENRG_CUM_IMP_VAH}</span></div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{padding: "4px 18px"}}>
					  <BootstrapTooltip title={'Register$1_0_1_7_0_255'}><span>{ColumnName?.METER_DETAILS?.filter((item)=>(item?.Column=='Register$1_0_1_7_0_255'))[0]?.Header}</span></BootstrapTooltip>
					  <div><span>{InstantaneousLTData?.EMT_PPWR_AVG_W}</span></div>
                      </li>
					  <li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{padding: "4px 18px"}}>
					  <BootstrapTooltip title={'Register$1_0_3_7_0_255'}><span>{ColumnName?.METER_DETAILS?.filter((item)=>(item?.Column=='Register$1_0_3_7_0_255'))[0]?.Header}</span></BootstrapTooltip>
					  <div><span>{InstantaneousLTData?.EMT_QPWR_AVG_VAR}</span></div>
                      </li>
					  <li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{padding: "4px 18px"}}>
					  <BootstrapTooltip title={'Register$1_0_9_7_0_255'}><span>{ColumnName?.METER_DETAILS?.filter((item)=>(item?.Column=='Register$1_0_9_7_0_255'))[0]?.Header}</span></BootstrapTooltip>
					  <div><span>{InstantaneousLTData?.EMT_SPWR_AVG_VA}</span></div>
                      </li>
					  <li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{padding: "4px 18px"}}>
					  <BootstrapTooltip title={'Register$1_0_32_7_0_255'}><span>{ColumnName?.METER_DETAILS?.filter((item)=>(item?.Column=='Register$1_0_32_7_0_255'))[0]?.Header}</span></BootstrapTooltip>
					  <div><span>{InstantaneousLTData?.EMT_VOLT_RPH_V}</span></div>
                      </li>
					  <li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{padding: "4px 18px"}}>
					  <BootstrapTooltip title={'Register$1_0_52_7_0_255'}><span>{ColumnName?.METER_DETAILS?.filter((item)=>(item?.Column=='Register$1_0_52_7_0_255'))[0]?.Header}</span></BootstrapTooltip>
					  <div><span>{InstantaneousLTData?.EMT_VOLT_YPH_V}</span></div>
                      </li>
					  <li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{padding: "4px 18px"}}>
					  <BootstrapTooltip title={'Register$1_0_72_7_0_255'}><span>{ColumnName?.METER_DETAILS?.filter((item)=>(item?.Column=='Register$1_0_72_7_0_255'))[0]?.Header}</span></BootstrapTooltip>
					  <div><span>{InstantaneousLTData?.EMT_VOLT_BPH_V}</span></div>
                      </li>
					  <li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{padding: "4px 18px"}}>
					  <BootstrapTooltip title={'Register$1_0_31_7_0_255'}><span>{ColumnName?.METER_DETAILS?.filter((item)=>(item?.Column=='Register$1_0_31_7_0_255'))[0]?.Header}</span></BootstrapTooltip>
					  <div><span>{InstantaneousLTData?.EMT_CUR_RPH_A}</span></div>
                      </li>
					  <li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{padding: "4px 18px"}}>
					  <BootstrapTooltip title={'Register$1_0_51_7_0_255'}><span>{ColumnName?.METER_DETAILS?.filter((item)=>(item?.Column=='Register$1_0_51_7_0_255'))[0]?.Header}</span></BootstrapTooltip>
					  <div><span>{InstantaneousLTData?.EMT_CUR_YPH_A}</span></div>
                      </li>
					  <li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{padding: "4px 18px"}}>
					  <BootstrapTooltip title={'Register$1_0_71_7_0_255'}><span>{ColumnName?.METER_DETAILS?.filter((item)=>(item?.Column=='Register$1_0_71_7_0_255'))[0]?.Header}</span></BootstrapTooltip>
					  <div><span>{InstantaneousLTData?.EMT_CUR_BPH_A}</span></div>
                      </li>
					  <li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{padding: "4px 18px"}}>
					  <BootstrapTooltip title={'Register$1_0_13_7_0_255'}><span>{ColumnName?.METER_DETAILS?.filter((item)=>(item?.Column=='Register$1_0_13_7_0_255'))[0]?.Header}</span></BootstrapTooltip>
					  <div><span>{InstantaneousLTData?.EMT_PF_AVG}</span></div>
                      </li>
					  <li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{padding: "4px 18px"}}>
					  <BootstrapTooltip title={'Register$1_0_14_7_0_255'}><span>{ColumnName?.METER_DETAILS?.filter((item)=>(item?.Column=='Register$1_0_14_7_0_255'))[0]?.Header}</span></BootstrapTooltip>
					  <div><span>{InstantaneousLTData?.EMT_FREQ_HZ}</span></div>
                      </li>
					  
                    </ul></div>
								</div>
							</div>
							{permision?.ROLES_OTHER_PERMISSIONS?.GSM_CONSUMER_DASH?.MODEM?.view?.filter((item)=>(item=='Loadsurvey')).length>0||userInfo?.ROLE=='ROLE1661856938676'?<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4>Loadsurvey</h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMData.UNIQUE_TABLE_NAME.LOAD_SURVEY, 'Clock$0_0_1_0_0_255', 'Loadsurvey')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{LoadsurvayChart.date.length > 0 && LoadsurvayChart.total.length > 0 ? <Chart options={Loadsurveystate.options} type="bar" series={Loadsurveystate.series} height={"300"} /> :LoadsurvayChart?.res_code==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>: <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>:''}
							{permision?.ROLES_OTHER_PERMISSIONS?.GSM_CONSUMER_DASH?.MODEM?.view?.filter((item)=>(item=='Dailyload')).length>0||userInfo?.ROLE=='ROLE1661856938676'?<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4>Dailyload</h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMData.UNIQUE_TABLE_NAME.DAILY_LOAD, 'Clock$0_0_1_0_0_255', 'Dailyload')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{DailyChart.date.length > 0 && DailyChart.total.length > 0  ? <Chart options={Dailystate.options} type="bar" series={Dailystate.series} height={"300"} /> :  DailyChart?.res_code==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>: <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>:''}
							{permision?.ROLES_OTHER_PERMISSIONS?.GSM_CONSUMER_DASH?.MODEM?.view?.filter((item)=>(item=='Monthbill')).length>0||userInfo?.ROLE=='ROLE1661856938676'?<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4>Monthbill</h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => {
												setisModalVisibleMonth(true)
												setModelHeader('Monthbill')
											}}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{MonthlyChart.date.length > 0 && MonthlyChart.total.length > 0 ? <Chart options={Monthlytate.options} type="bar" series={Monthlytate.series} height={"300"} /> :  MonthlyChart?.res_code==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>: <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>:''}
							
							{permision?.ROLES_OTHER_PERMISSIONS?.GSM_CONSUMER_DASH?.MODEM?.view?.filter((item)=>(item=='Instantaneous')).length>0||userInfo?.ROLE=='ROLE1661856938676'?<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4>Instantaneous</h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMData.UNIQUE_TABLE_NAME.INSTANTANEOUS, 'Clock$0_0_1_0_0_255', 'Instantaneous')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{InstantaneousChart.date.length > 0 && InstantaneousChart.total.length > 0 ? <Chart options={Instantaneousstate.options} type="bar" series={Instantaneousstate.series} height={"300"} /> :  InstantaneousChart?.res_code==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>: <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>

							</div>:''}

							
							{/* {permision?.ROLES_OTHER_PERMISSIONS?.GSM_CONSUMER_DASH?.MODEM?.view?.filter((item)=>(item=='Buffer')).length>0||userInfo?.ROLE=='ROLE1661856938676'?<div class="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-danger">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4>Buffer</h4>
										<div class="card-header-action">
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{BufferChart.length > 0 && BufferChart.length > 0 ? <Chart options={options} type="bar" series={series} height={"300"} /> : <div style={{ height: '315px', textAlign: 'center', fontSize: '30px' }}>No Data Available</div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>

								</div>
							</div>:''
							} */}
							<div className="row">
						{/* <div class="col-lg-3" style={{ marginBottom: "10px" }}>
								<div class="card card-danger " style={{height:'94.5%'}}>
									<div class="card-header">
										<h4 class="font-15">Buffer Count</h4>
										<div class="card-header-action">
										<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={()=>{setBufferModal(true)
										onGetBuferCountInByStatus(GSMData.UNIQUE_TABLE_NAME.GSM_BUFFER)}}>Expand</a>
										</div>
									</div>
									<div class="card-body">
										<p class="font-12" style={{ lineHeight: "15px" }}>Total <span class="float-right"><b>{AllBufferStatus?.total_length}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Success <span class="float-right"><b>{AllBufferStatus?.success}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Pending <span class="float-right"><b>{AllBufferStatus?.pending}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Timeout <span class="float-right"><b>{AllBufferStatus?.timeout}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Cancelled By Consumer <span class="float-right"><b>{AllBufferStatus?.cancel}</b></span></p>
									</div>
								</div>
							</div> */}
							
						</div>
							{/* <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4>Battery Percentage*</h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={{}}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										<Apexchart />
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>

								</div>
							</div> */}
							
						</div>


						<div className="row">
							<div className="col-12">
								<div className="card  card-danger">
									<div className="card-header">
										<h4>
											GSM Consumer Map
										</h4>
									</div>
									<div class="card-body" style={{ height: '400px', width: "100%" }}>
										<div class="map-inner" style={{ height: '350px', width: "100%" }}>
											{/*dev- AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8 */}
											{/*server- AIzaSyDG0jH5rr6z6KU0Zo_ilALl5ZXUP5NSPSs */}
											<ArticlesMap
														loadingElement={{ height: '100%', width: '100%' }}
														containerElement={{ height: '100%', width: '100%' }}
														mapElement={{ height: '100%', width: '100%' }}
														onClick={(ev) => { console.log(ev) }}
														mapInfo={GSMData}
														// update 1 add defaultDate
														defaultDate={defaultDate}
														curentpos={{ lat: 21.727293496271507, lng: 75.5823933220894 }}
													/>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* <div class="row">
							<div class="col-12"> */}
								<div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-5">
									<div class="col">
										<div class="card card-info">
											<div class="card-statistic-4">
												<div class="align-items-center justify-content-between">
													<div class="row ">
														<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
															<div class="card-content">
																<h5 class="font-14">Heartbeat</h5>
																<h2 class="font-18 col-blue">{HardbeatCount?.total || 0}</h2>
															</div>
														</div>
														<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
															<div class="banner-img">
																<img
																	src={heartbeat}
																	alt=""
																	style={{ paddingTop: "15px" }}
																	height="45px"
																/>
															</div>
														</div>
													</div>
													<div class="row ">
														<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
															<p class="mb-0 font-10">
																<BootstrapTooltip placement="top" title={HardbeatCount?.ENTRYTS ? moment(HardbeatCount?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{HardbeatCount?.ENTRYTS ? moment.utc(HardbeatCount?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col">
										<div class="card card-success">
											<div class="card-statistic-4">
												<div class="align-items-center justify-content-between">
													<div class="row ">
														<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
															<div class="card-content">
																<h5 class="font-14">Loadsurvey</h5>
																<h2 class="font-18 col-blue">{LoadsurvayCount?.total || 0}</h2>
															</div>
														</div>
														<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
															<div class="banner-img">
																<img
																	src={hourglass}
																	alt=""
																	style={{ paddingTop: "15px" }}
																	height="45px"
																/>
															</div>
														</div>
													</div>
													<div class="row ">
														<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
															<p class="mb-0 font-10">
															<BootstrapTooltip placement="top" title={LoadsurvayCount?.ENTRYTS ? moment.utc(LoadsurvayCount?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{LoadsurvayCount?.ENTRYTS ? moment.utc(LoadsurvayCount?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col">
										<div class="card card-success">
											<div class="card-statistic-4">
												<div class="align-items-center justify-content-between">
													<div class="row ">
														<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
															<div class="card-content">
																<h5 class="font-14">Dailyload</h5>
																<h2 class="font-18 col-blue">{DailyCount?.total || 0}</h2>
															</div>
														</div>
														<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
															<div class="banner-img">
																<img
																	src={calendar}
																	alt=""
																	style={{ paddingTop: "15px" }}
																	height="45px"
																/>
															</div>
														</div>
													</div>
													<div class="row ">
														<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
															<p class="mb-0 font-10">
															<BootstrapTooltip placement="top" title={DailyCount?.ENTRYTS ? moment.utc(DailyCount?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{DailyCount?.ENTRYTS ? moment.utc(DailyCount?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col">
										<div class="card card-success">
											<div class="card-statistic-4">
												<div class="align-items-center justify-content-between">
													<div class="row ">
														<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
															<div class="card-content">
																<h5 class="font-14">Monthbill</h5>
																<h2 class="font-18 col-blue">{MonthlyCount?.total || 0}</h2>
															</div>
														</div>
														<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
															<div class="banner-img">
																<img
																	src={timetable}
																	alt=""
																	style={{ paddingTop: "20px" }}
																	height="45px"
																/>
															</div>
														</div>
													</div>
													<div class="row ">
														<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
															<p class="mb-0 font-10">
															<BootstrapTooltip placement="top" title={MonthlyCount?.ENTRYTS ? moment.utc(MonthlyCount?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{MonthlyCount?.ENTRYTS ? moment.utc(MonthlyCount?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col">
										<div class="card card-success">
											<div class="card-statistic-4">
												<div class="align-items-center justify-content-between">
													<div class="row ">
														<div class="row ">
															<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
																<div class="card-content">
																	<h5 class="font-14">Instantaneous</h5>
																	<h2 class="font-18 col-blue">{InstantaneousCount?.total || 0}</h2>
																</div>
															</div>
															<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
																<div class="banner-img">
																	<img
																		src={server}
																		alt=""
																		style={{ paddingTop: "15px" }}
																		height="45px"
																	/>
																</div>
															</div>
														</div>
														<div class="row ">
															<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
																<p class="mb-0 font-10">
																<BootstrapTooltip placement="top" title={InstantaneousCount.ENTRYTS ? moment.utc(InstantaneousCount.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A'): 0}><span class="col-grey">{InstantaneousCount.ENTRYTS ? moment.utc(InstantaneousCount.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col">
										<div class="card card-success">
											<div class="card-statistic-4">
												<div class="align-items-center justify-content-between">
													<div class="row ">
														<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
															<div class="card-content">
																<h5 class="font-14">Volt Event</h5>
																<h2 class="font-18 col-blue">{EventCount?.voltevent || 0}</h2>
															</div>
														</div>
														<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
															<div class="banner-img">
																<img
																	src={alert}
																	alt=""
																	style={{ paddingTop: "15px" }}
																	height="45px"
																/>
															</div>
														</div>
													</div>
													<div class="row ">
														<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
															<p class="mb-0 font-10">
															<BootstrapTooltip placement="top" title={EventCount?.volteventTS ? moment.utc(EventCount?.volteventTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{EventCount?.volteventTS ? moment.utc(EventCount?.volteventTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col">
										<div class="card card-success">
											<div class="card-statistic-4">
												<div class="align-items-center justify-content-between">
													<div class="row ">
														<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
															<div class="card-content">
																<h5 class="font-14">Current Event</h5>
																<h2 class="font-18 col-blue">{EventCount?.currentevent || 0}</h2>
															</div>
														</div>
														<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
															<div class="banner-img">
																<img
																	src={alert}
																	alt=""
																	style={{ paddingTop: "15px" }}
																	height="45px"
																/>
															</div>
														</div>
													</div>
													<div class="row ">
														<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
															<p class="mb-0 font-10">
															<BootstrapTooltip placement="top" title={EventCount?.currenteventTS ? moment.utc(EventCount?.currenteventTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{EventCount?.currenteventTS ? moment.utc(EventCount?.currenteventTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col">
										<div class="card card-success">
											<div class="card-statistic-4">
												<div class="align-items-center justify-content-between">
													<div class="row ">
														<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
															<div class="card-content">
																<h5 class="font-14">Power Event</h5>
																<h2 class="font-18 col-blue">{EventCount?.powerevent || 0}</h2>
															</div>
														</div>
														<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
															<div class="banner-img">
																<img
																	src={alert}
																	alt=""
																	style={{ paddingTop: "15px" }}
																	height="45px"
																/>
															</div>
														</div>
													</div>
													<div class="row ">
														<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
															<p class="mb-0 font-10">
															<BootstrapTooltip placement="top" title={EventCount?.powereventTS ? moment.utc(EventCount?.powereventTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{EventCount?.powereventTS ? moment.utc(EventCount?.powereventTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col">
										<div class="card card-success">
											<div class="card-statistic-4">
												<div class="align-items-center justify-content-between">
													<div class="row ">
														<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
															<div class="card-content">
																<h5 class="font-14">Transection Event</h5>
																<h2 class="font-18 col-blue">{EventCount?.transectionevent || 0}</h2>
															</div>
														</div>
														<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
															<div class="banner-img">
																<img
																	src={alert}
																	alt=""
																	style={{ paddingTop: "15px" }}
																	height="45px"
																/>
															</div>
														</div>
													</div>
													<div class="row ">
														<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
															<p class="mb-0 font-10">
															<BootstrapTooltip placement="top" title={EventCount?.transectioneventTS ? moment.utc(EventCount?.transectioneventTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{EventCount?.transectioneventTS ? moment.utc(EventCount?.transectioneventTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col">
										<div class="card card-success">
											<div class="card-statistic-4">
												<div class="align-items-center justify-content-between">
													<div class="row ">
														<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
															<div class="card-content">
																<h5 class="font-14">Other Event</h5>
																<h2 class="font-18 col-blue">{EventCount?.otherevent || 0}</h2>
															</div>
														</div>
														<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
															<div class="banner-img">
																<img
																	src={alert}
																	alt=""
																	style={{ paddingTop: "15px" }}
																	height="45px"
																/>
															</div>
														</div>
													</div>
													<div class="row ">
														<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
															<p class="mb-0 font-10">
															<BootstrapTooltip placement="top" title={EventCount?.othereventTS ? moment.utc(EventCount?.othereventTS).format('DD-MM-YYYY hh:mm:ss A'): 0}><span class="col-grey">{EventCount?.othereventTS ? moment.utc(EventCount?.othereventTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col">
										<div class="card card-success">
											<div class="card-statistic-4">
												<div class="align-items-center justify-content-between">
													<div class="row ">
														<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
															<div class="card-content">
																<h5 class="font-14">Nonrollover Event</h5>
																<h2 class="font-18 col-blue">{EventCount?.nonrolloverevent || 0}</h2>
															</div>
														</div>
														<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
															<div class="banner-img">
																<img
																	src={alert}
																	alt=""
																	style={{ paddingTop: "15px" }}
																	height="45px"
																/>
															</div>
														</div>
													</div>
													<div class="row ">
														<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
															<p class="mb-0 font-10">
															<BootstrapTooltip placement="top" title={EventCount?.nonrollovereventTS ? moment.utc(EventCount?.nonrollovereventTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{EventCount?.nonrollovereventTS ? moment.utc(EventCount?.nonrollovereventTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col">
										<div class="card card-success">
											<div class="card-statistic-4">
												<div class="align-items-center justify-content-between">
													<div class="row ">
														<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
															<div class="card-content">
																<h5 class="font-14">Control Event</h5>
																<h2 class="font-18 col-blue">{EventCount?.controlevent || 0}</h2>
															</div>
														</div>
														<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
															<div class="banner-img">
																<img
																	src={alert}
																	alt=""
																	style={{ paddingTop: "15px" }}
																	height="45px"
																/>
															</div>
														</div>
													</div>
													<div class="row ">
														<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
															<p class="mb-0 font-10">
															<BootstrapTooltip placement="top" title={EventCount?.controleventTS ? moment.utc(EventCount?.controleventTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{EventCount?.controleventTS ? moment.utc(EventCount?.controleventTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									
								</div>
							{/* </div>
						</div> */}
						
						<div class="row">


							<div class="col-lg-3" style={{ marginBottom: "10px" }}>
								<div class="card  card-danger" >
									<div class="card-header">
										<h4 class="font-15">Errorbin Count</h4>
									</div>
									<div class="card-body pt-0" >
									<p class="font-15 mb-0 text-center">Meter</p>
									<hr   style={{margin:'0px 0px 12px 0px'}}/>
									<p class="font-12" style={{ lineHeight: "15px" }}>Instantaneous<span class="float-right"><b>{BinCount?.instantaneous?.ERC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Loadsurvey<span class="float-right"><b>{BinCount?.loadsurvey?.ERC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Dailyload<span class="float-right"><b>{BinCount?.dailyload?.ERC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Monthbill<span class="float-right"><b>{BinCount?.monthbill?.ERC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Nameplate<span class="float-right"><b>{BinCount?.nameplate?.ERC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Volt Event<span class="float-right"><b>{BinCount?.voltevent?.ERC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Power Event<span class="float-right"><b>{BinCount?.powerevent?.ERC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Current Event<span class="float-right"><b>{BinCount?.currentevent?.ERC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Transection Event<span class="float-right"><b>{BinCount?.transectionevent?.ERC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Other Event<span class="float-right"><b>{BinCount?.otherevent?.ERC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Nonrollover Event<span class="float-right"><b>{BinCount?.nonrolloverevent?.ERC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Control Event<span class="float-right"><b>{BinCount?.heartbeatdata?.ERC||0}</b></span></p>
									
									</div>
								</div>
							</div>
							<div class="col-lg-3" style={{ marginBottom: "10px" }}>
								<div class="card  card-danger " >
									<div class="card-header">
										<h4 class="font-15">Duplicatebin Count</h4>
									</div>
									<div class="card-body pt-0" >
									<p class="font-15 mb-0 text-center">Meter</p>
									<hr   style={{margin:'0px 0px 12px 0px'}}/>
									<p class="font-12" style={{ lineHeight: "15px" }}>Instantaneous<span class="float-right"><b>{BinCount?.instantaneous?.DUC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Loadsurvey<span class="float-right"><b>{BinCount?.loadsurvey?.DUC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Dailyload<span class="float-right"><b>{BinCount?.dailyload?.DUC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Monthbill<span class="float-right"><b>{BinCount?.monthbill?.DUC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Nameplate<span class="float-right"><b>{BinCount?.nameplate?.DUC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Volt Event<span class="float-right"><b>{BinCount?.voltevent?.DUC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Power Event<span class="float-right"><b>{BinCount?.powerevent?.DUC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Current Event<span class="float-right"><b>{BinCount?.currentevent?.DUC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Transection Event<span class="float-right"><b>{BinCount?.transectionevent?.DUC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Other Event<span class="float-right"><b>{BinCount?.otherevent?.DUC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Nonrollover Event<span class="float-right"><b>{BinCount?.nonrolloverevent?.DUC||0}</b></span></p>
									<p class="font-12" style={{ lineHeight: "15px" }}>Control Event<span class="float-right"><b>{BinCount?.heartbeatdata?.DUC||0}</b></span></p>
									</div>
								</div>
							</div>
							
							
						</div>
					</>)
}
					{/* {btnSubText=='Meter'&&(<><Meter_GSM_dashboard GSMdata={GSMData}/></>)} */}
					</>
				)}
			</div>
		</>
	);
}

export const ArticlesMap = (props) => {

	{/*dev- AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8 */ }
	{/*server- AIzaSyDG0jH5rr6z6KU0Zo_ilALl5ZXUP5NSPSs */ }

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8'
	});

	const [jobDetail, setJobDetail] = useState({ activeMarker: {}, isOpen: false, Info: {} })

	const onMarkerClicked = (data, marker) => {
		console.log(data, marker);
		setJobDetail({
			activeMarker: { lat: data?.latLng?.lat(), lng: data?.latLng?.lng() },
			isOpen: true,
			Info: marker
		})
	}

	const onToggleOpen = () => {
		setJobDetail({ ...jobDetail, isOpen: false })
	}

	function getSecondsTodayBydate(ts) {
		// update 1 add defaultDate
		var now = props?.defaultDate
		var then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD-MM-YYYY HH:mm:ss");
		return moment(now, "DD-MM-YYYY HH:mm:ss").diff(moment(then, "DD-MM-YYYY HH:mm:ss"), 'second')
	}

	if (!isLoaded) return < GoogleLoader bodyClassName={'tw-h-[70%]'} />;

	return (
		<GoogleMap
			mapContainerStyle={props?.containerElement}
			Zoom={5}
			center={{ lat: props.curentpos.lat, lng: props.curentpos.lng }}
			onClick={(e) => { console.log("e.latLng.lat()", e.latLng.lat(), "e.latLng.lng()", e.latLng.lng()) }}
		>
			{<MarkerF
				defaultZoomOnClick={4}
				icon={{
					url: (getSecondsTodayBydate(props?.mapInfo?.HEARTBEAT_TS) < props?.mapInfo?.HEARTBEAT_INTERVAL) ? mapicon : mapRedicon,
					scaledSize: new window.google.maps.Size(25, 25)
				}}
				options={{ optimized: false }}
				onMouseOver={(data) => onMarkerClicked(data, props?.mapInfo)}
				position={{ lat: parseFloat(props?.mapInfo?.ADDRESS?.address_lattitude || ''), lng: parseFloat(props?.mapInfo?.ADDRESS?.address_longitude || '') }}
			/>}

			{jobDetail?.activeMarker?.lat && jobDetail.isOpen &&
				<InfoWindowF
					position={{ lat: jobDetail?.activeMarker?.lat, lng: jobDetail?.activeMarker?.lng }}
					onCloseClick={onToggleOpen}
					zIndex={1}
					options={{ pixelOffset: new window.google.maps.Size(0, -20) }}>
					<div className="detailCard">
						<div className="d-flex main-div">
							<div className="d-inline-block">
								<div className="text-black name">{jobDetail?.Info?.ADDRESS.address_compony_name}
								</div>
							</div>
						</div>
					</div>
				</InfoWindowF>}
		</GoogleMap>

	);
}
