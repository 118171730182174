import moment from "moment";
import React, { useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(ChartDataLabels, zoomPlugin);
export const DashboardChart = ({ analytics }) => {
  // console.log("analytics", analytics)
  // Extracting dates and task data from analytics
  const dates = analytics?.map(data => {
    // Format the date as required (18-02-2024(Sun))
    const formattedDate = moment(data.date).format('DD-MM-YYYY (ddd)');
    return formattedDate;
  });
  const completedTasks = analytics?.map(data => data.completed_tasks);
  const cancelledTasks = analytics?.map(data => data.cancelled_tasks);
  const createdTasks = analytics?.map(data => data.created_tasks || 0); // Handling missing created_tasks

  const data = {
    labels: dates,
    datasets: [
      {
        label: 'Created Tasks',
        backgroundColor: '#3336FF',
        borderColor: '#3336FF',
        borderWidth: 1,
        data: createdTasks
      },
      {
        label: 'Cancelled Tasks',
        backgroundColor: '#5A8DC8',
        borderColor: '#5A8DC8',
        borderWidth: 1,
        data: cancelledTasks,

      },
      {
        label: 'Completed Tasks',
        backgroundColor: '#F16965',
        borderColor: '#F16965',
        borderWidth: 1,
        data: completedTasks,
      }
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        // type: 'time',
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          font: {
            size: 12
          },
        }
      },
      y: {
        grace: '5%',
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
            modifierKey: 'ctrl'
          },
          mode: "x",
        },
        pan: {
          enabled: true,
          mode: "x",
        }
      },

      datalabels: {
        anchor: 'end',
        align: 'top',
        clamp: false,
        clip: true,
        formatter: Math.round,
        font: {
          weight: 'bold',
        },
      },

    }

  };

  const chartRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        if(window.innerWidth <= 768){
          chartRef.current.zoom(1.2);
        }
        
        console.log("midValue in handleResize:", window.innerWidth);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <Bar ref={chartRef} data={data} options={options} height={300} />
  );
};