import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ChartLoaders from '../../../../Commoncomponet/Loaders/ChartLoader'
import Chart from "react-apexcharts";
import { GetMqttConnectionStatusByTable, GetMqttConnectionStatusDurationByTable } from '../../../../Commoncomponet/Utils';


export default function Analytics(props) {
    const [ModemStatus, setModemStatus] = useState({ online_count: [], offline_count: [], date: [] })
    const [ModemStatusDuration, setModemStatusDuration] = useState({ online_count: [], offline_count: [], date: [] })

    const ModemStatusState = {
        series: [{
            name: 'Online',
            data: ModemStatus.online_count
        }, {
            name: 'Offline',
            data: ModemStatus.offline_count
        }],
        options: {
            legend: {
                show: false,

            },
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10,
                    borderRadiusApplication: 'end',
                    columnWidth: ModemStatus.date.length < 3 ? 10 + (60 / (1 + 30 * Math.exp(-ModemStatus.date.length / 3))) : '60%',
                    dataLabels: {
                        total: {
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            xaxis: {
                categories: ModemStatus?.date?.map((item) => ([item, ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
                labels: {
                    show: true,
                    rotate: -90,
                    rotateAlways: true,
                    offsetX: 0,
                    offsetY: 3,
                }
            },
            yaxis: [{
                allowDecimals: false,
                floating: false,
                labels: {
                    formatter: (value) => {
                        return value.toFixed(0)
                    },
                }
            }],
            fill: {
                opacity: 1
            },
            colors: ['#00e396', '#feb019']
        },
    }

    const minutestohours = (totalMinutes) => {
        var minutes = Math.round(totalMinutes % 60);
        var hours = Math.round((totalMinutes - minutes) / 60);
        return hours + ':' + minutes;
    }

    const ModemStatusDurationState = {
        series: [{
            name: 'Online Duration',
            data: ModemStatusDuration.online_count
        }, {
            name: 'Offline Duration',
            data: ModemStatusDuration.offline_count
        }],
        options: {
            legend: {
                show: false,

            },
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10,
                    borderRadiusApplication: 'end',
                    dataLabels: {
                        total: {
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            xaxis: {
                categories: ModemStatusDuration?.date?.map((item) => ([item, ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
                labels: {
                    show: true,
                    rotate: -90,
                    rotateAlways: true,
                    offsetX: 0,
                    offsetY: 3,
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    console.log(series, seriesIndex, dataPointIndex, w);
                    return `<div style={{padding:'5px'}}>` + w.config.xaxis.categories[dataPointIndex] + `<br/>` + 'online:' + series[seriesIndex][dataPointIndex] + ' ' + 'Minutes' + `(${minutestohours(series[seriesIndex][dataPointIndex])} Hours)` + `<br/>` + 'offline:' + series[seriesIndex + 1][dataPointIndex] + ' ' + 'Minutes' + `(${minutestohours(series[seriesIndex + 1][dataPointIndex])} Hours)` + `</div>`
                }
            },
            yaxis: [{
                allowDecimals: false,
                floating: false,
            }],

            fill: {
                opacity: 1
            },
            colors: ['#00e396', '#feb019']
        },
    }


    const onGetMqttConnectivity=async()=>{
        setModemStatus({ online_count: [], offline_count: [], date: [], res_code: '' })

		const data = await GetMqttConnectionStatusByTable({ date: 7, tableName: props?.logs_table })
		if (data.success === true) {
			setModemStatus({ online_count: data.data.data.map((item) => (item.online_count)), offline_count: data.data.data.map((item) => (item.offline_count)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('something Went wrong')
			setModemStatus({ online_count: [], offline_count: [], date: [], res_code: data?.err?.response?.status })
		}
    }

    const onGetconnectivityInduration=async()=>{
        setModemStatusDuration({ online_count: [], offline_count: [], date: [], res_code: '' })

		const data = await GetMqttConnectionStatusDurationByTable({ date: 7, tableName: props?.logs_table })
		if (data.success === true) {
			setModemStatusDuration({ online_count: data.data.data.map((item) => (item.online_total_duretion)), offline_count: data.data.data.map((item) => (item.offline_total_duretion)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('something Went wrong')
			setModemStatusDuration({ online_count: [], offline_count: [], date: [], res_code: data?.err?.response?.status })
		}
    }

    useEffect(() => {
        onGetMqttConnectivity()
        onGetconnectivityInduration()
    },[])
    return (
        <>
            <div class="row mt-4">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="card card-info">
                        <div class="card-header" style={{ padding: "10px 15px" }}>
                            <h4>Online/Offline Status</h4>
                            <div class="card-header-action">
                                {/* <a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => {
													setisModalVisibleModemST(true)
													setModelHeader('Onlie/Offline Status')
													ongetInStatusStartAndEndDateByTable(GSMData.UNIQUE_TABLE_NAME.ONLINE_OFFLINE, 'SCR_TS_ENTRY')
												}}>Expand</a> */}
                            </div>
                        </div>
                        <div class="card-body" style={{ padding: "0px" }}>
                            {ModemStatus.date.length > 0 && ModemStatus.offline_count.length > 0 && ModemStatus.online_count.length > 0 ? <Chart options={ModemStatusState.options} type="bar" series={ModemStatusState.series} height={"300"} /> : ModemStatus?.res_code == 503 ? <h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
                        </div>
                        <p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="card card-info">
                        <div class="card-header" style={{ padding: "10px 15px" }}>
                            <h4>Online/Offline Status Duration</h4>
                            <div class="card-header-action">
                                {/* <a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => {
													setisModalVisibleModemStatus(true)
													setModelHeader('Onlie/Offline Status Duration')
												}}>Expand</a> */}
                            </div>
                        </div>
                        <div class="card-body" style={{ padding: "0px" }}>
                            {ModemStatusDuration.date.length > 0 && ModemStatusDuration.offline_count.length > 0 && ModemStatusDuration.online_count.length > 0 ? <Chart options={ModemStatusDurationState.options} type="bar" series={ModemStatusDurationState.series} height={"300"} /> : ModemStatusDuration?.res_code == 503 ? <h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
                        </div>
                        <p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
                    </div>
                </div>
            </div>
        </>
    )
}
