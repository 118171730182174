import html2canvas from "html2canvas";

const CaptureImage = async (refiMAGE, format, name) => {

    html2canvas(refiMAGE.current).then(canvas => {
        const baseurl = canvas.toDataURL(`image/${format}`);
        var anchor = document.createElement("a");
        anchor.setAttribute("href", baseurl);
        anchor.setAttribute("download", `${name}-${Date.now()}`);
        anchor.click();
        anchor.remove();
    }).catch(err => {
        console.error("err", err)
    });
}

export { CaptureImage };
