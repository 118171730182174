import React, { useState } from 'react'
import Connectivity from './Connectivity';
import Security from './Security';
import LastWill from './LastWill';
import Publications from './Publications';
import Subscription from './Subscription';
import Log from './OnlineOfflineLogs/Log';
import Other from './Other';
import Main from './OnlineOfflineLogs/MainPage';

export default function Mqttspy(props) {
    const buttonArray = ["Connectivity", "Security", "Publications", "Subscription",'Online Offline Logs'];
	const [btnText, setButtontext] = useState("Connectivity");
	const onHandalebtn = (text) => {
		setButtontext(text);
	};
  return (
    <div className='tw-w-[100%] mb-2'>
			<div className="card-body border-bottom" style={{ paddingTop: "0px", paddingLeft: '15px', paddingBottom: '0px' }}>
				<div className="d-flex justify-content-between">
					<div className="buttons">
						{buttonArray.map((item, id) => (
							<button
								style={{
									// backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#22c55e').alpha(0.4).css() : '',
									// color: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#22c55e').darken(1.5).css() : '#22c55e',
									borderBottom: buttonArray.findIndex((data) => data === btnText) === id ? `3px solid ${'#6366F1'}` : '0px',
									// boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${'#22c55e'}`  : '',
								}}
								className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? '' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
								onClick={() => onHandalebtn(item)}>
								{item}
							</button>
						))}
					</div>
				</div>
			</div>
			<div className=''>
			{btnText === 'Connectivity' && <Connectivity {...props} setcredentialdetails={(info)=>props?.setcredentialdetails({...info})}/>}
			{btnText === 'Security' && <Security {...props} setcredentialdetails={(info)=>props?.setcredentialdetails({...info})}/>}
			{btnText === 'Publications' && <Publications {...props} setcredentialdetails={(info)=>props?.setcredentialdetails({...info})}/>}
			{btnText === 'Subscription' && <Subscription {...props} setcredentialdetails={(info)=>props?.setcredentialdetails({...info})}/>}
			{btnText === 'Online Offline Logs' && <Main {...props} setcredentialdetails={(info)=>props?.setcredentialdetails({...info})} logs_table={JSON.parse(props?.credentialdetails?.integration_table_name||'{}')?.integration_online_offline_logs} />}
			</div>
		</div>
  )
}
