import { forwardRef } from "react"
import ReactApexChart from "react-apexcharts"

function Charts({ type, labels, series }, ref) {
    const options = {
        chart: {
            height: '400px',
            type: type,
            border: '1px solid #000'
        },
        legend: {
			position: 'right',
			verticalAlign: 'bottom',
		},
        series: series,
        labels: labels,
        responsive: [{
			breakpoint: 1500,
			options: {
				legend: {
					position: 'right',
                    width: 150,
				}

			}
		}, {
			breakpoint: 1300,
            chart: {
                height: '500px',
                type: type,
                border: '1px solid #000'
            },
			options: {
				legend: {
					position: 'bottom',
                    width: "100%",
				}
			}
		},{
			breakpoint: 500,
			options: {
				legend: {
					show: false
				}
			}
		}],
    };

    return (
        <div ref={ref} className="tw-absolute tw-w-full -tw-top-[1000%] -tw-left-0 -tw-z-[99999]" >
            <ReactApexChart options={options} series={options.series} type={options.chart.type} />
        </div>
    )
}

export default forwardRef(Charts);