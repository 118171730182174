import { Dropdown } from "react-bootstrap";
import { FaEllipsisVertical } from "react-icons/fa6";


const CardAddEditBtn = ({
    activeTab,
    hasAddCardPermission,
    onAddCard,
    onRemoveCard,
}) => {
    return (
        <Dropdown className="d-inline ">
            <Dropdown.Toggle variant="primary" className="!tw-py-0.5 !tw-px-2.5 tw-rounded-[30px]" id="dropdown-basic">
            <FaEllipsisVertical />

            </Dropdown.Toggle>

            <Dropdown.Menu>
                {activeTab !== 'Custom Dashboard' && (hasAddCardPermission ) && (
                    <Dropdown.Item onClick={onAddCard}>
                        <i
                            className="fas fa-plus "
                            style={{ fontSize: "14px", paddingLeft: "5px", paddingRight: "5px" }}
                        />
                        Add Card
                    </Dropdown.Item>
                )}
                {activeTab === 'Custom Dashboard' && (
                    <Dropdown.Item onClick={onRemoveCard}>
                        <i className="fa fa-trash mr-2"
                            style={{ fontSize: "14px", paddingLeft: "5px", paddingRight: "5px" }} />
                        Remove Card
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default CardAddEditBtn;