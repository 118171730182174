import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import DataTable from '../../Commoncomponet/Datatable/Datatable';
import GoogleLoader from '../../Commoncomponet/Loaders/GoogleLoader';
import { getAllCredentialList, getAllTableInfo, getAttachmentList, syncDatabaseTabledata, syncFilesToDatabase } from '../../Commoncomponet/Utils';
import { BootstrapTooltip } from '../../Commoncomponet/common';
import classNames from 'classnames';

const ModemCredential = () => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0)
    const [loadingMessage, setLoadingMessage] = useState("Please wait while we are fetching data, the number of records are huge. It may take some time.")
    const [isSideExapnded,setIsSideExapnded]=useState(false)
    const [SideDetails,setSideDetails]=useState({})
    useEffect(() => {
        setIsLoading(true)
        getData()
    }, []);

    const getData = async () => {
        const firstPageIndex = (currentPage) * 50;

        const getDetails = await getAllCredentialList({})
        if (getDetails.success === true) {
            setTableData(getDetails.data.data)
            setTotal(getDetails.data.total)
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            console.log("Error while getting all table info = ", getDetails.err)
        }
    }

    const convertToMb = (size) => {
        return size / 1024 / 1024
    }


    const columns = tableData.length > 0 ? Object.keys(tableData[0]).map(key => ({
        field: key,
        label: key.charAt(0).toUpperCase() + key.slice(1),
        type: key === "created_at" || key === "updated_at" || key === "last_modified" ? 'datetime' : 'text',
        cellRenderer: key === "size" ? (row) => `${convertToMb(row).toFixed(2)} mb` : null,
    })) : [];

    const onRowClick = (row) => {
        // alert(JSON.stringify(row, null, 2))
    }

    let userInfo = JSON.parse(window.localStorage.getItem("user_info"));

    const syncList = async () => {
        setIsLoading(true)
        setLoadingMessage("Please wait while we are syncing data from s3 bucket to database.The number of records are huge. It may take some time.")
        const data = await syncDatabaseTabledata({ createdBy: userInfo?.UNIQUE_ID,tableName:'pcmt_eums_modem_credentials',synctableName:'pcst_eums_modem_credentials' })
        if (data.success === true) {
            toast.success("Synced successfully !");
            getData()
        }
        else {
            setIsLoading(false)
            console.log("Error while getting all table info = ", data.err)
        }
    }

    return (
        <>
            {/* <div className="main-content"> */}
                <section className="section">
                    <div className="card !tw-rounded-none">
                        <div className='card-header'>
                            <h4><span className='mr-2'>Modem Credentials List</span><BootstrapTooltip title={`Table Name is attachment_data_index`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>

                            <div className="card-header-action">
                                <button className="btn btn-primary" onClick={() => syncList()}>Sync List</button>
                            </div>
                        </div>

                        <div className='card-body !tw-p-0 mb-4'>
                            {isLoading ? <> <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                                <GoogleLoader bodyClassName={'tw-h-[70%]'} msg={loadingMessage} />
                            </div></> :<><div > 
                            <div > 
                            <DataTable
                                data={tableData}
                                columns={columns}
                                initialSortField="size"
                                initialSortDirection="desc"
                                pageSize={50}
                                filterable
                                tableClassName='tableCustom table table-striped tw-w-full tw-text-sm text-center'
                                theadClassName='tw-sticky tw-z-50 tw-top-0 tw-text-xs tw-bg-gray-100'
                                onRowClick={(row)=>{
                                    setIsSideExapnded(true)
                                    setSideDetails(row)}}
                                setParentCurrentPage={(page) => setCurrentPage(page)}

                            />
                             
                            </div>
                            </div>
                           
                            </>}
                        </div>
                    </div></section>

                    {/* </div> */}
        </>
    )
}

export default ModemCredential