import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import './assets/css/style.css'
import './assets/css/components.css'
import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Switch, withRouter,Redirect } from "react-router-dom";
import Login from './Componet/Login/Login'
import Header from './Componet/Header/Header';
import { ToastContainer } from 'react-toastify';
import PrivetRoute from './Commoncomponet/PrivetRoute';
import Deshboard from './Componet/Deshboard/Deshboard';
import AddUser from './Componet/User/AddUser';
import AddGsm from './Componet/GSM/AddGsm';
import AddAccount from './Componet/Profiles/AddProfiles/AddAccount';
import User from './Componet/User/User';
import Account from './Componet/Profiles/Account';
import Ga from './Componet/Profiles/Ga';
import Circle from './Componet/Profiles/Circle';
import Division from './Componet/Profiles/Division';
import Subdevision from './Componet/Profiles/Subdevision';
import Area from './Componet/Profiles/Area';
import AddGa from './Componet/Profiles/AddProfiles/AddGa';
import AddCircle from './Componet/Profiles/AddProfiles/AddCircle';
import AddDivision from './Componet/Profiles/AddProfiles/AddDivision';
import AddSubdivison from './Componet/Profiles/AddProfiles/AddSubdevision';
import AddArea from './Componet/Profiles/AddProfiles/AddArea';
import All from './Componet/Modem/All';
import Firmware from './Componet/Modem/Firmware';
import Hardwarev from './Componet/Modem/Hardwarev';
import Make from './Componet/Modem/Make';
import ModelNumber from './Componet/Modem/ModelNumber';
import SKU from './Componet/Modem/SKU';
import Type from './Componet/Modem/Type';
import AddFirmware from './Componet/Modem/AddModem/AddFirmware';
import AddHardware from './Componet/Modem/AddModem/AddHardware';
import AddMake from './Componet/Modem/AddModem/AddMake';
import AddmodelNumber from './Componet/Modem/AddModem/AddmodelNumber';
import Addsku from './Componet/Modem/AddModem/Addsku';
import AddType from './Componet/Modem/AddModem/AddType';
import AddAll from './Componet/Modem/AddModem/AddAll';
import Allmeter from './Componet/Meter/All'
import Firmwaremeter from './Componet/Meter/Firmware';
import Hardwarevmeter from './Componet/Meter/Hardware';
import Makemeter from './Componet/Meter/Make';
import ModelNumbermeter from './Componet/Meter/MeterNumber';
import Typemeter from './Componet/Meter/Type';
import Softwaremeter from './Componet/Meter/Software';
import Categorymeter from './Componet/Meter/Category';
import AddCategory from './Componet/Meter/Addmeter/AddCategory';
import AddSoftwaremeter from './Componet/Meter/Addmeter/AddSoftware';
import AddTypemeter from './Componet/Meter/Addmeter/Addtype';
import AddModelNumbermeter from './Componet/Meter/Addmeter/Addmetrenumber';
import AddMakemeter from './Componet/Meter/Addmeter/Addmake';
import AddHardwarevmeter from './Componet/Meter/Addmeter/AddHardware';
import AddFirmwaremeter from './Componet/Meter/Addmeter/AddFirmware';
import AddAllmeter from './Componet/Meter/Addmeter/Addall'
import AddScriptcodeId from './Componet/Scriptcodeid/AddScriptcodeId';
import Scriptcodeid from './Componet/Scriptcodeid/Scriptcodeid';
import Roles from './Componet/Roles/Roles';
import Gsm from './Componet/GSM/Gsm';
import GSM_deshboard from './Componet/GSM/GSM_deshboard';
import AddRoles from './Componet/Roles/AddRoles';
import Class from './Componet/Meter/Class';
import AddClass from './Componet/Meter/Addmeter/AddClass'
import Lora from './Componet/Lora/Lora';
import AddLora from './Componet/Lora/AddLora';
import ServiceProfile from './Componet/ServiceProfile/ServiceProfile';
import AddserviceProfile from './Componet/ServiceProfile/AddserviceProfile';
import Lora_daeshboard from './Componet/Lora/Lora_deshboard';
import GSM_Deshboard from './Componet/Deshboard/GSM/GSM_Main_Dashboard';
import Lora_Deshboard from './Componet/Deshboard/Lora_Deshboard';
import Reports, { ReportHistory } from './Componet/Reports/Reports';
import Userprofiles from './Componet/Userprofiles/Userprofile';
import moment from 'moment';
import Tags from './Componet/Tags/Tags';
import Addtags from './Componet/Tags/Addtags';
import GSMmap from './Componet/Mapview/GSMmap';
import Gsm_buffer from './Componet/Gsm_buffer/Gsm_buffer';
import CreateTable from './Componet/CreateTable/CreateTable';
import Tasks from './Componet/Tasks/Tasks';
import ReleaseNotes from './Componet/ReleaseNotes/ReleaseNotes';
import AddReleaseNotes from './Componet/ReleaseNotes/AddReleaseNotes';
import ScripList from './Componet/ScripList/ScripList';
import Mobileapp from './Componet/Mobileapp/Mobileapp';
import Wimesh from './Componet/GSM/Wimesh/Wimesh';
import Polling from './Componet/GSM/polling/polling';
import AddModemDetails from './Componet/GSM/AddModemDetails';
import AddLoraModemDetails from './Componet/Lora/AddModemDetails';
import MainTaskPage from './Componet/Task2/MainTaskPage';
import AttachmentList from './Componet/Admin/AttachmentList';
import ShowDbInfo from './Componet/Admin/ShowDbInfo';
import Notify from './Componet/Notification/Notify';
import AddNotifyReceiver from './Componet/Notification/AddNotifyReceiver';
import Group from './Componet/UsersGroup.js/Group';
import WebTabs from './Componet/WebTabs/WebTabs';
import BadDataAnalytice from './Componet/BadDataAnalytice/BadDataAnalytice';
import AddRemoteMobileDevice from './Componet/Mobileapp/RemoteMobileDevice/AddRemoteMobileDevice';
import PDAll from './Componet/PortableDeviceModem/All';
import PDFirmware from './Componet/PortableDeviceModem/Firmware';
import PDHardwarev from './Componet/PortableDeviceModem/Hardwarev';
import PDMake from './Componet/PortableDeviceModem/Make';
import PDModelNumber from './Componet/PortableDeviceModem/ModelNumber';
import PDSKU from './Componet/PortableDeviceModem/SKU';
import PDType from './Componet/PortableDeviceModem/Type';
import AddPDFirmware from './Componet/PortableDeviceModem/AddModem/AddFirmware';
import AddPDHardware from './Componet/PortableDeviceModem/AddModem/AddHardware';
import AddPDMake from './Componet/PortableDeviceModem/AddModem/AddMake';
import AddPDmodelNumber from './Componet/PortableDeviceModem/AddModem/AddmodelNumber';
import AddPDsku from './Componet/PortableDeviceModem/AddModem/Addsku';
import AddPDType from './Componet/PortableDeviceModem/AddModem/AddType';
import AddPDAll from './Componet/PortableDeviceModem/AddModem/AddAll';
import PDAllmeter from './Componet/PortableDeviceMeter/All'
import PDFirmwaremeter from './Componet/PortableDeviceMeter/Firmware';
import PDHardwarevmeter from './Componet/PortableDeviceMeter/Hardware';
import PDMakemeter from './Componet/PortableDeviceMeter/Make';
import PDModelNumbermeter from './Componet/PortableDeviceMeter/MeterNumber';
import PDTypemeter from './Componet/PortableDeviceMeter/Type';
import PDSoftwaremeter from './Componet/PortableDeviceMeter/Software';
import PDCategorymeter from './Componet/PortableDeviceMeter/Category';
import AddPDCategory from './Componet/PortableDeviceMeter/Addmeter/AddCategory';
import AddPDSoftwaremeter from './Componet/PortableDeviceMeter/Addmeter/AddSoftware';
import AddPDTypemeter from './Componet/PortableDeviceMeter/Addmeter/Addtype';
import AddPDModelNumbermeter from './Componet/PortableDeviceMeter/Addmeter/Addmetrenumber';
import AddPDMakemeter from './Componet/PortableDeviceMeter/Addmeter/Addmake';
import AddPDHardwarevmeter from './Componet/PortableDeviceMeter/Addmeter/AddHardware';
import AddPDFirmwaremeter from './Componet/PortableDeviceMeter/Addmeter/AddFirmware';
import AddPDAllmeter from './Componet/PortableDeviceMeter/Addmeter/Addall'
import PDClass from './Componet/PortableDeviceMeter/Class';
import AddPDClass from './Componet/PortableDeviceMeter/Addmeter/AddClass'
import AddPDScriptcodeId from './Componet/PortableDeviceScriptdcodeid/AddScriptcodeId';
import PDScriptcodeid from './Componet/PortableDeviceScriptdcodeid/Scriptcodeid';
import AddMeterConsumer from './Componet/Mobileapp/MeterConsumption/AddMeterConsumer';
import MeterConsumerDeshboard from './Componet/Mobileapp/MeterConsumption/MeterConsumerDeshboard';
import MsSqlIntegration from './Componet/Integration/Integration_Componet/IntegrationDetailTabs';
import Integrations from './Componet/Integration/Integration_Componet/Integrations';
import Add_update_structure from './Componet/Integration/Integration_Componet/Add_update_structure';
import ModemCredential from './Componet/Admin/ModemCredential';
import MeterModelIndex from './Componet/Meter/MeterModelIndex';
import CommonTable from './Componet/Admin/CommonTables/CommonTable';
import AddPTAllmeter from './Componet/TPMeter/Addmeter/Addall'
import AddPTAllmodem from './Componet/TPModem/AddModem/AddAll'
import ThirdPartyConsumer from './Componet/ThirdPartyConsumer/ThirdPartyConsumer';
import AddTPMeterConsumer from './Componet/ThirdPartyConsumer/MeterConsumption/AddMeterConsumer';
import TPMeterConsumerDeshboard from './Componet/ThirdPartyConsumer/MeterConsumption/MeterConsumerDeshboard';

function App(props) {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: " %d seconds ago",
      ss: "%d seconds ago",
      m: "%d min ago",
      mm: "%d min ago",
      h: "an hours ago",
      hh: "%d hours ago",
      d: "a day ago",
      dd: "%d days ago",
      w: "a week ago",
      ww: "%d weeks ago",
      M: "a month ago",
      MM: "%d months ago",
      y: "a year ago",
      yy: "%d years ago"
    }
  });
	const userInfo=JSON.parse(window.localStorage.getItem('user_info'))||{}

 const [manuPermission,setmanuPermission]=useState([])
 const [isRouteLoading,setIsRouteLoading]=useState(true)

 const onHandalSwitch = (key) => {
  if(Object.keys(manuPermission||{}).length){
      var obj_flag=false
  const updateData = (items) => items?.map(item => {
      if (item?.id === key&&item?.view==true) {
          obj_flag=true
          return item.view
      }
      if (item.children) {
          return updateData(item.children) 
      }
      return item.view;
  });
      updateData(manuPermission)
 return obj_flag
}
};

useEffect(()=>{
  if(props.history.location.pathname === '/gsm-map-view'){
  setIsRouteLoading(false)
  }
},[])
  return (
    <>
      {props.history.location.pathname === '/' || props.history.location.pathname === '/gsm-map-view' ? "" : <Header setmanuPermission={(info)=>setmanuPermission(info)} manuPermission={manuPermission} setIsRouteLoading={setIsRouteLoading}/>}
      <Switch>
        <Route exact path="/" component={withRouter(Login)} />
        {/* deshboard */}
        <PrivetRoute path="/all-deshboard" Component={withRouter(Deshboard)} exact />
        {!isRouteLoading||userInfo?.ROLE=='ROLE1661856938676'?<Switch>
        {/* Users */}
        {(onHandalSwitch('MENU11_1')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/add-users" Component={withRouter(AddUser)} exact />}
        {(onHandalSwitch('MENU11_1')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/user" Component={withRouter(User)} exact />}
        {/* GSM */}
        {(onHandalSwitch('MENU3_1')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/add-gsm-modem" Component={withRouter(AddGsm)} exact />}
        {(onHandalSwitch('MENU3_1')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/All-GSM' Component={withRouter(Gsm)} exact />}
        {(onHandalSwitch('MENU3_1')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/view_existing_consumer_gsm/:GSMID' Component={withRouter(GSM_deshboard)} exact />}
        {(onHandalSwitch('MENU3_1')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/AddModemDetails' Component={withRouter(AddModemDetails)} exact />}
        {/* Lorawan */}
        {(onHandalSwitch('MENU3_2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/All-Lorawan' Component={withRouter(Lora)} exact />}
        {(onHandalSwitch('MENU3_2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/add-Lorawan-modem" Component={withRouter(AddLora)} exact />}
        {(onHandalSwitch('MENU3_2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/view_existing_consumer_lorawan/:LoraID' Component={Lora_daeshboard} exact />}
        {(onHandalSwitch('MENU3_2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/AddLoraModemDetails' Component={withRouter(AddLoraModemDetails)} exact />}
        {/* Profile */}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/add-account" Component={withRouter(AddAccount)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/add-ga/:accountId" Component={withRouter(AddGa)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/add-circle/:accountId/:gaId" Component={withRouter(AddCircle)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/add-division/:accountId/:gaId/:circleId" Component={withRouter(AddDivision)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/add-subdivision/:accountId/:gaId/:circleId/:divisionId" Component={withRouter(AddSubdivison)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/add-Area/:accountId/:gaId/:circleId/:divisionId/:subdivisionId" Component={withRouter(AddArea)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/account" Component={withRouter(Account)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/ga/:accountId" Component={withRouter(Ga)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/circle/:accountId/:gaId" Component={withRouter(Circle)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/division/:accountId/:gaId/:circleId" Component={withRouter(Division)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/subdivision/:accountId/:gaId/:circleId/:divisionId" Component={withRouter(Subdevision)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/area/:accountId/:gaId/:circleId/:divisionId/:subdivisionId" Component={withRouter(Area)} exact />}
        {/* Admin Tab */}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/Admin/CommonTables" Component={withRouter(CommonTable)} exact />}

        {/* Modem */}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/allmodem" Component={withRouter(All)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/modemfirmware" Component={withRouter(Firmware)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/modemhardwarev" Component={withRouter(Hardwarev)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/modemmake" Component={withRouter(Make)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/modemmodelnumber" Component={withRouter(ModelNumber)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/modemsku" Component={withRouter(SKU)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/modemtype" Component={withRouter(Type)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/add-allmodem" Component={withRouter(AddAll)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/add-firmware" Component={withRouter(AddFirmware)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/add-Hardware" Component={withRouter(AddHardware)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/add-make" Component={withRouter(AddMake)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/add-modelNumber" Component={withRouter(AddmodelNumber)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/add-sku" Component={withRouter(Addsku)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/add-type" Component={withRouter(AddType)} exact />}
        {/* Meter */}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/allmeter" Component={withRouter(Allmeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/MeterModelIndex" Component={withRouter(MeterModelIndex)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/metefirmware" Component={withRouter(Firmwaremeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/metehardwarev" Component={withRouter(Hardwarevmeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/metemake" Component={withRouter(Makemeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/metemodelnumber" Component={withRouter(ModelNumbermeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/metetype" Component={withRouter(Typemeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/metesoftware" Component={withRouter(Softwaremeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/metecategory" Component={withRouter(Categorymeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/meteclass" Component={withRouter(Class)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-allmeter' Component={withRouter(AddAllmeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-metecategory' Component={withRouter(AddCategory)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-meteclass' Component={withRouter(AddClass)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-metesoftware' Component={withRouter(AddSoftwaremeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-metetype' Component={withRouter(AddTypemeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-metemodelnumber' Component={withRouter(AddModelNumbermeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-metermake' Component={withRouter(AddMakemeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-metehardwarev' Component={withRouter(AddHardwarevmeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-metefirmware' Component={withRouter(AddFirmwaremeter)} exact />}
        {/* scripcodeid */}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-scriptcodeid' Component={withRouter(AddScriptcodeId)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/scripcodeid' Component={withRouter(Scriptcodeid)} exact />}
        {/* Roles */}
        {(onHandalSwitch('MENU11_2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/Roles' Component={withRouter(Roles)} exact />}
        {(onHandalSwitch('MENU11_2')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/add-Roles' Component={withRouter(AddRoles)} exact />}
        {/* Service Profile */}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/service_provider' Component={withRouter(ServiceProfile)} exact />}
       {userInfo?.ROLE=='ROLE1661856938676'&& <PrivetRoute path='/add-service_provider' Component={withRouter(AddserviceProfile)} exact />}
        {/* GSM_Deshboard */}
        {(onHandalSwitch('MENU3_1')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/gsm_dashboard' Component={withRouter(GSM_Deshboard)} exact />}
        {/* Lora_Deshboard */}
        {(onHandalSwitch('MENU11_2')||userInfo?.ROLE=='ROLE1661856938676')&& <PrivetRoute path='/lorawan_dashboard' Component={withRouter(Lora_Deshboard)} exact />}
        {/*Reports */}
        <PrivetRoute path='/reports' Component={withRouter(Reports)} exact />
        <PrivetRoute path='/reportHistory' Component={withRouter(ReportHistory)} exact />
        {/* Profile */}
        <PrivetRoute path='/profiles' Component={withRouter(Userprofiles)} exact />
        {/* Tags */}
        {(onHandalSwitch('MENU6')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/tags' Component={withRouter(Tags)} exact />}
        {(onHandalSwitch('MENU6')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/add-tags' Component={withRouter(Addtags)} exact />}
        {/* map */}
        {(onHandalSwitch('MENU3_1')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/gsm-map-view' Component={withRouter(GSMmap)} exact />}
        {/* Buffer */}
        {/* <PrivetRoute path='/gsm-buffer' Component={withRouter(Gsm_buffer)} exact /> */}
        {/* Create Table */}
        {userInfo?.ROLE=='ROLE1661856938676'?<PrivetRoute path='/create-table' Component={withRouter(CreateTable)} exact />:''}
        {/* Tasks */}
        <PrivetRoute path="/tasks" Component={withRouter(MainTaskPage)} exact />
 {/* Release Notes */}
 {(onHandalSwitch('MENU8')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/release-notes" Component={withRouter(ReleaseNotes)} exact />}
        {(onHandalSwitch('MENU8')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/add-realese-notes' Component={withRouter(AddReleaseNotes)}></PrivetRoute>}
        {/* Script List */}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/scriptlist' Component={withRouter(ScripList)}></PrivetRoute>}
{/* Mobile app */}
{(onHandalSwitch('MENU3_3_1')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/mobileapp' Component={withRouter(Mobileapp)}></PrivetRoute>}
{(onHandalSwitch('MENU3_3_1')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/add-remote-mobile-device' Component={withRouter(AddRemoteMobileDevice)}></PrivetRoute>}
{(onHandalSwitch('MENU3_3_1')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/add-meter-consumer' Component={withRouter(AddMeterConsumer)}></PrivetRoute>}
{(onHandalSwitch('MENU3_3_1')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/view_portable_meter_consumer/:GSMID' Component={withRouter(MeterConsumerDeshboard)} exact />}

{/* Wi-Mesh */}
{(onHandalSwitch('MENU3_5')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/wimesh' Component={withRouter(Wimesh)}></PrivetRoute>}
{/* Polling */}
 {(onHandalSwitch('MENU3_4')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/polling' Component={withRouter(Polling)}></PrivetRoute>}

 {/* integration */}
 
 {(onHandalSwitch('MENU7')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/integration' Component={withRouter(Integrations)}></PrivetRoute>}
 {(onHandalSwitch('MENU7')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/Add-Update-integration-Structure' Component={withRouter(Add_update_structure)}></PrivetRoute>}
 {(onHandalSwitch('MENU7')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/integrations/Integrations-details/:MSID' Component={withRouter(MsSqlIntegration)}></PrivetRoute>}
 {/* database all table */}

 {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/admin/dbDetails' Component={withRouter(ShowDbInfo)}></PrivetRoute>}
 {userInfo?.ROLE=='ROLE1661856938676'&& <PrivetRoute path='/admin/AttachmentList' Component={withRouter(AttachmentList)}></PrivetRoute>}

        {/* Notification */}
        {(onHandalSwitch('MENU5')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/Notification' Component={withRouter(Notify)}></PrivetRoute>}
        {(onHandalSwitch('MENU5')||userInfo?.ROLE=='ROLE1661856938676')&& <PrivetRoute path='/AddNotifyReceiver' Component={withRouter(AddNotifyReceiver)}></PrivetRoute>}

        {/* users group */}
        {(onHandalSwitch('MENU11_3')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/UsersGroup' Component={withRouter(Group)}></PrivetRoute>}
{/* Web Tabs */}
{(onHandalSwitch('MENU9')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/Web-Tabs' Component={withRouter(WebTabs)}></PrivetRoute>}
{/* bad data analytice */}
{(onHandalSwitch('MENU10')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/BadDataAnalytics' Component={withRouter(BadDataAnalytice)} />}
{/*Poratable Device Modem */}
{userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/allPoratableDevicemodem" Component={withRouter(PDAll)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/PoratableDevicemodemfirmware" Component={withRouter(PDFirmware)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/PoratableDevicemodemhardwarev" Component={withRouter(PDHardwarev)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/PoratableDevicemodemmake" Component={withRouter(PDMake)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/PoratableDevicemodemmodelnumber" Component={withRouter(PDModelNumber)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/PoratableDevicemodemsku" Component={withRouter(PDSKU)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/PoratableDevicemodemtype" Component={withRouter(PDType)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/add-PoratableDevice-allmodem" Component={withRouter(AddPDAll)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/add-PoratableDevice-firmware" Component={withRouter(AddPDFirmware)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/add-PoratableDevice-Hardware" Component={withRouter(AddPDHardware)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/add-PoratableDevice-make" Component={withRouter(AddPDMake)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/add-PoratableDevice-modelNumber" Component={withRouter(AddPDmodelNumber)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/add-PoratableDevice-sku" Component={withRouter(AddPDsku)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/add-PoratableDevice-type" Component={withRouter(AddPDType)} exact />}
        {/*Poratable Device Meter */}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/allPoratableDevicemeter" Component={withRouter(PDAllmeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/PoratableDevicemetefirmware" Component={withRouter(PDFirmwaremeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/PoratableDevicemetehardwarev" Component={withRouter(PDHardwarevmeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/PoratableDevicemetemake" Component={withRouter(PDMakemeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/PoratableDevicemetemodelnumber" Component={withRouter(PDModelNumbermeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/PoratableDevicemetetype" Component={withRouter(PDTypemeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/PoratableDevicemetesoftware" Component={withRouter(PDSoftwaremeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/PoratableDevicemetecategory" Component={withRouter(PDCategorymeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/PoratableDevicemeteclass" Component={withRouter(PDClass)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-PoratableDevice-allmeter' Component={withRouter(AddPDAllmeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-PoratableDevice-metecategory' Component={withRouter(AddPDCategory)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-PoratableDevice-meteclass' Component={withRouter(AddPDClass)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-PoratableDevice-metesoftware' Component={withRouter(AddPDSoftwaremeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-PoratableDevice-metetype' Component={withRouter(AddPDTypemeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-PoratableDevice-metemodelnumber' Component={withRouter(AddPDModelNumbermeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-PoratableDevice-metermake' Component={withRouter(AddPDMakemeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-PoratableDevice-metehardwarev' Component={withRouter(AddPDHardwarevmeter)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-PoratableDevice-metefirmware' Component={withRouter(AddPDFirmwaremeter)} exact />}
 {/* portable scripcodeid */}
 {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/add-portable-scriptcodeid' Component={withRouter(AddPDScriptcodeId)} exact />}
        {userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/portable-scripcodeid' Component={withRouter(PDScriptcodeid)} exact />}

{/* Modem Credential */}
{userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path='/admin/Modem-Credential' Component={withRouter(ModemCredential)} exact />}
{/*Third Party Device Modem */}
{userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/AllThirdPartyModem" Component={withRouter(AddPTAllmodem)} exact />}
{/*Third Party Device Modem */}
{userInfo?.ROLE=='ROLE1661856938676'&&<PrivetRoute path="/AllThirdPartyMeter" Component={withRouter(AddPTAllmeter)} exact />}
{/*Third Party Consumer */}
{(onHandalSwitch('MENU3_6')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/All_Third_party_consumer" Component={withRouter(ThirdPartyConsumer)} exact />}
{(onHandalSwitch('MENU3_6')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path="/add_Third_party_consumer" Component={withRouter(AddTPMeterConsumer)} exact />}
{(onHandalSwitch('MENU3_6')||userInfo?.ROLE=='ROLE1661856938676')&&<PrivetRoute path='/view_third_party_meter_consumer/:TPID' Component={withRouter(TPMeterConsumerDeshboard)} exact />}
        <Redirect from="*" to="/" />

        </Switch>
        :<div className="main-content">
        <div class="loader"></div>
      </div>}


      </Switch>
      <ToastContainer position={"top-right"} autoClose={3000} limit={4} />
    </>
  );
}

export default withRouter(App);
