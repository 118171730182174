import React from 'react'
import { Link } from 'react-router-dom'

export default function Deshboard() {
  return (
    <>
        <div className="main-content" style={{ minHeight: "562px" }}>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/all-deshboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
					</ol>
				</nav>
                </div>
    </>
  )
}
