import moment from 'moment';

// Time calculation hook
export function TimeCalculationHook({
  data,
  lastDate = '',
  startdate = '',
  isMoreDatas,
  last_status,
  lookup = {}
}) {

  if (!data.length && !isMoreDatas) {
    return { dataSet: [], datasetForCount: [], keys: [], datasetForMinutes: [] };
  }

  console.log(data,lastDate,startdate, isMoreDatas, last_status,  lookup )
  let oldestDate = null;
 
  data.forEach((v) => {
    const currentDate = moment.utc(v[lookup['entry_ts']]);
    console.log('Current Date:', currentDate.format('YYYY-MM-DD'));
 
    if (!oldestDate || currentDate.isBefore(oldestDate)) {
      oldestDate = currentDate;
    }
  });
 
    if (oldestDate) {
    console.log('Oldest Date:', oldestDate.format('YYYY-MM-DD'));
  }
  let startDate = isMoreDatas ? startdate : oldestDate
  console.log(startDate,data[0])
  const { entries, keys } = formatDataByDate(data, lastDate, startDate, isMoreDatas, last_status, lookup);
  const finalEntries = calculateTime(entries, lastDate, startDate, lookup);

  console.log("results",finalEntries)

  return {
    dataSet: finalEntries.hoursDataSet,
    keys,
    groupValue: finalEntries.groupValue,
    datasetForMinutes: finalEntries.groupByResultDataSet,
  };
}

// Format data by date
function formatDataByDate(data, lastDate, startDate, isMoreDatas, last_status,lookup) {
  // Group data by date
  const groupedData = groupDataByDate(data, lookup);

  console.log({groupedData})

  const dastaLength = data?.length

  // Fill missing dates
  const filledData = fillMissingDates(groupedData, lastDate, startDate, lookup ,dastaLength , last_status);

  return { entries: Object.entries(filledData), keys: Object.keys(filledData) };
}

// Group data by date
function groupDataByDate(data, lookup) {
  const groupedData = {};

  data.forEach((item, index) => {
    const date = moment.utc(item[lookup['entry_ts']]).format('DD-MM-YYYY');
    const gatewayStatus = item[lookup['status']] === lookup['onlineValue'] ? lookup['offlineVlaue'] : lookup['onlineValue'];

    if (!groupedData[date]) {
      groupedData[date] = [];
    }

    groupedData[date].push({
      ...item,
      [lookup['entry_ts']]: moment.utc(item[lookup['entry_ts']]).format('DD-MM-YYYY hh:mm:ss A'),
    });

    // Add start and end of day entries
    if (!groupedData[date].find((entry) => entry[lookup['entry_ts']] === moment(date, "DD-MM-YYYY").startOf('day').format('DD-MM-YYYY hh:mm:ss A'))) {
      groupedData[date].unshift({
        [lookup['status']]: gatewayStatus,
        [lookup['entry_ts']]: moment(date, "DD-MM-YYYY").startOf('day').format('DD-MM-YYYY hh:mm:ss A'),
      });
    }

    if (index === data.length - 1 || moment.utc(data[index + 1][lookup['entry_ts']]).format('DD-MM-YYYY') !== date) {
      if (moment().format('DD-MM-YYYY') === date) {
        groupedData[date].push({
          [lookup['status']]: gatewayStatus,
          [lookup['entry_ts']]: moment(date, "DD-MM-YYYY").format('DD-MM-YYYY hh:mm:ss A'),
        });
      } else {
        groupedData[date].push({ [lookup['status']]: item[lookup['status']], [lookup['entry_ts']]: moment(date, "DD-MM-YYYY").endOf('day').format('DD-MM-YYYY hh:mm:ss A') });
      }
    }


  });

  return groupedData;
}

// Fill missing dates
function fillMissingDates(groupedData, lastDate, startDate, lookup , dastaLength , last_status) {
  console.log({groupedData, lastDate, startDate, lookup , dastaLength , last_status})
  const filledData = {};
  const dates = getDates(startDate, lastDate);

  dates.forEach((date) => {
    if (groupedData[date]) {
      filledData[date] = groupedData[date];
    } else {
      filledData[date] = getDefaultValue(date, groupedData, filledData, lookup , lastDate , dastaLength , last_status);
    }
  });

  console.log()
  return filledData;
}

// Get default value
function getDefaultValue(date, groupedData, filledData, lookup , lastDate , dastaLength , last_status) {
  const previousDate = moment(date, 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
  let gatewayStatus = filledData[previousDate]?.[filledData[previousDate].length - 1]?.[lookup['status']];
  let endTime = moment(date, 'DD-MM-YYYY').endOf('day').format('DD-MM-YYYY hh:mm:ss A')


  if (!gatewayStatus) {
    if (last_status && dastaLength === 0) {
      gatewayStatus = last_status
    }else{
      gatewayStatus = Object.values(groupedData)[0][0][lookup['status']];
    }
  }

  if (moment().format('DD-MM-YYYY') === date) {
    endTime = moment().format('DD-MM-YYYY hh:mm:ss A')
  }

  return [
    {
      [lookup['status']]: gatewayStatus,
      [lookup['entry_ts']]: moment(date, 'DD-MM-YYYY').startOf('day').format('DD-MM-YYYY hh:mm:ss A'),
    },
    {
      [lookup['status']]: gatewayStatus,
      [lookup['entry_ts']]: endTime
    },
  ];
}

// Get dates
function getDates(startDate, lastDate) {
  const dates = [];
  const currentDate = moment(startDate, 'YYYY-MM-DD');

  while (currentDate.valueOf() <= moment(lastDate, 'YYYY-MM-DD').valueOf()) {
    dates.push(currentDate.format('DD-MM-YYYY'));
    currentDate.add(1, 'days');
  }

  return dates;
}

// Calculate time
function calculateTime(entries, lastDate, startDate, lookup) {
  const result = {};
  const groupByResult = {};

  entries.forEach(([date, data]) => {
    let totalTime = 0;

    result[date] = data.reduce((acc, item, index, arr) => {
      if (index < arr.length - 1) {
        const timeGap = moment.duration(moment(arr[index + 1][lookup['entry_ts']], 'DD-MM-YYYY hh:mm:ss A').diff(moment(item[lookup['entry_ts']], 'DD-MM-YYYY hh:mm:ss A')));
        const diff = timeGap.asHours();

        acc.push({
          ...item,
          x: item[lookup['entry_ts']].split(' ')[0],
          y: [totalTime, totalTime + diff],
          timerange: `${item[lookup['entry_ts']]} to ${arr[index + 1][lookup['entry_ts']]}`,
          durationRang:`${timeGap.hours()} : ${timeGap.minutes()} : ${timeGap.seconds()}`,
          durationHours: timeGap.asHours(),
          durationInMinutes: timeGap.asMinutes(),
          durationInSecond: timeGap.asSeconds(),
        });
        totalTime += diff;
      }
      return acc;
    }, []);

    groupByResult[date] = result[date].reduce((acc, item) => {

      if (item[lookup['status']] === lookup['onlineValue']) {
        acc.totalOnlineTime = (acc.totalOnlineTime ?? 0) + item.durationInMinutes;
        acc.onlinecount = (acc.onlinecount ?? 0) + 1;
      } else {
        acc.totalOfflineTime = (acc.totalOfflineTime ?? 0) + item.durationInMinutes;
        acc.offlinecount = (acc.offlinecount ?? 0) + 1;
      }
      return acc;
    }, {});
  })

  const ResultFilter = {};
  const hoursDataSet = []

  const groupValue = {
    totalMinutesofOnline: [],
    totalMinutesofoffline: [],
};
const groupByResultDataSet = []


  Object.values(result).forEach((v) => {

    v.forEach((element, index) => {

      if (!ResultFilter[element[lookup['status']]]) {
        ResultFilter[element[lookup['status']]] = []
      };
  
      ResultFilter[element[lookup['status']]].push({ ...element, status: element[lookup['status']] });
    })
  })

  const groupByEntries = Object.entries(groupByResult);
const lastIndex = groupByEntries.length - 1;


groupByEntries.forEach(([date, data], index) => {
      const { totalOnlineTime, totalOfflineTime } = data;
  
      let adjustedOnlineTime = totalOnlineTime?.toFixed(2) || 0;
      let adjustedOfflineTime = totalOfflineTime?.toFixed(2) || 0;
  
      // Add 0.02 to all but the last value
      console.log(adjustedOnlineTime,adjustedOfflineTime)
      if (index !== lastIndex) {
          adjustedOnlineTime = parseFloat(adjustedOnlineTime);
          adjustedOfflineTime = parseFloat(adjustedOfflineTime);
      }
  
      groupValue["totalMinutesofOnline"].push({ 
          x: date, 
          y: parseFloat(adjustedOnlineTime)?.toFixed(2), 
          totalOnlineTime 
      });
      groupValue["totalMinutesofoffline"].push({ 
          x: date, 
          y: parseFloat(adjustedOfflineTime)?.toFixed(2), 
          totalOfflineTime 
      });
  });
  


  Object.entries(ResultFilter).map((v) => {
    console.log(v)
    hoursDataSet.push({ label: v[0], data: v[1], backgroundColor: ` ${v[0] == lookup['onlineValue'] ? '#4fe396' : '#f7b019'}`, barBorderRadius: 3 })
  })

  Object.entries(groupValue).map((v) => {
    groupByResultDataSet.push({ label: v[0], data: v[1], backgroundColor: ` ${v[0] == lookup['onlineValue'] ? '#4fe396' : '#f7b019'}`, barThickness: 35,label: ` ${v[0] === "totalMinutesofOnline" ? 'Online minutes ' : 'Offline minutes '}`  })
  })


  return { hoursDataSet, groupByResultDataSet ,groupValue}

}