import React, { useState } from 'react'
import ButtonTabs from '../../../Commoncomponet/Tabs/ButtonTabs'
import All from '../../TPModem/All'
import Type from '../../TPModem/Type'
import Make from '../../TPModem/Make'
import ModelNumber from '../../TPModem/ModelNumber'
import Firmware from '../../TPModem/Firmware'
import SKU from '../../TPModem/SKU'
import Hardwarev from '../../TPModem/Hardwarev'

const TPModemTables = () => {
    const [DefaultTab,setDefaultTab]=useState("tab1")
    const [IsDefault,setIsDefault]=useState(false)
    return (
        <>
                <section className="section">
                    <div className="">

                        <div className='tw-mt-2 tw-w-full tw-mx-auto tw-px-0'>
                            <ButtonTabs color="indigo" className={'tw-flex-wrap'} defaultTab={'tab1'} setIsDefault={setIsDefault} IsDefault={IsDefault}>
                                <div name='pcst_eums_third_party_modem_index' value={'tab1'} color='#22c55e' onClick={()=>setDefaultTab('tab1')}>
                                   <All/>
                                </div>
                                <div className='border-top' name='pcst_eums_third_party_modem_type' value={'tab2'} color='#6366f1' onClick={()=>setDefaultTab('tab2')}>
                                   <Type/>
                                </div>
                                <div className='border-top' name='pcst_eums_third_party_modem_make' value={'tab3'} color='#0ea5e9' onClick={()=>setDefaultTab('tab3')}>
                                   <Make/>
                                </div>
                                <div className='border-top' name='pcst_eums_third_party_modem_model_number' value={'tab4'} color='#8b5cf6' onClick={()=>setDefaultTab('tab4')}>
                                   <ModelNumber/>
                                </div>
                                <div className='border-top' name='pcst_eums_third_party_modem_firmware' value={'tab5'} color='#e3bf0e' onClick={()=>setDefaultTab('tab5')}>
                                   <Firmware/>
                                </div>
                                <div className='border-top' name='pcst_eums_third_party_modem_product_code' value={'tab6'} color='#f43f5e' onClick={()=>setDefaultTab('tab6')}>
                                   <SKU/>
                                </div>
                                <div className='border-top' name='pcst_eums_third_party_modem_hardware_version' value={'tab7'} color='#0ea5e9' onClick={()=>setDefaultTab('tab7')}>
                                   <Hardwarev/>
                                </div>
                            </ButtonTabs>
                        </div>
                    </div>
                </section>
        </>
    )
}

export default TPModemTables