import React from 'react'
import { Award, Users } from 'react-feather'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import MapPicker from 'react-google-map-picker'
import { useState } from 'react';
import {Modal} from 'antd'
import GeoLocation from '../../../Commoncomponet/GecodeLocation';
import { activeandinactive, CheckUseConsumer, getAccount, getPortableAllModem, getProfilNameByAccountId, getUsers, InsertGa, InsertRemoteMobildeDevice, Table_Get_All_City_by_State, Table_Get_All_Country, Table_Get_All_State_by_Country, UpdateGa, UpdateRemoteMobileDevice } from '../../../Commoncomponet/Utils';
import { useEffect } from 'react';
import {toast} from 'react-toastify'
import { BootstrapTooltip } from '../../../Commoncomponet/common';
import swal from "sweetalert2";
window.Swal = swal;
const DefaultLocation = { lat: null, lng: null};
const DefaultZoom = 10;
export default function AddRemoteMobileDevice() {
  const params = useParams();

   const [disable,setDisable]=useState(true)

	const [UserData,setUserData]=useState([])
    const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
    const [isModalVisible, setIsModalVisible] = useState(false);
const [AccountInput,serAccountInput]=useState({imei:'',users:'',country:'',state:'',city:'',modem_type:''})
  const [location, setLocation] = useState(defaultLocation);
  const [DeviceId, setGaId] = useState('');
  const [flagBtn, setflegBtn] = useState(false);
  const [ModemData,setModemData]=useState()

  const [zoom, setZoom] = useState(DefaultZoom);
  const locations=useLocation()
  const history = useHistory();
  const userInfo=JSON.parse(window.localStorage.getItem("user_info")||{})

  useEffect(() => {
    if (locations?.state) {
       serAccountInput({imei:locations?.state?.imei,users:JSON.parse(locations?.state?.user_id||'[]')[0]||'',STATUS:locations?.state?.status})
       setGaId(locations?.state?.unique_id)
       setDisable(false)

    }
 }, [locations]);
  const handleCancel = () => {
    setIsModalVisible(false);
    setLocation({ ... DefaultLocation});
    setZoom(DefaultZoom);
};
  function handleChangeLocation (lat, lng){
    setLocation({lat:lat, lng:lng});
  }
  
  function handleChangeZoom (newZoom){
    setZoom(newZoom);
  }

  function handleResetLocation(){
    setDefaultLocation({ ... DefaultLocation});
    setZoom(DefaultZoom);
  }

  const onHandaleSelection=async()=>{
    const data=await getUsers()
    if(data.success===true){
        setUserData(data.data.data)
    }else{
      console.log('somthing Went wrong')
    }
  }


  const onHandalModemList=async()=>{
   const data6 = await getPortableAllModem();
   if (data6.success === true) {
      setModemData(data6.data.data);
   }
  }

  useEffect(() => {
    onHandaleSelection()
    ongetProfilNameByAccountId()
    onHandalModemList()
  }, []);

  const onSubmitAccountData=async(e)=>{
    e.preventDefault()
    setflegBtn(true)
    if(AccountInput?.modem_type==''){
      toast.error('Please Select Modem Type')
      setflegBtn(false)
      return
    }
 if(AccountInput?.imei===''){
    toast.error('Please Fill IMEI')
    setflegBtn(false)
 }else{
    if(DeviceId===''){
 const data=await InsertRemoteMobildeDevice({...AccountInput,users:JSON.stringify([AccountInput?.users]),add_by:userInfo?.UNIQUE_ID})
 if(data.success===true){
    toast.success("User GA Success")
    history.push(`/mobileapp`)
    setflegBtn(false)
 
 }else if(data?.err?.response?.status===301){
    toast.error("Duplicat IMEI Number")
    setflegBtn(false)
 }else{
    toast.error("Something Went Wrong")
    setflegBtn(false)
 }
    }
    else{
       const data=await UpdateRemoteMobileDevice({...AccountInput,users:JSON.stringify([AccountInput?.users]),update_by:userInfo?.UNIQUE_ID},DeviceId)
 if(data.success===true){
    toast.success("Update GA Success")
    history.push(`/mobileapp`)
    setflegBtn(false)
 
 }else if(data?.err?.response?.status===301){
    toast.error("Duplicat IMEI Number")
    setflegBtn(false)
 }else{
    toast.error("Something Went Wrong")
    setflegBtn(false)
 
 }
    }
 }
   }

   const oninactive=async()=>{
      
         swal.fire({
            title: "Are You Sure InActive Remote Mobile Device?",
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "warning",
            showCancelButton: true,
         }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'portable_device_index',colName:'status',value:'0',matchId:'unique_id'},DeviceId)
      if(data.success===true){
         toast.success('Success Inactive Remote Mobile Device')
         history.push(`/mobileapp`)
      }else{
         toast.error('Something Went Wrong')
      }
   }
         })
   
     }
     const onactive=async()=>{
      swal.fire({
         title: "Are You Sure Active Remote Mobile Device?",
         // text: "Once deleted, you will not be able to recover this User Details!",
         icon: "warning",
         showCancelButton: true,
      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'portable_device_index',colName:'status',value:'1',matchId:'unique_id'},DeviceId)
      if(data.success===true){
         toast.success('Success Active Remote Mobile Device')
         history.push(`/mobileapp`)
      }else{
         toast.error('Something Went Wrong')
      }
   }
      })
     }
   
     const [NameObj,setNameObj]=useState({})

     const ongetProfilNameByAccountId=async()=>{
        const data=await getProfilNameByAccountId({ID:params.accountId})
        if(data.success===true){
           setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME||'{}'))
        }else{
           console.log('Something Went Wrong')
        }
     }
  return (
    <>
	<Modal title="Map" visible={isModalVisible} onOk={()=>(setIsModalVisible(false))} onCancel={handleCancel} width={800}>
    <MapPicker defaultLocation={{ lat: parseFloat(location?.lat||'19.506765954448635'), lng: parseFloat(location?.lng||'76.46875000000001')}}
    zoom={zoom}
    mapTypeId="roadmap"
    style={{height:'500px'}}
    onChangeLocation={handleChangeLocation} 
    onChangeZoom={handleChangeZoom}
    apiKey='AIzaSyCXFhk3O45n6rnm7yMXyN_bAzguizB_GjA'/>
    </Modal>
    <div className="main-content" style={{ minHeight: "562px" }}>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/all-deshboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
                            <Users style={{ height: "16px" }} /> Consumer
							</Link>
						</li>
						<li className="breadcrumb-item">
						<Link >
                  Portable Device
						</Link>
					</li>
                    <li className="breadcrumb-item">
						<Link to='/mobileapp'>
                  Mobile App
						</Link>
					</li>
						<li className="breadcrumb-item">
							<Link >{DeviceId!==''?'Update Remote Mobile Device':'Add Remote Mobile Device'}</Link>
						</li>
					</ol>
				</nav>
        <section class="section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                        <div class="card-header">
                           <h4>{DeviceId!==''?'Update Remote Mobile Device':'Add Remote Mobile Device'}</h4>
                           <div class="card-header-action">  
                           {userInfo?.ROLE==='ROLE1661856938676'&&DeviceId!==''?<div  class="btn btn-info text-white btnedit mr-2" onClick={()=>(setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:DeviceId!==''&&locations?.state?.CATEGORY1_edit?.filter((items)=>(items===DeviceId)).length>0?<div  class="btn btn-info text-white btnedit mr-2" onClick={()=>(setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:''}
										{userInfo?.ROLE==='ROLE1661856938676'&&DeviceId!==''&&AccountInput?.STATUS===1?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2"  onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:DeviceId!==''&&AccountInput?.STATUS===1&&AccountInput?.STATUS===1&&locations?.state?.CATEGORY1_active.filter((item)=>(item===DeviceId)).length>0?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2"  onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:''}
										{userInfo?.ROLE==='ROLE1661856938676'&&DeviceId!==''&&AccountInput?.STATUS===0?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:DeviceId!==''&&AccountInput?.STATUS===0&&AccountInput?.STATUS===0&&locations?.state?.CATEGORY1_active.filter((item)=>(item===DeviceId)).length>0?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:''} 
                              <Link to={`/mobileapp`} class="btn btn-info text-white">Back</Link>
                           </div>
                        </div>
                        <div class="card-body">
                           <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" onSubmit={onSubmitAccountData}>
                              <div class="form-row">
                              <div class="form-group col-md-3">
                                    <label for="inputEmail4">User:</label>
                                    <select class="form-control form-select" name="accountname" id="accountname" value={AccountInput?.users} onChange={(e)=>(serAccountInput({...AccountInput,users:e.target.value}))} disabled={!disable}>
                                       <option value="">Select User</option>
                                                                         {UserData&&UserData.map((item)=>(<option value={item?.UNIQUE_ID}>{item?.FIRST_NAME+' '+item?.LAST_NAME}</option>))  }
                                                                           </select>
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                            <label for="inputPassword4">Modem Profile: <span class="text-danger">*</span></label>
                                            <select class="form-control form-select modemtypename anothermodemname" required="" name="modem_profile_id" id="modem_profile_id" onChange={(e)=>(serAccountInput({...AccountInput,modem_type:e.target.value}))} value={AccountInput.modem_type} disabled={!disable}>
                                                <option value="">Select Modem</option>
                                                {ModemData&&ModemData.map((item)=>(<option value={item?.MODEM_UNIQUE_ID}>{item?.MODEM_PROFILE_NAME}</option>))}       
                                                </select>
                                             <span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
														</span>
                                        </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">IMEI:<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" name="account_name" placeholder="Enter User Name" required="" onChange={(e)=>(serAccountInput({...AccountInput,imei:e.target.value}))} value={AccountInput?.imei} disabled={!disable}/>
                                 </div>
                              </div>
                             
                              <p style={{marginLeft:"25px"}} class="text-danger">Note : * Indicates Mandatory Fields</p>
                           <div class="form-group" style={{marginTop:"20px"}}>
                              <div class="text-left">
                                  {userInfo?.ROLE==='ROLE1661856938676'&&disable?<input type="submit" name="submit" class={`btn btn-danger ${flagBtn?'btn-progress disabled':''} ml-4 mr-3`} value={DeviceId===''?'Submit':'Update'} id="submitvn" />:DeviceId!==''&&disable?<input type="submit" name="submit" class={`btn btn-danger ${flagBtn?'btn-progress disabled':''} ml-4 mr-3`} value={DeviceId===''?'Submit':'Update'} id="submitvn" />:''}
                                    
                                    <Link class={`btn btn-light text-dark ${flagBtn?'disabled':''}`} to={`/mobileapp`}>Cancel</Link>  
                              </div>
                           </div>
                           </form></div>
                     </div>
                  </div>
               </div>
            </div>
      </section>
      </div>
    </>
  )
}
