import React, { useEffect, useState } from 'react'
import { BootstrapTooltip } from '../../../Commoncomponet/common'
import { Link, useHistory } from "react-router-dom";
import { getThirdPartyUnknownMeterData } from '../../../Commoncomponet/Utils';
import chroma from 'chroma-js';
import Pagination from '../../../Commoncomponet/Pagination';
import moment from 'moment';
import KnownMeterData from './KnownMeterData';
import { Modal } from 'antd';

export default function UnknownMeterData() {
	const [activeTab, setActiveTab] = useState("Unknown");
	const btnColor = ['#fc544b', '#3abaf4', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']

	const [Devicedata, setDeviceData] = useState([])
	const [PageSize, setPageSize] = useState(50)
	const [numberofPage, setnumberofPage] = useState(1)
	const [currentPage, setCurrentPage] = useState(1);
	const [isModalrow, setIsModalRow] = useState(false)
	const [rowData, setRowData] = useState('{}')
	const history = useHistory()

	const onGetRemoteMobileDevice = async () => {
		const firstPageIndex = (currentPage - 1) * PageSize;
		const data = await getThirdPartyUnknownMeterData({ start: firstPageIndex, end: PageSize }, { data_status: 0 })
		if (data.success == true) {
			setDeviceData(data.data.data)
			setnumberofPage(data.data.total)
		} else {
			console.log('Server Issue')
		}
	}

	useEffect(() => {
		onGetRemoteMobileDevice()
	}, [])

	const onInfoClick = (payload) => {
		setIsModalRow(true)
		setRowData(payload)
	}
	return (
		<>
			<Modal title="Payload" visible={isModalrow} footer="" onCancel={() => setIsModalRow(false)} width={1000}>
				<div className="tw-h-[800px] tw-overflow-scroll">
					<pre>{JSON.stringify(JSON.parse(rowData || '[]'), null, 2)}</pre>
				</div>
			</Modal>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-header ">
							<ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "15px", alignItems: "center" }}>
								<li>
									<button
										style={{
											backgroundColor: activeTab === "Unknown" ? chroma(btnColor[0]).alpha(0.4).css() : '',
											color: activeTab === "Unknown" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
											borderBottom: `1px solid ${btnColor[0]}`,
											boxShadow: activeTab === "Unknown" ? `0 2px 8px -3px ${btnColor[0]}` : '',
										}}
										className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "GSM" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
										data-toggle="tab"
										href="#home"
										role="tab"
										aria-controls="home"
										aria-selected="true"
										onClick={() => setActiveTab("Unknown")}
									>
										Unknown
									</button>
								</li>
								<li>
									<button
										style={{
											backgroundColor: activeTab === "Known" ? chroma(btnColor[1]).alpha(0.4).css() : '',
											color: activeTab === "Known" ? chroma(btnColor[1]).darken(1.5).css() : btnColor[1],
											borderBottom: `1px solid ${btnColor[1]}`,
											boxShadow: activeTab === "Known" ? `0 2px 8px -3px ${btnColor[1]}` : '',
										}}
										className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Known" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
										data-toggle="tab"
										href="#home"
										role="tab"
										aria-controls="home"
										aria-selected="true"
										onClick={() => setActiveTab("Known")}
									>
										Known
									</button>
								</li>
							</ul>

						</div>
						<div className="card-body">
							{activeTab == 'Unknown' && <div className="table-responsive">
								<div id="table-1_wrapper" className="dataTables_wrapper no-footer">
									<div className="dataTables_length" id="table_id_length">
										<label>
											Show{" "}
											<select name="table_id_length" aria-controls="table_id" className="">
												<option value={10}>10</option>
												<option value={25}>25</option>
												<option value={50}>50</option>
												<option value={100}>100</option>
											</select>{" "}
											entries
										</label>
									</div>
									<table id="table_id" className="tableCustom table table-striped">
										<thead>
											<tr>
												<th class="GSM_Link">Unique Id</th>
												<th>imei</th>
												<th class="" data-mui-internal-clone-element="true">
													topic
												</th>
												<th>emt_id_asn</th>
												<th>payload</th>
												<th>created_at</th>
												<th>requested_by</th>
											</tr>
										</thead>
										<tbody>
											{Devicedata.length > 0 && Devicedata?.map((item) => (
												<tr>
													<td><span>{item?.u_sr_no}</span></td>
													<td>{item?.imei}</td>
													<td>{item?.topic}</td>
													<td>{item?.emt_id_asn}</td>
													<td><button className='btn btn-info btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onInfoClick(item?.payload)}><i className="fa-solid fa-info"></i></button></td>
													<td>{moment.utc(item?.created_at).format('DD-MM-YYYY hh:mm:ss A')}</td>
													<td>{item?.requested_by}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
								<><div className='d-flex justify-content-between'>
									<div>Total Number Of Row {numberofPage}</div><Pagination
										className="pagination-bar"
										currentPage={currentPage}
										totalCount={numberofPage || 1}
										pageSize={PageSize}
										onPageChange={(page) => setCurrentPage(page)}
									/></div></>
							</div>}
							{activeTab == 'Known' && <KnownMeterData />}
						</div>
					</div>
				</div>
			</div></>
	)
}
