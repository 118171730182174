import React, { useEffect, useRef, useState } from 'react';
import { getGsmCustomDashboadList, getRolesById, InsertGsmCustomDashboard, UpdateGsmCustomDashboard } from '../../../Commoncomponet/Utils';
import { BootstrapTooltip } from '../../../Commoncomponet/common';
import { Modal } from 'antd';
import { toast } from 'react-toastify';
import chroma from 'chroma-js';
import GoogleLoader from '../../../Commoncomponet/Loaders/GoogleLoader';
import GSM_Dashboard from './GSM_Dashboard';
import { Card, CardHeader } from '../common/Card';
import { LuPlus } from "react-icons/lu";

export default function GSM_Custom_Dashboard(props) {

    const { Profile, setProfile, AllCategoryJson } = props;
    const [permision, setpermision] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fildDisable, setfildDisable] = useState(false);
    const [TabID, setTabID] = useState(null);
    const [DashTabs, setDashTabs] = useState([]);
    const [DashTabDetailById, setDashTabDetailById] = useState({});
    const [TabDetails, setTabDetails] = useState({ name: '', description: '' });
    const [dashboardId, setdashboardId] = useState('');
    const [loader, setLoader] = useState(false);
    const [AddLoader, setAddLoader] = useState(false);
    const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
    const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece'];

    // Handle roles on component mount
    useEffect(() => {
        onHandaleRoles();
    }, []);

    // Handle tab details on component mount
    useEffect(() => {
        setdashboardId(DashTabDetailById?.unique_id);
        setProfile({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "", ins_no: [] });
    }, [DashTabDetailById]);

    // Handle roles
    const onHandaleRoles = async () => {
        setLoader(true);
        const data = await getRolesById(userInfo?.ROLE);
        if (data.success === true) {
            const obj = data.data.data[0];
            setpermision({
                ...obj,
                ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0),
                ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5),
                ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2),
                ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3),
                ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1),
                ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4),
                ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS)
            });
            onLoadCosutomDashboardList(JSON.parse(obj.ROLES_OTHER_PERMISSIONS));
        } else {
            setLoader(false);
        }
    };

    // Load custom dashboard list
    const onLoadCosutomDashboardList = async (info) => {
        const data = await getGsmCustomDashboadList();
        if (data.success == true) {
            const dashtab = data?.data?.data?.filter((item) => (info?.CUSTOM_DASHBOARD?.dashboard_view?.includes(item?.unique_id) || userInfo?.ROLE == 'ROLE1661856938676'));
            setDashTabs(dashtab);
            setLoader(false);
        } else {
            console.log('something went wrong');
            setLoader(false);
        }
    };

    // Show modal
    const showModal = () => {
        setIsModalVisible(true);
    };

    // Handle cancel
    const handleCancel = () => {
        setfildDisable(true)
        setIsModalVisible(false);
        setTabID(null);
        setTabDetails({ name: '', description: '' })
    };

    // Handle OK
    const handleOk = async () => {
        if (TabDetails?.name === "") {
            toast.error("Dashboard name is required");
            return;
        }
        setAddLoader(true);
        try {
            let data;
            if (TabID === null) {
                // Add new dashboard
                data = await InsertGsmCustomDashboard({
                    ...TabDetails,
                    user_id: userInfo.UNIQUE_ID
                });
                if (data.success) {
                    toast.success("Dashboard added successfully");
                }
            } else {
                // Update existing dashboard
                data = await UpdateGsmCustomDashboard({
                    ...TabDetails,
                    user_id: userInfo.UNIQUE_ID,
                    tabId: TabID,
                });
                if (data.success) {
                    toast.success("Dashboard updated successfully");
                }
            }

            if (data.success) {
                setIsModalVisible(false);
                setTabID(null);
                setTabDetails({ name: '', description: '' });
                onLoadCosutomDashboardList(permision?.ROLES_OTHER_PERMISSIONS);
            } else {
                toast.error(data.message || "Operation failed");
            }
        } catch (error) {
            console.error("Error in handleOk:", error);
            toast.error("An error occurred. Please try again.");
        } finally {
            setAddLoader(false);
        }
    };

    // Edit tab
    const onEditTab = async (id) => {
        setTabID(id);
        const tabDetails = DashTabs.filter((item) => (item?.unique_id == id))[0];
        setTabDetails({ name: tabDetails?.name, description: tabDetails?.description });
        setIsModalVisible(true);
        setfildDisable(false)
    };

    const handleTabDataChange = (info) => {
        setDashTabDetailById(info)
        let TabData = [...DashTabs]
        const CurrentTabIndex = DashTabs.findIndex((data) => data?.unique_id === dashboardId)
        TabData[CurrentTabIndex] = info;
        setDashTabs(TabData)
    }

    
  const [showPadding, setShowPadding] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const checkWidth = () => {
      if (containerRef.current) {
        setShowPadding(containerRef.current.offsetWidth > containerRef.current.offsetWidth * 0.85);
      }
    };

    checkWidth();
    window.addEventListener('resize', checkWidth);
    return () => window.removeEventListener('resize', checkWidth);
  }, []);


  const hasPermission = (role) => userInfo?.ROLE === role;
    return (
        <>
            {/* Modal */}
            <Modal
                title={
                    <div className='tw-flex tw-items-center tw-justify-between tw-mr-5 ' >
                        {!TabID ? "Add New Dashboard" : 'Update New Dashboard'}
                        {TabID ? (
                            <div
                                className="btn btn-info text-white btnedit ml-2"
                                onClick={() => setfildDisable(true)}
                                style={{ padding: '1px 8px 2px 8px' }}
                            >
                                <BootstrapTooltip title='edit'>
                                    <span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit">
                                        <i className="fas fa-edit text-white"></i>
                                    </span>
                                </BootstrapTooltip>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                }
                visible={isModalVisible}
                footer=''
                onCancel={handleCancel}
            >
                <form className="">
                    <div className="form-group">
                        <label>Dashboard Name</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <i className="fas fa-table"></i>
                                </div>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Dashboard Name"
                                name="tabName"
                                onChange={(e) => setTabDetails({ ...TabDetails, name: e.target.value })}
                                value={TabDetails?.name}
                                disabled={TabID ? (!fildDisable) : false}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Dashboard Description</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <i className="fas fa-table"></i>
                                </div>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Dashboard Description"
                                name="tabName"
                                onChange={(e) => setTabDetails({ ...TabDetails, description: e.target.value })}
                                value={TabDetails?.description}
                                disabled={TabID ? (!fildDisable) : false}
                            />
                        </div>
                    </div>
                </form>
                <div className="mt-3">
                    {fildDisable || !TabID ? (
                        <a
                            className={`btn btn-danger ${fildDisable === false && TabID ? 'disabled' : ''} text-white ${AddLoader == true ? 'btn-progress disabled' : ''
                                }`}
                            onClick={handleOk}
                        >
                            {!TabID ? 'Submit' : 'Update'}
                        </a>
                    ) : (
                        ''
                    )}
                    {fildDisable || !TabID ? (
                        <a className="btn btn-light text-dark ml-2" onClick={handleCancel}>
                            cancel
                        </a>
                    ) : (
                        ''
                    )}

                </div>
            </Modal>

            {/* Dashboard content */}
            {DashTabs?.length == 0 && loader == true ? (
                <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                    <GoogleLoader bodyClassName={'tw-h-[70%]'} />
                </div>
            ) : (
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-lg-12">
                                <Card className=' !tw-border-t   !tw-border-t-slate-100' >
                                    <CardHeader className="d-flex justify-content-between">
                                        <div ref={containerRef} className="d-flex tw-w-[85%] justify-content-between tw-items-center  tw-overflow-x-auto" style={{ width: "100%" }}>
                                            <ul
                                                className={`nav nav-pills !pb-0 tw-items-center tw-flex-nowrap !tw-mb-0 ${showPadding ? 'tw-pb-0.5' : ''}`}
                                                id="myTab3"
                                                role="tablist"
                                                style={{
                                                    width: "1500px",
                                                    overflowX: "scroll",
                                                    flexWrap: "nowrap",
                                                }}
                                            >
                                                {DashTabs?.length > 0 ? (
                                                    DashTabs.map((item, index) => (
                                                        <TabButton
                                                            key={index}
                                                            item={item}
                                                            index={index}
                                                            dashboardId={dashboardId}
                                                            btnColor={btnColor}
                                                            setdashboardId={setdashboardId}
                                                            setDashTabDetailById={setDashTabDetailById}
                                                            DashTabs={DashTabs}
                                                        />
                                                    ))
                                                ) : (
                                                    <div className='!tw-font-semibold pb-0'>No Data Found</div>
                                                )}
                                            </ul>
                                            <div className="card-header-action d-flex">
                                                {/* // refetch btn */}
                                                <TooltipButton
                                                    className="btn btn-outline-danger mr-2 mt-0 !tw-py-0.5 !tw-px-3  !tw-rounded-[30px]"
                                                    icon={<i className="fas fa-redo-alt" />}
                                                    tooltip="Refresh"
                                                    style={{ marginTop: '6px' }}
                                                />
                                                {/* // setting btn */}
                                                <TooltipButton
                                                    className="btn btn-outline-success mr-2 mt-0 !tw-py-0.5 !tw-px-3  !tw-rounded-[30px]"
                                                    icon={<i className="fas fa-cog" />}
                                                    tooltip="Edit Dashboard"
                                                    style={{ marginTop: '6px' }}
                                                    onClick={() =>
                                                        hasPermission('ROLE1661856938676') ? onEditTab(dashboardId) : toast.error('You are not authorized to Edit Dashboard Details.')
                                                    }
                                                />
                                                {/* // add btn */}
                                                <TooltipButton
                                                    className="btn btn-primary mt-0 !tw-py-0.5 !tw-px-3  !tw-rounded-[30px]"
                                                    icon={<LuPlus className='tw-text-[18px]' />}
                                                    tooltip="Add Dashboard"
                                                    style={{
                                                        marginTop: '6px',
                                                    }}
                                                    onClick={() =>
                                                        hasPermission('ROLE1661856938676') ? showModal() : toast.error('You are not authorized to Add Dashboard.')
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </CardHeader>
                                </Card>
                            </div>
                        </div>
                        {dashboardId && <GSM_Dashboard handleTabDataChange={handleTabDataChange} Profile={Profile} setProfile={setProfile} activeTab={'Custom Dashboard'} DashTabDetailById={DashTabDetailById} dashboardId={dashboardId} onLoadCosutomDashboardList={(info) => onLoadCosutomDashboardList(info)} AllCategoryJson={AllCategoryJson} {...props} />}
                    </div>
                </section>
            )}
        </>
    );
}


const TooltipButton = ({ className, icon, tooltip, style, onClick }) => (
    <BootstrapTooltip title={tooltip} placement="top">
        <span className={className} style={style} onClick={onClick}>
            {icon}
        </span>
    </BootstrapTooltip>
);

const TabButton = ({
    item,
    index,
    dashboardId,
    btnColor,
    setdashboardId,
    setDashTabDetailById,
    DashTabs,
}) => {
    const isActive = DashTabs.findIndex((data) => data?.unique_id === dashboardId) === index;
    const DashTabsLength = DashTabs?.length;

    // Use modulo operator to repeat colors
    const colorIndex = index % btnColor.length;
    const currentColor = btnColor[colorIndex];

    return (
        <li className="nav-item mr-2">
            <button
                onClick={() => {
                    setdashboardId(item?.unique_id);
                    setDashTabDetailById(item);
                }}
                style={{
                    backgroundColor: isActive ? chroma(currentColor).alpha(0.4).css() : '',
                    color: isActive ? chroma(currentColor).darken(1.5).css() : currentColor,
                    borderBottom: `1px solid ${currentColor}`,
                    boxShadow: isActive ? `0 2px 8px -3px ${currentColor}` : '',
                }}
                className={`!tw-py-[2px] tw-font-semibold tw-whitespace-nowrap tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${isActive ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'
                    }`}
            >
                {item.name}
            </button>
        </li>
    );
};