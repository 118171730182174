import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Lock } from 'react-feather'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { activeandinactive, geModemIdWiseColumnName, getModemFirmware, getModemHardwareVersion, getModemMake, getModemModelNumber, getModemProductCode, getModemType, Insertmodem_index, SyncScriptIdWiseTableColumn, Updatemodem_index } from '../../../Commoncomponet/Utils'
import { toast } from 'react-toastify'
import { BootstrapTooltip } from '../../../Commoncomponet/common'
import swal from "sweetalert2";
import DraggabalTable from '../../../Commoncomponet/DraggabalTable'
import { Modal } from 'antd'
import clockPending from '../../../assets/img/icons8-clock.gif'
import {socket} from '../../../Commoncomponet/common'
import Select from 'react-select'
window.Swal = swal;
export default function AddAll() {

    const [type,setType]=useState([])
    const [make,setMake]=useState([])
    const [ModelNumber,setModelNumber]=useState([])
    const [Firmware,setFirmware]=useState([])
    const [sku,setsku]=useState([])
    const [Hardware,setHardware]=useState([])
const [loginErrors, setValidation] = useState({});
const [flagBtn, setflegBtn] = useState(false);
const [ModemId,setModemId]=useState('')
const [disable,setDisable]=useState(true)
const [status,setStatus]=useState(null)

const [inputs,setInputs]=useState({Name:'',Type:'',Make:'',ModelNumber:'',Firmware:[],sku:'',Hardware:'',wifi:'',bluetooth:'',note:''})
const history = useHistory();
const userInfo=JSON.parse(window.localStorage.getItem("user_info")||{})
const [ChangesJson,setChangesJson]=useState({})
const [FixJson,setFixJson]=useState({})
const [TableKey,setTableKey]=useState('HEARTBEAT')

const locations=useLocation()
      useEffect(() => {
          if (locations?.state) {
             setInputs({Name:locations?.state?.MODEM_PROFILE_NAME,Type:locations?.state?.MODEM_TYPE,Make:locations?.state?.MODEM_MAKE,ModelNumber:locations?.state?.MODEM_MODEL_VERSION,Firmware:JSON.parse(locations?.state?.MODEM_FIRMWARE),sku:locations?.state?.MODEM_PRODUCT_CODE,Hardware:locations?.state?.MODEM_HARWARE_VERSION,wifi:locations?.state?.MODEM_WIFIVERIANT,bluetooth:locations?.state?.MODEM_BLUETOOTHVERIANT,note:locations?.state?.MODEM_NOTES})
             setModemId(locations?.state?.MODEM_UNIQUE_ID)
             setDisable(false)
             setStatus(locations?.state?.MODEM_STATUS)
             setFixJson(JSON.parse(locations?.state?.FIX_COLUMN_NAME||'{}'))
             setChangesJson(JSON.parse(locations?.state?.COLUMN_NAME||'{}'))
          }
       }, [locations]);
    const onHandaleSelection=async()=>{
        const data=await getModemType()
        if(data.success===true){
            setType(data.data.data)
            const data1=await getModemMake()
        if(data1.success===true){
            setMake(data1.data.data)
            const data2=await getModemFirmware()
        if(data2.success===true){
         setFirmware(data2.data.data.map((item)=>({value:item?.MODEM_FIRMWARE_UNIQUE_ID,label:item?.MODEM_FIRMWARE_NAME})))

            const data3=await getModemProductCode()
        if(data3.success===true){
            setsku(data3.data.data)
            const data4=await getModemHardwareVersion()
        if(data4.success===true){
            setHardware(data4.data.data)
        }
        const data5=await getModemModelNumber()
        if(data5.success===true){
            setModelNumber(data5.data.data)
        }
        }
        
        }
        }
        }else{
          console.log('somthing Went wrong')
        }
      }

      

      useEffect(() => {
        onHandaleSelection()
      }, []);

      const validation = (name, value) => {
         switch (name) {
             case 'Name':
                 if (!value) {
                     return 'Please input Modem Name!'
                 } else {
                     return '';
                   }
                   case 'Type':
               //   if (!value) {
               //       return 'Please input Modem Type!'
               //   } else {
               //       return '';
               //     }
               //     case 'Make':
               //   if (!value) {
               //       return 'Please input Modem Make!'
               //   } else {
               //       return '';
               //     }
               //     case 'ModelNumber':
               //   if (!value) {
               //       return 'Please input Modem Model Number!'
               //   } else {
               //       return '';
               //     }
               //     case 'Firmware':
               //   if (!value) {
               //       return 'Please input Modem Firmware!'
               //   } else {
               //       return '';
               //     }
               //     case 'sku':
               //   if (!value) {
               //       return 'Please input Modem sku!'
               //   } else {
               //       return '';
               //     }
               //     case 'Hardware':
               //   if (!value) {
               //       return 'Please input Modem Hardware!'
               //   } else {
               //       return '';
               //     }
                   default: {
                     return null;
                 }
             }
             }

      const onHandaleSubmitData=async(e)=>{
         e.preventDefault()
         setflegBtn(true)
         let allErrors = {};
      Object.keys(inputs).forEach(key => {
        const error = validation(key, inputs[key]);
        if (error && error.length) {
            allErrors[key] = error
        }
    });
    console.log(allErrors);
    if (Object.keys(allErrors).length) {
      setflegBtn(false)
     return setValidation(allErrors)
       }else{
         if(ModemId===''){
         const data=await Insertmodem_index({...inputs,add_by:userInfo?.UNIQUE_ID})
         if(data.success===true){
             toast.success('Add Modem Success')
         setflegBtn(false)
         history.push('/Admin/CommonTables')
         }else{
             toast.error('Something Went Wrong')
         setflegBtn(false)
         }
     }else{
     const data2=await Updatemodem_index({...inputs},ModemId)
     if(data2.success===true){
         toast.success('Update Modem Success')
     setflegBtn(false)
     history.push('/Admin/CommonTables')
     }else{
         toast.error('Something Went Wrong')
     setflegBtn(false)
 
     }
     }
       }
     }

     const oninactive=async()=>{
      swal.fire({
			title: "Are You Sure InActive Modem?",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'modem_index',colName:'MODEM_STATUS',value:'0',matchId:'MODEM_UNIQUE_ID'},ModemId)
      if(data.success===true){
         toast.success('Success Inactive Modem')
         history.push('/Admin/CommonTables')
      }else{
         toast.error('Something Went Wrong')
      }
   }
})
     }
     const onactive=async()=>{
      swal.fire({
			title: "Are You Sure Modem?",
			// text: "Once deleted, you will not be able to recover this User Details!",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'modem_index',colName:'MODEM_STATUS',value:'1',matchId:'MODEM_UNIQUE_ID'},ModemId)
      if(data.success===true){
         toast.success('Success Active Modem')
         history.push('/Admin/CommonTables')
      }else{
         toast.error('Something Went Wrong')
      }
   }
})
     }

     const [MassageSync,setMassageSync]=useState({})
     const [RespondMsg,setRespondMsg]=useState({})
     const [ModelInfo,setModelInfo]=useState(false)

     useEffect(() => {
        socket.on('mqtttableinfo',(massges)=>{
            if(MassageSync?.MSGID==massges?.MSGID&&MassageSync?.MODEM_TYPE==massges?.MODEM_TYPE){
                setRespondMsg(massges)
                onRedirectPage(massges)
            }
        })
    }, [MassageSync]);

    const onRedirectPage=async(massges)=>{
            if(massges?.RESULT=='SUCCESS'){
               const data=await geModemIdWiseColumnName(massges?.MODEM_TYPE)
               if(data.success===true){
     setChangesJson(JSON.parse(data?.data?.data[0]?.COLUMN_NAME||'{}'))
               }else{
                console.log('Something Went Wrong')
               }

            }
    }

     const onSyncScriptIdWiseTableColumn=async()=>{
      setRespondMsg({})
      setMassageSync({})
        const obj={
            MSGID: new Date().valueOf(),
            MODEM_TYPE:ModemId
         }
        const data=await SyncScriptIdWiseTableColumn(obj)
        if(data.success==true){
            setMassageSync(obj)
            setModelInfo(true)
            setTimeout(() => {
                if(Object.keys(RespondMsg).length===0)
                {
                    setRespondMsg({RESULT:'Time Out'})
                    setMassageSync({})
                }
                },5000)
        }else{
            console.log('Something Went Wrong')
        }
     }
     const columns=[{
      Header: "Id",
      accessor: "Order"
   },{
      Header: "Database Column",
      accessor: "Column"
   },{
      Header: "Header Column",
      accessor: "Header"
   }]   
  return (
    <>

<Modal  visible={ModelInfo} footer='' maskClosable={false} onCancel={() => (setModelInfo(false))} width={500}>
    <h5 class="modal-title text-center" id="meter_data_mqtt_info_txt">Getting Response For Sync Table Column</h5>
   <h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{!RespondMsg?.RESULT&&RespondMsg?.RESULT!=='SUCCESS'?<img className='mr-2' src={clockPending} height='20px' width={'20px'}></img>:RespondMsg?.RESULT==='SUCCESS'?<sapn><i className='fas fa-check mr-2' style={{color:'green'}}></i></sapn>:<sapn><i className='fas fa-times mr-2' style={{color:'red'}}></i></sapn>} Sync Table Column {RespondMsg?.RESULT&&RespondMsg?.RESULT!=='SUCCESS'?`(${RespondMsg?.RESULT})`:''}</h5>
    <div class=""> 
               </div>
               <div class="text-center" style={{marginTop:"20px"}}>
                  <input type="button" class="btn btn-danger " value="Close" id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={()=>{setModelInfo(false)
                 }}/>
               </div>
            </Modal>
    <div className="main-content">
    <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/all-deshboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
                            <Lock className='header-logo' style={{ height: "16px" }} /> Admin
							</Link>
						</li>
						<li className="breadcrumb-item">
						<Link >
							 Modem Driver
						</Link>
					</li>
						<li className="breadcrumb-item">
							<Link >{ModemId===''?'Add Modem':'Update Modem'}</Link>
						</li>
					</ol>
				</nav>
                <section class="section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                        <div class="card-header">
                           <h4>{ModemId===''?'Add Modem':'Update Modem'}</h4>
                           <div class="card-header-action">  
                           {/* {ModemId!==''?<div  class="btn btn-info text-white btnedit mr-2" onClick={()=>(setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:''}
										{ModemId!==''&&status===1?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2" onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:''}
										{ModemId!==''&&status===0?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:''} */}
                              <Link to="/Admin/CommonTables" class="btn btn-info text-white align-left">
                              Back
                              </Link>                        
                           </div>
                        </div>
                        <div class="card-body">
                           <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" onSubmit={onHandaleSubmitData}>
                              <div class="form-row" style={{marginLeft:"10px"}}>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Modem Profile Name: <span class="text-danger">*</span></label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Modem Profile Name" required="" onChange={(e)=>(setInputs({...inputs,Name:e.target.value}))} value={inputs.Name} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.Name}</span>    
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Modem Type</label>
                                    <select class="form-control form-select" name="modemtype" id="modemtype" onChange={(e)=>(setInputs({...inputs,Type:e.target.value}))} value={inputs.Type} disabled={!disable}>
                                       <option value="">Select Modem Type</option>
                                                           {type&&type.map((item)=>(<option value={item.UNIQUE_ID}>{item?.NAME}</option>))}                      
                                                                                 
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Name}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Modem Make</label>
                                    <select class="form-control form-select" name="modemmake" id="modemmake" onChange={(e)=>(setInputs({...inputs,Make:e.target.value}))} value={inputs.Make} disabled={!disable}>
                                       <option value="">Select Modem Make</option>
                                       {make&&make.map((item)=>(<option value={item.MODEM_MAKE_UNIQUE_ID}>{item?.MODEM_MAKE_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Make}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Modem Model Number</label>
                                    <select class="form-control form-select " name="modemmodalversion" id="modemmodalversion" onChange={(e)=>(setInputs({...inputs,ModelNumber:e.target.value}))} value={inputs.ModelNumber} disabled={!disable}>
                                       <option value="">Select Modem Model Number</option>
                                       {ModelNumber&&ModelNumber.map((item)=>(<option value={item.MODEM_MODEL_UNIQUE_ID}>{item?.MODEM_MODEL_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.ModelNumber}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Modem Firmware</label>
                                    <Select name='account' isMulti options={Firmware} onChange={(e)=>(setInputs({...inputs,Firmware: e.map((item) => (item.value))}))} value={Firmware.filter((item)=>inputs?.Firmware?.includes(item?.value))} isDisabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.Firmware}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Modem SKU</label>
                                    <select class="form-control form-select" name="modemproductcode" id="modemproductcode" onChange={(e)=>(setInputs({...inputs,sku:e.target.value}))} value={inputs.sku} disabled={!disable}>
                                       <option value="">Select Modem SKU</option>
                                       {sku&&sku.map((item)=>(<option value={item.UNIQUE_ID}>{item?.NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.sku}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Modem Hardware Version</label>
                                    <select class="form-control form-select select2-hidden-accessible" name="modemhardware[]" id="modemhardware" multiple="" tabindex="-1" aria-hidden="true" onChange={(e)=>(setInputs({...inputs,Hardware:e.target.value}))} value={inputs.Hardware} disabled={!disable}>
                                       <option value="">Select Modem Hardware Version</option>
                                       {Hardware&&Hardware.map((item)=>(<option value={item.UNIQUE_ID}>{item?.NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Hardware}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Is Modem WIFI Variant?</label>
                                    <select class="form-control form-select" name="wifivariant" id="wifivariant" onChange={(e)=>(setInputs({...inputs,wifi:e.target.value}))} value={inputs.wifi} disabled={!disable}>
                                       <option value="">Select Option</option>
                                       <option value="yes">Yes</option>
                                       <option value="no">No</option>
                                    </select>  

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Is Modem Bluetooth Variant?</label>
                                    <select class="form-control form-select" name="bluetoothvariant" id="bluetoothvariant" onChange={(e)=>(setInputs({...inputs,bluetooth:e.target.value}))} value={inputs.bluetooth} disabled={!disable}>
                                       <option value="">Select Option</option>
                                       <option value="yes">Yes</option>
                                       <option value="no">No</option>
                                    </select>

                                 </div>       
                              </div>
                              <div class="form-row" style={{marginLeft:"10px"}}>
                                 <div div="" class="form-group col-md-3">
                                    <label for="inputPassword4">Modem Notes: </label>
                                    <textarea class="form-control" name="modemnotes" id="modemnotes" type="text" placeholder="Enter Modem Notes" onChange={(e)=>(setInputs({...inputs,note:e.target.value}))} value={inputs.note} disabled={!disable}></textarea>
                                 </div>
                              </div>

                              {ModemId===''?'': ChangesJson?<><div class="form-group col-md-3 d-flex" id="grpeui" style={{paddingLeft:'0px'}}>
                                            <select class="form-control form-select second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onChange={(e)=>(setTableKey(e.target.value))} value={TableKey}>
                                                {Object.keys(ChangesJson).length>0&&Object.keys(ChangesJson).map((item)=>(<option value={item} >{item}</option>))}                  
                                                </select>
                                            <div id="meteid"></div>
                                            <div  name="submit" value='Sync' className='btn btn-primary ml-2 col-md-3' onClick={onSyncScriptIdWiseTableColumn}>Sync</div>
                                        </div></>:''}
                                    <div className='table-responsive'>
                                   {ModemId===''?'':ChangesJson[TableKey]?.length>0? <DraggabalTable columns={columns} data={ChangesJson[TableKey]} setitem={(e) => setChangesJson({...ChangesJson,[TableKey]:e})}/>:''}
                                   </div>
                              {/* <p style={{marginLeft:"25px"}} class="text-danger">Note : * Indicates Mandatory Fields</p>
                              <div class="text-left">
                                 <input type="submit" name="submit" class={`btn btn-danger ${flagBtn?'btn-progress disabled':''} ml-4 mr-3`} value={ModemId===''?'Submit':'Update'} id="submitvn"/>
                                 <Link class="btn btn-light text-dark" to="/Admin/CommonTables">Cancel</Link>  
                              </div> */}
                           </form>
                        </div>
                     </div>
                  </div>
                </div>
            </div>
         </section>
      </div>
    </>
  )
}
