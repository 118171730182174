import { Spin } from 'antd';
import React from 'react'
import { cn } from '../lib/utils';
import moment from 'moment';

const DynamicTable = ({
  data,
  isLoading = false,
  removeKeys = [], // Custom keys to remove
  maxDepth = 0, // New prop to limit the recursion depth
  currentDepth = 0,    // Internal prop to track the current depth
  parentDivClassName,
  loaderClassName,
  tableClassName,
  tableHeaderClassName,
  tableHeaderRowClassName,
  tableHeaderElementClassName,
  tableBodyClassName,
  tableBodyRowClassName,
  tableBodyElementClassName,
  onRowClick, // Add onClick prop for rows
}) => {
  if (!data) return <p>No data available</p>;

  const mandatoryRemoveKeys = [
    'MMD_ID_CMD', 'MMD_ID_IMEI', 'MMD_ID_VD', 'MMD_ID_MSG',
    'SCR_ID_CMD_TYPE', 'MMD_TS', 'MMD_ID_POTP', 'MMD_ID_COTP',
    'MMD_ID_VERSTR', 'MMD_ID_DRXTYPE'
  ];

  const finalRemoveKeys = [...new Set([...mandatoryRemoveKeys, ...removeKeys])];

  const cleanData = Array.isArray(data)
    ? data
    : [Object.keys(data).reduce((acc, key) => {
      if (!finalRemoveKeys.includes(key)) {
        acc[key] = data[key];
      }
      return acc;
    }, {})];
  const headers = Object.keys(cleanData[0]);

  const formatValue = (value) => {
    const momentDate = moment(value, moment.ISO_8601, true);
    if (value && typeof value!=='number'&&(value instanceof Date||momentDate.isValid())) {
      // If the value is a valid date, format it accordingly
      if (moment(value).isSame(moment(value).startOf('day'))) {
        // Only date
        return moment(value).format('DD-MM-YYYY');
      }
      // Date and time
      return moment(value).format('DD-MM-YYYY hh:mm:ss A');
    }
    return value;
  };

  return (


    <div className={cn(`tw-max-h-[75dvh] tw-overflow-auto ${parentDivClassName ? parentDivClassName : ''}`)}>
      {isLoading ?
        <div className={cn(`${loaderClassName ? loaderClassName : ''} tw-h-[75dvh] tw-flex tw-items-center tw-justify-center`)}>
          <Spin />
        </div> :
        <table className={cn(`${tableClassName ? tableClassName : ''} tw-w-full tw-border-collapse tw-border tw-border-gray-300 `)}>
          <thead className={cn(`${tableHeaderClassName ? tableHeaderClassName : ''} tw-sticky tw-top-0`)}>
            <tr className={cn(`${tableHeaderRowClassName ? tableHeaderRowClassName : ''} tw-bg-gray-100`)}>
              {headers.map((header) => (
                <th key={header} className={cn(`${tableHeaderElementClassName ? tableHeaderElementClassName : ''} tw-border tw-capitalize tw-border-gray-300 tw-p-2`)}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={cn(`${tableBodyClassName ? tableBodyClassName : ''}`)}>
            {cleanData.map((item, index) => (
              <tr key={index} className={cn(`${tableBodyRowClassName ? tableBodyRowClassName : ''}`)} onClick={() => onRowClick && onRowClick(item)} >
                {headers.map((header) => (
                  <td key={header} style={{minWidth:'100px'}} className={cn(`${tableBodyElementClassName ? tableBodyElementClassName : ''} tw-border tw-border-gray-300 tw-p-2 text-center `)}>
                    {/* {typeof item[header] === 'object'
                      ? JSON.stringify(item[header], null, 2)
                      // ? <DynamicTable data={item[header]} />
                      : formatValue(item[header])
                    } */}
                    {typeof item[header] === 'object'
                      ? currentDepth < maxDepth
                        ? <DynamicTable
                          data={item[header]}
                          maxDepth={maxDepth}
                          currentDepth={currentDepth + 1}
                          removeKeys={removeKeys}
                          tableClassName={tableClassName}
                          tableHeaderClassName={tableHeaderClassName}
                          tableHeaderRowClassName={tableHeaderRowClassName}
                          tableHeaderElementClassName={tableHeaderElementClassName}
                          tableBodyClassName={tableBodyClassName}
                          tableBodyRowClassName={tableBodyRowClassName}
                          tableBodyElementClassName={tableBodyElementClassName}
                        />
                        : JSON.stringify(item[header], null, 2)
                      : formatValue(item[header])}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      }
    </div>
  )
}

export default DynamicTable