
import React, { useEffect, useState } from 'react'
import { Link, useHistory,useLocation } from 'react-router-dom'
import Pagination from '../../Commoncomponet/Pagination'
import * as $ from "jquery";
import dt from "datatables.net-dt";
import { BootstrapTooltip } from '../../Commoncomponet/common';
import axios from 'axios';
import { getAccount, getAllMeter, getAllModem, getArea, getAreaById, getCircle, getCircleById, getDivision, getDivisionById, getGa, getGaById, getGenretedAllGSMReport, GetGetwayData, getLorawanData, getRolesById, getSubdevision, getSubdevisionById, getTags, LoraModemColumanWiseSerchData, LoraModemFilterData, LoraModemSerchData,getSecondaryConsumerById,GetReportType,getGenreteGSMMRIReport } from '../../Commoncomponet/Utils'
import moment from 'moment';
import { DatePicker, Modal, Space } from 'antd';
import { Users } from 'react-feather';
import { toast } from 'react-toastify';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import swal from 'sweetalert2';
import { array } from 'prop-types';
import './Gsm.css'
import signal2 from '../../assets/img/secondsignal.png'
import signal3 from '../../assets/img/signal3.png'
import signal4 from '../../assets/img/signal4.png'
import signal5 from '../../assets/img/signal5.png'
import exports from '../../assets/img/export.png'
import ModemDetails from './ModemDetails'
import classNames from 'classnames'
import MultiInput from '../../Commoncomponet/MultiInput/MultiInput';
import chroma from 'chroma-js';
import GoogleLoader from '../../Commoncomponet/Loaders/GoogleLoader';

// import $ from 'jquery'
window.Swal = swal;

$.fn.DataTable = dt;

const FilterDisplay = ({ filter, onClearFilter }) => {
	console.log('filter', filter)
	const [showAll, setShowAll] = useState(false);
	const [popoverVisible, setPopoverVisible] = useState(false);
	const maxValues = 10;

	if (Object.entries(filter).length === 0) { return null }
	const filterEntries = Object.entries(filter);

	const totalValues = filterEntries.reduce((count, [, value]) => {
		if (Array.isArray(value)) {
			return count + value.length;
		} else {
			return count + 1;
		}
	}, 0);

	// const viewMoreButton = totalValues > maxValues ? (
	// 	<button
	// 		onClick={() => setShowAll(!showAll)}
	// 		className='tw-bg-blue-100 tw-whitespace-nowrap tw-rounded-md tw-text-xs tw-px-2 tw-py-1 border !tw-border-blue-500 hover:tw-bg-blue-200 tw-transition-all tw-mr-2'
	// 	>
	// 		{showAll ? 'View Less' : 'View More'}
	// 	</button>
	// ) : null;

	let valuesDisplayed = 0;
	let keysDisplayed = 0;

	const remainingValues = totalValues - maxValues;
	const viewMoreButton = totalValues > maxValues && !showAll ? (
		<button
			onClick={() => setShowAll(true)}
			className='tw-bg-orange-100 tw-whitespace-nowrap tw-rounded-md tw-text-xs tw-px-2 tw-py-1 border !tw-border-orange-500 hover:tw-bg-orange-200 tw-transition-all tw-mr-2'>
			+{remainingValues}
		</button>
	) : null;


	const filterValues = filterEntries.map(([key, value]) => {
		if (!showAll && valuesDisplayed >= maxValues) {
			console.log('returning null')
			return null;
		}

		let valueOutput;
		if (key === 'RSSI' || key === 'BAT_PER') {
			if (value?.LessEqual === 'True') {
				valueOutput = (
					<> LessAndEqualTo{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1">{value?.Value}</span> </>
				);
			} else if (value?.GreaterEqual === 'True') {
				valueOutput = (
					<> GreaterAndEqualTo{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1">{value?.Value}</span> </>
				);
			} else {
				valueOutput = (
					<> between{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border tw-whitespace-nowrap !tw-border-indigo-500">{value?.From}</span>{' '} and{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap">{value?.To}</span> </>
				);
			}
			valuesDisplayed++;
		} else if (key === 'Last_Seen') {
			valueOutput = (
				<> {value?.LessEqual === 'True' ? 'less than' : 'more than'}{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap">{value?.Value} {value?.Last_Seen}</span>{' '} </>
			);
			valuesDisplayed++;
		} else if (key === 'Installation_Date' || key === 'Dataretrival_Date') {
			if (value?.LessEqual === 'True') {
				valueOutput = (
					<> LessAndEqualTo{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap">{value?.Start_Date}</span> </>
				);
			} else if (value?.GreaterEqual === 'True') {
				valueOutput = (
					<> GreaterAndEqualTo{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap">{value?.Start_Date}</span> </>
				);
			} else {
				valueOutput = (
					<> between{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap">{value?.Start_Date}</span>{' '} and{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap">{value?.End_Date}</span> </>
				);
			}
			valuesDisplayed++;
		} else if (Array.isArray(value)) {
			const displayedValues = showAll ? value : value.slice(0, maxValues - valuesDisplayed);
			valueOutput = displayedValues.map((v, i) => (
				<span key={i} className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap"> {v} </span>
			));
			valuesDisplayed += displayedValues.length;
		} else if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
			valueOutput = (
				<span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap"> {JSON.stringify(value)} </span>
			);
			valuesDisplayed++;
		} else {
			valueOutput = (
				<span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap"> {value} </span>
			);
			valuesDisplayed++;
		}

		return (
			<>
				<strong className="mr-1">{key}:</strong>
				{valueOutput}
			</>
		);
	});
	return (
		<div className={classNames("tw-mb-4 tw-px-1 tw-flex tw-items-center tw-gap-2")}>
			<h3 className="tw-my-0 tw-text-sm tw-font-bold tw-bg-[#f1f1f1] tw-py-1 tw-px-2 tw-rounded-lg">Filters:</h3>

			{showAll && (
				<div className="tw-flex tw-flex-wrap tw-gap-1 tw-text-xs tw-items-center">
					<div className="tw-absolute tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-lg tw-border tw-border-gray-300 tw-z-50 tw-max-w-[90%]">
						<div className="tw-flex tw-flex-wrap tw-gap-1 tw-items-center">

							{filterValues}
							<button onClick={() => setShowAll(false)} className='tw-bg-orange-100 tw-whitespace-nowrap tw-rounded-md tw-px-2 tw-py-1 border !tw-border-orange-500 hover:tw-bg-orange-200 tw-transition-all'> View Less </button>
						</div>
					</div>
				</div>
			)
			}
			<div className="tw-flex tw-flex-wrap tw-gap-1 tw-text-xs tw-items-center tw-max-w-[90%]">
				<>
					{filterValues.slice(0, maxValues)}
					{viewMoreButton}
				</>

				<div className=''>
					<button onClick={onClearFilter} className='tw-bg-red-100 tw-whitespace-nowrap tw-rounded-md tw-text-xs tw-px-2 tw-py-1 border !tw-border-red-500 hover:tw-bg-red-200 tw-transition-all'> Clear Filter </button>
				</div>
			</div>
		</div>
	);
}
export default function Gsm() {
	const [PageSize, setPageSize] = useState(50)
	const [numberofPage, setnumberofPage] = useState(1)
	const [currentPage, setCurrentPage] = useState(1);
	const [getwayList, setGetwayList] = useState([])
	const [GetwayListSerch, setGetwayListSerch] = useState([])
	const [getwayDiscription, setgetwayDiscription] = useState('')
	const [order, setOrder] = useState('asc')
	const [ismodel, setModel] = useState(false)
	const [loader, setLoader] = useState(true)
	const [ismodelFilter, setismodelFilter] = useState(false)
	const [permision, setpermision] = useState({})
	const [report, setReport] = useState({ value: '', name: '',start_date:'',end_date:'' })
	const userInfo = JSON.parse(window.localStorage.getItem('user_info'))
	const [SubdevisionData, setSubdevisionData] = useState([]);
	const [DivisionData, setDivisionData] = useState([]);
	const [CircleData, setCircleData] = useState([]);
	const [GaData, setGaData] = useState([]);
	const [AccounData, setAccountData] = useState([]);
	const [AccountKey,setAccountKey]=useState([])
	const [AreaData, setAreaData] = useState([]);
	const [ModemData, setModemData] = useState([]);
	const [MeterData, setMeterData] = useState([]);
	const [filterObj, setFilterObj] = useState({})
	const [showfilterObj, setShowFilterObj] = useState({})

	const [reportType, setReportType] = useState('')
	const [InputText, setInputText] = useState('')
	const [CostomFilter,setCostomFilter]=useState({search:'',col:''})
	const [activeTab, setActiveTab] = useState("GSM");
	const [FilterLoader, setFilterLoader] = useState(false)
	const [searchTerms, setSearchTerms] = useState([]);
	const [serachLoader, setSerachLoader] = useState(false)
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
	const [ReportType, setReportTypes] = useState([])

	const [Profile, setProfile] = useState({
		accountuser: false,
		gauser: false,
		circleuser: false,
		areauser: false,
		divisionuser: false,
		subDivisionuser: false,
	})
	const [installState, setinstallState] = useState({
		Between: false,
		lessThan: false,
		greaterThan: false
	})
	const [DRState, setDRState] = useState({
		Between: false,
		lessThan: false,
		greaterThan: false
	})
	const [consumer, setConsumer] = useState({
		checktext1: false,
		checktext2: false,
		checktext3: false,
		checktext4: false,
		checktext5: false,
		checktext6: false,
		checktext7: false,
		checktextCS: false,
		checktextMP: false,
		checktextMOP: false,
		checktextTA: false,
		checktextInTS: false,
		checktextLSs: false,
		Consumer_Type: false,
		checktextDRTS:false,
		Consumer_Priority:false

	})
	const [dates,setDates]=useState({start:'',end:''})


	const [modem, setModem] = useState({
		checktextRSSI: false,
		checktextLS: false,
		checktextBP: false,
	})

	const [modemStart, setModemState] = useState({
		Between: false,
		lessThan: false,
		greaterThan: false
	})

	const [betteryPr, setbetteryPr] = useState({
		Between: false,
		lessThan: false,
		greaterThan: false
	})

	const [last, setLastSeen] = useState({
		lessThan: false,
		greaterThan: false
	})
	const [FilterTitle,setFilterTitle]=useState('')
	const [tagsList, settagsList] = useState([])
	const location=useLocation()

	const history = useHistory()
	const onHandaleList = async (info) => {
		
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		// setLoader(true);
		// $(".loader").fadeOut("slow");
		const data = await getLorawanData({ start: firstPageIndex, end: PageSize }, info)
		if (data.success === true) {
			setGetwayList(data.data.data)
			setGetwayListSerch(data.data.data)
			setnumberofPage(parseInt(data.data.totalCount))
			setLoader(false)

		} else {
			console.log('somethig Went wrong')
			setLoader(false)
		}
	}
	useEffect(()=>{
		if(filterObj?.Unique_ID?.length>0){
		onSubmiteFilter()
		}
	},[filterObj?.Unique_ID])
	const onSubmiteFilter = async (e) => {
		console.log(Object.keys(filterObj).length);
		if (Object.keys(filterObj).length > 0) {
			const firstPageIndex = (currentPage - 1) * PageSize;
			const data = await LoraModemFilterData({ start: firstPageIndex, end: PageSize }, { ...filterObj, CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view,CATEGORY0_view:permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view,CATEGORY2_view:permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view,CATEGORY3_view:permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view,CATEGORY4_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,CATEGORY5_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY5_view,roles:userInfo?.ROLE })
			if (data.success == true) {
				setGetwayList(data.data.data)
				setGetwayListSerch(data.data.data)
				setnumberofPage(parseInt(data.data.totalCount))
				setismodelFilter(false)
				setismodelFilter(false)
				setLoader(false)
			} else {
				console.log('somethig Went wrong')
				setismodelFilter(false)
				setLoader(false)
			}
		} else {
			onHandaleList({  CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view,CATEGORY0_view:permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view,CATEGORY2_view:permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view,CATEGORY3_view:permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view,CATEGORY4_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,CATEGORY5_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY5_view,Roles:userInfo?.ROLE})
			setismodelFilter(false)
				setLoader(false)
		}
	}

	const onHandaleRoles = async () => {
		const data = await getRolesById(userInfo?.ROLE)
		if (data.success === true) {
			const obj = data.data.data[0]
			const Roles = { ...obj, ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0), ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5), ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2), ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3), ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1), ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4) }
			if(location?.state?.detail){

			
			}else{
			onHandaleList({ CATEGORY1_view: Roles?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view,CATEGORY0_view:Roles?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view,CATEGORY2_view:Roles?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view,CATEGORY3_view:Roles?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view,CATEGORY4_view:Roles?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,CATEGORY5_view:Roles?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY5_view,Roles:userInfo?.ROLE})
			}
			setpermision(Roles)
		} else {
			console.log('Something Went Wrong')
			setLoader(false)
		}
	}

	const onGetTags = async () => {
		const data = await getTags()
		if (data.success === true) {
			settagsList(data.data.data.map((item) => ({ value: item?.UNIQUE_ID, label: item.TAG_NAME })))
		} else {
			console.log('Something Went Wrong');
		}
	}

	useEffect(() => {
		onHandaleRoles()
		
		if(location?.state?.detail){
			setFilterObj({...filterObj,Unique_ID:location?.state?.detail})
			setConsumer({...consumer,checktext5:true})
			setShowFilterObj({ ...showfilterObj,Unique_ID:location?.state?.detail})
			setFilterTitle(location?.state?.title)
		}else{
		setFilterObj({...filterObj,Status:'1'})
		setShowFilterObj({ ...showfilterObj, Status: 'Active' })
		setConsumer({...consumer,checktextCS:true})
		}
	}, []);



	const ongetGaById = async (id) => {
		const data1 = await getGaById(id);
		if (data1.success === true) {
			const options = []
			let activedata = data1?.data?.data.filter(v => v.CATEGORY1_STATUS === 1)
			const optionArray = activedata.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter((items) => (items === data.CATEGORY1_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY1_UNIQUE_ID, label: item?.CATEGORY1_NAME })))
			setGaData(options)
		}
	};

	const ongetCircle = async (id) => {
		const data1 = await getCircleById(id);
		if (data1.success === true) {
			const options = []
			let activedata = data1?.data?.data.filter(v => v.CATEGORY2_STATUS === 1)
			const optionArray = activedata?.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter((items) => (items === data.CATEGORY2_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY2_UNIQUE_ID, label: item?.CATEGORY2_NAME })))
			setCircleData(options);
		}
	};
	const ongetSubdevisionById = async (id) => {
		const data1 = await getSubdevisionById(id);
		if (data1.success === true) {
			const options = []
			let activedata = data1?.data?.data.filter(v => v.CATEGORY4_STATUS === 1)
			const optionArray = activedata.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter((items) => (items === data.CATEGORY4_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY4_UNIQUE_ID, label: item?.CATEGORY4_NAME })))
			setSubdevisionData(options);
		}
	};
	const ongetDivisionById = async (id) => {
		const data3 = await getDivisionById(id);
		if (data3.success === true) {
			const options = []
			let activedata = data3?.data?.data.filter(v => v.CATEGORY3_STATUS === 1)
			const optionArray = activedata.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter((items) => (items === data.CATEGORY3_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY3_UNIQUE_ID, label: item?.CATEGORY3_NAME })))
			setDivisionData(options);
		}
	};
	const ongetArea = async (id) => {
		const data3 = await getAreaById(id);
		if (data3.success === true) {
			const options = []
			let activedata = data3?.data?.data.filter(v => v.STATUS === 1)
			const optionArray = activedata.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'));
			optionArray.map((item) => (options.push({ value: item?.UNIQUE_ID, label: item?.CATEGORY5_NAME })))
			setAreaData(options);
		}
	};

	useEffect(() => {
		if (filterObj?.CATEGORY0?.length > 0) {
			ongetGaById(filterObj?.CATEGORY0);
			setCircleData([]);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([])
		} else {
			setGaData([]);
			setCircleData([]);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([]);
		}
	}, [filterObj.CATEGORY0]);

	useEffect(() => {
		if (filterObj?.CATEGORY1?.length > 0) {
			ongetCircle(filterObj?.CATEGORY1);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([]);

		} else {
			setCircleData([]);
			setDivisionData([]);
			setSubdevisionData([]);
			setAreaData([]);


		}
	}, [filterObj.CATEGORY1]);

	useEffect(() => {
		if (filterObj?.CATEGORY2?.length > 0) {
			ongetDivisionById(filterObj?.CATEGORY2);
			setAreaData([]);
			setSubdevisionData([]);

		} else {
			setAreaData([]);
			setDivisionData([]);
			setSubdevisionData([]);
		}
	}, [filterObj.CATEGORY2]);

	useEffect(() => {
		if (filterObj?.CATEGORY3?.length > 0) {
			ongetSubdevisionById(filterObj?.CATEGORY3);
			setAreaData([]);
		} else {
			setSubdevisionData([]);
			setAreaData([]);
		}
	}, [filterObj.CATEGORY3]);

	useEffect(() => {
		if (filterObj?.CATEGORY4?.length > 0) {
			ongetArea(filterObj?.CATEGORY4);
		} else {
			setAreaData([]);

		}
	}, [filterObj.CATEGORY4]);

	const onHandaleAccount = async () => {
		const data = await getAccount();
		if (data.success === true) {
			const options = []
			let activedata = data?.data?.data.filter(v => v.STATUS === 1)
			const optionArray = activedata?.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'))
			optionArray.map((item) => (options.push({ value: item?.UNIQUE_ID, label: item?.CATEGORY0 })))
			setAccountKey(data?.data?.data)
		} else {
			console.log("somthing Went wrong");
		}
	};

	const ongetAllModem = async () => {
		const data6 = await getAllModem();
		if (data6.success === true) {
			const options = []
			data6?.data?.data?.map((item) => (options.push({ value: item?.MODEM_UNIQUE_ID, label: item?.MODEM_PROFILE_NAME })))
			setModemData(options);
		} else {
			console.log("somthing Went wrong");
		}
	}
	const ongetAllMeter = async () => {
		const data7 = await getAllMeter();
		if (data7.success === true) {
			const options = []
			data7?.data?.data?.map((item) => (options.push({ value: item?.METER_UNIQUE_ID, label: item?.METER_PROFILE_NAME })))
			setMeterData(options);
		} else {
			console.log("somthing Went wrong");
		}
	}

	useEffect(() => {
		// if (Profile.accountuser === true) {
		// 	if (AccounData.length === 0) {
				
		// 	}
		// }
		if (Profile.gauser === true) {
			if (GaData.length === 0) {
				ongetGaById()
			}
		}
		if (Profile.circleuser === true) {
			if (CircleData.length === 0) {
				ongetCircle()
			}
		}
		if (Profile.divisionuser === true) {
			if (DivisionData.length === 0) {
				ongetDivisionById()
			}
		}
		if (Profile.subDivisionuser === true) {
			if (SubdevisionData.length === 0) {
				ongetSubdevisionById()
			}
		}
		if (Profile.areauser === true) {
			if (AreaData.length === 0) {
				ongetArea()
			}
		}
	}, [Profile])


	const showModal = (data) => {
		setModel(true);
		setgetwayDiscription(data)
		onGetReportTypeGSM()
	};

	const handleCancel = () => {
		setModel(false);
	};

	const onHandaleViewGSM = (id) => {
		window.open(`/view_existing_consumer_lorawan/${id}`, '_blank')
	}

	const onHandalSerch = async () => {
		setSerachLoader(true)

		if (searchTerms.length==0) {
			onHandaleList({  CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view,CATEGORY0_view:permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view,CATEGORY2_view:permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view,CATEGORY3_view:permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view,CATEGORY4_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,CATEGORY4_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,Roles:userInfo?.ROLE,Roles:userInfo?.ROLE})
			setSerachLoader(false)

		} else {
			const data = await LoraModemSerchData({ text: searchTerms,CATEGORY0_view:permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view,CATEGORY1_view:permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view,CATEGORY2_view:permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view,CATEGORY3_view:permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view,CATEGORY4_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,CATEGORY5_view:permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view,Roles:userInfo?.ROLE,filter_obj: filterObj  })
			if (data.success === true) {
				setGetwayList(data.data.data)
			setGetwayListSerch(data.data.data)
			setnumberofPage(parseInt(data.data.totalCount))
				// setGetwayList(data.data.data)
				// setnumberofPage(parseInt(data.data.totalCount))
			setSerachLoader(false)

			} else {
			setSerachLoader(false)

				console.log('something Went warong')
			}
		}
	}


	const sorting = (col) => {
		if (order === 'asc') {
			const sorted = [...getwayList].sort((a, b) => (
				a[col] > b[col] ? 1 : -1
			))
			setGetwayList(sorted)
			setOrder('desc')
		}
		if (order === 'desc') {
			const sorted = [...getwayList].sort((a, b) => (
				a[col] < b[col] ? 1 : -1
			))
			setGetwayList(sorted)
			setOrder('asc')
		}
	}

	const ongetGenretedAllGSMReport = async (e) => {
		e.preventDefault()
		if (report.name !== '' && report.value !== ''&&reportType!=='') {
			const obj={name: report.name, type: report.value,start_date:report.start_date, add_id: userInfo.UNIQUE_ID, add_by: userInfo.FIRST_NAME + ' ' + userInfo.LAST_NAME, reportType: reportType ,roles:userInfo?.ROLE,CATEGORY0_view:permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view,CATEGORY1_view:permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view,CATEGORY2_view:permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view,CATEGORY3_view:permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view,CATEGORY4_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,CATEGORY5_view:permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view,...filterObj}
			if (report?.value == 'allgsmconsumerlist') {
			const data = await getGenretedAllGSMReport(obj, permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view)
			if (data.success === true) {
				swal.fire('Download Proccess Start', 'Please Check After 15 to 20 minutes in Report Tab', 'success');
				handleCancel()
			} else {
				toast.error('Something Went Wrong')
			}
		}else{
			const x = new Date(moment(report.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
				if(report?.start_date==''){
					return	toast.warn('Please Select "Start Date" And "End Date"')
					}else{
					 
					}
					const data = await getGenreteGSMMRIReport(obj, permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view)
			if (data.success === true) {
				swal.fire('Download Proccess Start', 'Please Check After 15 to 20 minutes in Report Tab', 'success');
				handleCancel()
			} else {
				toast.error('Something Went Wrong')
			}
		}
		}else{
			return	toast.warn('Please Fill Mandatory Fields')

		}
	}
	const onHandalReport = (e) => {
		setReport({ value: e.target.value, name: 'All GSM Consumer Details',start_date: '', end_date: '' })
	}

	const onOpenFilter = () => {
		setismodelFilter(true)
		ongetAllMeter()
		ongetAllModem()
		onGetTags()
	}

	const onChange = (date, dateString) => {
		setDates({...dates,start:dateString})
		  };
		  const onChanges = (date, dateString) => {
		setDates({...dates,end:dateString})
		  };

		  const handleChangeS = (e) => {
			if (e.target.checked) {
				if (Profile.hasOwnProperty(e.target.id)) {
					setProfile({ ...Profile, [e.target.id]: true })
					setFilterObj({ ...filterObj, [e.target.name]: [] })
	
				}
				if (consumer.hasOwnProperty(e.target.id)) {
					setConsumer({ ...consumer, [e.target.id]: true })
					setFilterObj({ ...filterObj, [e.target.name]: '' })
	
				}
				if (modem.hasOwnProperty(e.target.id)) {
					setModem({ ...modem, [e.target.id]: true })
				}
				if (e.target.id == 'checktextLS') {
					setFilterObj({ ...filterObj, [e.target.name]: {} })
				}
				if (e.target.id == 'checktextRSSI') {
					setFilterObj({ ...filterObj, [e.target.name]: {} })
				}
				if (e.target.id == 'checktextInTS') {
					setFilterObj({ ...filterObj, [e.target.name]: {} })
				}
				if (e.target.id == 'checktextBP') {
					setFilterObj({ ...filterObj, [e.target.name]: {} })
				}
				if (e.target.id == 'checktextDRTS') {
					setFilterObj({ ...filterObj, [e.target.name]: {} })
				}
			} else {
				if (Profile.hasOwnProperty(e.target.id)) {
					setProfile({ ...Profile, [e.target.id]: false })
					delete filterObj[e.target.name]
					delete showfilterObj[e.target.name]
				}
				if (consumer.hasOwnProperty(e.target.id)) {
					setConsumer({ ...consumer, [e.target.id]: false })
					delete filterObj[e.target.name]
					delete showfilterObj[e.target.name]
					if (e.target.id == 'checktextDRTS') {
						setDRState({ ...DRState, greaterThan: false, lessThan: false, Between: false })
					}
				}
				if (modem.hasOwnProperty(e.target.id)) {
					setModem({ ...modem, [e.target.id]: false })
					if (e.target.id == 'checktextRSSI') {
						delete filterObj[e.target.name]
						delete showfilterObj[e.target.name]
						setModemState({ ...modemStart, greaterThan: false, lessThan: false, Between: false })
					}
					if (e.target.id == 'checktextBP') {
						delete filterObj[e.target.name]
						delete showfilterObj[e.target.name]
						setbetteryPr({ ...betteryPr, greaterThan: false, lessThan: false, Between: false })
					}
					if (e.target.id == 'checktextLS') {
						console.log(e.target.id)
						delete filterObj[e.target.name]
						delete showfilterObj[e.target.name]
						setLastSeen({ ...last, greaterThan: false, lessThan: false })
					}
				}
			}
		}

	const onHandaleInpurt = (e) => {
		setFilterObj({ ...filterObj, [e.target.name]: e.target.value })
	}


	const handleSelectChange = (e) => {
		const stateUpdate = Object.keys(modemStart)
		setModemState(stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, RSSI: stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: "True" }), {}) })
	};

	const handleSelectChangeInstall = (e) => {
		const stateUpdate = Object.keys(modemStart)
		setinstallState(stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, Installation_Date: stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: "True" }), {}) })
	};
	const handleSelectChangeDR = (e) => {
		const stateUpdate = Object.keys(modemStart)
		setDRState(stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, Dataretrival_Date: stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: "True" }), {}) })
	};

	const handleBetteryPr = (e) => {
		const BpUpdate = Object.keys(betteryPr)
		setbetteryPr(BpUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
	};

	const handleLastSeen = (e) => {
		const lastSeenUpdate = Object.keys(last)
		setLastSeen(lastSeenUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({...filterObj,Last_Seen:{...filterObj.Last_Seen,[e.value]:true}})

	};


	const customStyles = {
		menu: provided => ({ ...provided, zIndex: 9999 }),
		multiValue: (provided, { data }) => {
			return {
				...provided,
				width: 'min-content',
				backgroundColor: "#6777ef",
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "white",
		}),


	}

	const animatedComponents = makeAnimated();

	const options1 = [
		{ value: "account-adani", label: 'Adani Total Gas Limited' },
		{ value: "meMighty", label: 'MEMIGHTY' },
	]

	const consumers = [
		{ value: 'all', label: 'All' },
		{ value: '1', label: 'Active' },
		{ value: '0', label: 'InActive' },
		{ value: '-1', label: 'Pending Configurations' },
		{ value: '2', label: 'Under Maintenance' },
		{ value: '3', label: 'Stand By' }

	]
	const dataWise = [
		{ value: "Between", label: 'Is between' },
		{ value: "LessEqual", label: 'Is Less than Equel to' },
		{ value: "GreaterEqual", label: "Is Greater than Equel to" }
	]
	const lastSeenWise = [
		{ value: "LessEqual", label: 'Less than' },
		{ value: "GreaterEqual", label: 'Greater than' }
	]
	const lastSeenWiseOption = [
		{ value: "Hours", label: 'Hours' },
		{ value: "Minutes", label: 'Minutes' }
	]
	const lastSeenWiseShort = [
		{ value: "desc", label: 'Desc' },
		{ value: "asc", label: 'Asc' }
	]
	const consumerTypeArray = [
		{ value: "Industrial", label: 'Industrial' },
		{ value: "Residential", label: 'Residential' },
		{ value: "Commercial", label: 'Commercial' },
	]
	const consumerPriority = [
		{ value: 1, label: 'Primary' },
		{ value: 2, label: 'Secondary' },
	]
	useEffect(() => {
		onHandaleAccount()
		if (Object.keys(permision).length > 0) {
			if (Object.keys(filterObj).length == 0) {
				onHandaleList({  CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view,CATEGORY0_view:permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view,CATEGORY2_view:permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view,CATEGORY3_view:permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view,CATEGORY4_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,CATEGORY4_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,Roles:userInfo?.ROLE,Roles:userInfo?.ROLE})
			} else {
				onSubmiteFilter()
			}
		}
	}, [currentPage, PageSize])

	function getSecondsTodayBydate(ts) {
		var now  = moment().add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
var then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
		return moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"),'second')
	}

	$(document).ready(function(){
		var maxLength = 9;
		$(".show-read-more").each(function(){
			var myStr = $(this).text();
			if($.trim(myStr).length > maxLength){
				var newStr = myStr.substring(0, maxLength);
				$(this).empty().html(newStr);
				$(this).append('<span>...</span>');
				
			}
		});
		var maxLengths = 40;
		$(".show-read-more-name").each(function(){
			var myStr = $(this).text();
			if($.trim(myStr).length > maxLengths){
				var newStr = myStr.substring(0, maxLengths);
				$(this).empty().html(newStr);
				$(this).append('<span>...</span>');
				
			}
		});
	});

	const onClickCostumFilter=async()=>{
		if(CostomFilter?.search!==''){
		const firstPageIndex = (currentPage - 1) * PageSize;

		const data=await LoraModemColumanWiseSerchData({  CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view,CATEGORY0_view:permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view,CATEGORY2_view:permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view,CATEGORY3_view:permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view,CATEGORY4_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,CATEGORY4_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,Roles:userInfo?.ROLE,Roles:userInfo?.ROLE,search:CostomFilter?.search,col:CostomFilter?.col,start: firstPageIndex, end: PageSize})
		if(data.success===true){
			setGetwayList(data.data.data)
		setnumberofPage(parseInt(data.data.totalCount))

		}else{
			console.log('something wait wrong')
		}
	}else{
		onHandaleList({  CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view,CATEGORY0_view:permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view,CATEGORY2_view:permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view,CATEGORY3_view:permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view,CATEGORY4_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,CATEGORY4_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,Roles:userInfo?.ROLE,Roles:userInfo?.ROLE})
	}
	}

	const onChangeCostomFilter=(e)=>{
		setCostomFilter({...CostomFilter,search:e.target.value})
	}

	const onClearFilter = async () => {
		setFilterLoader(true)
		setFilterObj({Status:'1'})
		setShowFilterObj({Status: 'Active' })
		setProfile({
			accountuser: false,
			gauser: false,
			circleuser: false,
			areauser: false,
			divisionuser: false,
			subDivisionuser: false,
		})
		setDRState({...DRState,greaterThan: false, lessThan: false, Between: false })
		setModemState({ ...modemStart, greaterThan: false, lessThan: false, Between: false })
		setbetteryPr({ ...betteryPr, greaterThan: false, lessThan: false, Between: false })
		setLastSeen({ ...last, greaterThan: false, lessThan: false })
		setinstallState({ ...installState, greaterThan: false, lessThan: false, Between: false })
		setConsumer({
			checktext1: false,
			checktext2: false,
			checktext3: false,
			checktext4: false,
			checktext5: false,
			checktext6: false,
			checktext7: false,
			checktextCS: false,
			checktextMP: false,
			checktextMOP: false,
			checktextTA: false,
			checktextInTS: false,
			checktextLSs: false,
			Consumer_Type: false,
			checktextDRTS:false

		})
		setModem({
			checktextRSSI: false,
			checktextLS: false,
			checktextBP: false,
		})
		history.replace({ pathname: '/All-GSM', state: {} })

		const emptyObj = {};
		const firstPageIndex = (currentPage - 1) * PageSize;
		const data = await LoraModemFilterData({ start: firstPageIndex, end: PageSize }, { emptyObj, CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view, CATEGORY0_view: permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view, CATEGORY2_view: permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view, CATEGORY3_view: permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view, CATEGORY4_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view, CATEGORY5_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY5_view, roles: userInfo?.ROLE })
		if (data.success == true) {
			setGetwayList(data.data.data)
			setGetwayListSerch(data.data.data)
			setnumberofPage(parseInt(data.data.totalCount))
			setismodelFilter(false)
			setFilterLoader(false)

		} else {
			console.log('somethig went wrong')
			setFilterLoader(false)

		}
	}

	const onGetReportTypeGSM = async () => {
		const data = await GetReportType({ type: 'Lora' })
		if (data.success == true) {
			var arrayReport = []
			console.log(permision?.ROLES_OTHER_PERMISSIONS);
			data?.data?.data?.map((item) => (permision?.ROLES_OTHER_PERMISSIONS?.Report?.filter((info) => (info == item?.BACK_TYPE_NAME)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676' ? arrayReport.push({ value: item?.BACK_TYPE_NAME, label: item?.FRONT_TYPE_NAME }) : ''))
			setReportTypes(arrayReport)
		} else {

		}
	}

	// const onChangeCostomFilter = (e) => {
	// 	setCostomFilter({ ...CostomFilter, search: e.target.value })
	// }

	const onHandalSearchMulti = (newSearchTerms) => {
		setSearchTerms(newSearchTerms);
		
	};
	
	const onItemCleared = (clearedIndex) => {
		// Create a new array without the cleared item
		const newSearchTerms = searchTerms.filter((_, index) => index !== clearedIndex);
		// Update the search results based on the new search terms
		onHandalSearchMulti(newSearchTerms);
	};
	const onClearAllItems = () => {
		// Reset the search results to the original data
		onHandalSearchMulti([]);
	
	};

	const onSearchMultiFilterJson = (newSearchTerms,json_name) => {
		console.log('step1',newSearchTerms);
		setFilterObj({ ...filterObj, [json_name]: newSearchTerms })
		
	};
	
	const onItemClearedFilterJson  = (clearedIndex,json_name) => {
		console.log('step12',json_name,clearedIndex);

		// Create a new array without the cleared item
		const newSearchTerms = searchTerms.filter((_, index) => index !== clearedIndex);
		// Update the search results based on the new search terms
		onSearchMultiFilterJson(newSearchTerms,json_name);
	};
	const onClearAllItemsFilterJson  = (arr,json_name) => {
		console.log('step3',json_name);

		// Reset the search results to the original data
		onSearchMultiFilterJson([],json_name);
	
	};

	const onGetSecondaryConsumerById=async(GSMID)=>{
			window.open(`/view_existing_consumer_lorawan/${GSMID}`, '_blank')
	}

	const onRedirectMap=()=>{
		history.replace({ pathname: '/gsm-map-view', state: {detail:filterObj} })

	}
	return (
		<>
			<Modal title="Lorawan Consumers Filter" visible={ismodelFilter} footer='' onCancel={() => setismodelFilter(false)} width={1500}>
				<fieldset className="customLegend">
					<legend >Group Wise Filter</legend>
					<div className="row" style={{
						marginLeft: 10,
						marginRight: 10

					}} >
						<div className="col-12">
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="accountuser" name="CATEGORY0" className="custom-control-input " value="account" checked={Profile.accountuser} onChange={handleChangeS} />
									<label className="custom-control-label" for="accountuser">CATEGORY0</label>
								</div>
								{Profile.accountuser && (<div className="form-group col-md-3 accountgrp" style={{ marginTop: -10 }} >
									<Select value={AccounData.filter((item) => filterObj?.CATEGORY0?.includes(item.value)) || []} placeholder='Select account...' styles={customStyles} components={animatedComponents} name='account' isMulti options={AccounData} onChange={(e) => {
										setFilterObj({ ...filterObj, CATEGORY0: e.map((item) => (item.value)) })
										setShowFilterObj({ ...showfilterObj, CATEGORY0: e.map((item) => (item.label)) })
									}} />
								</div>)}
							</div>

							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="gauser" name="CATEGORY1" className="custom-control-input" value="ga" checked={Profile.gauser} onChange={handleChangeS} />
									<label className="custom-control-label" for="gauser">CATEGORY1</label>
								</div>
								{Profile.gauser && (<div className="form-group col-md-3 gagrp" style={{ marginTop: -10 }}>
									<Select value={GaData.filter((item) => filterObj?.CATEGORY1?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={GaData} onChange={(e) => {
										setFilterObj({ ...filterObj, CATEGORY1: e.map((item) => (item.value)) })
										setShowFilterObj({ ...showfilterObj, CATEGORY1: e.map((item) => (item.label)) })
									}} />
								</div>)}
							</div>

							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="circleuser" name="CATEGORY2" className="custom-control-input" checked={Profile.circleuser} value="circle" onChange={handleChangeS} />
									<label className="custom-control-label" for="circleuser">CATEGORY2</label>
								</div>
								{Profile.circleuser && (<div className="form-group col-md-3 circlegrp" style={{ marginTop: -10 }}>
									<Select value={CircleData.filter((item) => filterObj?.CATEGORY2?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={CircleData} onChange={(e) => {
										setFilterObj({ ...filterObj, CATEGORY2: e.map((item) => (item.value)) })
										setShowFilterObj({ ...showfilterObj, CATEGORY2: e.map((item) => (item.label)) })
									}} />
								</div>)}
							</div>

							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="divisionuser" name="CATEGORY3" className="custom-control-input" checked={Profile.divisionuser} value="division" onChange={handleChangeS} />
									<label className="custom-control-label" for="divisionuser">CATEGORY3</label>
								</div>
								{Profile.divisionuser && (<div className="form-group col-md-3 divisiongrp" style={{ marginTop: -10 }}>
									<Select value={DivisionData.filter((item) => filterObj?.CATEGORY3?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={DivisionData} onChange={(e) => {
										setFilterObj({ ...filterObj, CATEGORY3: e.map((item) => (item.value)) })
										setShowFilterObj({ ...showfilterObj, CATEGORY3: e.map((item) => (item.label)) })
									}} />

								</div>)}
							</div>

							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="subDivisionuser" name="CATEGORY4" className="custom-control-input" checked={Profile.subDivisionuser} value="subdivision" onChange={handleChangeS} />
									<label className="custom-control-label " for="subDivisionuser">CATEGORY4</label>
								</div>
								{Profile.subDivisionuser && (<div className="form-group col-md-3 subdivisiongrp" style={{ marginTop: -10 }}>
									<Select value={SubdevisionData.filter((item) => filterObj?.CATEGORY4?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={SubdevisionData} onChange={(e) => {
										setFilterObj({ ...filterObj, CATEGORY4: e.map((item) => (item.value)) })
										setShowFilterObj({ ...showfilterObj, CATEGORY4: e.map((item) => (item.label)) })
									}} />

								</div>)}
							</div>

							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="areauser" name="CATEGORY5" className="custom-control-input" checked={Profile.areauser} value="area" onChange={handleChangeS} />
									<label className="custom-control-label" for="areauser">CATEGORY5</label>
								</div>
								{Profile.areauser && (<div className="form-group col-md-3 areagrp" style={{ marginTop: -10 }}>
									<Select value={AreaData.filter((item) => filterObj?.CATEGORY5?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={AreaData} onChange={(e) => {
										setFilterObj({ ...filterObj, CATEGORY5: e.map((item) => (item.value)) })
										setShowFilterObj({ ...showfilterObj, CATEGORY5: e.map((item) => (item.label)) })
									}} />

								</div>)}
							</div>
						</div>

					</div>
				</fieldset>
				<fieldset className="customLegend ">
					<legend >Consumer Info Wise Filter</legend>
					<div className="row" style={{ marginLeft: 10 }}>
						<div className="col-12">
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktext1" name="Installation_Number" className="custom-control-input" checked={consumer.checktext1} value="installnumber" onChange={handleChangeS} />
									<label className="custom-control-label" for="checktext1">Installation Number</label>
								</div>
								{consumer.checktext1 &&<MultiInput
									placeholder="search and enter..."
									onChange={(arr)=>onSearchMultiFilterJson(arr,"Installation_Number")}
									maxItems={null} // Allow any number of items
									onClear={(arr)=>onItemClearedFilterJson(arr,"Installation_Number")}
									onClearAll={(arr)=>onClearAllItemsFilterJson(arr,"Installation_Number")}
									value={filterObj?.Installation_Number}
								/>}
								
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktext2" name="Business_Number" className="custom-control-input" value="businessnu" checked={consumer.checktext2} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktext2">Business Partner Number</label>
								</div>
								{consumer.checktext2 &&<MultiInput
									placeholder="search and enter..."
									onChange={(arr)=>onSearchMultiFilterJson(arr,"Business_Number")}
									maxItems={null} // Allow any number of items
									onClear={(arr)=>onItemClearedFilterJson(arr,"Business_Number")}
									onClearAll={(arr)=>onClearAllItemsFilterJson(arr,"Business_Number")}
								/>}
								
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktext3" name="Modem_Serial_No" className="custom-control-input" value="modemserial" checked={consumer.checktext3} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktext3">Modem Serial No</label>
								</div>
								{consumer.checktext3 &&<MultiInput
									placeholder="search and enter..."
									onChange={(arr)=>onSearchMultiFilterJson(arr,"Modem_Serial_No")}
									maxItems={null} // Allow any number of items
									onClear={(arr)=>onItemClearedFilterJson(arr,"Modem_Serial_No")}
									onClearAll={(arr)=>onClearAllItemsFilterJson(arr,"Modem_Serial_No")}
								/>}
								
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktext4" name="Panel_Serial_No" className="custom-control-input" value="panelserno" checked={consumer.checktext4} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktext4">Panel Serial No</label>
								</div>
								{consumer.checktext4 &&<MultiInput
									placeholder="search and enter..."
									onChange={(arr)=>onSearchMultiFilterJson(arr,"Panel_Serial_No")}
									maxItems={null} // Allow any number of items
									onClear={(arr)=>onItemClearedFilterJson(arr,"Panel_Serial_No")}
									onClearAll={(arr)=>onClearAllItemsFilterJson(arr,"Panel_Serial_No")}
								/>}
								
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktext5" name="Unique_ID" className="custom-control-input" value="cnuniqueid" checked={consumer.checktext5} onChange={handleChangeS}/>
									<label className="custom-control-label" for="checktext5">Consumer Unique Id</label>
								</div>
								{consumer.checktext5 &&<MultiInput
									placeholder="search and enter..."
									onChange={(arr)=>onSearchMultiFilterJson(arr,"Unique_ID")}
									maxItems={null} // Allow any number of items
									onClear={(arr)=>onItemClearedFilterJson(arr,"Unique_ID")}
									onClearAll={(arr)=>onClearAllItemsFilterJson(arr,"Unique_ID")}
									value={filterObj?.Unique_ID}
								/>}
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktext6" name="Distirbuter_ID" className="custom-control-input" value="distid" checked={consumer.checktext6} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktext6">Customer Id</label>
								</div>
								{consumer.checktext6 &&<MultiInput
									placeholder="search and enter..."
									onChange={(arr)=>onSearchMultiFilterJson(arr,"Distirbuter_ID")}
									maxItems={null} // Allow any number of items
									onClear={(arr)=>onItemClearedFilterJson(arr,"Distirbuter_ID")}
									onClearAll={(arr)=>onClearAllItemsFilterJson(arr,"Distirbuter_ID")}
								/>}
								
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktext7" name="IMEI" className="custom-control-input" value="allimei" checked={consumer.checktext7} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktext7">EUI</label>
								</div>
								{consumer.checktext7 &&<MultiInput
									placeholder="search and enter..."
									onChange={(arr)=>onSearchMultiFilterJson(arr,"IMEI")}
									maxItems={null} // Allow any number of items
									onClear={(arr)=>onItemClearedFilterJson(arr,"IMEI")}
									onClearAll={(arr)=>onClearAllItemsFilterJson(arr,"IMEI")}
								/>}
								
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextCS" name="Status" className="custom-control-input" value="filterstatus" checked={consumer.checktextCS} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktextCS">Consumer Status</label>
								</div>
								{consumer.checktextCS && (<div className="form-group col-md-3 checktextCS filterstatus" style={{ marginTop: -10 }}>
									<Select styles={customStyles} components={animatedComponents} name='Status' options={consumers} onChange={(e) => {
										if(!e&&e?.value!=1&&e?.value!=0){
											delete filterObj["SubStatus"]
										}
										setFilterObj({ ...filterObj, Status: e?.value||'' })
										setShowFilterObj({ ...showfilterObj, Status: e?.label||'' })
									}} isClearable  value={[filterObj?.Status?consumers.filter((item)=>(item?.value==filterObj?.Status))[0]:[]]} />

								</div>)}
								{consumer.checktextCS&&filterObj?.Status && filterObj?.Status==1 && <div className="form-group col-md-3 checktextCS filterstatus" style={{ marginTop: -10 }}>
									<Select styles={customStyles} components={animatedComponents} name='Status' options={[{ value: '1', label: 'Live' },{ value: '2', label: 'Temporary Disconnection' }]} onChange={(e) => {
										setFilterObj({ ...filterObj, SubStatus: e?.value||'' })
										setShowFilterObj({ ...showfilterObj, SubStatus: e?.label||'' })
									}} isClearable/>
								</div>}
								{consumer.checktextCS &&filterObj?.Status && filterObj?.Status==0 && <div className="form-group col-md-3 checktextCS filterstatus" style={{ marginTop: -10 }}>
									<Select styles={customStyles} components={animatedComponents} name='Status' options={[{ value: '3', label: 'Permanent Disconnection' }]} onChange={(e) => {
										setFilterObj({ ...filterObj, SubStatus: e?.value||'' })
										setShowFilterObj({ ...showfilterObj, SubStatus: e?.label||'' })
									}} isClearable/>
								</div>}
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextMP" name="Meter_Profile" className="custom-control-input" value="meterprofile" checked={consumer.checktextMP} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktextMP">EVC Profile</label>
								</div>
								{consumer.checktextMP && (<div className="form-group col-md-3 checktextMP meterprofile" style={{ marginTop: -10 }}>
									<Select styles={customStyles} components={animatedComponents} name='Meter_Profile' options={MeterData} isMulti onChange={(e) => {
										setFilterObj({ ...filterObj, Meter_Profile: e.map((item) => (item.value)) })
										setShowFilterObj({ ...showfilterObj, Meter_Profile: e.map((item) => (item.label)) })
									}} />

								</div>)}
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextMOP" name="Modem_Profile" className="custom-control-input" value="modemprofile" checked={consumer.checktextMOP} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktextMOP">Modem Profile</label>
								</div>
								{consumer.checktextMOP && (<div className="form-group col-md-3 checktextMOP modemprofile" style={{ marginTop: -10 }}>
									<Select styles={customStyles} components={animatedComponents} name='Modem_Profile' options={ModemData} isMulti onChange={(e) => {
										setFilterObj({ ...filterObj, Modem_Profile: e.map((item) => (item.value)) })
										setShowFilterObj({ ...showfilterObj, Modem_Profile: e.map((item) => (item.label)) })
									}} />

								</div>)}
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextTA" name="Tags" className="custom-control-input" value="tagsvalue" checked={consumer.checktextTA} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktextTA">Tags</label>
								</div>
								{consumer.checktextTA && (<div className="form-group col-md-3 checktextTA tagsvalue" style={{ marginTop: -10 }}>
									<Select styles={customStyles} name='Tags' isMulti options={tagsList} onChange={(e) => {
										setFilterObj({ ...filterObj, Tags: e.map((item) => (item.value)) })
										setShowFilterObj({ ...showfilterObj, Tags: e.map((item) => (item.label)) })
									}
									} />

								</div>)}

							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextInTS" name="Installation_Date" className="custom-control-input" value="tagsvalue" checked={consumer.checktextInTS} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktextInTS">Installation Date</label>
								</div>
								{consumer.checktextInTS && (<div className="form-group col-md-3 checktextRSSI length rssival" style={{ marginTop: -10 }}>
									<Select components={animatedComponents} placeholder="Select Condition..." name='RSSI' options={dataWise}
										styles={customStyles} onChange={handleSelectChangeInstall} />

								</div>)}
								{consumer.checktextInTS&& installState?.Between && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
									<Space direction="horizontal">
										<div className="d-flex flex-column">Start In</div>
										<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Installation_Date: { ...filterObj.Installation_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
											disabledDate={(current) => {
												return current.isAfter(moment())
											}} />
										<div className="d-flex flex-column" >End In</div>
										<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Installation_Date: { ...filterObj.Installation_Date, End_Date: dateString } })} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
											return current.isAfter(moment())
										}} />
									</Space>

								</div>)}
								{consumer.checktextInTS&& installState?.LessEqual && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
									<Space direction="horizontal">
										<div className="d-flex flex-column">Date</div>
										<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Installation_Date: { ...filterObj.Installation_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
											disabledDate={(current) => {
												return current.isAfter(moment())
											}} />
									</Space>

								</div>)}
								{consumer.checktextInTS&& installState?.GreaterEqual && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
									<Space direction="horizontal">
										<div className="d-flex flex-column">Date</div>
										<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Installation_Date: { ...filterObj.Installation_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
											disabledDate={(current) => {
												return current.isAfter(moment())
											}} />
									</Space>

								</div>)}
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextDRTS" name="Dataretrival_Date" className="custom-control-input" value="tagsvalue" checked={consumer.checktextDRTS} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktextDRTS">Data Retrival Date</label>
								</div>
								{consumer.checktextDRTS && (<div className="form-group col-md-3 checktextRSSI length rssival" style={{ marginTop: -10 }}>
									<Select components={animatedComponents} placeholder="Select Condition..." name='RSSI' options={dataWise}
										styles={customStyles} onChange={handleSelectChangeDR} />

								</div>)}
								{consumer.checktextDRTS&& DRState?.Between && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
									<Space direction="horizontal">
										<div className="d-flex flex-column">Start In</div>
										<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Dataretrival_Date: { ...filterObj.Dataretrival_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
											disabledDate={(current) => {
												return current.isAfter(moment())
											}} />
										<div className="d-flex flex-column" >End In</div>
										<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Dataretrival_Date: { ...filterObj.Dataretrival_Date, End_Date: dateString } })} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
											return current.isAfter(moment())
										}} />
									</Space>

								</div>)}
								{consumer.checktextDRTS&& DRState?.LessEqual && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
									<Space direction="horizontal">
										<div className="d-flex flex-column">Date</div>
										<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Dataretrival_Date: { ...filterObj.Dataretrival_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
											disabledDate={(current) => {
												return current.isAfter(moment())
											}} />
									</Space>

								</div>)}
								{consumer.checktextDRTS&& DRState?.GreaterEqual && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
									<Space direction="horizontal">
										<div className="d-flex flex-column">Date</div>
										<DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Dataretrival_Date: { ...filterObj.Dataretrival_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
											disabledDate={(current) => {
												return current.isAfter(moment())
											}} />
									</Space>

								</div>)}
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="Consumer_Type" name="Consumer_Type" className="custom-control-input" value="area" checked={consumer.Consumer_Type} onChange={handleChangeS} />
									<label className="custom-control-label" for="Consumer_Type">Consumer Type</label>
								</div>
								{consumer?.Consumer_Type && <div class="form-group col-md-3">
									<Select styles={customStyles} components={animatedComponents} name='Consumer_Type' isMulti options={consumerTypeArray} onChange={(e) => {
										setFilterObj({ ...filterObj, Consumer_Type: e.map((item) => (item.value)) })
										setShowFilterObj({ ...showfilterObj, Consumer_Type: e.map((item) => (item.label)) })
									}} />
									{/* <select
															class="form-control form-select modemtypename anothermodemname"
															name="cust_sal"
															id="filter_course"
															required=""
															onChange={(e) => (setFilterObj({ ...filterObj, Consumertype: e.target.value }))}
															value={filterObj.Consumertype}
														>
															<option value="">Select Consumer Type </option>
															<option value="Residential">Residential</option>
															<option value="Industrial">Industrial</option>
															<option value="Commercial">Commercial</option>
														</select> */}
								</div>}
							</div>
						</div>
					</div>
				</fieldset>

				<fieldset className="customLegend ">
					<legend >Modem Data Wise Filter</legend>
					<div className="row" style={{ marginLeft: 10 }}>
						<div className="col-12">
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextRSSI" name="RSSI" className="custom-control-input" value="rssival" checked={modem.checktextRSSI} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktextRSSI">RSSI</label>
								</div>
								{modem.checktextRSSI && (<div className="form-group col-md-3 checktextRSSI length rssival" style={{ marginTop: -10 }}>
									<Select components={animatedComponents} placeholder="Select Condition..." name='RSSI' options={dataWise}
										styles={customStyles} onChange={handleSelectChange} />

								</div>)}
								{modemStart.Between && modem.checktextRSSI && (<div className="form-group col-md-3 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, From: e.target.value } }) }} />
								</div>)}
								{modemStart.Between && modem.checktextRSSI && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, To: e.target.value } }) }} />
								</div>)}
								{modemStart.LessEqual && modem.checktextRSSI && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, Value: e.target.value } }) }} />
								</div>)}
								{modemStart.GreaterEqual && modem.checktextRSSI && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, Value: e.target.value } }) }} />
								</div>)}
							</div>

							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextLS" name="Last_Seen" className="custom-control-input" value="lastssen" checked={modem.checktextLS} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktextLS">Last seen</label>
								</div>
								{modem.checktextLS && (<div className="form-group col-md-3 checktextLS lastssen" style={{ marginTop: -10 }}>
									<Select components={animatedComponents} styles={customStyles} name='Last_Seen' options={lastSeenWiseOption} placeholder="Select Condition..."
										onChange={(e) => { setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, Last_Seen: e.value } }) }} />
								</div>)}
								{modem.checktextLS && (<div className="form-group col-md-3 checktextLS lastssen" style={{ marginTop: -10 }}>
									<Select components={animatedComponents} styles={customStyles} name='Last_Seen' options={lastSeenWise} placeholder="Select Condition..."
										onChange={handleLastSeen} />
								</div>)}

								{last.LessEqual && modem.checktextLS && (<div className="form-group col-md-3 checktext31" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="" onChange={(e) => { setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, Value: e.target.value } }) }} />
								</div>)}

								{last.GreaterEqual && modem.checktextLS && (<div className="form-group col-md-3 checktext31" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_hours" id="filter_greater_hours" placeholder="" onChange={(e) => { setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, Value: e.target.value } }) }} />
								</div>)}
							</div>



						</div>
					</div>
				</fieldset>
					<div className="text-left mt-2">
						<input type="submit" className="btn btn-danger mr-2" value="Filter" name="filter_customer_form" onClick={onSubmiteFilter} />
						<Link className="btn btn-light text-dark" to="/All-GSM">Reset</Link>
					</div>



				
			</Modal>
			<Modal title="Generate Lora Reports" visible={ismodel} footer='' onCancel={handleCancel} width={1500}>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data">
									<div className="form-row" style={{ marginLeft: "10px" }}>
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4 ">Report Type :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reporttype" required="required" onChange={onHandalReport}>
												<option value="">Report Type</option>
												{ReportType?.map((item) => (<option value={item?.value}>{item?.label}</option>))}

											</select>
										</div>
										{report?.value=='GSM_MRI_Report'?<><div className="form-group col-md-3" id="strdate">
											<label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
											<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='MM-YYYY' name='start' style={{ width: '100%', height: '40px' }} picker="month"
												disabledDate={(current) => {
													return current.isAfter(moment().add(-1, 'days'))
												}} />
										</div>
											</>:''}
										{report?.value=='GSM_MRI_Report'?<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reportextension" onChange={(e) => (setReportType(e.target.value))} value={reportType}>
												<option value="">Select Extension</option>
												<option value="mri">mri</option>
											</select>
										</div>:<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reportextension" onChange={(e) => (setReportType(e.target.value))} value={reportType}>
												<option value="">Select Extension</option>
												<option value="xls">XLS</option>
												<option value="csv">CSV</option>
											</select>
										</div>}
									</div>
									<p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
									<div className="text-left">
										<input type="submit" name="submit" className="btn btn-danger mr-2" value="Generate" id="submitvn" onClick={ongetGenretedAllGSMReport} />
										<Link className="btn btn-light text-dark" onClick={handleCancel}>Cancel</Link>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</Modal>
			{loader ? (
						<div className="loader"></div>
				) : (
					""
				)}
			{FilterLoader ? <> <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
				<GoogleLoader bodyClassName={'tw-h-[70%]'} />
			</div></> :<div className="main-content" style={{ minHeight: "562px" }}>
				
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb ">
						<li className="breadcrumb-item">
							<Link to="/all-deshboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								<Users style={{ height: "16px" }} /> Consumers
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to='/All-GSM'>
								<Users style={{ height: "16px" }} /> Lorawan Consumers
							</Link>
						</li>
					</ol>
				</nav>
				<section className="section">
				<div class="section-body">
						<div class="row">
							<div class="col-12 col-sm-12 col-lg-12 tw-p-0">
								<div class="card">
									<div class="card-header d-flex tw-border-b-0 " style={{ justifyContent: "space-between" }}>
										<ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "20px", alignItems: "center" }}>
											<li>
												<button
													style={{
														backgroundColor: activeTab === "GSM" ? chroma(btnColor[0]).alpha(0.4).css() : '',
														color:activeTab === "GSM" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
														borderBottom: `1px solid ${btnColor[0]}`,
														boxShadow: activeTab === "GSM" ? `0 2px 8px -3px ${btnColor[0]}` : '',
													}}
														className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "GSM" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
													data-toggle="tab"
													href="#home"
													role="tab"
													aria-controls="home"
													aria-selected="true"
													onClick={() => setActiveTab("GSM")}
												>
													Lorawan Consumers
												</button>
											</li>
											<li>
											{userInfo.ROLE === 'ROLE1661856938676' || permision?.ROLES_OTHER_PERMISSIONS?.Tabs?.filter((item) => (item == "Modem_Details")).length > 0?<button
													style={{
														backgroundColor: activeTab === "Modem Details" ? chroma(btnColor[1]).alpha(0.4).css() : '',
														color:activeTab === "Modem Details" ? chroma(btnColor[1]).darken(1.5).css() : btnColor[1],
														borderBottom: `1px solid ${btnColor[1]}`,
														boxShadow: activeTab === "Modem Details" ? `0 2px 8px -3px ${btnColor[1]}` : '',
													}}
														className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Modem Details" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
													id="profile-tab"
													data-toggle="tab"
													href="#profile"
													role="tab"
													aria-controls="profile"
													aria-selected="false"
													onClick={() => setActiveTab("Modem Details")}
												>
													Modem Details
												</button>:''}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					{activeTab==='GSM'&&<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header ">
										<h4  ><span className='mr-2'>Lorawan Consumers</span><BootstrapTooltip title='Table Name is Lora_consumer_device_index' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div className="card-header-action">
											<Link to='/add-Lorawan-modem' className="btn btn-info text-white mr-2">
												<BootstrapTooltip title="Add">
													<span>
														<i
															className="fas fa-plus  text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</Link>
											<button type="button" className="btn btn-info mr-2" onClick={() => (onOpenFilter())}>
												<BootstrapTooltip title="Filter">
													<span>
														<i className="fa fa-filter text-white"></i>
													</span>
												</BootstrapTooltip>
											</button>
											
											<div className="dropdown d-inline mr-2">
											<button
																			className="btn btn-info dropdown"
																			type="button"
																			data-toggle="dropdown"
																			aria-haspopup="true"
																			aria-expanded="false"
																		>
																			<div>
																			<i className="fas fa-download text-white" style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>
																			</div>
																		</button>
																		<div
																			className="dropdown-menu tab-setting"
																			x-placement="bottom-start"
																			style={{
																				position: "absolute",
																				transform: "translate3d(-5px, 28px, 0px)",
																				top: "0px",
																				left: "-110px",
																				willChange: "transform",
																			}}
																		>	
																			<a className="dropdown-item has-icon" onClick={showModal} >
																				 Generate Report
																			</a>
																			<Link to='/reports'
																				className="dropdown-item has-icon"
																			>
																				Report History
																			</Link>
																		</div>
																	</div>
																	<div className="dropdown d-inline ">
												<button
													className="btn btn-info dropdown"
													type="button"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false">
													<div>
													<i className="fa fa-ellipsis-v text-white" style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>
													</div>
												</button>
												<div
													className="dropdown-menu tab-setting"
													x-placement="bottom-start"
													style={{
														position: "absolute",
														transform: "translate3d(-5px, 28px, 0px)",
														top: "0px",
														left: "-110px",
														willChange: "transform",
													}}>
													<Link onClick={()=>{onRedirectMap()}}
														className="dropdown-item has-icon">
														Map View
													</Link>
												</div>
											</div>
										</div>
									</div>
									<div className="card-body">
									<FilterDisplay filter={{ ...filterObj, ...showfilterObj }} onClearFilter={onClearFilter} /> 
										<div className="table-responsive tw-h-screen tw-max-h-screen">
											<div id="table-1_wrapper" className="dataTables_wrapper no-footer">
												<div className="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select name="table_id_length" aria-controls="table_id" className="" onChange={(e) => (setPageSize(e.target.value))} value={PageSize}>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
												<div id="table-1_filter" className="dataTables_filter"><label className="mr-2">Search:</label><MultiInput
									placeholder="search and enter..."
									onChange={onHandalSearchMulti}
									maxItems={null} // Allow any number of items
									onClear={onItemCleared}
									onClearAll={onClearAllItems}
									onHandalSerch={onHandalSerch}
								/>
								<div className={`ml-2 btn btn-primary ${serachLoader === true ? 'btn-progress disabled' : ''}`} onClick={onHandalSerch} style={{ padding: '0.2rem 0.8rem', top: '0' }}><i className={`fas fa-search `}></i></div></div>
												<table id="table_id" className="tableCustom table table-striped tw-w-full tw-text-sm text-center">
													<thead className='tw-sticky tw-z-50 tw-top-0 tw-text-xs tw-bg-gray-100' style={{height:'80px'}}>
														<tr>
															<th className="text-center"><div className=''><span>Unique No</span> </div><div
																			className='dropdown'
																			id='SRNO'
																			data-toggle="dropdown"
																			aria-haspopup="true"
																			aria-expanded="false"
																			style={{padding:'0',float:'right',color:CostomFilter.col=='SRNO'?'#3abaf4':''}}
																			onClick={(e)=>{setCostomFilter({...CostomFilter,col:'SRNO'})
																			console.log(e)}}
																		><i className={`fas fa-search `} style={{fontSize: '9px',cursor: 'pointer'}} 
																			></i></div><div
																			className="dropdown-menu "
																			x-placement="bottom-start"
																			style={{
																				position: "absolute",
																				transform: "translate3d(-5px, 28px, 0px)",
																				top: "0px",
																				left: "-110px",
																				willChange: "transform",
																			}}
																		><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
										<input type="text" className="form-control" name="filter_hours" id="filter_less_hours123" placeholder="Search Text" onChange={onChangeCostomFilter}/>
									</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter}/></div>	</th>
															<th style={{ minWidth: '200px' }}><div className=''><span>Company Name</span></div><div
																			className="dropdown mt-4"
																			data-toggle="dropdown"
																			aria-haspopup="true"
																			aria-expanded="false"
																			style={{padding:'0',float:'right',color:CostomFilter.col==`JSON_VALUE(ADDRESS,'$.address_compony_name')`?'#3abaf4':''}}
																			onClick={()=>(setCostomFilter({...CostomFilter,col:`JSON_VALUE(ADDRESS,'$.address_compony_name')`}))}
																		><i className={`fas fa-search`} style={{fontSize: '9px',cursor: 'pointer'}}></i></div><div
																			className="dropdown-menu "
																			x-placement="bottom-start"
																			style={{
																				position: "absolute",
																				transform: "translate3d(-5px, 28px, 0px)",
																				top: "0px",
																				left: "-110px",
																				willChange: "transform",
																			}}primary
																		><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
										<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={onChangeCostomFilter}/>
									</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter}/></div></th>
															<th style={{ minWidth: '125px' }}><span>Group</span></th>
															<th><div className=''><span>Customer ID</span></div><div
																			className="dropdown"
																			data-toggle="dropdown"
																			aria-haspopup="true"
																			aria-expanded="false"
																			style={{padding:'0',float:'right',color:CostomFilter.col=='CUSTOMER_ID'?'#3abaf4':''}}
																			onClick={()=>(setCostomFilter({...CostomFilter,col:'CUSTOMER_ID'}))}
																		><i className={`fas fa-search `} style={{fontSize: '9px',cursor: 'pointer'}}></i></div><div
																			className="dropdown-menu "
																			x-placement="bottom-start"
																			style={{
																				position: "absolute",
																				transform: "translate3d(-5px, 28px, 0px)",
																				top: "0px",
																				left: "-110px",
																				willChange: "transform",
																				
																			}}
																		><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
										<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text"/>
									</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter}/></div></th>
															<th><div className=''><span>Installation Number</span></div><div
																			className="dropdown"
																			data-toggle="dropdown"
																			aria-haspopup="true"
																			aria-expanded="false"
																			style={{padding:'0',float:'right',color:CostomFilter.col=='INSTALLATION_NUMBER'?'#3abaf4':''}}
																			onClick={()=>(setCostomFilter({...CostomFilter,col:'INSTALLATION_NUMBER'}))}
																		><i className={`fas fa-search `} style={{fontSize: '9px',cursor: 'pointer'}}></i></div><div
																			className="dropdown-menu "
																			x-placement="bottom-start"
																			style={{
																				position: "absolute",
																				transform: "translate3d(-5px, 28px, 0px)",
																				top: "0px",
																				left: "-110px",
																				willChange: "transform",
																				
																			}}
																		><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
										<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={onChangeCostomFilter}/>
									</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter}/></div></th>
															<th style={{minWidth:'128px'}}><div className=''><span>Business Partner Number</span></div><div
																			className="dropdown"
																			data-toggle="dropdown"
																			aria-haspopup="true"
																			aria-expanded="false"
																			style={{padding:'0',float:'right',color:CostomFilter.col=='BUSINESS_PARTNER_NUMBER'?'#3abaf4':''}}
																			onClick={()=>(setCostomFilter({...CostomFilter,col:'BUSINESS_PARTNER_NUMBER'}))}
																		><i className={`fas fa-search`} style={{fontSize: '9px',cursor: 'pointer'}}></i></div><div
																			className="dropdown-menu "
																			x-placement="bottom-start"
																			style={{
																				position: "absolute",
																				transform: "translate3d(-5px, 28px, 0px)",
																				top: "0px",
																				left: "-110px",
																				willChange: "transform",
																				
																			}}
																		><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
										<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={onChangeCostomFilter}/>
									</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter}/></div></th>
															<th ><div className=''><span>EUI</span></div><div
																			className="dropdown mt-4"
																			data-toggle="dropdown"
																			aria-haspopup="true"
																			aria-expanded="false"
																			style={{padding:'0',float:'right',color:CostomFilter.col=='EUI'?'#3abaf4':''}}
																			onClick={()=>(setCostomFilter({...CostomFilter,col:'EUI'}))}
																		><i className={`fas fa-search `} style={{fontSize: '9px',cursor: 'pointer'}}></i></div><div
																			className="dropdown-menu "
																			x-placement="bottom-start"
																			style={{
																				position: "absolute",
																				transform: "translate3d(-5px, 28px, 0px)",
																				top: "0px",
																				left: "-110px",
																				willChange: "transform",
																				
																			}}
																		><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
										<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={onChangeCostomFilter}/>
									</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter}/></div></th>
															<th ><div className=''><span>Modem Serial No</span></div><div
																			className="dropdown"
																			data-toggle="dropdown"
																			aria-haspopup="true"
																			aria-expanded="false"
																			style={{padding:'0',float:'right',color:CostomFilter.col=='DEVICE_SERIAL_NO'?'#3abaf4':''}}
																			onClick={()=>(setCostomFilter({...CostomFilter,col:'DEVICE_SERIAL_NO'}))}
																		><i className={`fas fa-search `} style={{fontSize: '9px',cursor: 'pointer'}}></i></div><div
																			className="dropdown-menu "
																			x-placement="bottom-start"
																			style={{
																				position: "absolute",
																				transform: "translate3d(-5px, 28px, 0px)",
																				top: "0px",
																				left: "-110px",
																				willChange: "transform",
																				
																			}}
																		><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
										<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={onChangeCostomFilter}/>
									</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter}/></div></th>
															<th style={{minWidth:'125px'}}><div className=''><span>Panel Serial No</span></div><div
																			className="dropdown mt-4"
																			data-toggle="dropdown"
																			aria-haspopup="true"
																			aria-expanded="false"
																			style={{padding:'0',float:'right',color:CostomFilter.col=='PANEL_SERIAL_NO'?'#3abaf4':''}}
																			onClick={()=>(setCostomFilter({...CostomFilter,col:'PANEL_SERIAL_NO'}))}
																		><i className={`fas fa-search `} style={{fontSize: '9px',cursor: 'pointer'}}></i></div><div
																			className="dropdown-menu "
																			x-placement="bottom-start"
																			style={{
																				position: "absolute",
																				transform: "translate3d(-5px, 28px, 0px)",
																				top: "0px",
																				left: "-110px",
																				willChange: "transform",
																				
																			}}
																		><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
										<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={onChangeCostomFilter}/>
									</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter}/></div></th>
															<th ><span>RSSI</span></th>
															<th ><span>SNR</span></th>
															<th ><span>SF</span></th>
															<th style={{ minWidth: '100px' }}><div className=''><span>Last Seen</span></div><div
																			className="dropdown mt-4"
																			data-toggle="dropdown"
																			aria-haspopup="true"
																			aria-expanded="false"
																			style={{padding:'0',float:'right',color:CostomFilter.col=='HEARTBEAT_TS'?'#3abaf4':''}}
																			onClick={()=>(setCostomFilter({...CostomFilter,col:'HEARTBEAT_TS'}))}
																		><i className={`fas fa-search`} style={{fontSize: '9px',cursor: 'pointer'}}></i></div><div
																			className="dropdown-menu "
																			x-placement="bottom-start"
																			style={{
																				position: "absolute",
																				transform: "translate3d(-5px, 28px, 0px)",
																				top: "0px",
																				left: "-110px",
																				willChange: "transform",
																				
																			}}
																		><div className="form-group mb-0 tw-p-2 " style={{ marginTop: -10 }}>
										<input type="radio" className='mr-1' id="checktextLSs" name="check" value={'desc'} onChange={onChangeCostomFilter}/>
										<label for="checktextLSs">Descending</label><br/>
										<input type="radio" className='mr-1' id="checktextLSs2" name="check"  value={'asc'} onChange={onChangeCostomFilter}/>
										<label for="checktextLSs2">Ascending</label>
									</div></div></th>
															
															<th ><span>Status</span></th>
															<th style={{ minWidth: '180px' }}><span>Action</span></th>
															<th style={{ minWidth: '300px' }}><span>Tags</span></th>
															<th><span>Information</span></th>
														</tr>
													</thead>
													<tbody>
														{getwayList.length > 0 && getwayList.map((item, id) => (
															<tr className="trheigth"  onMouseEnter={()=>{
 										var element = document.getElementById(item?.SRNO+'GSM');
   											element.classList.add('within');
   											element.classList.remove('display-icon');
															}} onMouseLeave={()=>{
 										var element = document.getElementById(item?.SRNO+'GSM');
   											element.classList.add('display-icon');
   											element.classList.remove('within');
															}}>
																<td className="GSM_Link">
																<a href={`/view_existing_consumer_lorawan/${item?.UNIQUE_ID}`} target='_blank'>{item?.SRNO}</a>
																<a id={item?.SRNO+'GSM'} className='display-icon' href={`/view_existing_consumer_lorawan/${item?.UNIQUE_ID}`} target='_blank'><img src={exports} style={{height:"10px"}} /></a></td>
																<td ><BootstrapTooltip title={JSON.parse(item?.ADDRESS)?.address_compony_name} placement="top"><div className='show-read-more-name'>{JSON.parse(item?.ADDRESS)?.address_compony_name}</div></BootstrapTooltip></td>
																<BootstrapTooltip title={<div>
																	ACCOUNT:{item?.ACCOUNT_NAME}
																	<br />
																	{JSON.parse(AccountKey.filter((inac)=>(inac?.UNIQUE_ID===item?.CAT0_ID))[0]?.PROFILE_NAME||'{}')?.CATEGORY1||'CATEGORY1'}:{item?.GA_NAME}
																	<br />
																	{JSON.parse(AccountKey.filter((inac)=>(inac?.UNIQUE_ID===item?.CAT0_ID))[0]?.PROFILE_NAME||'{}')?.CATEGORY2||'CATEGORY2'}:{item?.CIRCLE_NAME}
																	<br />
																	{JSON.parse(AccountKey.filter((inac)=>(inac?.UNIQUE_ID===item?.CAT0_ID))[0]?.PROFILE_NAME||'{}')?.CATEGORY3||'CATEGORY3'}:{item?.DIVISION_NAME}
																	<br />
																	{JSON.parse(AccountKey.filter((inac)=>(inac?.UNIQUE_ID===item?.CAT0_ID))[0]?.PROFILE_NAME||'{}')?.CATEGORY4||'CATEGORY4'}:{item?.SUB_DIVISION_NAME}
																	<br />
																	{JSON.parse(AccountKey.filter((inac)=>(inac?.UNIQUE_ID===item?.CAT0_ID))[0]?.PROFILE_NAME||'{}')?.CATEGORY5||'CATEGORY5'}:{item?.AREA_NAME}
																</div>} placement='top'><td><b className='tw-font-semibold'>ACCOUNT:</b><br />{item?.ACCOUNT_NAME}</td></BootstrapTooltip>
																<td>{item?.CUSTOMER_ID}</td>
																<td>{item?.INSTALLATION_NUMBER}</td>
																<td>{item?.BUSINESS_PARTNER_NUMBER}</td>
																
																<td>{item?.IMEI}</td>
																<td>{item?.DEVICE_SERIAL_NO}</td>
																<td>{item?.PANEL_SERIAL_NO}</td>
																<BootstrapTooltip title={`RSSI:${JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI >= 2 && JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI <= 9?'Marginal':JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI >= 10 && JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI <= 14?'Ok':JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI >= 15 && JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI <= 19?'Good':JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI >= 20 && JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI <= 31?'Excellent':''	}`} placement="top">
																	<td className="text-center">
																		{JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI >= 2 && JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI <= 9 ? (
																			<>
																				<img src={signal2} style={{height:'20px',width:"40px",border:'0',boxShadow:'none',borderRadius:'none'}}/>
																				<span className="text-center">{JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI} </span> 
																			</>
																		) : JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI >= 10 && JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI <= 14 ? (
																			<>
																			<img src={signal3} style={{height:'20px',width:"40px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																					<span className="text-center">{JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI} </span> 
																			</>
																		) : JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI >= 15 && JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI <= 19 ? (
																			<>
																			<img src={signal4} style={{height:'20px',width:"40px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																			<span className="text-center">{JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI} </span> 
																			</>
																		) : JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI >= 20 && JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI <= 31 ? (
																			<>
																			<img src={signal5} style={{height:'20px',width:"40px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																			<span className="text-center">{JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LRSSI} </span> 
																			</>
																		) : (
																			"-"
																		)}
																	</td>
																		</BootstrapTooltip>
																<td>{JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LSNR}</td>
																<td>{JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LSF}</td>

																{/* {console.log(getSecondsTodayBydate(item.HEARTBEAT_TS))} */}
																		<BootstrapTooltip title={item.HEARTBEAT_TS?moment.utc(item.HEARTBEAT_TS).format("DD-MM-YYYY hh:mm:ss A"):''} placement="top"><td>{getSecondsTodayBydate(item.HEARTBEAT_TS)<(item.HEARTBEAT_INTERVAL)?<div className="badge badge-success badge-shadow text-white">{item?.HEARTBEAT_TS?moment.utc(item.HEARTBEAT_TS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm'):''}</div>:<div className="badge badge-danger badge-shadow text-white">{item?.HEARTBEAT_TS?moment.utc(item.HEARTBEAT_TS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm'):''}</div>}</td></BootstrapTooltip>
																		
																
																<td className="text-center">
																	<div className={`badge ${item?.CUSTOMER_STATUS === 1 ? 'badge-success' : item?.CUSTOMER_STATUS === -1 ? 'badge-warning' : item?.CUSTOMER_STATUS === 0?'badge-danger':item?.CUSTOMER_STATUS === 2?'badge-info':'badge-dark'} badge-shadow text-white`}>{item?.CUSTOMER_STATUS === 1 ? 'Active' : item?.CUSTOMER_STATUS === -1 ? 'Pending Configration' :  item?.CUSTOMER_STATUS === 0 ?'Inactive':item?.CUSTOMER_STATUS === 2 ?'Under Maintenance':'Stand By'}</div>
																</td>
																<td>{item?.secondary_count?<div className='btn btn-outline-primary' onClick={()=>onGetSecondaryConsumerById(item?.secondary_count)}>Secondary Consumer</div>:''}</td>
																
																<td>
																{item?.TAGS&&item?.TAGS?.length > 0 && item?.TAGS?.map((items) => (
																	<div className={`btn btn-icon btn-sm icon-left  ml-1 mb-1`} style={{ background: items?.TAG_COLOR, color: "white" }}><i className="fa fa-tag fa-lg" style={{ marginRight: '4px' }} ></i><BootstrapTooltip title={items?.TAG_NAME} placement="top"><span className='show-read-more'>{items?.TAG_NAME}</span></BootstrapTooltip></div>
																))}</td>
																<td>
																<BootstrapTooltip title={'Info'} placement="top" className='Direction'><a href="#" className="btn btn-primary btn-sm font-12 mr-1" style={{padding:"0px 4px 0px 4px"}}><i className="fa fa-info-circle " aria-hidden="true" ></i></a></BootstrapTooltip>
                                                                                            {item?.LONGITUDE&&item?.LATITUDE?<><BootstrapTooltip title={'Direction'} placement="top" className='Direction'><a href={`https://map.google.com/?q=${item?.LATITUDE},${item?.LONGITUDE}`} target="_blank" data-toggle="tooltip" data-placement="Top" title="" className="btn btn-success btn-sm text-white font-12 mr-1" data-original-title="Direction" style={{padding:"0px 4px 0px 4px"}}> <i className="fas fa-directions" ></i></a></BootstrapTooltip></>:''}
																							
																							{item?.LONGITUDE&&item?.LATITUDE?<a href={`/gsm-map-view?${item?.UNIQUE_ID}`} target="_blank" className="btn btn-danger btn-sm text-white font-12 " style={{padding:"0px 4px 0px 4px"}} > <i className="fas fa-map-marker-alt"></i></a>:''}
																							
                                                                                       </td>
																{/* <th><BootstrapTooltip title='Info' placement='top'><a className="btn btn-primary btn-sm" style={{ background: '#6777ef', color: 'white', padding: '5px', borderRadius: '5px' }}><i className="fa fa-info-circle" aria-hidden="true"></i></a></BootstrapTooltip></th> */}
															</tr>
														))}


													</tbody>
												</table>
											</div>
										</div>
										<><div className='d-flex justify-content-between'>
											<div>Total Number Of Row {numberofPage}</div><Pagination
												className="pagination-bar"
												currentPage={currentPage}
												totalCount={numberofPage || 1}
												pageSize={PageSize}
												onPageChange={(page) => setCurrentPage(page)}
											/></div></>
									</div>
								</div>
							</div>
						</div>
					</div>}
					{activeTab==='Modem Details'&&<ModemDetails/>}
				</section>
			</div>}
		</>
	)
}
