import React, { useState } from 'react'
import MeterConfigretion from './Configretion/MeterConfigretion';
import ModemConfigretion from './Configretion/ModemConfigretion';
import WebConfig from './Configretion/WebConfig';
import chroma from 'chroma-js';

export default function Configration(props) {
	const buttonArray = ["Modem Configuration", "Meter Configuration","Web Configration"];
	const [btnText, setButtontext] = useState("");
    const onHandalebtn = (text) => {
		setButtontext(text);
	};
  return (
    <>
        <div className="card-body"  style={{ paddingTop: "0px",paddingLeft:'15px',paddingBottom:'5px' }}>
				<div className="d-flex justify-content-between">
					<div className="buttons">
						{buttonArray.map((item, id) => (
							<button
							style={{
								backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#fc544b').alpha(0.4).css() : '',
								color: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#fc544b').darken(1.5).css() : '#fc544b',
								borderBottom: `1px solid ${'#fc544b'}`,
								boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${'#fc544b'}`  : '',
							}}
							className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
							onClick={() => onHandalebtn(item)}>
							{item}
						</button>
						))}
					</div>
                    </div>
                    </div>
					{btnText==="Modem Configuration"&&<ModemConfigretion GSMdata={props.GSMdata}/>}
					{btnText==="Meter Configuration"&&<MeterConfigretion GSMdata={props.GSMdata}/>}
					{btnText==="Web Configration"&&<WebConfig GSMdata={props.GSMdata} onRefreshData={props.onRefreshData}/>}

    </>
  )
}
