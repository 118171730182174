import * as React from 'react';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

const linkify = new LinkifyIt();
linkify.tlds(tlds);

const defaultComponentDecorator = (decoratedHref, decoratedText, key) => (
    <a href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer">
        {decoratedText}
    </a>
);

const defaultHrefDecorator = href => href;

const defaultMatchDecorator = text => linkify.match(text) || [];

const defaultTextDecorator = text => text;

const Linkify = ({
    children,
    componentDecorator = defaultComponentDecorator,
    hrefDecorator = defaultHrefDecorator,
    matchDecorator = defaultMatchDecorator,
    textDecorator = defaultTextDecorator,
}) => {
    const parseString = string => {
        if (string === '') {
            return string;
        }

        const matches = matchDecorator(string);
        if (!matches) {
            return string;
        }

        const elements = [];
        let lastIndex = 0;
        matches.forEach((match, i) => {
            if (match.index > lastIndex) {
                elements.push(string.substring(lastIndex, match.index));
            }

            const decoratedHref = hrefDecorator(match.url);
            const decoratedText = textDecorator(match.text);
            const decoratedComponent = componentDecorator(decoratedHref, decoratedText, i);
            elements.push(decoratedComponent);

            lastIndex = match.lastIndex;
        });

        if (string.length > lastIndex) {
            elements.push(string.substring(lastIndex));
        }

        return elements.length === 1 ? elements[0] : elements;
    };

    const parse = (children, key = 0) => {
        if (typeof children === 'string') {
            return parseString(children);
        } else if (React.isValidElement(children) && children.type !== 'a' && children.type !== 'button') {
            return React.cloneElement(children, { key }, parse(children.props.children));
        } else if (Array.isArray(children)) {
            return children.map((child, i) => parse(child, i));
        }

        return children;
    };

    return <>{parse(children)}</>;
};

export default Linkify;
