import React, { useState } from "react";
// import styled from "styled-components";
import { useTable } from "react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import update from "immutability-helper";

import { HolderOutlined } from "@ant-design/icons";
import { Checkbox, InputNumber, Switch } from "antd";

// const Styles = styled.div`
//   padding: 1rem;

//   table {
//     border-spacing: 0;
//     border: 1px solid black;

//     tr {
//       :last-child {
//         td {
//           border-bottom: 0;
//         }
//       }
//     }

//     th,
//     td {
//       margin: 0;
//       padding: 0.5rem;
//       border-bottom: 1px solid black;
//       border-right: 1px solid black;

//       :last-child {
//         border-right: 0;
//       }
//     }
//   }
// `;

export default function DraggabalTable  ({ columns, data, setitem,onDragEnd }) {
  const [records, setRecords] = React.useState(data||[]);
  const [ShowIn,setShowIn]=useState('')

  const getRowId = React.useCallback((row) => {
    return row.Order;
  }, []);
  React.useEffect(()=>{
    setRecords(data)
  },[data])

  React.useEffect(() => {
    setitem(records)
  }, [records]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    data: data||[],
    columns,
    getRowId
  });

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord]
        ]
      })
    );
    if (typeof onDragEnd === 'function') {
      onDragEnd();
    }
  };
  const onHandalswitch=async(e,col)=>{
    if(e==true){
      setRecords(data.map((item)=>(item?.Column==col?{...item,Display:true}:item)))
    }else{
      setRecords(data.map((item)=>(item?.Column==col?{...item,Display:false}:item)))
    }
}
const onHandalCheckboxDecimal=async(e,col)=>{
  if(e.target.checked==true){
    setRecords(data.map((item)=>(item?.Column==col?{...item,Float:true}:item)))
  }else{
    setRecords(data.map((item)=>(item?.Column==col?{...item,Float:false}:item)))
  }
}

const onHandalCheckboxDivision=async(e,col)=>{
  if(e.target.checked==true){
    setRecords(data.map((item)=>(item?.Column==col?{...item,Division:true}:item)))
  }else{
    setRecords(data.map((item)=>(item?.Column==col?{...item,Division:false}:item)))
  }
}

const onHandalMFCheck=async(e,col)=>{
  if(e.target.checked==true){
    setRecords(data.map((item)=>(item?.Column==col?{...item,MF:true}:item)))
  }else{
    setRecords(data.map((item)=>(item?.Column==col?{...item,MF:false}:item)))
  }
}

const onHandalInput=async(e,col,Type)=>{
    setRecords(data.map((item)=>(item?.Order==col?{...item,[Type]:e.target.value}:item)))
 
}
  return (
    <DndProvider backend={HTML5Backend}>
      <table {...getTableProps()} class="table table-striped fixed_header1" style={{width:'700px'}}>
        <thead>
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th></th>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
              <th>Display Status</th>
              <th style={{ minWidth:'180px' }}>Decimal Number(Fraction Part)</th>
              <th style={{ minWidth:'180px' }}>Value Division Number</th>
              <th style={{ minWidth:'180px' }} className='text-center'>Is Apply MF?</th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map(
            (row, index) =>
              prepareRow(row) || (
                <Row
                  index={index}
                  row={row}
                  moveRow={moveRow}
                  onHandalswitch={(e,col)=>onHandalswitch(e,col)}
                  onHandalCheckboxDivision={(e,col)=>onHandalCheckboxDivision(e,col)}
                  onHandalCheckboxDecimal={(e,col)=>onHandalCheckboxDecimal(e,col)}
                  onHandalMFCheck={(e,col)=>onHandalMFCheck(e,col)}
                  onHandalInput={(e,col,Type)=>onHandalInput(e,col,Type)}
                  setShowIn={(e)=>setShowIn(e)}
                  ShowIn={ShowIn}
                  {...row.getRowProps()}
                />
              )
          )}
        </tbody>
      </table>
    </DndProvider>
  );
};

const DND_ITEM_TYPE = "row";

const Row = ({ row, index, moveRow,onHandalswitch,onHandalCheckboxDivision,onHandalCheckboxDecimal,onHandalInput,setShowIn,ShowIn,onHandalMFCheck }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);
  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);
  return (
    <tr ref={dropRef} style={{ opacity }}>
      <td ref={dragRef} style={{height:'45px'}}><HolderOutlined /></td>
      {row.cells.map((cell,id) => {
        return <td {...cell.getCellProps()} style={{height:'45px'}}>{ShowIn==row?.original?.Column&&row?.cells?.length-1==id?'':cell.render("Cell")}{ShowIn==row?.original?.Column&&row?.cells?.length-1==id?'':row?.cells?.length-1==id?<span onClick={()=>setShowIn(row?.original?.Column)}><i className="fas fa-pen ml-2"  style={{cursor:'pointer'}}/></span>:''}{ShowIn==row?.original?.Column&&row?.cells?.length-1==id?<><input type='text' onChange={(e)=>(onHandalInput(e,row?.original?.Order,"Header"))} value={row?.original?.Header}/><span className='ml-2' onClick={()=>setShowIn('')} style={{cursor:'pointer'}}><i className="fas fa-times"/></span></>:''}</td>;
      })}
      <td style={{height:'45px'}} className='costom-switch'><Switch size="small" checked={row?.original?.Display} onChange={(e)=>(onHandalswitch(e,row?.original?.Order))}/></td>
      <td style={{height:'45px'}} className='costom-switch text-center'><Checkbox  checked={row?.original?.Float?row?.original?.Float:false} onChange={(e)=>(onHandalCheckboxDecimal(e,row?.original?.Order))} disabled={row?.original?.DataType=='DATETIME'?true:false} className='mr-2'/>{row?.original?.Float==true?<InputNumber min={0} max={10} onChange={(ei)=>onHandalInput({target:{value:ei}},row?.original?.Order,"FloatPoint")} value={row?.original?.FloatPoint}/>:''}</td>
      <td style={{height:'45px'}} className='costom-switch text-center'><Checkbox  checked={row?.original?.Division?row?.original?.Division:false} onChange={(e)=>(onHandalCheckboxDivision(e,row?.original?.Order))} disabled={row?.original?.DataType=='DATETIME'?true:false}  className='mr-2'/>{row?.original?.Division==true?<InputNumber onChange={(ei)=>onHandalInput({target:{value:ei}},row?.original?.Order,"DivisionNumber")} value={row?.original?.DivisionNumber}/>:''}</td>
      <td style={{height:'45px'}} className='costom-switch text-center'><Checkbox checked={row?.original?.MF?row?.original?.MF:false} onChange={(e)=>(onHandalMFCheck(e,row?.original?.Order))} disabled={row?.original?.DataType=='DATETIME'?true:false} className='mr-2'/></td>
    </tr>
  );
};