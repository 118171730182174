import React, { useEffect, useState } from 'react'
import { GeflowEncodeRegister } from '../../../Commoncomponet/Utils'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'
import exports from '../../../assets/img/export.png'
import { BootstrapTooltip } from '../../../Commoncomponet/common'

export default function Integration_structure() {
  const [structure_data, setstructure_data] = useState([])
  const history = useHistory()
  const ongetEncodeRegister = async () => {
    const data = await GeflowEncodeRegister()
    if (data.success == true) {
      setstructure_data(data?.data?.data)
    } else {
      console.log('Server Issue')
    }
  }

  useEffect(() => {
    ongetEncodeRegister()
  }, [])

  const RedirectLink = (item) => {
    history.push({ pathname: `/Add-Update-integration-Structure`, state: item })
  }

  return (
    <>
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header ">
                  <h4><BootstrapTooltip title={`Table Name is integration_structure_register`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
                  <div className="card-header-action">
                    <Link to='/Add-Update-integration-Structure'>
                      <div className='btn btn-primary'>+ Add New</div>
                    </Link>
                  </div>
                </div>
                <div className='card-body'>
                  <div className="table-responsive">
                    <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                      <div class="dataTables_length" id="table_id_length">

                      </div>

                      <table id="table_id" className="tableCustom table table-striped">
                        <thead>
                          <tr >
                            <th className="text-center" >Srno</th>
                            <th style={{ minWidth: '200px' }}>Unique Id</th>
                            <th style={{ minWidth: '125px' }}>Name</th>
                            <th>Description</th>
                            <th>JSON Type</th>
                            <th>Created At</th>
                            <th>Created By</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {structure_data.length > 0 && structure_data.map((item, id) => (
                            <tr className="trheigth" onMouseEnter={() => {
                              var element = document.getElementById(item?.u_sr_no + 'GSM');
                              element.classList.add('within');
                              element.classList.remove('display-icon');
                            }} onMouseLeave={() => {
                              var element = document.getElementById(item?.u_sr_no + 'GSM');
                              element.classList.add('display-icon');
                              element.classList.remove('within');
                            }}>
                              <td className="GSM_Link"  onClick={() => (RedirectLink(item))}>
                                <a onClick={() => (RedirectLink(item))}>{item?.u_sr_no}</a>
                                <a id={item?.u_sr_no + 'GSM'} className="display-icon" onClick={() => (RedirectLink(item))}><img src={exports} style={{ height: "10px" }} /></a></td>
                              <td>{item?.unique_id}</td>
                              <td>{item?.name}</td>
                              <td>{item?.description}</td>
                              <td>{item?.json_type==1?'Standard JSON':item?.json_type==2?'OPC JSON':''}</td>
                              <td>{moment.utc(item?.created_at).format('DD-MM-YYYY hh:mm:ss A')}</td>
                              <td>{item?.created_by}</td>
                              <td className="mt-0 d"><div className={`ml-2 badge badge-${item?.status === 1 ? 'success' : item?.status === 0 ? 'danger' : 'warning'}`}>{item?.status === 1 ? 'Active' : item?.status === 0 ? 'InActive' : 'Pending'}</div></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
