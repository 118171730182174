import React, { useEffect, useState } from 'react'
import { BootstrapTooltip } from '../../../Commoncomponet/common'
import { Link,useHistory } from "react-router-dom";
import { getRemoteMobileDevice } from '../../../Commoncomponet/Utils';

export default function RemoteMobileDevice() {

    const [Devicedata,setDeviceData]=useState([])
    const history=useHistory()

    const onGetRemoteMobileDevice=async()=>{
        const data=await getRemoteMobileDevice()
        if(data.success==true){
            setDeviceData(data.data.data)
        }else{
            console.log('Server Issue')
        }
    }

    useEffect(()=>{
        onGetRemoteMobileDevice()
    },[])

    const handleRowClick = (item) => {
		history.push({ pathname: `/add-remote-mobile-device`, state: { ...item } })
	}
  return (
    <>
    <div className="row">
						<div className="col-12">
							<div className="card">
							<div className="card-header ">
							<h4>Remote Mobile Device</h4>
							<div className="card-header-action">
											<Link to='/add-remote-mobile-device' className="btn btn-info text-white mr-2">
												<BootstrapTooltip title="Add">
													<span>
														<i
															className="fas fa-plus  text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</Link>
							</div>
							</div>
								<div className="card-body">
									<div className="table-responsive">
										<div id="table-1_wrapper" className="dataTables_wrapper no-footer">
											<div className="dataTables_length" id="table_id_length">
												<label>
													Show{" "}
													<select name="table_id_length" aria-controls="table_id" className="">
														<option value={10}>10</option>
														<option value={25}>25</option>
														<option value={50}>50</option>
														<option value={100}>100</option>
													</select>{" "}
													entries
												</label>
											</div>
											<table id="table_id" className="tableCustom table table-striped">
												<thead>
													<tr>
															<th class="GSM_Link">Unique Id</th>
															<th>
																IMEI
															</th>
															<th class="" data-mui-internal-clone-element="true">
																Users List
															</th>
															<th>Modem Type</th>
															<th>Created At</th>
															<th>Created By</th>
                                                            <th>Updated At</th>
															<th>Updated By</th>
															<th class="text-center"> Status</th>
													</tr>
												</thead>
												<tbody>
                                                {Devicedata.length>0&&Devicedata?.map((item)=>(
                                                    <tr>
                                                        <td class="tw-px-2 tw-py-3 tw-text-center tw-text-blue-600 tw-cursor-pointer tw-group" onClick={() => handleRowClick(item)}>
                                                            <div className='tw-flex tw-items-center  tw-gap-2 tw-mx-2'>
                                                                <span>{item?.u_sr_no}</span>
                                                                <div className="tw-opacity-0 tw-transition-all group-hover:tw-opacity-100" >

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" id="right-arrow"><g data-name="Layer 2"><g data-name="arrowhead-right"><path fill='currentColor' d="m18.78 11.37-4.78-6a1 1 0 0 0-1.41-.15 1 1 0 0 0-.15 1.41L16.71 12l-4.48 5.37a1 1 0 0 0 .13 1.41A1 1 0 0 0 13 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 .01-1.27z"></path><path fill='currentColor' d="M7 5.37a1 1 0 0 0-1.61 1.26L9.71 12l-4.48 5.36a1 1 0 0 0 .13 1.41A1 1 0 0 0 6 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 0-1.27z"></path></g></g></svg>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    <td>{item?.imei}</td>
                                                    <td>{item?.user_id}</td>
                                                    <td>{item?.modem_profile_name}</td>
                                                    <td>{item?.created_at}</td>
                                                    <td>{item?.created_by_name}</td>
                                                    <td>{item?.updated_at}</td>
                                                    <td>{item?.updated_by}</td>
                                                    <td>{item?.status}</td>
                                            </tr>
                                                ))}
													
                         
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div></>
  )
}
