import moment from "moment";
import React,{useEffect} from "react";

import {Switch,Route,Redirect,withRouter, useHistory} from "react-router-dom"
import { CheckValidUser } from "./Utils";
import { socket } from "./common";

const PrivetRoute = ({path,Component}) =>{
  const history=useHistory()
  let token =window.localStorage.getItem("token");
  let logintime =window.localStorage.getItem("login_time");
  let user_info =window.localStorage.getItem("user_info");
  const current_time=moment().utcOffset("+05:30")
  useEffect(()=>{ 
    const diff_hours=current_time.diff(moment(logintime).utcOffset("+05:30"),'hours')
      console.log(current_time.diff(moment(logintime).utcOffset("+05:30"),'hours'))
      if(logintime==NaN||logintime==undefined||diff_hours>=24){
        window.localStorage.setItem('token', '')
        window.localStorage.setItem('user_info', JSON.stringify({}))
        window.localStorage.setItem('login_time','')
        history.push('/')
      }
      onHandalCheckValidUses()
      socket.on('CheckLoginUseValid',(massges)=>{
        console.log(massges,JSON.parse(user_info||'{}'));
        if(massges?.UNIQUE_ID==JSON.parse(user_info||'{}')?.UNIQUE_ID){
          onHandalCheckValidUses()
        }
      })
    },[])

    const onHandalCheckValidUses=async()=>{
      const data=await CheckValidUser(JSON.parse(user_info||'{}'))
      if(data?.success==true){

      }else if(data?.err?.response?.status===400){
        window.localStorage.setItem('token', '')
        window.localStorage.setItem('user_info', JSON.stringify({}))
        window.localStorage.setItem('login_time','')
        history.push('/')
      }
    }
    return(
      <>
      
    {token&&token!=="" ? <Route  
        path={path}
        component={Component}
        exact
        />
        :
        <Redirect push to="/" />
        }
      </>
    )
  }
  
  export default withRouter(PrivetRoute)