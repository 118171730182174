import React, { useEffect, useRef, useState } from 'react';
import { Modal, Tag } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// import initialModems from './data/Modemdata.json';
// import initialFirmware from './data/ModemFirmware.json';
import { Button } from 'react-bootstrap'
import axios from 'axios';
import './table.css'
import { FiEdit, FiFilter } from "react-icons/fi";
import { CgSpinnerTwo } from "react-icons/cg";
import { addTransciverData, getAllModem, getFilterData, getModemFirmware, getTransciverDocument, updateDocumentByUniqueId } from '../../../Commoncomponet/Utils';


export default function Transceiver() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [dataLists, setDataList] = useState([]);
    const [filterData, setFilterData] = useState();
    const [editData, setEditData] = useState();
    const [initialModems,setinitialModems]=useState([])
    const [initialFirmware,setinitialFirmware]=useState([])
    let cmd_description = useRef(null);
    let response_description = useRef(null);
    let cmd_example = useRef(null);
    let response_example = useRef(null);
	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));


    const fetchData = async () => {
        const data=await getTransciverDocument()
        if(data.success==true){
                console.log(data);
                setDataList(data.data.data);
            }else{
                console.error('Failed to fetch data:',data);
           }
    };

    useEffect(() => {
        fetchData();
        geModemData()
        ongetModemFirmwareData()
    }, []);

    const handleDataOnsubmit = async (data) => {
        console.log(data)
        data['unique_id'] = `MTD${Date.now()}`
        data['created_by'] = userInfo?.UNIQUE_ID
        data['updated_by'] = userInfo?.UNIQUE_ID
        data['status'] = data?.status?.value;
        data['modem_firmware']= data.modem_firmware.map(item => item?.value)
        data['modem_type']= data.modem_type.map(item => item?.value)

        if(editData){
            const result=await updateDocumentByUniqueId(data,editData.data.unique_id)
            if(result.success==true){
                fetchData();
                setIsModalOpen(false);
            }else{
                console.error('Error:', result.message);
            }
            
        }else{
            const result=await addTransciverData(data)
            if(result.success==true){
                fetchData();
                setIsModalOpen(false);
            }else{
                console.error('Error:', result.message);
            }
            
        }
    };

    const handleFilter = async (data) => {
        const result=await getFilterData(data)
       if(result.success==true){
                setDataList(result.data.data);
                setFilterData(data)
       }else{
            console.error('Error:', result.message);
        }
    }

    const geModemData=async()=>{
        const data6 = await getAllModem();
        if (data6.success === true) {
            console.log(data6.data.data)
            setinitialModems(data6.data.data);
        }else{
            console.error('Error:', data6.message);
        }
    }

    const ongetModemFirmwareData = async () => {
		const data = await getModemFirmware()
		if (data.success === true) {
            console.log(data.data.data)

			setinitialFirmware(data.data.data)
		} else {
			console.log('somthing Went wrong')
		}
	}

    const handleEditData = (data) => {
        setEditData(data);
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setEditData(null);
    };

    // =============== filter ==========

    const handleFilterModal = () => {
        setIsFilterModalOpen(false);
    }

    const clearFilter = () => {
        setFilterData();
        fetchData();
    }

    return (
        <div className=' tw-w-full transceiver-ui' >
            <div className='card tw-p-4'>
                <div className='tw-flex tw-items-center tw-justify-between'>
                    <h4 className='tw-capitalize tw-text-2xl tw-font-bold' >transceiver documents</h4>
                    <div className='tw-text-end tw-mb-6 tw-flex tw-items-center tw-gap-2'>
                        <button type="button" className="btn btn-danger" onClick={() => setIsFilterModalOpen(true)} >Filter<FiFilter className='tw-ml-1' /> </button>
                        <Button type="primary" onClick={() => setIsModalOpen(true)}>
                            Add +
                        </Button>
                    </div>
                </div >
                {
                    filterData && <div className='tw-flex tw-flex-wrap tw-gap-3 tw-mb-5' >
                        <FilterBar data={filterData} />
                        <button onClick={clearFilter}className='tw-inline-block tw-bg-red-100 tw-whitespace-nowrap tw-rounded-md tw-text-xs tw-px-2 tw-py-1 border !tw-border-red-500 hover:tw-bg-red-200 tw-transition-all'>
                            Clear Filter
                        </button>
                    </div>
                }
                <div className="accordion !tw-mb-0" id="accordionExample">
                    {dataLists && dataLists.length > 0 ? (
                        dataLists.map((datalist, index) => (
                            <div key={index} className="card  !tw-mb-3 !tw-rounded-md">
                                <div className="card-header !tw-px-1 " id={`heading${index}`}>
                                    <h2 className="mb-0 tw-flex tw-w-full tw-items-center tw-justify-between  ">
                                        <button
                                            className="btn btn-block text-left tw-px-2 tw-w-full"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target={`#collapse${index}`}
                                            aria-expanded="false"
                                            aria-controls={`collapse${index}`}
                                        >
                                            
                                            <span className="tw-text-lg tw-font-semibold tw-flex tw-items-center tw-gap-x-2 ">
                                            <span className="" >{`${index + 1} )` }</span>
                                                {datalist.cmd_title}
                                                
                                            </span>
                                        </button>
                                        <div className='tw-flex tw-items-center tw-gap-x-2 tw-justify-center' >
                                            {
                                                datalist?.status == "1"
                                                    ? <Tag style={{ fontSize: '14px', padding: '4px 8px', borderRadius: '10px' }} color="#87d068">Active</Tag>
                                                    : <Tag style={{ fontSize: '14px', padding: '4px 8px', borderRadius: '10px' }} color="#f50">InActive</Tag>
                                            }
                                            <button
                                                onClick={() => handleEditData({ data: datalist, unique_id: datalist.unique_id })}
                                                className="btn btn-primary tw-flex tw-items-center tw-justify-center tw-h-9 tw-w-9 !tw-p-0 tw-border-none !tw-mt-0"
                                                type="button"
                                            >

                                                <FiEdit className="tw-h-4 tw-w-4" />
                                            </button>
                                        </div>
                                    </h2>
                                </div>
                                <div id={`collapse${index}`} className="collapse border-top tw-border-gray-800" aria-labelledby={`heading${index}`} data-parent="#accordionExample">
                                    <div className="card-body !tw-pt-2 tw-flex tw-flex-col tw-gap-y-2">
                                        {/* <DataBody title={'Title'} content={datalist?.cmd_title} /> */}
                                        <DataBody title={'Command  Description'} content={datalist?.cmd_description} />
                                        <DataBody title={'Response description'} content={datalist?.response_description} />
                                        <DataBody title={'Command Example'} content={datalist?.cmd_example} />
                                        <DataBody title={'Command Response Example'} content={datalist?.response_example} />
                                        <DataBody title={'Modem Type'} content={JSON.parse(datalist?.modem_type||'[]')} type='modem' show_data={initialModems} />
                                        <DataBody title={'modem firmware'} content={JSON.parse(datalist?.modem_firmware||'[]')} type='firmwar' show_data={initialFirmware}/>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <h4 className="tw-text-center tw-font-bold">Data Not Found</h4>
                    )}
                </div>
                <AddList editData={editData?.data} cmd_description={cmd_description} response_description={response_description} response_example={response_example} cmd_example={cmd_example} handleEditData={handleEditData} isModalOpen={isModalOpen} handleCancel={handleCancel} handleDataOnsubmit={handleDataOnsubmit} initialModems={initialModems} initialFirmware={initialFirmware}/>
                <FilterModal isFilterModalOpen={isFilterModalOpen} handleFilterModal={handleFilterModal} handleFilter={handleFilter} initialModems={initialModems} initialFirmware={initialFirmware}/>
            </div>
        </div>
    );
}

function AddList({ editData, isModalOpen, handleCancel, handleDataOnsubmit, cmd_description, response_description, response_example, cmd_example ,initialModems,initialFirmware}) {

    const [isLoading, setIsLoading] = useState(true);
    const [modems, setModems] = useState(initialModems);
    const [firmware, setFirmware] = useState(initialFirmware);
    const [isEditorReady, setIsEditorReady] = useState(false)
    useEffect(()=>(
        setModems(initialModems)
    ),[initialModems])

    useEffect(()=>(
        setFirmware(initialFirmware)
    ),[initialFirmware])
    const formSchema = yup.object({
        cmd_title: yup.string().required('Title is required'),
        modem_type: yup.array().typeError("Atleast one Modemdata selected ").min(1, "Atleast one Modemdata selected"),
        modem_firmware: yup.array().typeError("Atleast one Modem Firmware selected").min(1, "Atleast one Modem Firmware selected"),
    }).required();

    const { register, handleSubmit, setValue, reset, formState: { errors }, control } = useForm({
        resolver: yupResolver(formSchema)
    });

    useEffect(() => {
        if (!isModalOpen) {
            reset({
                cmd_title: '',
                modem_type: [],
                modem_firmware: [],
                status: [],
            })
            reset();
            setIsLoading(true)
        }
    }, [isModalOpen, reset]);

    useEffect(() => {
        if (isModalOpen && editData) {
            setValue("cmd_title", editData?.cmd_title)
            setValue("modem_type",modems.filter((item)=>(JSON.parse(editData?.modem_type||'[]').includes(item?.MODEM_UNIQUE_ID))).length>0?modems.map((item)=>JSON.parse(editData?.modem_type||'[]').includes(item?.MODEM_UNIQUE_ID)?{label:item?.MODEM_PROFILE_NAME,value:item?.MODEM_UNIQUE_ID}:null) :[])
          
            setValue("modem_firmware",firmware.filter((item)=>(JSON.parse(editData?.modem_firmware||'[]').includes(item?.MODEM_FIRMWARE_UNIQUE_ID))).length>0?firmware.map((item)=>JSON.parse(editData?.modem_firmware||'[]').includes(item?.MODEM_FIRMWARE_UNIQUE_ID)?{label:item?.MODEM_FIRMWARE_NAME,value:item?.MODEM_FIRMWARE_UNIQUE_ID}:null) :[])
            console.log(firmware.filter((item)=>(JSON.parse(editData?.modem_firmware||'[]').includes(item?.MODEM_FIRMWARE_UNIQUE_ID))).length>0?firmware.map((item)=>JSON.parse(editData?.modem_firmware||'[]').includes(item?.MODEM_FIRMWARE_UNIQUE_ID)?{label:item?.MODEM_FIRMWARE_NAME,value:item?.MODEM_FIRMWARE_UNIQUE_ID}:null) :[])
            setValue("status", { label: editData?.status==1?'Active':'InActive', value: editData?.status })
            if (isEditorReady) {
                callOnReady();
            }
        };
    }, [isModalOpen, editData, setValue]);

    function callOnReady() {
        if (editData) {
            cmd_description?.current.editor.setData(editData?.cmd_description);
            response_description?.current.editor.setData(editData?.response_description);
            cmd_example?.current.editor.setData(editData?.cmd_example);
            response_example?.current.editor.setData(editData?.response_example);
            setIsEditorReady(true)
        }
    }

    const clearEditor = () => {
        cmd_description.current.editor.setData('')
        response_description.current.editor.setData('')
        cmd_example.current.editor.setData('')
        response_example.current.editor.setData('')
    };

    const handleCancelModal = () => {
        clearEditor();
        handleCancel();
    };

    const submitHandler = (data) => {
        setIsLoading(false)
        handleDataOnsubmit(data);
        clearEditor();
    };

    return (
        <Modal
            title={editData ? 'Edit Transceiver Documents' : "Add Transceiver Documents"}
            visible={isModalOpen}
            onCancel={handleCancelModal}
            width={920}
            maskClosable={false}
            footer={[
                <div className='tw-flex tw-gap-x-3 tw-items-center'>
                    <button type='button' className='btn btn-danger' onClick={handleSubmit(submitHandler)}>{isLoading ? editData ? "Edit" : "Add" : <CgSpinnerTwo className="tw-size-5 tw-animate-spin" />}</button>
                    <button type='button' className="btn btn-light text-dark" onClick={handleCancelModal}>Cancel</button>
                </div>
            ]}
        >
            <form onSubmit={handleSubmit(submitHandler)}>
                <div className='tw-flex tw-flex-col tw-gap-y-2' >
                    <div >
                        <FormField label="Title">
                            <div className='tw-w-[79%]' >
                                <input {...register("cmd_title")} type="text" className="form-control" placeholder="" />
                            </div>
                        </FormField>
                        {errors?.cmd_title && <p className='tw-text-sm tw-font-bold tw-w-[79%] tw-ml-auto tw-text-red-600 ' >{errors?.cmd_title.message}</p>}
                    </div>


                    <CKEditorFormField
                        label="CMD Description"
                        name="cmd_description"
                        editorRef={cmd_description}
                        setValue={setValue}
                        callOnReady={callOnReady}
                        placeholder="CMD Description"
                    />

                    <CKEditorFormField
                        label="Response Description"
                        name="response_description"
                        editorRef={response_description}
                        setValue={setValue}
                        callOnReady={callOnReady}
                        placeholder="Response description"
                    />

                    <CKEditorFormField
                        label="CMD Example"
                        name="cmd_example"
                        editorRef={cmd_example}
                        setValue={setValue}
                        callOnReady={callOnReady}
                        placeholder="CMD Example"
                    />

                    <CKEditorFormField
                        label="CMD Response Example"
                        name="response_example"
                        editorRef={response_example}
                        setValue={setValue}
                        callOnReady={callOnReady}
                        placeholder="CMD Response Example"
                    />

                    <div >
                        <FormField label='modem type'>
                            <div className="tw-w-[79%]" >
                                <Controller
                                    name="modem_type"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            isMulti
                                            {...field}
                                            options={modems.map((modem) => ({
                                                label: modem.MODEM_PROFILE_NAME,
                                                value: modem.MODEM_UNIQUE_ID
                                            }))}
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.label}
                                            isClearable
                                        />
                                    )}
                                />
                            </div>
                        </FormField>
                        {errors?.modem_type && <p className='tw-text-sm tw-font-bold tw-w-[79%] tw-ml-auto tw-text-red-600 ' >{errors?.modem_type.message}</p>}
                    </div>
                    <div>
                        <FormField label='modem firmware'>
                            <div className="tw-w-[79%]" >
                                <Controller
                                    name="modem_firmware"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            isMulti
                                            {...field}
                                            options={firmware.map((fw) => ({
                                                label: fw.MODEM_FIRMWARE_NAME,
                                                value: fw.MODEM_FIRMWARE_UNIQUE_ID,
                                            }))}
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.label}
                                            isClearable
                                        />
                                    )}
                                />
                            </div>
                        </FormField>
                        {errors?.modem_firmware && <p className='tw-text-sm tw-font-bold tw-w-[79%] tw-ml-auto tw-text-red-600 ' >{errors?.modem_firmware.message}</p>}
                    </div>

                    {
                        editData && <div>
                            <FormField label='status'>
                                <div className="tw-w-[79%]" >
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={[
                                                    { label: "Active", value: "1" },
                                                    { label: "InActive", value: "0" }
                                                ]}
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.label}
                                                isClearable
                                            />
                                        )}
                                    />
                                </div>
                            </FormField>
                        </div>
                    }

                </div>
            </form>
        </Modal>
    );
}

function FilterModal({ isFilterModalOpen, handleFilterModal, handleFilter,initialModems,initialFirmware }) {

    const [modems, setModems] = useState(initialModems);
    const [firmware, setFirmware] = useState(initialFirmware);

    const { register, handleSubmit, setValue, reset, control } = useForm()
    useEffect(()=>(
        setModems(initialModems)
    ),[initialModems])

    useEffect(()=>(
        setFirmware(initialFirmware)
    ),[initialFirmware])
    const submitHandler = (data) => {
        if (data.modem_firmware.length > 0 || data.modem_type.length > 0 || Object.keys(data.status).length > 0) {
            handleFilter({ ...data, status: data?.status?.value,modem_firmware:data.modem_firmware.map(item => item?.value),modem_type:data.modem_type.map(item => item?.value) });
            handleFilterModal();
        }
    };

    const handleFilterModals = () => {
        handleFilterModal();
    };

    useEffect(() => {
        if (!isFilterModalOpen) {
            // reset({
            //     modem_type: [],
            //     modem_firmware: [],
            //     status: [],
            // })
        }
    }, [isFilterModalOpen])

    return (
        <Modal
            title={'Filter Transceiver Documents'}
            visible={isFilterModalOpen}
            onCancel={handleFilterModals}
            width={720}
            maskClosable={false}
            footer={[
                <div className='tw-flex tw-gap-x-3 tw-items-center'>
                    <button type='button' className='btn btn-danger' onClick={handleSubmit(submitHandler)}>Filter</button>
                    <button type='button' className="btn btn-light text-dark" onClick={handleFilterModals}>Cancel</button>
                </div>
            ]}
        >
            <form onSubmit={handleSubmit(submitHandler)}>
                <div className='tw-flex tw-flex-col tw-gap-y-5' >
                    <div >
                        <FormField label='modem type'>
                            <div className="tw-w-[79%]" >
                                <Controller
                                    name="modem_type"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            isMulti
                                            {...field}
                                            options={modems.map((modem) => ({
                                                label: modem.MODEM_PROFILE_NAME,
                                                value: modem.MODEM_UNIQUE_ID
                                            }))}
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.label}
                                            isClearable
                                        />
                                    )}
                                />
                            </div>
                        </FormField>
                    </div>
                    <div>
                        <FormField label='modem firmware'>
                            <div className="tw-w-[79%]" >
                                <Controller
                                    name="modem_firmware"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            isMulti
                                            {...field}
                                            options={firmware.map((fw) => ({
                                                label: fw.MODEM_FIRMWARE_NAME,
                                                value: fw.MODEM_FIRMWARE_UNIQUE_ID,
                                            }))}
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.label}
                                            isClearable
                                        />
                                    )}
                                />
                            </div>
                        </FormField>
                    </div>
                    <div>
                        <FormField label='status'>
                            <div className="tw-w-[79%]" >
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={[
                                                { label: "Active", value: "1" },
                                                { label: "InActive", value: "0" }
                                            ]}
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.value}
                                            isClearable
                                        />
                                    )}
                                />
                            </div>
                        </FormField>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

function FilterBar({ data }) {
    console.log(Object.entries(data))
    return (
        <>
            {
                Object.entries(data).map(([key, values]) =>
                    < >
                        {
                            typeof values === "object" && values.length > 0 &&
                            <div className='' >
                                <span className='tw-font-bold tw-mr-2 tw-capitalize ' >{key.split('_').join(" ")} :</span>
                                {
                                    values.map((value) => (
                                        <span className='tw-text-xs border tw-py-1 tw-px-2 tw-inline-block !tw-border-indigo-600 tw-rounded tw-mx-1' >{value}</span>
                                    ))
                                }
                            </div>
                        }
                        {
                            typeof values === "string" && values &&
                            <div>
                                <span className='tw-font-bold tw-mr-2 tw-capitalize ' >{key.split('_').join(" ")} :</span>
                                <span className='tw-text-xs border tw-py-1 tw-px-2 tw-inline-block !tw-border-indigo-600 tw-rounded tw-mx-1' >{values}</span>
                            </div>
                        }

                    </>
                )
            }
        </>
    )
}

function FormField({ label, children }) {
    return (
        <div className="tw-mb-1 tw-flex tw-items-center tw-justify-between">
            <label className="tw-mr-2 tw-mb-0 form-label tw-w-1/5 tw-flex tw-items-center tw-capitalize tw-justify-between">{label}<span>:</span></label>
            {children}
        </div>
    );
}

function CKEditorFormField({ label, name, editorRef, setValue, placeholder, callOnReady }) {
    return (
        <FormField label={label}>
            <div className="tw-w-[79%]">
                <CKEditor
                    editor={ClassicEditor}
                    ref={editorRef}
                    config={{
                        placeholder: placeholder,
                        toolbar: { removeItems: ['uploadImage'] }
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setValue(name, data);
                    }}
                    onReady={() => {
                        callOnReady();
                    }}
                />
            </div>
        </FormField>
    );
}

function DataBody({ title, content,type,show_data }) {
  return (
    <div className='tw-grid tw-grid-cols-12 tw-gap-x-8 tw-pb-2  border-bottom'>
      <div className='tw-col-span-2  tw-py-3 tw-flex tw-justify-between tw-items-center tw-capitalize border-right !tw-border-gray-300 tw-font-bold '>{title}</div>
      {
        typeof content === 'string' ?
          <div className='tw-col-span-10 tw-py-3 ' dangerouslySetInnerHTML={{ __html: content }} />
          :
          <div className='tw-col-span-10 tw-flex tw-items-center tw-gap-2 tw-flex-wrap'>
            {
              [...(show_data)].map((info) => {
                return (
                    content.includes(type=='modem'?info?.MODEM_UNIQUE_ID:info?.MODEM_FIRMWARE_UNIQUE_ID)? <span key={type!='modem'?info?.MODEM_FIRMWARE_NAME:info?.MODEM_PROFILE_NAME} className='tw-bg-gray-200 tw-rounded-sm tw-px-2 tw-py-1' >{type!='modem'?info?.MODEM_FIRMWARE_NAME : info?.MODEM_PROFILE_NAME}</span>:''
                )
              }
              )
            }

          </div>
      }

    </div>
  )

}