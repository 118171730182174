import React, { useState } from "react";
import { Users } from "react-feather";
import { Link } from "react-router-dom";
import { BootstrapTooltip } from "../../Commoncomponet/common";
import MeterConsumer from "./MeterConsumption/MeterConsumer";
import RemoteMobileDevice from "./RemoteMobileDevice/RemoteMobileDevice";
import chroma from "chroma-js";
import UnknownMeterData from "./MeterData/UnknownMeterData";

export default function Mobileapp() {
	const [activeTab, setActiveTab] = useState("Meter Consumer");
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']

	return (
		<>
			<div className="main-content">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb ">
						<li className="breadcrumb-item">
							<Link to="/all-deshboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link>
								<Users style={{ height: "16px" }} /> Consumers
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/All-GSM">Mobile App</Link>
						</li>
					</ol>
				</nav>

				<section class="section">
					<div class="section-body">
						<div class="row">
							<div class="col-12 col-sm-12 col-lg-12 tw-p-0">
								<div class="card">
									<div class="card-header d-flex tw-border-b-0 " style={{ justifyContent: "space-between" }}>
										<ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "15px", alignItems: "center" }}>
											<li>
												<button
													style={{
														backgroundColor: activeTab === "Meter Consumer" ? chroma(btnColor[0]).alpha(0.4).css() : '',
														color:activeTab === "Meter Consumer" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
														borderBottom: `1px solid ${btnColor[0]}`,
														boxShadow: activeTab === "Meter Consumer" ? `0 2px 8px -3px ${btnColor[0]}` : '',
													}}
														className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "GSM" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
													data-toggle="tab"
													href="#home"
													role="tab"
													aria-controls="home"
													aria-selected="true"
													onClick={() => setActiveTab("Meter Consumer")}
												>
													Meter Consumer
												</button>
											</li>
											<li>
												<button
													style={{
														backgroundColor: activeTab === "Remote Mobile Device" ? chroma(btnColor[1]).alpha(0.4).css() : '',
														color:activeTab === "Remote Mobile Device" ? chroma(btnColor[1]).darken(1.5).css() : btnColor[1],
														borderBottom: `1px solid ${btnColor[1]}`,
														boxShadow: activeTab === "Remote Mobile Device" ? `0 2px 8px -3px ${btnColor[1]}` : '',
													}}
														className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Remote Mobile Device" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
													data-toggle="tab"
													href="#home"
													role="tab"
													aria-controls="home"
													aria-selected="true"
													onClick={() => setActiveTab("Remote Mobile Device")}
												>
													Remote Mobile Device
												</button>
											</li>
											<li>
												<button
													style={{
														backgroundColor: activeTab === "Meter Data" ? chroma(btnColor[2]).alpha(0.4).css() : '',
														color:activeTab === "Meter Data" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
														borderBottom: `1px solid ${btnColor[2]}`,
														boxShadow: activeTab === "Meter Data" ? `0 2px 8px -3px ${btnColor[2]}` : '',
													}}
														className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Meter Data" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
													data-toggle="tab"
													href="#home"
													role="tab"
													aria-controls="home"
													aria-selected="true"
													onClick={() => setActiveTab("Meter Data")}
												>
													Meter Data
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					{activeTab=='Meter Consumer'&&<MeterConsumer/>}
					{activeTab=='Remote Mobile Device'&&<RemoteMobileDevice/>}
					{activeTab=='Meter Data'&&<UnknownMeterData/>}
				</section>
			</div>
		</>
	);
}
