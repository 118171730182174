import React, { useEffect, useState } from 'react'
import { Lock, Users } from 'react-feather'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { activeandinactive, getAllMeter, getAllModem, getScriptCodeIdWiseColumnName, InsertScriptdecodeId, SyncScriptIdWiseTableColumn, UpdatScriptdecodeId } from '../../Commoncomponet/Utils'
import {toast} from 'react-toastify'
import { BootstrapTooltip } from '../../Commoncomponet/common'
import DraggabalTable from '../../Commoncomponet/DraggabalTable'
import { Modal } from 'antd'
import clockPending from '../../assets/img/icons8-clock.gif'
import {socket} from '../../Commoncomponet/common'
import { useRef } from 'react'


// import 'jquery-ui/ui/widgets/sortable';
// import xlsx from 'xlsx'

export default function AddScriptcodeId() {
    const [modem,setModem]=useState([])
    const [Meter,setMeter]=useState([])
const [flagBtn, setflegBtn] = useState(false);
const [scriptId,setScriptId]=useState('')
const [inputs,setInputs]=useState({modemId:'',meterId:''})
const [codeid,setCodeId]=useState(inputs.modemId+'_'+inputs.meterId)
const [status,setStatus]=useState(null)
const [disabled,setDisable]=useState(true)
const [ChangesJson,setChangesJson]=useState({})
const [FixJson,setFixJson]=useState({})
const [TableKey,setTableKey]=useState('HEARTBEAT')

const userInfo=JSON.parse(window.localStorage.getItem("user_info")||{})
const history = useHistory();



    const onHandaleSelection=async()=>{
        const data=await getAllModem()
        if(data.success===true){
            setModem(data.data.data)
            const data1=await getAllMeter()
        if(data1.success===true){
            setMeter(data1.data.data)
        }
        }else{
          console.log('somthing Went wrong')
        }
      }

    //   const readUploadFile = (e) => {
    //     e.preventDefault();
    //     if (e.target.files) {
    //         const reader = new FileReader();
    //         reader.onload = (e) => {
    //             const data = e.target.result;
    //             const workbook = xlsx.read(data, { type: "array" });
    //             const sheetName = workbook.SheetNames[0];
    //             const worksheet = workbook.Sheets[sheetName];
    //             const json = xlsx.utils.sheet_to_json(worksheet);
    //             console.log(json);
    //         };
    //         reader.readAsArrayBuffer(e.target.files[0]);
    //     }
    // }

      useEffect(() => {
        
        onHandaleSelection()
      }, []);

      useEffect(() => {
        
        setCodeId(inputs.modemId+'_'+inputs.meterId)
      }, [inputs]);
      const locations=useLocation()
      useEffect(() => {
          if (locations?.state) {
            setInputs({modemId:locations?.state?.MODEM_ID,meterId:locations?.state?.METER_ID})
             setScriptId(locations?.state?.UNIQUE_ID)
             setDisable(false)
             setStatus(locations?.state?.STATUS)
             var keyofobj={}
            //  Object.entries(JSON.parse(locations?.state?.COLUMN_NAME||'{}')).map(([key,value])=>{
            //     keyofobj[key]=value.map((item,id)=>({...item,Order:id+1,Display:true}))
            //  })
             setFixJson(JSON.parse(locations?.state?.FIX_COLUMN_NAME||'{}'))
             setChangesJson(JSON.parse(locations?.state?.COLUMN_NAME||'{}'))
          }
       }, [locations]);

      const onsubmitHandale=async(e)=>{
        e.preventDefault()
          if(inputs.modemId!==''&&inputs.meterId!==''){
              setflegBtn(true)
            if(scriptId===''){
    const data=await InsertScriptdecodeId({name:codeid,add_by:userInfo?.UNIQUE_ID})
    if(data.success===true){
        toast.success('Add Script Code Id Success')
    setflegBtn(false)
    history.push('/Admin/CommonTables')
    }else{
        toast.error('Something Went Wrong')
    setflegBtn(false)
    }
}else{
    const data=await UpdatScriptdecodeId({name:codeid,add_by:userInfo?.UNIQUE_ID,ChangesJson:JSON.stringify(ChangesJson)},scriptId)
    if(data.success===true){
        toast.success('Update Script Code Id Success')
    setflegBtn(false)
    history.push('/Admin/CommonTables')
    }else{
        toast.error('Something Went Wrong')
    setflegBtn(false)
    }
}
}
      }

      const oninactive=async()=>{
        const data=await activeandinactive({tableName:'scriptdecode_id',colName:'STATUS',value:'0',matchId:'UNIQUE_ID'},scriptId)
        if(data.success===true){
           toast.success('Success Inactive scripcode Id')
           history.push('/Admin/CommonTables')
        }else{
           toast.error('Something Went Wrong')
        }
       }
       const onactive=async()=>{
        const data=await activeandinactive({tableName:'scriptdecode_id',colName:'STATUS',value:'1',matchId:'UNIQUE_ID'},scriptId)
        if(data.success===true){
           toast.success('Success Active scripcode Id')
           history.push('/Admin/CommonTables')
        }else{
           toast.error('Something Went Wrong')
        }
       }

       

       useEffect(()=>{
       
       },[ChangesJson])

      

        
             const columns=[{
                Header: "Id",
                accessor: "Order"
             },{
                Header: "Database Column",
                accessor: "Column"
             },{
                Header: "Header Column",
                accessor: "Header"
             }]    
             
             const [MassageSync,setMassageSync]=useState({})
             const [RespondMsg,setRespondMsg]=useState({})
             const [ModelInfo,setModelInfo]=useState(false)
             const mytime = useRef(null)

             useEffect(() => {
                socket.on('mqtttableinfo',(massges)=>{
                    if(MassageSync?.MSGID==massges?.MSGID&&MassageSync?.SCRIPTDECODE_ID==massges?.SCRIPTDECODE_ID){
                        setRespondMsg(massges)
                        onRedirectPage(massges)
                        clearTimeout(mytime.current);
				mytime.current = null
                    }
                })
            }, [MassageSync]);

            const onRedirectPage=async(massges)=>{
                
                    if(massges?.RESULT=='SUCCESS'){
                       const data=await getScriptCodeIdWiseColumnName(massges?.SCRIPTDECODE_ID)
                       if(data.success===true){
             setChangesJson(JSON.parse(data?.data?.data[0]?.COLUMN_NAME||'{}'))
                       }else{
                        console.log('Something Went Wrong')
                       }

                    }
                

            }

            useEffect(()=>{
                console.log(RespondMsg);
            },[RespondMsg])

             const onSyncScriptIdWiseTableColumn=async()=>{
                setRespondMsg({})
                 setMassageSync({})
                const obj={
                    MODEM_PROFILE_TYPE:modem.filter((item)=>(item?.MODEM_UNIQUE_ID==inputs.modemId))[0]?.MODEM_TYPE,
                    MSGID: new Date().valueOf(),
                    MODEM_TYPE:inputs.modemId,
                    METER_TYPE:inputs.meterId,
                    SCRIPTDECODE_ID:inputs.modemId+'_'+inputs.meterId}
                const data=await SyncScriptIdWiseTableColumn(obj)
                if(data.success==true){
                    setMassageSync(obj)
                    setModelInfo(true)
                    mytime.current= setTimeout(() => {
                        console.log(RespondMsg);
                        if(Object.keys(RespondMsg).length===0)
                        {
                            setRespondMsg({RESULT:'Time Out'})
                            setMassageSync({})
                        }
                        },5000)
                }else{
                    console.log('Something Went Wrong')
                }
             }
             const handleDragEnd = () => {
                setChangesJson({
                   ...ChangesJson,
                      [TableKey]: ChangesJson[TableKey]
                   
                });
             };
             
  return (
    <>

<Modal  visible={ModelInfo} footer='' maskClosable={false} onCancel={() => (setModelInfo(false))} width={500}>
    <h5 class="modal-title text-center" id="meter_data_mqtt_info_txt">Getting Response For Sync Table Column</h5>
   <h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{!RespondMsg?.RESULT&&RespondMsg?.RESULT!=='SUCCESS'?<img className='mr-2' src={clockPending} height='20px' width={'20px'}></img>:RespondMsg?.RESULT==='SUCCESS'?<span><i className='fas fa-check mr-2' style={{color:'green'}}></i></span>:<span><i className='fas fa-times mr-2' style={{color:'red'}}></i></span>} Sync Table Column {RespondMsg?.RESULT&&RespondMsg?.RESULT!=='SUCCESS'?`(${RespondMsg?.RESULT})`:''}</h5>
    <div class=""> 
               </div>
               <div class="text-center" style={{marginTop:"20px"}}>
                  <input type="button" class="btn btn-danger " value="Close" id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={()=>{setModelInfo(false)}}/>
               </div>
            </Modal>
    <div className="main-content">
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to="/group-deshboard">
							<i className="fas fa-tachometer-alt"></i> Home
						</Link>
					</li>
					<li className="breadcrumb-item">
						<Link >
							<Lock style={{ height: "16px" }} /> Admin

						</Link>
					</li>
                    <li className="breadcrumb-item">
						<Link to='/Admin/CommonTables'>
                        Script Decode Id
						</Link>
					</li>
					<li className="breadcrumb-item">
						<Link>
                        {scriptId!==''?'Update Script Code ID':'Add Script Code ID'}
						</Link>
					</li>
				</ol>
			</nav>
        <section class="section">
            <div class="section-body">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h4>{scriptId!==''?'Update Script Code ID':'Add Script Code ID'}</h4>
                                <div class="card-header-action">  
                                {scriptId!==''?<div  class="btn btn-info text-white btnedit mr-2"><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" onClick={()=>(setDisable(true))}><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:''}
										{/* {scriptId!==''&&status===1?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2" onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:''} */}
										{/* {scriptId!==''&&status===0?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:''} */}
                                    <Link to="/Admin/CommonTables" class="btn btn-info text-white align-left">
                                    Back
                                    </Link>
                                </div>
                            </div>
                            <div class="card-body">
                                <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" onSubmit={onsubmitHandale}>
                                    <div class="form-row" style={{marginLeft:"10px"}}>
                                        <div class="form-group col-md-3" id="grpeui">
                                            <label for="inputPassword4">Modem Profile: <span class="text-danger">*</span></label>
                                            <select class="form-control form-select modemtypename anothermodemname" required="" name="modem_profile_id" id="modem_profile_id" onChange={(e)=>(setInputs({...inputs,modemId:e.target.value}))} value={inputs.modemId} disabled={!disabled}>
                                                <option value="">Select Modem</option>
                                                {modem&&modem.map((item)=>(<option value={item?.MODEM_UNIQUE_ID}>{item?.MODEM_PROFILE_NAME}</option>))}       
                                                                                            </select>
                                            <div id="modeid"></div>
                                        </div>
                                        <div class="form-group col-md-3" id="grpeui">
                                            <label for="inputPassword4">Meter Profile : <span class="text-danger">*</span></label>
                                            <select class="form-control form-select second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onChange={(e)=>(setInputs({...inputs,meterId:e.target.value}))} value={inputs.meterId} disabled={!disabled}>
                                                <option value="">Select Meter</option>
                                                {Meter&&Meter.map((item)=>(<option value={item?.METER_UNIQUE_ID}>{item?.METER_PROFILE_NAME}</option>))} 
                                                                                                    
                                                                                            </select>
                                            <div id="meteid"></div>
                                        </div>
                                        <div class="form-group col-md-3" id="grpeui">
                                            <label for="inputPassword4">Generated Scriptdecode ID :</label>
                                            <input class="form-control lastname" id="SCRIPTDECODE_ID" name="finalscriptid" type="text" readonly="" placeholder="Generated Scriptdecode ID" value={codeid}/>
                                        </div>
                                    </div>
                                    {scriptId===''?'': ChangesJson?<><div class="form-group col-md-3 d-flex" id="grpeui" style={{paddingLeft:'0px'}}>
                                            <select class="form-control form-select second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onChange={(e)=>(setTableKey(e.target.value))} value={TableKey}>
                                                {Object.keys(ChangesJson).length>0&&Object.keys(ChangesJson).map((item)=>(<option value={item} >{item}</option>))}                  
                                                </select>
                                            <div id="meteid"></div>
                                            <div  name="submit" value='Sync' className='btn btn-primary ml-2 col-md-3' onClick={onSyncScriptIdWiseTableColumn}>Sync</div>
                                        </div></>:''}
                                    <div className='table-responsive'>
                                   {scriptId===''?'':ChangesJson[TableKey]?.length>0? <DraggabalTable columns={columns} data={ChangesJson[TableKey]} setitem={(e) => setChangesJson({...ChangesJson,[TableKey]:e})} onDragEnd={handleDragEnd}/>:''}
                                    {/* <table class="table table-striped fixed_header1" style={{width:'700px'}}  id="sortable-table">
                                    <thead>
                                       <tr >
                                          <th  class=""></th>
                                          <th >SRNO</th>
                                          <th >Database Column</th>
                                          <th >Header Column</th>
                                          <th >Status</th>
                                        
                                       </tr>
                                    </thead>
                                    <tbody>
                                    {ChangesJson[TableKey]?.map((item,id)=>(
                                        <tr id={item?.Column} data-position={id+1}>
                                        <td style={{height:'45px'}}><div class="sort-handler ui-sortable-handle">
                                        <HolderOutlined />
                              </div></td>
                                            <td style={{height:'45px'}}>{item?.Order}</td>
                                            <td style={{height:'45px'}}>{item?.Column}</td>
                                            <td style={{height:'45px'}}>{item?.Header}</td>
                                            <td style={{height:'45px'}} className='costom-switch'><Switch size="small" checked={item?.Display} onChange={(e)=>(onHandalswitch(e,item?.Column))}/></td>
                                        </tr>
                                    ))}
                                        
                                    </tbody>
                                    </table> */}
                                    </div>
                                    <p style={{marginLeft:"25px"}} class="text-danger">Note : * Indicates Mandatory Fields</p>
                                    <div class="text-left">
                                            <input type="submit" name="submit" id="submitvn" class={`btn btn-danger ${flagBtn?'btn-progress disabled':''} ml-4 mr-3`} value={scriptId===''?'Generate':'Update'}/>
                                        <Link class="btn btn-light text-dark" to="/Admin/CommonTables">Cancel</Link>  
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            </div>
    </>
  )
}
