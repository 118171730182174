import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const FilterDisplay = ({ filter, onClearFilter, onClearIndividualFilter }) => {
    if (Object.entries(filter).length === 0) {
        return null
    }
    const handleClearIndividualFilter = (key, value) => {
        onClearIndividualFilter(key, value);
    };
    return (
        <div className="tw-mb-4 tw-px-1 tw-flex tw-items-center tw-gap-2">
            <div className="tw-flex tw-flex-wrap tw-gap-2 tw-text-xs">
                {Object.entries(filter).length > 0 && Object.entries(filter).map(([key, value]) => (
                    <div key={key} className="tw-px-1 tw-py-1">
                        <strong className="mr-1">{key}:</strong>
                        {Array.isArray(value) ? (
                            value.map((v, i) => (
                                <span key={i} className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500">
                                    {v?.label}

                                    <button onClick={() => handleClearIndividualFilter(key, v?.value)} class="tw-border-none tw-font-bold tw-ml-1 tw-bg-transparent tw-transition-all tw-rounded hover:tw-bg-red-500/30 hover:tw-text-red-600 tw-px-1.5">×</button>
                                </span>
                            ))
                        ) :
                            (typeof value === 'object' &&
                                !Array.isArray(value) &&
                                value !== null
                            ) ? <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500">
                                {(value?.label)}
                            </span> : (
                                <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500">
                                    {JSON.stringify(value)}
                                    <button onClick={() => handleClearIndividualFilter(key, value)} class="tw-border-none tw-font-bold tw-ml-1 tw-bg-transparent tw-transition-all tw-rounded hover:tw-bg-red-500/30 hover:tw-text-red-600 tw-px-1">×</button>
                                </span>
                            )}
                    </div>
                ))}
                <div className=''>
                    <button onClick={onClearFilter} className='tw-bg-red-100 tw-whitespace-nowrap tw-rounded-md tw-text-xs tw-px-2 tw-py-1 border !tw-border-red-500 hover:tw-bg-red-200 tw-transition-all'>
                        Clear Filter
                    </button>
                </div>
            </div>
        </div>
    );
}


const ConsumerTaskFilter = ({ handleFilter, tagsTypsOptions, taskAssignToOptions, currentUser, currentFilter, currentShowFilter }) => {

    const taskPriorityOptions = [
        { value: 'High', label: 'High' },
        { value: 'medium', label: 'Medium' },
        { value: 'Low', label: 'Low' },
    ];

    const taskStatusOptions = [
        { value: '1', label: 'Completed' },
        { value: '0', label: 'In Progress' },
        { value: '-1', label: 'Yet to Start' },
    ];

    const taskTypeOptions = [
        { value: 'Development', label: 'Development' },
        { value: 'Action', label: 'Action' },
        { value: 'Bug', label: 'Bug' },
    ];

    const taskConsumerTypeOptions = [
        { value: 'gsm', label: 'GSM' },
        { value: 'lorawan', label: 'LORAWAN' },
    ];


    const [filters, setFilters] = useState({
        taskTitle: '',
        taskAssignTo: [],
        taskConsumerType: [],
        taskStatus: [],
        taskPriority: [],
        taskType: [],
        taskTags: [],
        overdue: false,
        todays: false,
        taskCreatedBy: []
    });

    const [showFilterObj, setShowFilterObj] = useState({
    })
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)

    const onFilterModalOpen = () => {
        setIsFilterModalOpen(true)
    }

    const onFilterModalClose = () => {
        setIsFilterModalOpen(false)
    }

    useEffect(() => {
        if (currentFilter) {
            console.log("Current Filter", currentFilter)
            setFilters(currentFilter)
        }
        if (currentShowFilter) {
            setShowFilterObj(currentShowFilter)
        }
    }, [currentFilter, currentShowFilter])


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
        setShowFilterObj((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
        if (value === '') {
            setShowFilterObj((prevFilters) => {
                const updatedFilters = { ...prevFilters };
                delete updatedFilters[name]
                return updatedFilters
            });
        }
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: checked,
        }));
        setShowFilterObj((prevFilters) => ({
            ...prevFilters,
            [name]: checked,
        }));
        if (!checked) {
            setShowFilterObj((prevFilters) => {
                const updatedFilters = { ...prevFilters };
                delete updatedFilters[name]
                return updatedFilters
            });
        }
    };

    const handleSelectChange = (selectedOptions, { name }) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: selectedOptions.map(item => item.value),
        }));
        setShowFilterObj((prevFilters) => ({
            ...prevFilters,
            [name]: selectedOptions,
        }));
        if (selectedOptions.length === 0) {
            setShowFilterObj((prevFilters) => {
                const updatedFilters = { ...prevFilters };
                delete updatedFilters[name]
                return updatedFilters
            });
        }
    };

    const handleSubmit = () => {
        handleFilter(filters);
        onFilterModalClose()
    };

    const onClearFilter = async () => {
        setFilters({
            taskTitle: '',
            taskAssignTo: [],
            taskConsumerType: [],
            taskStatus: [],
            taskPriority: [],
            taskType: [],
            taskTags: [],
            overdue: false,
            todays: false,
            taskCreatedBy:[]
        });
        handleFilter({
            taskTitle: '',
            taskAssignTo: [],
            taskConsumerType: [],
            taskStatus: [],
            taskPriority: [],
            taskType: [],
            taskTags: [],
            overdue: false,
            todays: false,
            taskCreatedBy:[]
        });

        setShowFilterObj({});
    }

    const onClearIndividualFilter = (key, value) => {
        const newFilters = { ...filters };
        let updatedShowFilters = { ...showFilterObj };

        if (value) {
            if (Array.isArray(newFilters[key])) {
                newFilters[key] = newFilters[key].filter(v => v !== value);
                updatedShowFilters[key] = updatedShowFilters[key].filter(v => v.value !== value);
            } else {
                newFilters[key] = key === 'taskTitle' ? '' : false;
                delete updatedShowFilters[key];
            }
        } else {
            delete newFilters[key];
            delete updatedShowFilters[key];
        }
        if (updatedShowFilters[key]?.length === 0) {
            delete updatedShowFilters[key];

        }

        setFilters(newFilters);
        handleFilter(newFilters);
        setShowFilterObj(updatedShowFilters);
    };


    return (
        <>
            <Modal title="Filter" style={{ top: 0 }} visible={isFilterModalOpen} footer='' onCancel={onFilterModalClose} width={1030}>

                <form>
                    <div className='tw-grid tw-grid-cols-2 tw-gap-4 tw-my-4'>

                        <div class="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskTitle">Task Title:</label>
                            <div class="tw-w-2/3">
                                <input className='form-control' type="text" name="taskTitle" value={filters.taskTitle} onChange={handleInputChange} />
                            </div>

                        </div>
                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskAssignTo">Task Assign To:</label>
                            <div class="tw-w-2/3">
                                <Select
                                    className='tw-w-full'
                                    isMulti
                                    value={showFilterObj?.taskAssignTo || []}
                                    name="taskAssignTo"
                                    options={taskAssignToOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>

                        </div>
                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskConsumerType">Task Consumer Type:</label>
                            <div class="tw-w-2/3">
                                <Select
                                    isMulti
                                    name="taskConsumerType"
                                    value={showFilterObj?.taskConsumerType || []}
                                    options={taskConsumerTypeOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskStatus">Task Status:</label>
                            <div class="tw-w-2/3">
                                <Select
                                    isMulti
                                    name="taskStatus"
                                    value={showFilterObj?.taskStatus || []}
                                    options={taskStatusOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskPriority">Task Priority:</label>
                            <div class="tw-w-2/3">
                                <Select
                                    isMulti
                                    name="taskPriority"
                                    value={showFilterObj?.taskPriority || []}
                                    options={taskPriorityOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskType">Task Type:</label>
                            <div class="tw-w-2/3">
                                <Select
                                    isMulti
                                    name="taskType"
                                    value={showFilterObj?.taskType || []}
                                    options={taskTypeOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskTags">Task Tags:</label>
                            <div class="tw-w-2/3">
                                <Select
                                    isMulti
                                    name="taskTags"
                                    value={showFilterObj?.taskTags || []}
                                    options={tagsTypsOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </div>

                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskDueDate">Task Created by:</label>
                            <div class="tw-w-2/3">
                                <Select
                                    isMulti
                                    name="taskCreatedBy"
                                    value={showFilterObj?.taskCreatedBy || []}
                                    options={taskAssignToOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </div>

                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <div className='tw-w-1/2 tw-flex tw-items-center tw-gap-2'>
                                <label className='tw-mb-0' htmlFor="overdue">Overdue Tasks:</label>
                                <input type="checkbox" name="overdue" checked={showFilterObj?.overdue || false} onChange={handleCheckboxChange} />
                            </div>

                            <div className='tw-w-1/2 tw-flex tw-items-center tw-gap-2'>
                                <label className='tw-mb-0' htmlFor="todays">Today's Tasks:</label>
                                <input type="checkbox" name="todays" checked={showFilterObj?.todays || false} onChange={handleCheckboxChange} />
                            </div>
                        </div>

                    </div>
                    <div className='border-top !tw-border-b-gray-300 tw-flex tw-items-center tw-justify-center'>
                        <div class="buttons tw-mt-5">
                            <button type='button' class="btn btn-danger" onClick={handleSubmit}>Filter</button>
                            <button type='button' class="btn btn-light text-dark" onClick={onFilterModalClose}>Cancel</button>
                        </div>
                    </div>

                </form>
            </Modal>


            <div className='tw-flex tw-items-center'>
                <button className='tw-border-none tw-bg-transparent' onClick={onFilterModalOpen}>

                    <i class="fas fa-filter tw-text-indigo-500"></i>
                </button>
                <span className='tw-ml-1 tw-font-semibold'>FILTERS : </span>

                <FilterDisplay filter={showFilterObj} onClearFilter={onClearFilter} onClearIndividualFilter={onClearIndividualFilter} />
            </div>

        </>
    );
};

export default ConsumerTaskFilter;
