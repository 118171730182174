import React, { useEffect, useState } from 'react'
import { Users } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import { GeflowEncodeRegister, GetMssqlCardDetails, InsertCardDetails, UpdateIntegrationCard } from '../../../Commoncomponet/Utils'
import APIIcon from "../../../assets/img/icons8-ui-design-99.png"
import GoogleLoader from '../../../Commoncomponet/Loaders/GoogleLoader'
import { Modal, TimePicker } from 'antd'
import { toast } from 'react-toastify'
import { BootstrapTooltip, btnColor } from '../../../Commoncomponet/common'
import chroma from 'chroma-js'
import Integration_structure from './Integration_structure'
import exports from '../../../assets/img/export.png'
import moment from 'moment'
import Transceiver from './Transceiver'

const Integrations = () => {
  const history = useHistory()
  const [CardDatails, setCardDatails] = useState([])
  const [Loader, setLoader] = useState(false)
  const [CardModal, setCardModal] = useState(false)
  const [CardInpurt, setCardInpurt] = useState({ Card_title: '', card_description: '', Card_type: '', Endpoint_type: '', Card_Encode: '' })
  const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
  const [encodeList, setencodeList] = useState([])
  const [card_id, setcard_id] = useState('')
  const [Disable, setDisable] = useState(false)
  const [activeTab, setActiveTab] = useState("Connection");
  const [MainactiveTab, setMainactiveTab] = useState("Memighty Consumer");

  const handleManageClick = (id) => {
    history.push('/integrations/Integrations-details/' + id)
  }

  const ongetcarddetail = async () => {
    setLoader(true)
    const data = await GetMssqlCardDetails(MainactiveTab=='Memighty Consumer'?1:2)
    if (data.success == true) {
      setCardDatails(data.data.LogDetails)
      setLoader(false)
    } else {
      console.log('somthing wait wrong')
      setLoader(false)

    }
  }

  useEffect(() => {
    ongetcarddetail()

  }, [MainactiveTab])

  const RedirectLink = (item) => {
    history.push({ pathname: `/Add-Update-integration-Structure`, state: item })
  }
  
  const onInsertCardInfo = async () => {
    if (CardInpurt?.Card_title !== '' && CardInpurt?.card_description !== '') {
      if (card_id) {
        const data = await UpdateIntegrationCard({ ...CardInpurt,Card_type:MainactiveTab=='Memighty Consumer'?1:2, add_by: userInfo?.UNIQUE_ID, id: card_id }, card_id)
        if (data.success === true) {
          ongetcarddetail()
          setCardModal(false)
          setCardInpurt({ Card_title: '', card_description: '', Card_type: '', Endpoint_type: '', Card_Encode: '' })
          setcard_id('')
        } else {
          toast.error('Server Issue')
        }
      } else {

        const data = await InsertCardDetails({ ...CardInpurt,Card_type:MainactiveTab=='Memighty Consumer'?1:2, add_by: userInfo?.UNIQUE_ID })
        if (data.success === true) {
          ongetcarddetail()
          setCardModal(false)
          setCardInpurt({ Card_title: '', card_description: '', Card_type: '', Endpoint_type: '', Card_Encode: '' })

        } else {
          toast.error('Server Issue')
        }
      }
    } else {
      toast.error('Mandatory Fields Require')
    }
  }
  return (
    <>
      <Modal title={<><span>{card_id ? 'Update Integration' : 'Add New Integration'}</span>{card_id && <div class="btn btn-info text-white btnedit mr-2 " onClick={() => { setDisable(false) }}><BootstrapTooltip title='edit'>
        <span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
          <i class="fas fa-edit  text-white"></i>
        </span></BootstrapTooltip>
      </div>}</>} visible={CardModal} className="my-modal-class" footer='' maskClosable={false} onCancel={() => {
        setCardModal(false)
        setcard_id('')
        setDisable(false)
        setCardInpurt({ Card_title: '', card_description: '', Card_type: '', Endpoint_type: '', Card_Encode: '' })
      }} width={500}>
        <section className="section mt-3">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="form-row" >
                  {/* <div className="form-group col-md-12" id="grpeui">
											<label for="inputPassword4 ">Report Type :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reporttype" required="required" >
												<option value="">Report Type</option>
												<option value="allgsmconsumerlist">All GSM Consumer Details</option>
											</select>
										</div> */}
                  <div className="form-group col-md-12" id="grpeui">
                    <label for="inputPassword4 ">Integration Title :<span className='text-danger'>*</span> </label>
                    <input type="text" class="form-control present_street_area" name="address_compony_name" id="address_compony_name" placeholder="" onChange={(e) => { setCardInpurt({ ...CardInpurt, Card_title: e.target.value }) }} value={CardInpurt?.Card_title} disabled={Disable} />
                  </div>
                  <div className="form-group col-md-12" id="grpeui">
                    <label for="inputPassword4 ">Integration Description :<span className='text-danger'>*</span> </label>
                    <textarea class="form-control present_street_area" name="address_compony_name" id="address_compony_name" placeholder="" onChange={(e) => { setCardInpurt({ ...CardInpurt, card_description: e.target.value }) }} value={CardInpurt?.card_description} disabled={Disable} />
                  </div>
                  {/* {card_id == '' ? <div className="form-group col-md-12" id="grpeui">
                    <label for="inputPassword4 ">integration Type :<span className='text-danger'>*</span> </label>
                    <select className="form-control form-select" name="reporttype" required="required" onChange={(e) => { setCardInpurt({ ...CardInpurt, Card_type: e.target.value }) }} value={CardInpurt?.Card_type} disabled={Disable}>
                      <option value="">Select integration Type</option>
                      <option value="1">Memighty Device</option>
                      <option value="2">Third Party Device</option>
                    </select>
                  </div> : ''} */}
                  {/* <div className="form-group col-md-12" id="grpeui">
                    <label for="inputPassword4 ">Integration Structure :<span className='text-danger'>*</span> </label>
                    <select className="form-control form-select" name="reporttype" required="required" onChange={(e) => { setCardInpurt({ ...CardInpurt, Card_Encode: e.target.value }) }} value={CardInpurt?.Card_Encode}>
                      <option value="">Select integration Structure</option>
                      {encodeList?.map((item) => (<option value={item?.unique_id}>{item?.name}</option>))}
                    </select>
                  </div> */}
                  {/* <div className="form-group col-md-12" id="grpeui">
											<label for="inputPassword4 ">Endpoint Type :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reporttype" required="required" onChange={(e)=>{setCardInpurt({...CardInpurt,Endpoint_type:e.target.value})}} value={CardInpurt?.Endpoint_type}>
												<option value="">Select Endpoint Type</option>
												<option value={1}>MQTT</option>
												<option value={2}>HTTP</option>
												<option value={3}>DATABASE</option>
											</select>
										</div> */}
                </div>
                <p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
                {!Disable && <div className="text-left">
                  <input type="submit" name="submit" className="btn btn-danger mr-2" value={card_id ? 'Update' : "Generate"} id="submitvn" onClick={onInsertCardInfo} />
                  <Link className="btn btn-light text-dark" onClick={() => {
                    setCardModal(false)
                    setcard_id('')
                    setDisable(false)
                    setCardInpurt({ Card_title: '', card_description: '', Card_type: '', Endpoint_type: '', Card_Encode: '' })
                  }}>Cancel</Link>
                </div>}
              </div>
            </div>
          </div>

        </section>
      </Modal>
      <div className="main-content">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb ">
            <li className="breadcrumb-item">
              <Link to="/all-deshboard">
                <i className="fas fa-tachometer-alt"></i> Home
              </Link>
            </li>
            <li className="breadcrumb-item">
              Integration
            </li>
            <li className="breadcrumb-item">
              <Link to="/integration">
                Plugin
              </Link>
            </li>
          </ol>
        </nav>
        <div className='card'>
        <div class="row ml-2 border-top py-2">
            <div class="d-flex tw-border-b-0 " style={{ justifyContent: "space-between" }}>
              <ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "20px", alignItems: "center" }}>
                <li>
                  <button
                    style={{
                      backgroundColor: MainactiveTab === "Memighty Consumer" ? chroma(btnColor[2]).alpha(0.4).css() : '',
                      color: MainactiveTab === "Memighty Consumer" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
                      borderBottom: `1px solid ${btnColor[2]}`,
                      boxShadow: MainactiveTab === "Memighty Consumer" ? `0 2px 8px -3px ${btnColor[2]}` : '',
                    }}
                    className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${MainactiveTab === "Memighty Consumer" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    onClick={() => setMainactiveTab("Memighty Consumer")}
                  >
                    Memighty Consumer
                  </button>
                </li>
                <li>
                  <button
                    style={{
                      backgroundColor: MainactiveTab === "Third Party Consumer" ? chroma(btnColor[0]).alpha(0.4).css() : '',
                      color: MainactiveTab === "Third Party Consumer" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
                      borderBottom: `1px solid ${btnColor[0]}`,
                      boxShadow: MainactiveTab === "Third Party Consumer" ? `0 2px 8px -3px ${btnColor[0]}` : '',
                    }}
                    className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${MainactiveTab === "Third Party Consumer" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    onClick={() => setMainactiveTab("Third Party Consumer")}
                  >
                    Third Party Consumer*
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {MainactiveTab=='Memighty Consumer'? <div class="row ml-2  py-2">
            <div class="d-flex tw-border-b-0 " style={{ justifyContent: "space-between" }}>
              <ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "20px", alignItems: "center" }}>
                <li>
                  <button
                    style={{
                      backgroundColor: activeTab === "Connection" ? chroma(btnColor[2]).alpha(0.4).css() : '',
                      color: activeTab === "Connection" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
                      borderBottom: `1px solid ${btnColor[2]}`,
                      boxShadow: activeTab === "Connection" ? `0 2px 8px -3px ${btnColor[2]}` : '',
                    }}
                    className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Connection" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    onClick={() => setActiveTab("Connection")}
                  >
                    Connection
                  </button>
                </li>
                <li>
                  <button
                    style={{
                      backgroundColor: activeTab === "Structure" ? chroma(btnColor[0]).alpha(0.4).css() : '',
                      color: activeTab === "Structure" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
                      borderBottom: `1px solid ${btnColor[0]}`,
                      boxShadow: activeTab === "Structure" ? `0 2px 8px -3px ${btnColor[0]}` : '',
                    }}
                    className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Structure" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    onClick={() => setActiveTab("Structure")}
                  >
                   Forward Structure
                  </button>
                </li>
                <li>
                  <button
                    style={{
                      backgroundColor: activeTab === "Transceiver Structure" ? chroma(btnColor[3]).alpha(0.4).css() : '',
                      color: activeTab === "Transceiver Structure" ? chroma(btnColor[3]).darken(1.5).css() : btnColor[3],
                      borderBottom: `1px solid ${btnColor[3]}`,
                      boxShadow: activeTab === "Transceiver Structure" ? `0 2px 8px -3px ${btnColor[3]}` : '',
                    }}
                    className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Transceiver Structure" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    onClick={() => setActiveTab("Transceiver Structure")}
                  >
                   Transceiver Documents*
                  </button>
                </li>
              </ul>
            </div>
          </div>:""}
          {MainactiveTab=='Third Party Consumer'? <div class="row ml-2  py-2">
            <div class="d-flex tw-border-b-0 " style={{ justifyContent: "space-between" }}>
              <ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "20px", alignItems: "center" }}>
                <li>
                  <button
                    style={{
                      backgroundColor: activeTab === "Connection" ? chroma(btnColor[2]).alpha(0.4).css() : '',
                      color: activeTab === "Connection" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
                      borderBottom: `1px solid ${btnColor[2]}`,
                      boxShadow: activeTab === "Connection" ? `0 2px 8px -3px ${btnColor[2]}` : '',
                    }}
                    className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Connection" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    onClick={() => setActiveTab("Connection")}
                  >
                    Connection *
                  </button>
                </li>
                <li>
                  <button
                    style={{
                      backgroundColor: activeTab === "Api Documents" ? chroma(btnColor[0]).alpha(0.4).css() : '',
                      color: activeTab === "Api Documents" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
                      borderBottom: `1px solid ${btnColor[0]}`,
                      boxShadow: activeTab === "Api Documents" ? `0 2px 8px -3px ${btnColor[0]}` : '',
                    }}
                    className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Api Documents" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    onClick={() => setActiveTab("Api Documents")}
                  >
                    Api Documents*
                  </button>
                </li>

              </ul>
            </div>
          </div>:""}
        </div>
        {(MainactiveTab=='Memighty Consumer'||MainactiveTab=='Third Party Consumer')&&activeTab == 'Connection' && <>
          {Loader == true ? <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
            <GoogleLoader bodyClassName={'tw-h-[70%]'} />
          </div> : <section className="section">
            <div className="section-body">
              <div className="row">
                <div className='d-flex' style={{ justifyContent: 'end' }}>
                  <div className='btn btn-primary mb-3' onClick={() => setCardModal(true)}>+ Add New</div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-lg-12">
                  <div className="" id="">
                    <div className=" d-flex" style={{ flexWrap: 'wrap' }}>
                      {CardDatails?.length > 0 ? CardDatails?.map((item, id) => (
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                          <div className="card">
                            <div className="card-body">
                              <ul className="list-unstyled list-unstyled-border user-list" id="message-list">

                                <li className="media tw-flex tw-items-center tw-justify-center tw-gap-2">
                                  {item?.TYPE == "API" ? <img alt="image" src={APIIcon} className="mr-3 user-img-radious-style user-list-img" /> : <><i className="fa-sharp tw-text-sky-400 fa-solid fa-database tw-h-8 tw-w-8"></i></>}
                                  {/* <img alt="image" src="https://gums.memighty.com/assets/Upload/database-icon.jpg" className="mr-3 user-img-radious-style user-list-img" /> */}
                                  <div className="media-body d-flex tw-justify-between">
                                    <div className="mt-0 font-weight-bold">{item?.name}<div className={`ml-2 badge badge-${item?.status === 1 ? 'success' : item?.status === 0 ? 'danger' : 'warning'}`}>{item?.status === 1 ? 'Active' : item?.status === 0 ? 'InActive' : 'Pending'}</div></div>
                                    <div><div class="btn btn-info text-white btnedit mr-2 " onClick={() => {
                                      setCardModal(true)
                                      setCardInpurt({ Card_title: item?.name, card_description: item?.description, Card_type: item.integration_type, Endpoint_type: '', Card_Encode: '' })
                                      setcard_id(item?.unique_id)
                                      setDisable(true)
                                    }}><BootstrapTooltip title='edit'>
                                        <span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
                                          <i class="fas fa-edit  text-white"></i>
                                        </span></BootstrapTooltip>
                                    </div></div>
                                  </div>
                                </li>
                              </ul>

                              <div className='d-flex'><b>Description :- </b><div className="text-small tw-ml-1">{item?.description}</div></div>
                              <div className='d-flex'><b>Type :- </b><div className="text-small tw-ml-1">{item?.integration_type == 1 ? 'Memighty Consumer' : item?.integration_type == 2 ? 'Third Party Consumer*' : ''}</div></div>
                              {item?.integration_type == 1?<div className='d-flex'><b>Structure :- </b><div className="text-small tw-ml-1">{item?.structure_name||'-'}{item?.structure_id&&<a className="ml-1" onClick={() => (RedirectLink({unique_id:item?.structure_id}))}><img src={exports} style={{ height: "10px" }} /></a>}</div></div>:''}
                              <div className='d-flex'><b>Last Seen :- </b><div className="text-small tw-ml-1">{item?.last_log_forward?.entryts ? moment.utc(item?.last_log_forward?.entryts).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : '-'}</div></div>
                              <div className='d-flex'><b>Connection Status :- </b> <div className={`ml-2 badge badge-${item?.last_log?.online_offline_status === 'online' ? 'success' : item?.last_log?.online_offline_status === 'offline' ? 'danger' : 'danger'}`}>{item?.last_log?.online_offline_status === 'online' ? 'Online' : item?.last_log?.online_offline_status === 'offline' ? 'offline' : 'offline'}</div></div>
                              <div id="set_up_btn"><button className="btn btn-outline-success mt-2" onClick={() => handleManageClick(item?.unique_id)}>Manage</button></div>
                            </div>
                          </div>
                        </div>
                      )) : <div className="col-12">
                        <div className="card">
                          <div className="card-body ">
                            <h4 className='text-center'> No Data Found</h4>
                          </div>
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>}
        </>}

        {MainactiveTab=='Memighty Consumer'&&activeTab == 'Structure' && <>
          <Integration_structure />
        </>}
        {MainactiveTab=='Memighty Consumer'&&activeTab=='Transceiver Structure' && <>
          <Transceiver />
        </>}
      </div>
    </>
  )
}

export default Integrations