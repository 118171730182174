import { useState } from 'react';
import { Document,Page } from 'react-pdf';

export function FileHandler({ pdfUrl }) {
  // console.log("pdfUrl",pdfUrl)
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  // Function to determine the file type based on its extension
  const getFileType = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    // console.log("file type is",filename,extension)
    if (extension === 'pdf') {
      // console.log("file type is",filename,extension)
      return 'pdf';
    } else if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
      return 'image';
    } else if (['mp4', 'webm', 'ogg'].includes(extension)) {
      return 'video';
    } 
    return 'unknown';
  };
 
  // Function to render different types of files
  const renderFile = () => {
    const fileType = getFileType(pdfUrl.filename);
    switch (fileType) {
      case 'pdf':
        return (
          <div>
            <Document file={pdfUrl.url} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
            </Document>
            <div className='tw-flex tw-mt-3'>
              <p>
                Page {pageNumber} of {numPages}
              </p>
              <div className='tw-ml-auto'>
                <button className=' border tw-mr-2 tw-bg-white tw-rounded tw-p-1 disabled:tw-text-gray-400 ' disabled={pageNumber === 1}  onClick={() => setPageNumber(pageNumber - 1)}>Previous</button>
                <button className='border tw-mr-2 tw-bg-white tw-rounded tw-p-1 disabled:tw-text-gray-400' disabled={pageNumber === numPages} onClick={() => setPageNumber(pageNumber + 1)}>Next</button>
              </div>
            </div>
          </div>
        );
      case 'image':
        return <img src={pdfUrl.url} alt={pdfUrl.filename} className='tw-max-w-full tw-max-h-90vh' />;
      case 'video':
        return <video src={pdfUrl.url} controls className='tw-max-w-full tw-max-h-90vh' />;
      default:
        return <p>Unsupported file type</p>;
    }
  };

  return (
    <div className='tw-overflow-hidden tw-w-full tw-flex tw-justify-center tw-items-center'>
      {renderFile()}
    </div>
  );
}
