import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { BootstrapTooltip } from "../../Commoncomponet/common";
import { getRolesById, getTags } from "../../Commoncomponet/Utils";
import * as $ from "jquery";
import dt from "datatables.net-dt";
$.fn.DataTable = dt;
export default function Tags() {

  const [tagsData,setTagsData]=useState([])
const [filterrolesData,setfilterRolesData]=useState([])
const [loader,setLoader]=useState(false)
const [permision,setpermision]=useState({})
const userInfo = JSON.parse(window.localStorage.getItem("user_info"));

const history=useHistory()
const onHandaleRoles = async () => {
	const data = await getRolesById(userInfo?.ROLE);
	if (data.success === true) {
		const obj = data.data.data[0];
		setpermision({...obj,ROLES_OTHER_PERMISSIONS:JSON.parse(obj.ROLES_OTHER_PERMISSIONS)});
	}else{
		console.log('Something Went Wrong')
	}
}

  const ongetTagsData=async()=>{
    setLoader(true);
		$(".loader").fadeOut("slow");
const data=await getTags()
if(data.success===true){
  setTagsData(data.data.data)
}else{
  console.log('somthing Went wrong')
  setLoader(false)
}
  }

  useEffect(() => {
	onHandaleRoles()
    ongetTagsData().then(() =>
    setTimeout(function () {
      $("#table-1").DataTable({"pageLength": 50,order: [[0, 'desc']]});
setLoader(false);
    }, 1000)
  );
  }, []);
  const UpdateRolesUser=(item)=>{
		history.push({ pathname: `/add-tags`, state: {...item,permision} })
	}
	return (
		<>
    {loader ? (
						<div className="main-content">
							<div class="loader"></div>
						</div>
					) : (
						""
					)}
			<div class="main-content">
				<div class="col-12 d-flex justify-content-between align-items-center pb-3 px-0">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb p-0 mb-0">
							<li class="breadcrumb-item">
								<a href="/">
									<i class="fa fa-dashboard fa-lg"></i> Home
								</a>
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								<a href="/" class="open_rightsidebar">
									<i class="fas fa-list"></i> Tags{" "}
								</a>
							</li>
						</ol>
					</nav>
				</div>
				<section class="section">
					<div class="section-body">
						<div class="row">
							<div class="col-12">
								<div class="card">
									<div class="card-header">
										<h4>Tags</h4>
										<div class="card-header-action">
											{permision?.ROLES_OTHER_PERMISSIONS?.Tags?.add===true||userInfo?.ROLE==='ROLE1661856938676'?<Link to="/add-tags" class="btn btn-info text-white">
                      <BootstrapTooltip title="Add">
												<span>
													<i
														className="fas fa-plus text-white"
														style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
													></i>
												</span>
											</BootstrapTooltip>
											</Link>:''}
										</div>
									</div>
									<div class="card-body">
                  <div className="table-responsive">
										<table className="table table-striped" id="table-1">
											<thead>
												<tr>
													<th className="text-center" style={{ maxWidth: "100px" }}>Unique No</th>
													<th >Tags Name</th>
													<th style={{ maxWidth: "100px" }}>Status</th>
													
												</tr>
											</thead>
											<tbody>
												{tagsData &&
													tagsData.map((item, id) => (
														<tr style={{ height: "4rem" }} className="trheigth" key={id} onClick={()=>UpdateRolesUser(item)}>
															<td className="text-center">{item.SRNO}</td>
															<td><div class={`btn btn-icon icon-left`} style={{ background: item?.TAG_COLOR, color: "white" }}><i class="fa fa-tag fa-lg" style={{marginRight:'4px'}}></i>{item.TAG_NAME}</div></td>
															{item.STATUS===1?<td className="text-center">
																			<div className="badge badge-success badge-shadow text-white">Active</div>
																		</td>:<td className="text-center">
																			<div className="badge badge-danger badge-shadow text-white">Inactive</div>
																		</td>}
														</tr>
													))}
											</tbody>
										</table>
									</div>
                  </div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
