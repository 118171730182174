import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DropdownMenu from './DropdownMenu';
import './styles.css';

const Dropdown2 = ({
    containerClassName,
    placeHolder,
    options,
    value,
    onChange,
    searchable,
    searchPlaceholder,
    className,
    style,
    valueClassname,
    disabled,
    isClearable,
    menuClassName,
    containerStyle,
    costomStyle
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={classNames("my-dropdown-container", containerClassName)} style={{ ...containerStyle }}>
            <DropdownMenu
                placeHolder={placeHolder}
                valueClassname={valueClassname}
                options={options}
                value={value}
                onChange={onChange}
                searchable={searchable}
                searchPlaceholder={searchPlaceholder}
                className={classNames(className)}
                style={style}
                disabled={disabled}
                isClearable={isClearable}
                menuClassName={menuClassName}
                costomStyle={costomStyle}
            />
        </div>
    );
};

Dropdown2.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired,
        })
    ).isRequired,
    value: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    searchable: PropTypes.bool,
    searchPlaceholder: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    isClearable: PropTypes.bool,
};

Dropdown2.defaultProps = {
    searchable: false,
    searchPlaceholder: 'Search...',
    disabled: false,
    isClearable: true,
};

export default Dropdown2;
