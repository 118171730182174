import { Modal, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import Pagination from '../../../../Commoncomponet/Pagination';
import { geModemIdWiseColumnName, getConfigretionCMD_TYPEWisePendding_Count_buffer, getConfigretionCMD_TYPEWisePendding_Data_buffer, getHeardbeatData, getScriptCodeIdWiseColumnName, getScriptCodeIdWiseConfigretionData, onModemConfigByGSMConsumer } from '../../../../Commoncomponet/Utils';
import clockPending from '../../../../assets/img/icons8-clock.gif'
import swal from 'sweetalert2';
import {BootstrapTooltip, socket} from '../../../../Commoncomponet/common'
import useInterval from 'use-interval';
import DynamicTable from '../../../../Commoncomponet/DynamicTable';
export default function APMEvent(props) {
	const [FlagMsg,setFlagMsg]=useState(false)
	const [modelTitle,setmodelTitle]=useState('')
	const [modal,setModal]=useState(false)
	  const [MqttMsg,setMqttMsg]=useState('')
	  const [time, setTime] = useState(60);
	  const [run, setRun] = useState(false);
	const [modalMsg,setmodalMsg]=useState(false)
    const [PageSize, setPageSize] = useState(50);
	const [numberofPage, setnumberofPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [loader, setLoader] = useState(false);
	const [heartData, setHeartData] = useState([])
    const [ColumnName,setColumnName]=useState({})
	const [pendingBuffer,setpendingBuffer]=useState('')
	var [CommandJson,setCommandJson]=useState({})
    var [CommandmsgJson,setCommandmsgJson]=useState({})
    const [CommandStatus,setCommandStatus]=useState('')
	const mytime = useRef(null)
	const CloseModel=()=>{
		setFlagMsg(true)
		setRun(false);
		setTime(0)
		setModal(false)
		setCommandmsgJson({})
	  setCommandStatus('')
	  //   swal.fire('', 'Command Add In Buffer', 'success');
	  }
	  const ongetConfigretionCMD_TYPEWisePendding_Count_buffer=async()=>{
		const data=await getConfigretionCMD_TYPEWisePendding_Count_buffer({CONSUMER_ID:props.GSMdata.UNIQUE_ID,CMD_TYPE:'APM_EVENT',tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.GSM_BUFFER})
		if(data.success===true){
			setpendingBuffer(data.data.data)
		}else{
			console.log('Something Went Wrong')
		}
	}
    const ongetHeardbeatData = async () => {
		setLoader(true)
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		const data = await getHeardbeatData({ start: firstPageIndex, end: PageSize }, { tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.APM_EVENT,changeCol:true,colName:'MMD_TS_APME' })
		if (data.success === true) {
			setHeartData(data.data.data)
			setnumberofPage(data.data.total)
			setLoader(false)
		} else {
			console.log('Something Went Wrong')
			setLoader(false)
		}
	}
	useEffect(() => {
		ongetHeardbeatData();
	  }, [currentPage, PageSize]);
	const ongetScriptCodeIdWiseColumnName=async()=>{
		if(props?.GSMdata?.SCRIPTDECODE_ID&&props?.GSMdata?.METER_TYPE){
        const data=await getScriptCodeIdWiseColumnName(props?.GSMdata?.SCRIPTDECODE_ID)
        if(data.success===true){
            if(data?.data?.data?.length>0){
            setColumnName(JSON.parse(data?.data?.data[0]?.COLUMN_NAME||'{}'))
            }
        }else{
console.log('Something Went Wrong');
        }
	}else{
		const data=await geModemIdWiseColumnName(props?.GSMdata?.MODEM_TYPE)
        if(data.success===true){
            if(data?.data?.data?.length>0){
            setColumnName(JSON.parse(data?.data?.data[0]?.COLUMN_NAME||'{}'))
            }
        }else{
console.log('Something Went Wrong');
        }
	}
    }
    useEffect(()=>{
        ongetHeardbeatData()
		ongetScriptCodeIdWiseColumnName()
		ongetConfigretionCMD_TYPEWisePendding_Count_buffer()

    },[])
	
	const ongetScriptCodeIdWiseConfigretionData=async()=>{
		const data=await getScriptCodeIdWiseConfigretionData(props?.GSMdata?.SCRIPTDECODE_ID)
		if(data.success===true){
			const CMD=JSON.parse(data?.data?.data[0]?.METER_QUERY_CMD||'{}')
			const obj={"MMD_ID_CMD":154,"SCR_TS_SYNC":new Date().getTime()+1,"MMD_ID_CMD_TYPE":"APM_EVENT","MMD_ID_POTP":"1234","MMD_ID_COTP":"5678",UNIQUE_ID:props?.GSMdata?.UNIQUE_ID,MMD_ID_IMEI:props?.GSMdata?.IMEI,SCRIPTDECODE_ID:props?.GSMdata?.SCRIPTDECODE_ID,MMD_ID_MSG:new Date().getTime()+1,TIMEZONE:props?.GSMdata?.TIMEZONE,GSM_BUFFER_TABLE:props?.GSMdata?.UNIQUE_TABLE_NAME?.GSM_BUFFER}
			  setFlagMsg(false)
			  setmodelTitle(obj.SCR_ID_CMD_TYPE)
			//   alert(JSON.stringify(obj))
			  setCommandJson(obj)

			  swal.fire({
				  title: "Are you sure?",
				  text: "This will initiate downlink",
				  icon: "warning",
				  showCancelButton: true,
			  }).then(async (willDelete) => {
				  if (willDelete.isConfirmed) {
			  setModal(true)
			  
			  const data=await onModemConfigByGSMConsumer({config_info:JSON.stringify(obj)})
			  if(data.success===true){
				mytime.current =setTimeout(() => {
					console.log(CommandStatus);
					if(Object.keys(CommandStatus).length===0)
										 	{
											 setCommandStatus({SCR_STATUS_CMD_ADD:'Time Out'})
										 }
										 },5000)  
			  }else{
				 
			  }
		  }
		  })
		}
	}

	useInterval(
		() => {
			if (time <= 1) {
				setRun(false);
				setModal(false)
				
			}
			setTime((preState) => preState - 1);
		},
		time && run ? 1000 : null
	  );
	useEffect(() => {
		socket.on('mqttbufferresponse',(massges)=>{
			console.log(massges);
			setCommandStatus(massges)
			
		})
		socket.on('mqttcommandresponse',(massges)=>{
			console.log(massges,CommandJson,massges.MSGID==CommandJson.MSGID,massges.SCRIPTDECODE_ID==CommandJson.SCRIPTDECODE_ID,massges.UNIQUE_ID==CommandJson.UNIQUE_ID,massges.CMD_TYPE==CommandJson.CMD_TYPE,massges.DRXTYPE,!massges.STATUS);
			setCommandmsgJson(massges)
		})
	}, []);
const BfferError=(msg)=>{
console.log(msg.MSGID,CommandJson.MSGID);
if(msg.MMD_ID_MSG==CommandJson.MMD_ID_MSG){
swal.fire('', msg?.SCR_STATUS_CMD_ADD, 'error');
setCommandStatus('')
setFlagMsg(true)
setRun(false);
setTime(0)
setModal(false)
}
}
useEffect(()=>{
if(CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'&&Object.keys(CommandStatus).length>0){

	BfferError(CommandStatus)
		clearTimeout(mytime.current);
		mytime.current = null
}else if(CommandStatus?.SCR_STATUS_CMD_ADD=='Added In Buffer'){
	
	setTime(60)
setRun(true);
	clearTimeout(mytime.current);
	clearTimeout(mytime.current);
		mytime.current = null
}
},[CommandStatus])
const getPopdata=(massges)=>{
console.log(massges,CommandJson);
if(massges.MMD_ID_MSG==CommandJson.MMD_ID_MSG&&massges.SCRIPTDECODE_ID==CommandJson.SCRIPTDECODE_ID&&massges.UNIQUE_ID==CommandJson.UNIQUE_ID&&massges.SCR_ID_CMD_TYPE==CommandJson.SCR_ID_CMD_TYPE&&massges.MMD_ID_DRXTYPE&&!massges.STATUS){
	setTime(0)
	setRun(false);
   setModal(false)
   if(FlagMsg===false){
   setmodalMsg(true)
   setMqttMsg(JSON.stringify(massges))
	}
	
}
}
function zeroPad(num) {
	var str = String(num);
	if (str.length < 2) {
		return "0" + str;
	}
  
	return str;
  }
useEffect(()=>{
	if(Object.keys(CommandmsgJson).length>0){
	getPopdata(CommandmsgJson)
	}
},[CommandmsgJson])

const [BufferPageSize, setBufferPageSize] = useState(50)
    const [BuffernumberofPage, setBuffernumberofPage] = useState(0)
    const [BuffercurrentPage, setBufferCurrentPage] = useState(1);
      const [BufferPenddingData,setBufferPenddingData]=useState([])
      const [modalBufferDetails,setmodalBufferDetails]=useState(false)
  
    const onBufferPenddingData=async(col)=>{
      setmodelTitle(col)
    
      setmodalBufferDetails(true)
      const firstPageIndex = (BuffercurrentPage - 1) * BuffercurrentPage;
    
      const data=await getConfigretionCMD_TYPEWisePendding_Data_buffer({CONSUMER_ID:props.GSMdata.UNIQUE_ID,CMD_TYPE:col,start:firstPageIndex,end:BufferPageSize,tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.GSM_BUFFER})
      if(data.success===true){
    setBuffernumberofPage(data.data.Total)
    setBufferPenddingData(data.data.data)
      }else{
      console.log('Something Went Wrong')
    
      }
    }
    
    useEffect(() => {
      if(BufferPenddingData.length>0){
        onBufferPenddingData()
      }
     }, [BuffercurrentPage, BufferPageSize])
  return (
    <>
	<Modal title={modelTitle+'('+(BuffernumberofPage?BuffernumberofPage:0)+')'}  visible={modalBufferDetails} className="my-modal-class" footer='' maskClosable={false} onCancel={() => (setmodalBufferDetails(false))} width={1500}>

<div className="table-responsive mt-2">
								<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
									<div class="dataTables_length ml-2" id="table_id_length">
										<label>
											Show{" "}
											<select name="table_id_length " aria-controls="table_id" class="" onChange={(e) => (setBufferPageSize(e.target.value))} value={BufferPageSize}>
												<option value={10}>10</option>
												<option value={25}>25</option>
												<option value={50}>50</option>
												<option value={100}>100</option>
											</select>{" "}
											entries
										</label>
									</div>
									
									<table id="table_id" className="tableCustom table table-striped">
										<thead>
											<tr>
											{ ColumnName?.GSM_BUFFER?.length>0&&ColumnName?.GSM_BUFFER?.map((col)=>(col?.Display==true?<th className=""><BootstrapTooltip title={col?.Column}><span>{col?.Header}</span></BootstrapTooltip></th>:''))}
											</tr>
										</thead>
										<tbody>
											{BufferPenddingData.length > 0 && BufferPenddingData.map((item, id) => (
												<tr className="trheigth" >
												 { ColumnName?.GSM_BUFFER?.length>0&&ColumnName?.GSM_BUFFER?.map((col)=>(col?.Display==false?'':col?.DataType=='DATETIME'?<td style={{ minWidth: "100px" }}>{item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''}</td>:col.Column=='SCR_STATUS_CMD'?<td>{item?.SCR_STATUS_CMD==1? <div class="badge badge-success badge-shadow">Success</div>:item?.SCR_CNT_CMD_RETRY<0&&item?.SCR_STATUS_CMD==0?<div class="badge badge-warning badge-shadow">Waiting For Downlink</div>:item?.SCR_STATUS_CMD==2?<div class="badge badge-info badge-shadow">Canceled By User</div>:item?.SCR_STATUS_CMD==-1?<div class="badge badge-danger badge-shadow">Timeout</div>:item?.SCR_CNT_CMD_RETRY>=0&&item?.SCR_STATUS_CMD==0?<div class="badge badge-primary text-white badge-shadow">Waiting For Uplink</div>:''}</td>:col.Column=='SCR_ID_CMD_ADDED_BY'?<td>{item?.SCR_ID_CMD_ADDED_BY===0?<div class="badge badge-warning badge-shadow">Script</div>:<div class="badge badge-success badge-shadow">User</div>}</td>:<td>{item[col.Column]}</td>))}
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
							<><div className='d-flex justify-content-between'>
								<div>Total Number Of Row {BuffernumberofPage||0}</div><Pagination
									className="pagination-bar"
									currentPage={BuffercurrentPage}
									totalCount={BuffernumberofPage || 0}
									pageSize={BufferPageSize}
									onPageChange={(page) => setBufferCurrentPage(page)}
								/></div></>
</Modal>
		  <Modal  visible={modal} footer='' maskClosable={false} onCancel={() => (setModal(false))} width={500}>
    <h5 class="modal-title text-center" id="meter_data_mqtt_info_txt">Getting {modelTitle} Command</h5>
    <h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{!CommandStatus?.SCR_STATUS_CMD_ADD&&CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'?<img className='mr-2' src={clockPending} height='20px' width={'20px'}></img>:CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'?<i className='fas fa-times mr-2' style={{color:'red'}}></i>:<i className='fas fa-check mr-2' style={{color:'green'}}></i>}{!CommandStatus?.SCR_STATUS_CMD_ADD?'Command Pending':CommandStatus?.SCR_STATUS_CMD_ADD}</h5>
    <div class=""> 
   { CommandStatus?.SCR_STATUS_CMD_ADD=='Added In Buffer'? <div class="text-center" style={{marginTop:"10px",fontSize: "20px"}}>
                     <label style={{fontWeight:"400"}}>Waiting For Respose</label>
                     <div id="meter_data_mqtt_timer_status" style={{fontSize: "18px"}}>{`${zeroPad(time)}`} seconds left</div>
                  </div>:''}
               </div>
               <div class="text-center" style={{marginTop:"20px"}}>
                  <input type="button" class="btn btn-danger " value="Close" id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={CloseModel}/>
               </div>
            </Modal>
            <Modal  visible={modalMsg} footer='' maskClosable={false} onCancel={() => (setmodalMsg(false))} width={500}>
			<div style={{minHeight:'100px'}}>
    <DynamicTable data={JSON.parse(MqttMsg||'{}')} maxDepth={2}/>
    </div>
            </Modal>
        <section className="card-diff-section">
				<div className="section-body">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h4></h4>
									<div className="card-header-action">
										<div id="filtergrm">
										<button type="button" id="mqtt_get_cmd_402" class="btn btn-warning mr-1" onClick={()=>(onBufferPenddingData('APM_EVENT'))}>Queue({pendingBuffer})</button>
											<button type="button" id="mqtt_get_cmd_301" class="btn btn-info mr-1" onClick={ongetScriptCodeIdWiseConfigretionData}>
												Get Data
											</button>
											<a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={ongetHeardbeatData}>
												<i class="fa fa-refresh" aria-hidden="true"></i>
											</a>
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="bottom" title="Download">
												<i class="fa fa-download" aria-hidden="true"></i>
											</a>
										</div>
									</div>
								</div>
								<div className="card-body">
									{loader && (
										<div className="example">
											<Spin size="large" />
										</div>
									)}
									{heartData.length === 0 && !loader && <h4 className="text-center">No Data Found</h4>}
									{heartData.length > 0 &&
										<div className="table-responsive">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												
												<table id="table_id" className="table border table-borderless table-striped font-10">
													<thead>
														<tr className="tw-border-b">
														{ ColumnName?.APM_EVENT?.length>0&&ColumnName?.APM_EVENT?.map((col)=>(col?.Display==true?<th>{col?.Header}</th>:''))}
														</tr>
													</thead>
													<tbody >
														{heartData.length > 0 && heartData.map((item, id) => (
															<tr className="trheigth  ">
																{ ColumnName?.APM_EVENT?.length>0&&ColumnName?.APM_EVENT
															?.map((col)=>(col?.Display==true?col?.DataType=='DATETIME'?<td style={{ minWidth: "100px" }}>{item[col.Column]?moment.utc(item[col.Column]).format("DD/MM/YYYY hh:mm:ss A"):''}</td>:col.Column=='MMD_STATUS_ONLINE_OFFLINE'?<td >
															<div style={{fontSize:'10px'}} className={`badge ${item[col.Column] === 'online' ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{item[col.Column] === 'online' ? 'Online' : 'Offline'}</div>
														</td>:col?.Float==true?col?.Division==true?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{(item[col.Column]/col?.DivisionNumber).toFixed(col?.Float)}</td>:<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{col?.Float?item[col.Column].toFixed(col?.Float):item[col.Column]}</td>:
															col?.Division==true?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]/col?.DivisionNumber}</td>:
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]}</td>
															:''))}
                                                            
															{/* <td><div style={{fontSize:'10px'}} className={`badge ${item?.DEVICE_STATUS === 'online' ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{heartData[id+1]?.ENTRY_TS?moment.duration(moment(heartData[id+1]?.ENTRY_TS).diff(moment(heartData?.ENTRY_TS),'minutes')).minutes()+' minutes ago':''}</div></td> */}
															
															</tr>
														))}
													</tbody>
												</table>
												<>
													<div className="d-flex justify-content-between">
														<div>Total Number Of Row {numberofPage}</div>
														<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select
															name="table_id_length"
															aria-controls="table_id"
															class=""
															onChange={(e) => setPageSize(e.target.value)}
															value={PageSize}
														>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
														<Pagination
															className="pagination-bar"
															currentPage={currentPage}
															totalCount={numberofPage || 1}
															pageSize={PageSize}
															onPageChange={(page) => setCurrentPage(page)}
														/>
													</div>
												</>
											</div>
										</div>}

								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
    </>
  )
}
