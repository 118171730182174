import { Checkbox, Radio } from 'antd'
import React, { useState } from 'react'
import SecurityUserAuth from './SecurityUserAuth';
import SecurityTLS from './SecurityTLS';

export default function Security(props) {
  const buttonArray = ["User auth.", "TLS",];
	const [btnText, setButtontext] = useState("User auth.");
	const onHandalebtn = (text) => {
		setButtontext(text);
	};
  return (
    // 
    <div className='tw-w-full '>
			<div className="card-body border-top border-bottom" style={{ paddingTop: "0px", paddingLeft: '15px', paddingBottom: '0px' }}>
				<div className="d-flex justify-content-between">
					<div className="buttons">
						{buttonArray.map((item, id) => (
							<button
								style={{
									// backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#22c55e').alpha(0.4).css() : '',
									// color: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#22c55e').darken(1.5).css() : '#22c55e',
									borderBottom: buttonArray.findIndex((data) => data === btnText) === id ? `3px solid ${'#6366F1'}` : '0px',
									// boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${'#22c55e'}`  : '',
								}}
								className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? '' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
								onClick={() => onHandalebtn(item)}>
								{item}
							</button>
						))}
					</div>
				</div>
			</div>
			<div className='tw-w-[50%]'>
			{btnText === 'User auth.' && <SecurityUserAuth {...props}/>}
			{btnText === 'TLS' && <SecurityTLS {...props}/>}
			</div>
		</div>
  )
}
