import React, { useEffect, useState } from 'react'
import { Lock, Users } from 'react-feather'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { activeandinactive, getAllMeter, getAllModem, GetintegrationCountByStructureId, getScriptCodeIdWiseColumnName, getScriptdecodeId, GetStructureById, InserIntegrationStructure, InsertScriptdecodeId, sentTriggerCardStatusUpdate, SyncScriptIdWiseTableColumn, UpdateIntegrationStructure, UpdatScriptdecodeId } from '../../../Commoncomponet/Utils'
import { toast } from 'react-toastify'
import { BootstrapTooltip, btnColor, exportExcelDownload } from '../../../Commoncomponet/common'
import DraggabalTable from '../../../Commoncomponet/DraggabalTable'
import { Modal } from 'antd'
import clockPending from '../../../assets/img/icons8-clock.gif'
import { socket } from '../../../Commoncomponet/common'
import { useRef } from 'react'
import Select from 'react-select';
import { Accordion, Card } from 'react-bootstrap'
import swal from "sweetalert2";
import chroma from 'chroma-js'
import { useVirtualizer } from '@tanstack/react-virtual';
window.Swal = swal;

// import 'jquery-ui/ui/widgets/sortable';
// import xlsx from 'xlsx'

export default function Add_update_structure() {
    const [modem, setModem] = useState([])
    const [Meter, setMeter] = useState([])
    const [flagBtn, setflegBtn] = useState(false);
    const [scriptId, setScriptId] = useState('')
    const [structureInfo, setstructureInfo] = useState({ name: '', description: '', json_type: '1' })
    const [inputs, setInputs] = useState({ scriptId: '' })
    const [codeid, setCodeId] = useState(inputs.modemId + '_' + inputs.meterId)
    const [status, setStatus] = useState(null)
    const [disabled, setDisable] = useState(true)
    const [ChangesJson, setChangesJson] = useState({})
    const [ModelInfo, setModelInfo] = useState(false)
    const [TableKey, setTableKey] = useState('HEARTBEAT')
    const [structureObj, setstructureObj] = useState([])
    const [finalStructure, setFinalStructure] = useState({})
    const [ConstObj, setConstObj] = useState([])
    const [activeKey, setActiveKey] = useState('');
    const [ColumnSetting, setColumnSetting] = useState(false)
    const [clickTableType, setclickTableType] = useState('')
    const [isSDIModel, setisSDIModel] = useState(false)
    const [isTableTypeModel, setisTableTypeModel] = useState(false)
    const [activeTab, setActiveTab] = useState('TABLE_HEADER_MAP')

    const userInfo = JSON.parse(window.localStorage.getItem("user_info") || {})
    const history = useHistory();

    const handleAccordionClick = (index) => {
        setActiveKey(index);
        setChangesJson(JSON.parse(modem?.filter((item) => (item?.NAME == index))[0]?.COLUMN_NAME || '{}'))
    };


    const onHandaleSelection = async () => {
        const data = await getScriptdecodeId()
        if (data.success === true) {
            setModem(data.data.data)
        } else {
            console.log('somthing Went wrong')
        }
    }

    useEffect(() => {

        onHandaleSelection()
    }, []);

    useEffect(() => {

        setCodeId(inputs.modemId + '_' + inputs.meterId)
    }, [inputs]);
    const locations = useLocation()
    useEffect(() => {
        if (locations?.state) {
            // setInputs({modemId:locations?.state?.MODEM_ID,meterId:locations?.state?.METER_ID})
            setScriptId(locations?.state?.unique_id)
            onGetStructureById(locations?.state?.unique_id)
            setDisable(false)

        }
    }, [locations]);

    const onGetStructureById = async (id) => {
        const data = await GetStructureById(id)
        if (data.success === true) {
            setStatus(data?.data.data[0]?.status)
            setFinalStructure(JSON.parse(data?.data.data[0]?.data_structure || '{}'))
            setstructureInfo({ name: data?.data.data[0]?.name, description: data?.data.data[0]?.description,json_type:data?.data.data[0]?.json_type||'1' })
        } else {
            console.log('something Went wrong')
        }
    }



    const onsubmitHandale = async (e) => {
        if (e) {
            e.preventDefault()
        }
        if (inputs.modemId !== '' && inputs.meterId !== '') {
            setflegBtn(true)
            if (scriptId === '') {
                const data = await InserIntegrationStructure({ name: structureInfo?.name, description: structureInfo?.description, add_by: userInfo?.UNIQUE_ID, finalStructure: finalStructure,json_type:structureInfo?.json_type}) 
                if (data.success === true) {
                    toast.success('Added Success')
                    setflegBtn(false)
                    history.push('/integration')
                } else {
                    toast.error('Something Went Wrong')
                    setflegBtn(false)
                }
            } else {
                const data = await UpdateIntegrationStructure({ name: structureInfo?.name, description: structureInfo?.description, add_by: userInfo?.UNIQUE_ID, finalStructure: finalStructure,json_type:structureInfo?.json_type }, scriptId)
                if (data.success === true) {
                    toast.success('Updated Success')
                    setflegBtn(false)
                    setDisable(false)
                    onGetStructureById(locations?.state?.unique_id)
                } else {
                    toast.error('Something Went Wrong')
                    setflegBtn(false)
                }
            }
        }
    }

    const oninactive = async () => {
        swal.fire({
            title: `are you sure Inactive Structure ?`,
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                const inteagration_count = await GetintegrationCountByStructureId(scriptId)
                if (inteagration_count.success === true) {
                    if (inteagration_count.data.data > 0) {
                        toast.warn(`There are ${inteagration_count.data.data} Integration related to this Structure. Please Inactive those Integration to Inactive this Structure`)
                    } else {
                        const data = await activeandinactive({ tableName: 'integration_structure_register', colName: 'status', value: '0', matchId: 'unique_id' }, scriptId)
                        if (data.success === true) {
                            toast.success('Success Inactive Structure')
                            history.push('/integration')
                        } else {
                            toast.error('Server Issue')
                        }
                    }
                } else {
                    toast.error('Integration Data Found')

                }
            }
        })
    }
    const onactive = async () => {
        const data = await activeandinactive({ tableName: 'integration_structure_register', colName: 'status', value: '1', matchId: 'unique_id' }, scriptId)
        if (data.success === true) {
            toast.success('Success Active Structure')
            history.push('/integration')
        } else {
            toast.error('something Went wrong')
        }
    }

    const onGetScriptDecodeIdColumn = async (massges) => {
        setstructureObj([...Object.entries(finalStructure[TableKey] && finalStructure[TableKey][inputs?.scriptId] && finalStructure[TableKey][inputs?.scriptId]["TABLE_HEADER_MAP"] || {}).map(([key, value]) => ({ key: key, COL: value?.COL, TYPE: value?.TYPE }))])
    }

    const onJsonAddInScriptdecodeId = async () => {
        CommonJsonUpdateTable({ ...finalStructure, [inputs.scriptId]: {} })
        // setFinalStructure({ ...finalStructure, [inputs.scriptId]: {} })
    }

    const CommonJsonUpdateTable = async (obj) => {
        const data = await UpdateIntegrationStructure({ name: structureInfo?.name, description: structureInfo?.description, add_by: userInfo?.UNIQUE_ID,json_type:structureInfo?.json_type, finalStructure: { ...obj } }, scriptId)
        if (data.success === true) {
            toast.success('Update Success')
            onGetStructureById(locations?.state?.unique_id)
            setisSDIModel(false)
            setisTableTypeModel(false)
            setColumnSetting(false)
        } else {
            toast.error('Something Went Wrong')
        }
    }

    const addRemoveSlaveData = (id) => {
        setstructureObj(structureObj?.filter((_, index) => index != id))
    }

    const addRemoveConst = (id) => {
        setConstObj(ConstObj?.filter((_, index) => index != id))
    }

    const addNewSlaveData = () => {
        var obj = { key: '', COL: '', TYPE: '' }
        setstructureObj([...structureObj, obj])
    }

    const addNewConst = () => {
        var obj = { COL: '', key: '' }
        setConstObj([...ConstObj, obj])
    }


    const onAddstructure = () => {
        var obj = finalStructure || {}
        obj[activeKey][clickTableType]["TABLE_HEADER_MAP"] = structureObj.reduce((acc, item) => {
            acc[item.key] = { COL: item.COL, TYPE: item.TYPE }
            return acc
        }, {})
        obj[activeKey][clickTableType]["CONST"] = ConstObj.reduce((acc, item) => {
            acc[item.key] = item.COL
            return acc
        }, {})
        setFinalStructure(obj)
        CommonJsonUpdateTable({ ...obj })
        setColumnSetting(false)

        toast.success('Structure Added Success. To Show Preview , Click "View Structure" Button')
    }

    const onChangeTableKeys = (e) => {
        setTableKey(e.target.value)

    }

    const onAddTable = () => {
        setFinalStructure({ ...finalStructure, [activeKey]: { ...finalStructure[activeKey], [TableKey]: { TABLE_HEADER_MAP: {}, CONST: {} } } })
        CommonJsonUpdateTable({ ...finalStructure, [activeKey]: { ...finalStructure[activeKey], [TableKey]: { TABLE_HEADER_MAP: {}, CONST: {} } } })

    }

    const openSetting = (table_type) => {
        setColumnSetting(true)
        setclickTableType(table_type)
        console.log(finalStructure,)
        setstructureObj([...Object.entries(finalStructure[activeKey][table_type]["TABLE_HEADER_MAP"] || {}).map(([key, value]) => ({ key: key, COL: value?.COL, TYPE: value?.TYPE }))])
        setConstObj(Object.entries(finalStructure[activeKey][table_type]["CONST"] || {}).map(([key, value]) => ({ key: key, COL: value })))
    }

    const onremoveTableType = (table_type) => {
        swal.fire({
            title: "Are You Sure Remove Table?",
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                var obj = finalStructure || {}
                delete obj[activeKey][table_type]
                setFinalStructure({ ...obj })
                CommonJsonUpdateTable({ ...obj })

            }
        })
    }

    const onremoveScriptdecode = (scr_id) => {
        swal.fire({
            title: "Are You Sure Remove Scriptdecode Id?",
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                var obj = finalStructure || {}
                delete obj[scr_id]
                setFinalStructure({ ...obj })
                CommonJsonUpdateTable({ ...obj })

            }
        })
    }
    const parentRef = React.useRef(null)

    const rowVirtualizer = useVirtualizer({
        count: structureObj.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 40,// Estimated row height
        overscan: 20,
    });

    const exportData = (data, fileName, type) => {
        // Create a link and download the file
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const exportTableSettingData=()=>{
		exportExcelDownload(structureObj.map((item)=>({keys:item?.key,description:ChangesJson[clickTableType]?.filter((opc) => (opc.Column == item.COL))[0]?.Desc})),`${clickTableType} DETAILS`)
    }
    return (
        <>
            <Modal visible={ModelInfo} footer='' maskClosable={false} onCancel={() => (setModelInfo(false))} width={500}>
                <div>
                    <pre>{JSON.stringify(finalStructure, null, 2)}</pre>
                </div>
            </Modal>
            <Modal title={clickTableType + ' Table setting'} visible={ColumnSetting} footer='' maskClosable={false} onCancel={() => (setColumnSetting(false))} width={900}>
                <div>
                    <div class="row ml-2  py-2">
                        <div class="d-flex " style={{ justifyContent: "space-between" }}>
                            <ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "20px", alignItems: "center" }}>
                                <li>
                                    <button
                                        style={{
                                            backgroundColor: activeTab === "TABLE_HEADER_MAP" ? chroma(btnColor[0]).alpha(0.4).css() : '',
                                            color: activeTab === "TABLE_HEADER_MAP" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
                                            borderBottom: `1px solid ${btnColor[0]}`,
                                            boxShadow: activeTab === "TABLE_HEADER_MAP" ? `0 2px 8px -3px ${btnColor[0]}` : '',
                                        }}
                                        className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "TABLE_HEADER_MAP" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
                                        data-toggle="tab"
                                        href="#home"
                                        role="tab"
                                        aria-controls="home"
                                        aria-selected="true"
                                        onClick={() => setActiveTab("TABLE_HEADER_MAP")}
                                    >
                                        Table Header Map
                                    </button>
                                </li>
                                <li>
                                    <button
                                        style={{
                                            backgroundColor: activeTab === "Const" ? chroma(btnColor[2]).alpha(0.4).css() : '',
                                            color: activeTab === "Const" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
                                            borderBottom: `1px solid ${btnColor[2]}`,
                                            boxShadow: activeTab === "Const" ? `0 2px 8px -3px ${btnColor[2]}` : '',
                                        }}
                                        className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Const" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
                                        id="profile-tab"
                                        data-toggle="tab"
                                        href="#profile"
                                        role="tab"
                                        aria-controls="profile"
                                        aria-selected="false"
                                        onClick={() => setActiveTab("Const")}
                                    >
                                        const variable
                                    </button>
                                </li>

                            </ul>
                            {activeTab === "TABLE_HEADER_MAP"&&   <BootstrapTooltip title={'download keys and description'}><button className='btn btn-info' onClick={exportTableSettingData}><span><i className='fas fa-download'></i></span></button></BootstrapTooltip>}
                        </div>
                    </div>
                    {activeTab == 'TABLE_HEADER_MAP' ? <>
                        <div className='table-responsive' >
                            <div id="table-1_wrapper" class="dataTables_wrapper no-footer tw-overflow-y-auto" ref={parentRef} style={{ height: '450px' }}>
                                <div style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                                    <table id="table_id" className="table text-center table-borderless table-striped font-12">
                                        <thead className='tw-sticky tw-top-0 tw-bg-gray-100 tw-z-[999]'>
                                            {/* <thead className=''> */}
                                            <tr className="tw-border-b">
                                                <th style={{ width: '50px' }}>No.</th>
                                                <th style={{ width: '100px' }}>Type</th>
                                                <th style={{ width: '100px' }}>Column</th>
                                                <th style={{ width: '100px' }}>keys</th>
                                                <th style={{ width: '100px' }}>Description</th>
                                                <th style={{ width: '50px' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {rowVirtualizer?.getVirtualItems()?.map((item, index) => (
                                                <tr key={item.index} style={{
                                                    height: `${item.size}px`,
                                                    transform: `translateY(${item.start - index * item.size
                                                        }px)`,
                                                }}>
                                                    <td><h6 className="d-flex tw-justify-center tw-items-center" id="strdate">
                                                        {item.index + 1} .
                                                    </h6></td>
                                                    <td>
                                                        <select class="form-control form-select second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onChange={(e) => {
                                                            const data = structureObj
                                                            data[item.index].TYPE = e.target.value
                                                            setstructureObj([...data])
                                                        }} value={structureObj[item.index].TYPE} >
                                                            <option value="">Select Type</option>
                                                            <option value="STRING">STRING</option>
                                                            <option value="NUMBER">NUMBER</option>
                                                        </select>
                                                    </td>
                                                    <td className=' tw-items-center tw-justify-center'>
                                                        <BootstrapTooltip title={structureObj[item.index]?.COL}>
                                                            <span>
                                                                <Select name='account' options={ChangesJson[clickTableType]?.map((opc) => ({ value: opc?.Column, label: opc?.Column }))} onChange={(e) => {
                                                                    const data = structureObj
                                                                    data[item.index].COL = e?.value || ''
                                                                    setstructureObj([...data])
                                                                }} value={structureObj[item.index].COL == '' ? [] : [{ value: ChangesJson[clickTableType]?.filter((opc) => (opc.Column == structureObj[item.index].COL))[0]?.Column, label: ChangesJson[clickTableType]?.filter((opc) => (opc.Column == structureObj[item.index].COL))[0]?.Column }]} isClearable styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), menu: provided => ({ ...provided, zIndex: "9999 !important" }) }} menuPortalTarget={document.body}/>
                                                            </span>
                                                        </BootstrapTooltip>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control" name="1" id="1" placeholder="key name" onChange={(e) => {
                                                            const data = structureObj
                                                            data[item.index].key = e.target.value
                                                            setstructureObj([...data])
                                                        }} value={structureObj[item.index]?.key} />
                                                    </td>
                                                    <td>
                                                    {ChangesJson[clickTableType]?.filter((opc) => (opc.Column == structureObj[item.index].COL))[0]?.Desc}
                                                    </td>
                                                    <td>
                                                        {item.index !== 0 ? <div class={`btn btn-danger text-white btndeactive defauldbtn mr-2 `} onClick={() => addRemoveSlaveData(item.index)}><BootstrapTooltip title='Delete'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Delete"><i class="far fa-trash-alt text-white"></i></span></BootstrapTooltip></div> : ''}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div className='d-flex tw-justify-end mt-2 mb-3'>
                            <button type="button" className="btn btn-primary mr-1" onClick={() => (addNewSlaveData())}>
                                <div className='tw-flex tw-items-center tw-gap-1 '>
                                    <i class="fas fa-plus" aria-hidden="true"></i>
                                    <span>
                                        New
                                    </span>
                                </div>
                            </button>

                        </div>
                    </> : ""}
                    {activeTab == 'Const' ? <>
                        <div className='table-responsive'>
                            <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                                <table id="table_id" className="table text-center table-borderless table-striped font-12">
                                    <thead className='tw-sticky tw-top-0 tw-bg-gray-100 tw-z-[999]'>
                                        <tr className="tw-border-b">
                                            <th style={{ width: '50px' }}>No.</th>
                                            <th style={{ width: '100px' }}>key</th>
                                            <th style={{ width: '100px' }}>Column</th>
                                            <th style={{ width: '50px' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {ConstObj?.map((item, id) => (
                                            <tr>
                                                <td><h6 className="d-flex tw-justify-center tw-items-center" id="strdate">
                                                    {id + 1} .
                                                </h6></td>

                                                <td>
                                                    <input type="text" class="form-control" name="1" id="1" placeholder="key name" onChange={(e) => {
                                                        const data = ConstObj
                                                        data[id].key = e.target.value
                                                        setConstObj([...data])
                                                    }} value={ConstObj[id]?.key} />
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control" name="1" id="1" placeholder="Column name" onChange={(e) => {
                                                        const data = ConstObj
                                                        data[id].COL = e.target.value
                                                        setConstObj([...data])
                                                    }} value={ConstObj[id]?.COL} />
                                                </td>
                                                <td>
                                                    {id !== 0 ? <div class={`btn btn-danger text-white btndeactive defauldbtn mr-2 `} onClick={() => addRemoveConst(id)}><BootstrapTooltip title='Delete'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Delete"><i class="far fa-trash-alt text-white"></i></span></BootstrapTooltip></div> : ''}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='d-flex tw-justify-end mb-3'>
                                <button type="button" className="btn btn-primary mr-1" onClick={() => (addNewConst())}>
                                    <div className='tw-flex tw-items-center tw-gap-1 '>
                                        <i class="fas fa-plus" aria-hidden="true"></i>
                                        <span>
                                            New
                                        </span>
                                    </div>
                                </button>

                            </div>
                        </div>

                    </> : ''}
                    <div className='d-flex tw-justify-start mb-3'>
                        <button type="button" className="btn btn-danger mr-1" onClick={() => (onAddstructure())}>
                            <div className='tw-flex tw-items-center tw-gap-1 '>
                                <span>
                                    Submit
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal title={'Add Scriptdecode Id'} visible={isSDIModel} footer='' maskClosable={false} onCancel={() => (setisSDIModel(false))} width={500}>
                <div class="form-row" style={{ marginLeft: "10px" }}>
                    <div class="form-group col-md-6" id="grpeui">
                        <label for="inputPassword4">Script Decode Id:</label>
                        <select class="form-control form-select modemtypename anothermodemname" required="" name="modem_profile_id" id="modem_profile_id" onChange={(e) => (setInputs({ ...inputs, scriptId: e.target.value }))} value={inputs.scriptId}>
                            <option value="">Select Script Decode Id</option>
                            {modem && modem.map((item) => (Object.keys(finalStructure || {}).filter(scr => scr == item?.NAME).length == 0 && item?.STATUS == 1 ? <option value={item?.NAME}>({item?.MODEM_NAME + '<->' + item?.METER_NAME}) {item?.NAME}</option> : ''))}
                        </select>
                        <div id="modeid"></div>
                    </div>
                    {/* <div class="form-group col-md-3" id="grpeui">
                                                    <label for="inputPassword4">Meter Profile : <span class="text-danger">*</span></label>
                                                    <select class="form-control form-select second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onChange={(e) => (setInputs({ ...inputs, meterId: e.target.value }))} value={inputs.meterId} disabled={!disabled}>
                                                        <option value="">Select Meter</option>
                                                        {Meter && Meter.map((item) => (<option value={item?.METER_UNIQUE_ID}>{item?.METER_PROFILE_NAME}</option>))}

                                                    </select>
                                                    <div id="meteid"></div>
                                                </div> */}
                    {/* <div class="form-group col-md-3" id="grpeui">
                                                    <label for="inputPassword4">Scriptdecode ID :</label>
                                                    <input class="form-control lastname" id="SCRIPTDECODE_ID" name="finalscriptid" type="text" readonly="" placeholder="Scriptdecode ID" value={codeid} />
                                                </div> */}

                </div>
                <div class="form-group  d-flex !tw-items-center ml-4" id="grpeui" >
                    <div name="submit" value='Fetch Column' className={`btn btn-primary ml-2 `} onClick={() => onJsonAddInScriptdecodeId()}>Add Script Decode Id</div>
                </div>

            </Modal>

            <Modal title={'Add Table'} visible={isTableTypeModel} footer='' maskClosable={false} onCancel={() => (setisTableTypeModel(false))} width={500}>
                <><div class="form-group col-md-8   tw-items-center" id="grpeui" style={{ paddingLeft: '0px' }}>
                    <label for="inputPassword4" className=' '>Table Names :- </label>
                    <select class="form-control form-select col-12 ml-2 second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onChange={(e) => { onChangeTableKeys(e) }} value={TableKey} >
                        <option value={''} >Select Table</option>
                        {Object.keys(ChangesJson).length > 0 && Object.keys(ChangesJson).map((item) => (Object.keys(finalStructure[activeKey] || {}).filter((info) => (info == item)).length == 0 ? (item != 'EMT_STATICS' && item !== 'MODEM_CONFIG' && item !== 'GSM_BUFFER' ? <option value={item} >{item}</option> : '') : ""))}
                    </select>

                </div></>
                <div class="form-group col-md-12 d-flex !tw-items-center mb-0" id="grpeui" >
                    <div name="submit" value='Fetch Column' className={`btn btn-primary ml-2 col-md-4`} onClick={() => onAddTable()}>Add Table</div>
                </div>
            </Modal>
            <div className="main-content">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/group-deshboard">
                                <i className="fas fa-tachometer-alt"></i> Home
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link >
                                <Lock style={{ height: "16px" }} /> Admin

                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            Integration
                        </li>
                        <li className="breadcrumb-item">
                            <Link to='/integration'>
                                Plugin
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link>
                                {scriptId !== '' ? 'Update integration Structure' : 'Add integration Structure'}
                            </Link>
                        </li>
                    </ol>
                </nav>
                <section class="section">
                    <div class="section-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4>{scriptId !== '' ? 'Update integration Structure' : 'Add integration Structure'}*</h4>
                                        <div class="card-header-action">
                                            {/* {scriptId !== '' ? <div class="btn btn-info text-white btnedit mr-2" onClick={() => (setDisable(true))}><BootstrapTooltip title='edit' ><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div> : ''} */}
                                            {scriptId !== '' && status === 1 ? <div class="btn btn-danger text-white btndeactive defauldbtn mr-2" onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div> : ''}
                                            {scriptId !== '' && status === 0 ? <div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div> : ''}
                                            <Link to="/integration" class="btn btn-info text-white align-left">
                                                Back
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="card-body">

                                        <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" onSubmit={onsubmitHandale}>
                                            <fieldset class="customLegend">
                                                <legend>Information</legend>
                                                {scriptId && <div style={{ position: 'absolute', right: '0', top: '9px' }}><div class="btn btn-info text-white btnedit mr-2" onClick={() => (setDisable(true))}><BootstrapTooltip title='edit'>
                                                    <span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
                                                        <i class="fas fa-edit  text-white"></i>
                                                    </span></BootstrapTooltip>
                                                </div>
                                                </div>}
                                                <div class="form-row" style={{ marginLeft: "10px" }}>
                                                    <div class="form-group col-md-3" id="grpeui">
                                                        <label for="inputPassword4">Name : <span class="text-danger">*</span></label>
                                                        <input type="text" class="form-control" name="1" id="1" placeholder="Name" onChange={(e) => { setstructureInfo({ ...structureInfo, name: e.target.value }) }} value={structureInfo?.name} disabled={!disabled} />
                                                    </div>
                                                    <div class="form-group col-md-3" id="grpeui">
                                                        <label for="inputPassword4">Description : <span class="text-danger">*</span></label>
                                                        <input type="text" class="form-control" name="1" id="1" placeholder="Description" onChange={(e) => { setstructureInfo({ ...structureInfo, description: e.target.value }) }} value={structureInfo?.description} disabled={!disabled} />
                                                    </div>
                                                    {scriptId && <div class="form-group col-md-3" id="grpeui">
                                                        <label for="inputPassword4">JSON Type:</label>
                                                        <select class="form-control form-select modemtypename anothermodemname" required="" name="modem_profile_id" id="modem_profile_id" onChange={(e) => (setstructureInfo({ ...structureInfo, json_type: e.target.value }))} value={structureInfo.json_type} disabled={!disabled}>
                                                            <option value="1">Standard JSON</option>
                                                            <option value="2">OPC JSON</option>
                                                        </select>
                                                        <div id="modeid"></div>
                                                    </div>}
                                                </div>
                                                <p style={{ marginLeft: "25px" }} class="text-danger">Note : * Indicates Mandatory Fields</p>
                                                {disabled ? <div class="text-left">
                                                    <input type="submit" name="submit" id="submitvn" class={`btn btn-danger ${flagBtn ? 'btn-progress disabled' : ''} ml-4 mr-3`} value={scriptId === '' ? 'Submit' : 'Update'} />
                                                    <Link class="btn btn-light text-dark" to="/integration">Cancel</Link>
                                                </div> : ''}
                                            </fieldset>
                                            {scriptId && <fieldset class="customLegend">
                                                <legend>Structure</legend>
                                                <div className="row mr-3">
                                                    <div className='d-flex ' style={{ justifyContent: 'end' }}>
                                                        <div className='btn btn-primary mb-3' onClick={() => setisSDIModel(true)}>+ Add Scriptdecode Id</div>
                                                        <div className='btn btn-info ml-2 mb-3' onClick={() => (setModelInfo(true))}>View Structure</div>
                                                        {Object.keys(finalStructure).length && <div className='btn btn-secondary text-dark ml-2 mb-3' onClick={() => (exportData(JSON.stringify(finalStructure, null, 4), `structure_${scriptId}.json`, 'application/json'))}>Download Structure</div>}
                                                    </div>
                                                </div>
                                                <div class={`form-row p-2 mr-2 ${Object.keys(finalStructure).length == 0 && 'tw-contents text-center'}`} style={{ marginLeft: "10px" }}>

                                                    {Object.keys(finalStructure).length ? <Accordion defaultActiveKey="0">
                                                        {Object.entries(finalStructure).map(([key, value], index) => (
                                                            <Card key={index}>
                                                                <Accordion.Toggle as={Card.Header} eventKey={key} onClick={() => handleAccordionClick(key)} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
                                                                    <h6 className=''>
                                                                        {index + 1}). {key}  ({modem.length && modem?.filter((item) => (item?.NAME == key))[0]?.MODEM_NAME + '<->' + modem?.filter((item) => (item?.NAME == key))[0]?.METER_NAME})
                                                                    </h6>
                                                                    <div>
                                                                        <div className='btn btn-danger  btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onremoveScriptdecode(key)}><i className="fas fa-trash"></i></div>
                                                                    </div>

                                                                </Accordion.Toggle>
                                                                <Accordion.Collapse eventKey={key}>
                                                                    <Card.Body>
                                                                        <div className="row mr-3">
                                                                            <div className='d-flex ' style={{ justifyContent: 'end' }}>
                                                                                <div className='btn btn-primary mb-3' onClick={() => setisTableTypeModel(true)}>+ Add Table</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='table-responsive'>
                                                                            <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                                                                                <table id="table_id" className="table text-center table-borderless table-striped font-12">
                                                                                    <thead className='tw-sticky tw-top-0 tw-bg-gray-100 tw-z-[999]'>
                                                                                        <tr className="tw-border-b">
                                                                                            <th style={{ width: '50px' }}>No.</th>
                                                                                            <th style={{ width: '100px' }}>Table Name</th>
                                                                                            <th style={{ width: '100px' }}>Setting</th>
                                                                                            <th style={{ width: '100px' }}>Action</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody >
                                                                                        {
                                                                                            Object.keys(finalStructure[key] || {}).map((info, index) => (
                                                                                                <tr>
                                                                                                    <td>{index + 1}</td>
                                                                                                    <td>{info}</td>
                                                                                                    <td className='tw-px-1 tw-py-3'> <div className='btn btn-primary  btn-sm !tw-px-3 hover:tw-text-white' onClick={() => { openSetting(info) }}><i className="fas fa-cog"></i></div>
                                                                                                    </td>
                                                                                                    <td className='tw-px-1 tw-py-3'> <div className='btn btn-danger  btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onremoveTableType(info)}><i className="fas fa-trash"></i></div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                        ))}
                                                    </Accordion> : <><div ><h5 >Scriptdecode Id Not Added</h5></div></>}
                                                </div>
                                                {/* <div class="text-left">
                                                    <input type="submit" name="submit" id="submitvn" class={`btn btn-danger ${flagBtn ? 'btn-progress disabled' : ''} ml-4 mr-3`} value={scriptId === '' ? 'Save and Submit' : 'Update and Save'} />
                                                    <Link class="btn btn-light text-dark" to="/integration">Cancel</Link>
                                                </div> */}
                                            </fieldset>}
                                            {/* {Object.keys(ChangesJson).length ? <><div class="form-group col-md-3 d-flex" id="grpeui" style={{ paddingLeft: '0px' }}>
                                                <label for="inputPassword4" className='d-flex tw-items-center'>Table Names :- </label>
                                                <select class="form-control form-select col-6 second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onChange={(e) => {onChangeTableKeys(e)}} value={TableKey}>
                                                    {Object.keys(ChangesJson).length > 0 && Object.keys(ChangesJson).map((item) => (<option value={item} >{item}</option>))}
                                                </select>
                                            </div></> : ''}
                                           {Object.keys(ChangesJson).length!=0&&TableKey!==''&& <div className='table-responsive'>
                                                <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                                                    <table id="table_id" className="table text-center table-borderless table-striped font-12">
                                                        <thead className='tw-sticky tw-top-0 tw-bg-gray-100 tw-z-[999]'>
                                                            <tr className="tw-border-b">
                                                                <th style={{ width: '50px' }}>No.</th>
                                                                <th style={{ width: '100px' }}>Type</th>
                                                                <th style={{ width: '100px' }}>Column</th>
                                                                <th style={{ width: '100px' }}>keys</th>
                                                                <th style={{ width: '50px' }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {structureObj?.map((item, id) => (
                                                                <tr>
                                                                    <td><h6 className="d-flex tw-justify-center tw-items-center" id="strdate">
                                                                        {id + 1} .
                                                                    </h6></td>
                                                                    <td>
                                                                        <select class="form-control form-select second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onChange={(e)=>{
                                                                            const data=structureObj
                                                                            data[id].TYPE=e.target.value
                                                                            setstructureObj([...data])
                                                                        }} value={structureObj[id].TYPE}>
                                                                            <option value="">Select Type</option>
                                                                            <option value="STRING">STRING</option>
                                                                            <option value="NUMBER">NUMBER</option>
                                                                        </select>
                                                                    </td>
                                                                    <td className=' tw-items-center tw-justify-center'>
                                                                    <Select name='account' options={ChangesJson[TableKey]?.map((item)=>({value:item?.Column,label:item?.Column}))} onChange={(e)=>{
                                                                            const data=structureObj
                                                                            data[id].COL=e.value
                                                                            setstructureObj([...data])
                                                                        }} value={structureObj[id].COL==''?[]:[{value:ChangesJson[TableKey]?.filter((item)=>(item.Column==structureObj[id].COL))[0]?.Column,label:ChangesJson[TableKey]?.filter((item)=>(item.Column==structureObj[id].COL))[0]?.Column}]}/>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" class="form-control" name="1" id="1" placeholder="key name"  onChange={(e)=>{
                                                                            const data=structureObj
                                                                            data[id].key=e.target.value
                                                                            setstructureObj([...data])
                                                                        }} value={structureObj[id]?.key}/>
                                                                    </td>
                                                                    <td>
                                                                        {id !== 0 ? <div class={`btn btn-danger text-white btndeactive defauldbtn mr-2 `} onClick={() => addRemoveSlaveData(id)}><BootstrapTooltip title='Delete'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Delete"><i class="far fa-trash-alt text-white"></i></span></BootstrapTooltip></div> : ''}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='d-flex tw-justify-end mb-3'>
                                                     <button type="button" className="btn btn-primary mr-1" onClick={() => (addNewSlaveData())}>
                                                        <div className='tw-flex tw-items-center tw-gap-1 '>
                                                            <i class="fas fa-plus" aria-hidden="true"></i>
                                                            <span>
                                                                New
                                                            </span>
                                                        </div>
                                                    </button> 
                                                   
                                                </div>
                                                </div>} */}

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
