import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Check, Lock, Users, X } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import { BootstrapTooltip } from '../../Commoncomponet/common'
import * as $ from "jquery";
import * as XLSX from 'xlsx';
import dt from "datatables.net-dt";
import jsPDF from "jspdf";
import { getmeter_obisList, syncDatabaseTabledata } from '../../Commoncomponet/Utils'
import { Modal } from 'antd'
import moment from 'moment'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import GoogleLoader from '../../Commoncomponet/Loaders/GoogleLoader'
import DataTable from '../../Commoncomponet/Datatable/Datatable'
$.fn.DataTable = dt;

export default function Obis_List() {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0)
    const [loadingMessage, setLoadingMessage] = useState("Please wait while we are fetching data, the number of records are huge. It may take some time.")

    const [loader, setLoader] = useState(false)
    const [roleFilter, setRoleFilter] = useState({ obis: '', tag: '', display: '', tagts: "", header: "" })
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [csvData, setCsvData] = useState([])
    const [isSideExapnded, setIsSideExapnded] = useState(false)
    const [SideDetails, setSideDetails] = useState({})
    const history = useHistory()

    useEffect(() => {
        setIsLoading(true)
        getData()
    }, []);

    const getData = async () => {
        const firstPageIndex = (currentPage) * 50;

        const getDetails = await getmeter_obisList({})
        if (getDetails.success === true) {
            setTableData(getDetails.data.data)
            setTotal(getDetails.data.data?.length || 0)
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            console.log("Error while getting all table info = ", getDetails.err)
        }
    }

    // type: key === "created_at" || key === "updated_at" || key === "last_modified" ? 'datetime' : 'text',
    // cellRenderer: key === "size" ? (row) => `${convertToMb(row).toFixed(2)} mb` : null,

    const columns = [{
        field: "SRNO",
        label: "Unique No",
    },
    {
        field: "OBIS",
        label: "OBIS",
    },
    {
        field: "TAG",
        label: "Tag",
    },
    {
        field: "TAGTS",
        label: "Extended Register Date Time",
    },
    {
        field: "HEADER",
        label: "Header",
    },
    {
        field: "IS_FLOAT",
        label: "Float",
    },
    {
        field: "FLOAT_POINT",
        label: "Float Point",
    },
    {
        field: "DIVISION",
        label: "Division",
    },
    {
        field: "DIVISION_NO",
        label: "Division No",
    },
    {
        field: "MULTIPLICATION_FACTOR",
        label: "Multiplication Factor",
    },
    {
        field: "DISPLAY",
        label: "Display",
    },

]

    const csvLink = useRef();

    const downloadReport = async (isCsv) => {
        if (tableData.length > 0) {
            const finalCsvData = await tableData.map((item) => ({ 'SRNO': item.SRNO, 'Unique ID': item.UNIQUE_ID, 'Created Time': moment(item.CREATED_AT).format('DD-MM-YYYY'), 'Created By': item.CREATED_BY, 'Name': item.METER_CATEGORY_NAME, 'Status': (item.STATUS === 1 ? 'Active' : 'InActive') }))
            setCsvData(finalCsvData)
            if (isCsv === true) {
                if (finalCsvData.length > 0) {
                    csvLink.current.link.click();
                }
            }
            else {
                if (finalCsvData.length > 0) {
                    const worksheet = XLSX.utils.json_to_sheet(finalCsvData);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    XLSX.writeFile(workbook, `Meter-category-data.xlsx`);
                }
            }
        }
    }


    const UpdateRolesUser = (item) => {
        // history.push({ pathname: `/add-type`, state: item })
        setIsSideExapnded(true)
        setSideDetails(item)
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const onhandleOk = () => {

        if (roleFilter.obis === '' && roleFilter.display === '' && roleFilter?.tag !== '' || roleFilter.header === '' && roleFilter?.tagts !== '') {
            return setTableData(tableData);
        }


        const filteData = tableData?.filter((item) =>
            (roleFilter?.obis !== '' ? item?.OBIS?.includes(roleFilter?.obis) : true) &&
            (roleFilter?.tag !== '' ? item?.TAG?.includes(roleFilter?.tag) : true) &&
            (roleFilter?.header !== '' ? item?.HEADER?.includes(roleFilter?.header) : true) &&
            (roleFilter?.tagts !== '' ? item?.TAGTS?.includes(roleFilter?.tagts) : true) &&
            (roleFilter?.display !== '' ? item?.DISPLAY == roleFilter?.display : true)
        );

        console.log(filteData);
        setTableData(filteData);
        setIsModalVisible(false)
    };


    const syncList = async () => {
        setLoader(true);
        $(".loader").fadeOut("slow");
        const userInfo = JSON.parse(window.localStorage.getItem("user_info") || {})
        const data = await syncDatabaseTabledata({ createdBy: userInfo?.UNIQUE_ID, tableName: 'pcmt_eums_obis_list', synctableName: 'pcst_eums_obis_list' })
        if (data.success === true) {
            toast.success("Synced successfully !");
            setLoader(false)
        }
        else {
            setLoader(false)
            console.log("Error while getting all table info = ", data.err)
        }
    }

    return (
        <>
            <Modal title="Filter OBIS List" visible={isModalVisible} footer="" onCancel={handleCancel}>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="inputState">OBIS Name:</label>
                        <input type="text" class="form-control" id="" onChange={(e) => (setRoleFilter({ ...roleFilter, obis: e.target.value }))} value={roleFilter.obis} name="FirstName" placeholder="Enter Meter Category Name" />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputState">Tag Name:</label>
                        <input type="text" class="form-control" id="" onChange={(e) => (setRoleFilter({ ...roleFilter, tag: e.target.value }))} value={roleFilter.tag} name="FirstName" placeholder="Enter Meter Category Name" />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputState">Extended DateTime:</label>
                        <input type="text" class="form-control" id="" onChange={(e) => (setRoleFilter({ ...roleFilter, tagts: e.target.value }))} value={roleFilter.tagts} name="FirstName" placeholder="Enter Meter Category Name" />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputState">Header:</label>
                        <input type="text" class="form-control" id="" onChange={(e) => (setRoleFilter({ ...roleFilter, header: e.target.value }))} value={roleFilter.header} name="FirstName" placeholder="Enter Meter Category Name" />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputPassword4">Status:</label>
                        <select
                            id="inputState"
                            class="form-select form-control"
                            name="modem_name"
                            onChange={(e) => (setRoleFilter({ ...roleFilter, display: e.target.value }))}
                            value={roleFilter.display}
                        >
                            <option value="">Select Status</option>
                            <option value={1}>Active</option>
                            <option value={0}>InActive</option>
                        </select>
                    </div>
                </div>
                <div class="text-left mt-2">
                    <input type="submit" class="btn btn-danger" value="Filter" name="filter_customer_form" onClick={onhandleOk} />
                    <Link class="btn btn-light text-dark ml-2" to={`/OBISList`}>
                        Reset
                    </Link>
                </div>
            </Modal>
            {loader ? (
                <div className="main-content">
                    <div class="loader"></div>
                </div>
            ) : (
                ""
            )}
            <section className="section">
                <div className="section-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4><span className='mr-2'>OBIS List</span><BootstrapTooltip title={`Table Name is meter_category`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
                                    <div className="card-header-action">
                                        <button className="btn btn-primary mr-2" onClick={() => syncList()}>Sync Data</button>
                                        <button type="button" className="btn btn-info mr-2" onClick={() => (setIsModalVisible(true))}>
                                            <BootstrapTooltip title="Filter">
                                                <span>
                                                    <i className="fa fa-filter text-white"></i>
                                                </span>
                                            </BootstrapTooltip>
                                        </button>
                                        <div className="dropdown d-inline mr-2">
                                            <button
                                                className="btn btn-info dropdown-toggle mr-2"
                                                type="button"
                                                id="dropdownMenuButton3"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                data-placement="bottom"
                                                title="Download"
                                            >
                                                <i className="fa fa-download mr-1"></i>
                                            </button>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item" >
                                                    PDF
                                                </a>
                                                <a onClick={() => downloadReport(false)} className="dropdown-item" >
                                                    Excel
                                                </a>
                                                <a onClick={() => downloadReport(true)} className="dropdown-item">
                                                    CSV
                                                </a>
                                                <CSVLink data={csvData} filename={"Meter-category-data.csv"} ref={csvLink} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className='card-body !tw-p-0 mb-4'>
                                    {isLoading ? <> <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                                        <GoogleLoader bodyClassName={'tw-h-[70%]'} msg={loadingMessage} />
                                    </div></> : <><div >
                                        <div >
                                            <DataTable
                                                data={tableData}
                                                columns={columns}
                                                initialSortField="size"
                                                initialSortDirection="desc"
                                                pageSize={50}
                                                filterable
                                                tableClassName='tableCustom table table-striped tw-w-full tw-text-sm text-center'
                                                theadClassName='tw-sticky tw-z-50 tw-top-0 tw-text-xs tw-bg-gray-100'
                                                onRowClick={(row) => {
                                                    setIsSideExapnded(true)
                                                    setSideDetails(row)
                                                }}
                                                setParentCurrentPage={(page) => setCurrentPage(page)}

                                            />

                                        </div>
                                    </div>

                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* </div> */}
        </>
    )
}
