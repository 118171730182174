import { Modal, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { saveFilterToDb } from '../../Commoncomponet/Utils';
import { toast } from 'react-toastify';
import MultiInput from '../../Commoncomponet/MultiInput/MultiInput';
import classNames from 'classnames'

const FilterDisplay = ({ filter, onClearFilter, onClearIndividualFilter }) => {

    const [isFilterData, setIsFilterData] = useState(false)

    if (Object.entries(filter).length === 0) {
        return null
    }
    const handleClearIndividualFilter = (key, value) => {
        onClearIndividualFilter(key, value);
    };
    const countTotalItemsInArrays = (filter) => {
        let totalCount = 0;


        for (const key in filter) {

            if (typeof filter[key] === 'object' && !Array.isArray(filter[key])) {
                Object.values(filter[key]).forEach(value => {

                    if (Array.isArray(value)) {
                        totalCount += value?.length;
                    }
                });
            } else if (typeof filter[key] === 'string') {
                totalCount += 1
            } else if (typeof filter[key] === 'boolean') {
                totalCount += 1
            }
        }
        return totalCount;
    };

    const getTotalFilterNumber = countTotalItemsInArrays(filter)
    console.log("TaskFilter/FilterDisplay/baseFilter", filter, getTotalFilterNumber);

    return (
        <div className="tw-px-1 tw-flex tw-items-center tw-gap-2">
            <div className="tw-flex tw-flex-wrap tw-gap-2 tw-text-xs tw-items-center">
                <div className={classNames(isFilterData ? "tw-hidden" : "tw-inline-block", "xl:tw-hidden")}>
                    <span className='tw-bg-orange-100 tw-whitespace-nowrap tw-rounded-md tw-text-sm tw-px-2 tw-py-1 border !tw-border-orange-500 hover:tw-bg-orange-200 tw-transition-all' onClick={() => { setIsFilterData(true) }}>
                        <i class="fas fa-plus" aria-hidden="true"></i>
                        <span className='tw-ml-1'>{getTotalFilterNumber}</span></span>
                </div>
                <div className={classNames(isFilterData ? "tw-flex" : "tw-hidden", "tw-items-center tw-flex-wrap tw-gap-1", "xl:tw-flex")}>

                    {Object.entries(filter).length > 0 && Object.entries(filter).map(([key, value]) => {
                        return (
                            <>
                                <strong className="tw-mr-0.5">{key} : {value?.type}</strong>
                                {
                                    Array.isArray(value?.values) ? key == 'taskid' ? (
                                        value?.values.map((v, i) => (
                                            <span key={i} className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500">
                                                {v}
                                                <button onClick={() => handleClearIndividualFilter(key, v)} class="tw-border-none tw-font-bold tw-ml-1 tw-bg-transparent tw-transition-all tw-rounded hover:tw-bg-red-500/30 hover:tw-text-red-600 tw-px-1.5">×</button>
                                            </span>
                                        ))
                                    ) : (
                                        value?.values.map((v, i) => (
                                            <span key={i} className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500">
                                                {v?.label}
                                                <button onClick={() => handleClearIndividualFilter(key, v?.value)} class="tw-border-none tw-font-bold tw-ml-1 tw-bg-transparent tw-transition-all tw-rounded hover:tw-bg-red-500/30 hover:tw-text-red-600 tw-px-1.5">×</button>
                                            </span>
                                        ))
                                    ) : typeof value?.values === 'string' &&
                                        value?.values !== null
                                        ? (
                                            <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500">
                                                {value?.values}
                                                <button onClick={() => handleClearIndividualFilter(key, value)} class="tw-border-none tw-font-bold tw-ml-1 tw-bg-transparent tw-transition-all tw-rounded hover:tw-bg-red-500/30 hover:tw-text-red-600 tw-px-1">×</button>
                                            </span>
                                        ) : (
                                            <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500">
                                                {JSON.stringify(value)}
                                                <button onClick={() => handleClearIndividualFilter(key, value)} class="tw-border-none tw-font-bold tw-ml-1 tw-bg-transparent tw-transition-all tw-rounded hover:tw-bg-red-500/30 hover:tw-text-red-600 tw-px-1">×</button>
                                            </span>
                                        )
                                }
                            </>
                        )
                    })}
                    <div className=''>
                        <button onClick={() => { setIsFilterData(false) }} className='tw-bg-orange-100 tw-whitespace-nowrap tw-rounded-md tw-text-xs tw-px-2 tw-py-1 border !tw-border-orange-500 hover:tw-bg-orange-200 tw-transition-all xl:tw-hidden'>
                            View Less
                        </button>
                    </div>
                </div>

                <div className=''>
                    <button onClick={onClearFilter} className='tw-inline-block tw-bg-red-100 tw-whitespace-nowrap tw-rounded-md tw-text-xs tw-px-2 tw-py-1 border !tw-border-red-500 hover:tw-bg-red-200 tw-transition-all'>
                        Clear Filter
                    </button>
                </div>
            </div>
        </div>
    );
}

const SelectboxIsIsnot = ({ handleSelectChange, lable, type }) => {
    const [selectedValue, setSelectedValue] = useState(type);

    const handleChange = (e) => {
        const { value } = e.target;
        // console.log(value)
        handleSelectChange(value, lable);
    };

    return (
        <select
            className="tw-w-1/6 tw-py-[7.5px]  tw-px-1 tw-h-fit tw-border tw-border-indigo-300 tw-rounded-md focus:tw-outline-none focus:tw-border-indigo-500"
            name="priority_type"
            value={type}
            onChange={handleChange}
        >
            <option value="is">is</option>
            <option value="is_not">is not</option>
        </select>
    );
};


const TaskFilter = ({ isFilterModalOpen, setIsFilterModalOpen, handleFilter, tagsTypsOptions, taskAssignToOptions, currentUser, currentFilter, handleShowFilter, currentShowFilter, onAddFavoriteFilter, getFavFilters, storedFilterType, storedFilterS, UpdateTrigger, setUpdateTrigger,userGroupList }) => {
    const taskPriorityOptions = [
        { value: 'High', label: 'High' },
        { value: 'medium', label: 'Medium' },
        { value: 'Low', label: 'Low' },
    ];

    const taskStatusOptions = [
        { value: "-1", label: 'Open' }, 
        { value: "0", label: 'Working On It' },
        { value: "2", label: 'In Review' },
        { value: '3', label: 'On Hold' },
        { value: "1", label: 'Complete' },
        { value: "4", label: 'Cancel' },
    ];

    const taskTypeOptions = [
        { value: 'Development', label: 'Development' },
        { value: 'Action', label: 'Action' },
        { value: 'Bug', label: 'Bug' },
    ];

    const taskPlan = [
        { value: -1, label: 'Yet To Decide', color: '#54ca68' },
        { value: 1, label: 'Today', color: '#fc544b' },
        { value: 0, label: 'This Week', color: '#ffa426' }
    ]

    const timeDuration = [
        { value: '1', label: '7 Days ago' },
        { value: '2', label: '15 Days ago' },
        { value: '3', label: '1 month ago' },
        { value: '4', label: '2 month ago' },
        { value: '5', label: '3 month ago' },
        { value: '6', label: '6 month ago' },
        { value: '7', label: '1 Year ago' },
        { value: '8', label: 'More Than 1 Year' },
    ]

    const taskDueStatus = [
        { value: -4, label: 'Above 45 days' },
        { value: -3, label: 'Past 31-45 Days' },
        { value: -2, label: 'Past 16-30 Days' },
        { value: -1, label: 'Past 1-15 Days' },
        { value: 1, label: 'Todays Overdue' },
        { value: 2, label: 'Next 1-15 Days' },
        { value: 3, label: 'Next 16-30 Days' },
        { value: 4, label: 'Next 31-45 Days' },
        { value: 5, label: 'Above 45 days' },
        { value: 6, label: 'No Deadline Dates tasks.' }
    ]

    const taskConsumerTypeOptions = [
        { value: 'gsm', label: 'GSM' },
        { value: 'lorawan', label: 'LORAWAN' },
    ];

    const allFilterOptions = {
        taskGroups:userGroupList,
        taskStatus: taskStatusOptions,
        taskAssignTo: taskAssignToOptions,
        taskAssignByMe: taskAssignToOptions,
        taskPriority: taskPriorityOptions,
        taskType: taskTypeOptions,
        taskConsumerType: taskConsumerTypeOptions,
        taskTags: tagsTypsOptions,
        taskCreatedBy: taskAssignToOptions,
        Plan: taskPlan,
        SharedWith: taskAssignToOptions,
        DuedateStatus: taskDueStatus,
        createdAt: timeDuration,
        updatedAt: timeDuration,
    };

    const [dummyFilter, setDummyFilter] = useState();
    const [dummyshowFilterObj, setDummyShowFilterObj] = useState();


    const [filters, setFilters] = useState({
        taskid: {
            "type": "is",
            "values": []
        },
        taskTitle: {
            "type": "is",
            "values": ''
        },
        taskAssignTo: {
            "type": "is",
            "values": [taskAssignToOptions.filter(item => item.value === currentUser)[0]?.value]
        },
        taskAssignByMe: {
            "type": "is",
            "values": []
        },
        taskAssignToMe: {
            "type": "is",
            "values": ''
        },
        taskConsumerType: {
            "type": "is",
            "values": []
        },
        taskStatus: {
            "type": "is",
            "values": ["-1", '0', '3', '2']
        },
        taskPriority: {
            "type": "is",
            "values": []
        },
        taskType: {
            "type": "is",
            "values": []
        },
        taskTags: {
            "type": "is",
            "values": []
        },
        Plan: {
            "type": "is",
            "values": []
        },
        overdue: false,
        todays: false,
        weeks: false,
        UnreadOnly: false,
        taskCreatedBy: {
            "type": "is",
            "values": []
        },
        SharedWith: {
            "type": "is",
            "values": []
        },
        createdAt: {
            'type': 'is',
            'values': ''
        },
        updatedAt: {
            'type': 'is',
            'values': ''
        },
        taskGroups: {
            'type': 'is',
            'values': ''
        },
        DuedateStatus: '',
    });


    const [showFilterObj, setShowFilterObj] = useState({
        taskAssignTo: { type: "is", values: [taskAssignToOptions.filter(item => item.value === currentUser)[0]] },
        taskStatus: { type: "is", values: [{ value: "-1", label: 'Open' }, { value: "0", label: 'Working On It' }, { value: "3", label: 'On Hold' }, { value: "2", label: 'In Review' }] },
    })
    const [isSaveFilter, setIsSaveFilter] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [filterName, setFilterName] = useState('')
    const [PropFilterName, setPropFilterName] = useState('')
    const [totalUserList, setTotalUserList] = useState([])
    const [taskTags, setTaskTags] = useState(tagsTypsOptions || [])
    // const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
    const [searchTerms, setSearchTerms] = useState([]);

    useEffect(() => {
        if (storedFilterType == 'Custom Filters' && UpdateTrigger == true) {
            if (storedFilterS) {
                // console.log(storedFilterS)
                setPropFilterName(storedFilterS?.value)
                setFilterName(storedFilterS?.value)
                setIsSaveFilter(true)
            }
        }
    }, [UpdateTrigger])

    const onFilterModalOpen = () => {
        setIsFilterModalOpen(true)
    }

    const onFilterModalClose = () => {
        if (dummyFilter && dummyshowFilterObj) {
            setFilters(dummyFilter);
            setShowFilterObj(dummyshowFilterObj)
        }
        setIsFilterModalOpen(false)
        setUpdateTrigger(false)
        setIsSaveFilter(false)
        setFilterName('')
        setPropFilterName('')
    }

    const transformFavoriteFilter = (favoriteFilter, allFilterOptions) => {


        const findLabelByValue = (options, value) => options.find(option => option.value === value)?.label;

        const transformed = {};

        Object.keys(favoriteFilter).forEach((key) => {
            const filterValues = favoriteFilter[key];

            if (Array.isArray(filterValues["values"]) && filterValues["values"].length > 0) {
                const filterOptions = allFilterOptions[key];
                if (filterOptions) {
                    if (filterValues["values"].map(value => findLabelByValue(filterOptions, value))[0]) {
                        transformed[key] = { type: filterValues["type"], values: filterValues["values"].map(value => ({ value, label: findLabelByValue(filterOptions, value) })) }
                    }
                } else {
                    transformed[key] = filterValues;
                }
            } else if (typeof filterValues === 'string' && filterValues !== '') {
                transformed[key] = filterValues;
            } else if (typeof filterValues === 'boolean' && filterValues === true) {
                transformed[key] = filterValues;
            } else if (typeof filterValues === 'number') {
                const filterOptions = allFilterOptions[key];
                transformed[key] = findLabelByValue(filterOptions, favoriteFilter[key])
            } else if (typeof filterValues === 'object' && key !== "taskAssignToMe" && filterValues['values'] !== '' && !Array.isArray(filterValues["values"])) {
                if (key === "taskTitle") {
                    transformed[key] = favoriteFilter[key]
                } if(key === 'taskGroups'){
                    transformed[key] = { type: filterValues["type"], values: filterValues['values']}
                } else {
                    transformed[key] = { type: filterValues["type"], values: timeDuration?.filter((items) => (filterValues['values'] == items.value)) }
                }
            }
        });

        // console.log("transformed", transformed);
        return transformed;
    };


    useEffect(() => {
        // console.log("currentFilter", currentFilter)
        if (currentFilter) {
            console.log("Current Filter", { currentFilter, allFilterOptions, showFilterObj })
            setFilters(currentFilter)
            const transformedFavoriteFilter = transformFavoriteFilter(currentFilter, allFilterOptions);
            setShowFilterObj(transformedFavoriteFilter);
        }
    }, [currentFilter])


    const handleInputChange = (e) => {
        if (!dummyFilter && !dummyshowFilterObj) {
            setDummyFilter(filters);
            setDummyShowFilterObj(showFilterObj)
        }
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: { type: filters[name]?.type, values: value },
        }));
        setShowFilterObj((prevFilters) => ({
            ...prevFilters,
            [name]: { type: filters[name]?.type, values: value },
        }));
        if (value === '') {
            setShowFilterObj((prevFilters) => {
                const updatedFilters = { ...prevFilters };
                delete updatedFilters[name]
                return updatedFilters
            });
        }
    };
    const handleCheckboxChange = (e) => {
        if (!dummyFilter && !dummyshowFilterObj) {
            setDummyFilter(filters);
            setDummyShowFilterObj(showFilterObj)
        }
        const { name, checked } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: checked,
        }));
        setShowFilterObj((prevFilters) => ({
            ...prevFilters,
            [name]: checked,
        }));
        if (!checked) {
            setShowFilterObj((prevFilters) => {
                const updatedFilters = { ...prevFilters };
                delete updatedFilters[name]
                return updatedFilters
            });
        }
    };

    const handleSaveFilterCheckboxChange = (e) => {
        setIsSaveFilter(e.target.checked)
    }

    const handleFilterNameChange = (e) => {
        setFilterName(e.target.value)
    }

    const handleSaveFilter = async () => {
        onAddFavoriteFilter(filterName, filters)
        if (filterName) {
            const addFilter = await saveFilterToDb({ filterName: filterName, filterData: filters, userId: currentUser, IsEdit: PropFilterName ? true : false, PropFilterName: PropFilterName })
            if (addFilter.success === true) {
                toast.success("Filter Saved Successfully")
                setIsSaveFilter(false)
                setFilterName('')
                setPropFilterName('')
                getFavFilters()
            }
            else {
                toast.error("Something went wrong")
            }
        } else {
            toast.error("Enter Filter Name")
        }
    }

    const handleSelectChange = (selectedOptions, { name }) => {
        if (!dummyFilter && !dummyshowFilterObj) {
            setDummyFilter(filters);
            setDummyShowFilterObj(showFilterObj)
        }

        console.log(selectedOptions, name);

        if (Array.isArray(selectedOptions)) {
            // console.log("fg")
            setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: { ...prevFilters[name], values: selectedOptions.map(item => item.value) },
            }));
            setShowFilterObj((prevFilters) => ({
                ...prevFilters,
                [name]: { type: filters[name]?.type, values: selectedOptions },
            }));
        } else {
            // console.log("selectedOptions", selectedOptions)
            setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: { ...filters[name], values: selectedOptions.value },
            }));
            setShowFilterObj((prevFilters) => ({
                ...prevFilters,
                [name]: { ...filters[name], values: selectedOptions },
            }));
        }

        // console.log("g", filters, showFilterObj)


        if (selectedOptions.length === 0) {
            setShowFilterObj((prevFilters) => {
                const updatedFilters = { ...prevFilters };
                delete updatedFilters[name]
                return updatedFilters
            });
        }

    };

    const handleSingelSelectChange = (selectedOptions, { name }) => {
        if (!dummyFilter && !dummyshowFilterObj) {
            setDummyFilter(filters);
            setDummyShowFilterObj(showFilterObj)
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: selectedOptions?.value,
        }));
        setShowFilterObj((prevFilters) => ({
            ...prevFilters,
            [name]: selectedOptions,
        }));
        // console.log("Filter Last change", taskDueStatus?.filter((items) => (showFilterObj?.DuedateStatus == items.value)));
    }


    const handleSubmit = () => {
        setDummyFilter();
        setDummyShowFilterObj()
        handleFilter(filters);
        handleShowFilter(showFilterObj);
        onFilterModalClose()

    };

    const onClearFilter = async () => {
        setFilters({
            taskid: {
                "type": "is",
                "values": []
            },
            taskTitle: {
                "type": "is",
                "values": ''
            },
            taskAssignTo: {
                "type": "is",
                "values": []
            },
            taskAssignByMe: {
                "type": "is",
                "values": []
            },
            taskAssignToMe: {
                "type": "is",
                "values": ''
            },
            taskConsumerType: {
                "type": "is",
                "values": []
            },
            taskStatus: {
                "type": "is",
                "values": []
            },
            taskPriority: {
                "type": "is",
                "values": []
            },
            taskType: {
                "type": "is",
                "values": []
            },
            taskTags: {
                "type": "is",
                "values": []
            },
            Plan: {
                "type": "is",
                "values": []
            },
            overdue: false,
            todays: false,
            weeks: false,
            UnreadOnly: false,
            taskCreatedBy: {
                "type": "is",
                "values": []
            },
            SharedWith: {
                "type": "is",
                "values": []
            },
            createdAt: {
                'type': 'is',
                'values': ''
            },
            updatedAt: {
                'type': 'is',
                'values': ''
            },
            taskGroups: {
                'type': 'is',
                'values': ''
            },
            DuedateStatus: '',
        });
        handleFilter({
            taskid: {
                "type": "is",
                "values": []
            },
            taskTitle: {
                "type": "is",
                "values": ''
            },
            taskAssignTo: {
                "type": "is",
                "values": []
            },
            taskAssignByMe: {
                "type": "is",
                "values": []
            },
            taskAssignToMe: {
                "type": "is",
                "values": ''
            },
            taskConsumerType: {
                "type": "is",
                "values": []
            },
            taskStatus: {
                "type": "is",
                "values": []
            },
            taskPriority: {
                "type": "is",
                "values": []
            },
            taskType: {
                "type": "is",
                "values": []
            },
            taskTags: {
                "type": "is",
                "values": []
            },
            Plan: {
                "type": "is",
                "values": []
            },
            overdue: false,
            todays: false,
            weeks: false,
            UnreadOnly: false,
            taskCreatedBy: {
                "type": "is",
                "values": []
            },
            SharedWith: {
                "type": "is",
                "values": []
            },
            createdAt: {
                'type': 'is',
                'values': ''
            },
            updatedAt: {
                'type': 'is',
                'values': ''
            },
            taskGroups: {
                'type': 'is',
                'values': ''
            },
            DuedateStatus: '',
        });

        setShowFilterObj({});
        handleShowFilter({});
    }

    const onClearIndividualFilter = (key, value) => {

        // console.log("call",{key, value})

        const newFilters = { ...filters };
        let updatedShowFilters = { ...showFilterObj };

        if (value) {
            if (Array.isArray(newFilters[key].values)) {
                newFilters[key] = { "type": newFilters[key]?.type, "values": newFilters[key].values.filter(v => v !== value) }
                updatedShowFilters[key] = { "type": updatedShowFilters[key]?.type, "values": updatedShowFilters[key]?.values.filter(v => v.value !== value) }
            } else {
                newFilters[key] = key === 'taskTitle' ? { "type": "is", "values": '' } : false;
                delete updatedShowFilters[key];
            }
        } else {
            delete newFilters[key];
            delete updatedShowFilters[key];
        }

        if (updatedShowFilters[key]?.values?.length === 0) {
            delete updatedShowFilters[key];
        }

        setFilters(newFilters);
        handleFilter(newFilters);
        setShowFilterObj(updatedShowFilters);
        handleShowFilter(updatedShowFilters);
    };


        const onSearchMultiFilterJson = (newSearchTerms, json_name) => {
            setFilters((prevFilters) => ({
                ...prevFilters,
                taskid: { type: 'is', values: newSearchTerms }
            }))
        };

    const onItemClearedFilterJson = (clearedIndex, json_name) => {
        console.log('fdgs1', clearedIndex)
        // Create a new array without the cleared item
        const newSearchTerms = filters?.taskid?.values?.filter((_, index) => index !== clearedIndex);
        // Update the search results based on the new search terms
        onSearchMultiFilterJson(newSearchTerms, json_name);
    };
    const onClearAllItemsFilterJson = (arr, json_name) => {
        // console.log('fdgs2')
        // Reset the search results to the original data
        onSearchMultiFilterJson([], json_name);

    };

    const handleIsIsNOtChange = (selectedOption, label) => {
        if (!dummyFilter && !dummyshowFilterObj) {
            setDummyFilter(filters);
            setDummyShowFilterObj(showFilterObj)
        }

        // console.log("handleIsIsNOtChange", selectedOption, label)

        setFilters((prevFilters) => ({
            ...prevFilters,
            [label]: {
                type: selectedOption,
                values: ''
            }
        }));
        setShowFilterObj((prevFilters) => ({
            ...prevFilters,
            [label]: {
                type: selectedOption,
                values: ''
            } // Reset selected options to empty array
        }));
    };
    const onSwitchChange = (checked) => {
        if (!dummyFilter && !dummyshowFilterObj) {
            setDummyFilter(filters);
            setDummyShowFilterObj(showFilterObj)
        }
        setFilters((prevFilters) => ({
            ...prevFilters,
            UnreadOnly: checked
        }));
    };

    return (
        <>
            <Modal
                title="Title"
                visible={isFilterModalOpen}
                confirmLoading={confirmLoading}
                onCancel={onFilterModalClose}
                width={1300}
                style={{ top: 10 }}
                footer={[
                    <div className='border-top !tw-border-b-gray-300 '>

                        <div className='tw-flex tw-items-center tw-pt-2 tw-gap-2'>
                            <input type="checkbox" name="saveFilter" id='saveFilter' onChange={handleSaveFilterCheckboxChange} checked={isSaveFilter} />
                            <label className='tw-mb-0' htmlFor="saveFilter">Save this filter as a favorite</label>
                            {
                                isSaveFilter && (
                                    <>

                                        <div className='tw-w-1/3 '>
                                            <input type='text' className='form-control' name="filterName" value={filterName} onChange={handleFilterNameChange} placeholder='Filter Name' />
                                        </div>


                                        <div className='tw-w-1/3 '>
                                            <button type='button' className='btn btn-primary' onClick={handleSaveFilter}>Save</button>
                                        </div>
                                    </>
                                )
                            }

                        </div>

                        <div className='tw-flex tw-items-center '>
                            <div class="buttons tw-mt-5">
                                <button type='button' className='btn btn-primary' onClick={handleSubmit}>Filter</button>
                                <button type='button' class="btn btn-light text-dark" onClick={onFilterModalClose}>Cancel</button>
                            </div>
                        </div>
                    </div>
                ]}
            >
                <div className='tw-flex tw-flex-row tw-flex-wrap tw-gap-y-6 tw-justify-between ' >
                    <FilterFieldBody>
                        <FilterLabel id="id" >Task ID :</FilterLabel>
                        <MultiInput
                            placeholder="search and enter..."
                            onChange={(arr) => onSearchMultiFilterJson(arr, "Installation_Number")}
                            maxItems={null} // Allow any number of items
                            onClear={(arr) => onItemClearedFilterJson(arr, "Installation_Number")}
                            onClearAll={(arr) => onClearAllItemsFilterJson(arr, "Installation_Number")}
                            className={'lg:tw-min-w-[75%] tw-w-[100%] '}
                        />
                    </FilterFieldBody>

                    <FilterFieldBody>
                        <FilterLabel id="taskTitle" >Task Title :</FilterLabel>
                        <input class="tw-py-2 tw-px-3 tw-border tw-border-indigo-300 tw-rounded-md focus:tw-outline-none focus:tw-border-indigo-500 lg:tw-min-w-[75%] tw-w-[100%]" type="text" id="taskTitle" name="taskTitle" value={showFilterObj?.taskTitle?.values || ''} placeholder="Filter by title" onChange={handleInputChange}></input>
                    </FilterFieldBody>

                    <FilterFieldBody>
                        <FilterLabel id="taskAssignTo" >Task Assign To :</FilterLabel>
                        <SelectboxIsIsnot handleSelectChange={handleIsIsNOtChange} lable={"taskAssignTo"} type={showFilterObj?.taskAssignTo?.type || 'is'} />
                        <FilterSelector>
                            <Select
                                className='tw-w-full'
                                isMulti
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '#6366f1' : '#a5b4fc',
                                    }),
                                }}
                                value={showFilterObj?.taskAssignTo?.values || []}
                                name="taskAssignTo"
                                options={taskAssignToOptions}
                                onChange={handleSelectChange}
                            />
                        </FilterSelector>
                    </FilterFieldBody>

                    <FilterFieldBody>
                        <FilterLabel id="taskAssignByMe" >Task Assign By Me :</FilterLabel>
                        <SelectboxIsIsnot handleSelectChange={handleIsIsNOtChange} lable={"taskAssignByMe"} type={showFilterObj?.taskAssignByMe?.type || 'is'} />
                        <FilterSelector>
                            <Select
                                className='tw-w-full'
                                isMulti
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '#6366f1' : '#a5b4fc',
                                    }),
                                }}
                                value={showFilterObj?.taskAssignByMe?.values || []}
                                name="taskAssignByMe"
                                options={taskAssignToOptions}
                                onChange={handleSelectChange}
                            />
                        </FilterSelector>
                    </FilterFieldBody>

                    <FilterFieldBody>
                        <FilterLabel id="taskStatus" >Task Status :</FilterLabel>
                        <SelectboxIsIsnot handleSelectChange={handleIsIsNOtChange} lable={"taskStatus"} type={showFilterObj?.taskStatus?.type || 'is'} />
                        <FilterSelector>
                            <Select
                                className='tw-w-full'
                                isMulti
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '#6366f1' : '#a5b4fc',
                                    }),
                                }}
                                value={showFilterObj?.taskStatus?.values || []}
                                name="taskStatus"
                                options={taskStatusOptions}
                                onChange={handleSelectChange}
                            />
                        </FilterSelector>
                    </FilterFieldBody>

                    <FilterFieldBody>
                        <FilterLabel id="taskConsumerType" >Task Consumer Type :</FilterLabel>
                        <SelectboxIsIsnot handleSelectChange={handleIsIsNOtChange} lable={"taskConsumerType"} type={showFilterObj?.taskConsumerType?.type || 'is'} />
                        <FilterSelector>
                            <Select
                                isMulti
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '#6366f1' : '#a5b4fc',
                                    }),
                                }}
                                name="taskConsumerType"
                                value={showFilterObj?.taskConsumerType?.values || []}
                                options={taskConsumerTypeOptions}
                                onChange={handleSelectChange}
                            />
                        </FilterSelector>
                    </FilterFieldBody>

                    <FilterFieldBody>
                        <FilterLabel id="taskPriority" >Task Priority :</FilterLabel>
                        <SelectboxIsIsnot handleSelectChange={handleIsIsNOtChange} lable={"taskPriority"} type={showFilterObj?.taskPriority?.type || 'is'} />
                        <FilterSelector>
                            <Select
                                className='tw-w-full'
                                isMulti
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '#6366f1' : '#a5b4fc',
                                    }),
                                }}
                                value={showFilterObj?.taskPriority?.values || []}
                                name="taskPriority"
                                options={taskPriorityOptions}
                                onChange={handleSelectChange}
                            />
                        </FilterSelector>
                    </FilterFieldBody>

                    <FilterFieldBody>
                        <FilterLabel id="taskType" >Task Type :</FilterLabel>
                        <SelectboxIsIsnot handleSelectChange={handleIsIsNOtChange} lable={"taskType"} type={showFilterObj?.taskType?.type || 'is'} />
                        <FilterSelector>
                            <Select
                                className='tw-w-full'
                                isMulti
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '#6366f1' : '#a5b4fc',
                                    }),
                                }}
                                value={showFilterObj?.taskType?.values || []}
                                name="taskType"
                                options={taskTypeOptions}
                                onChange={handleSelectChange}
                            />
                        </FilterSelector>
                    </FilterFieldBody>

                    <FilterFieldBody>
                        <FilterLabel id="taskTags" >Task Tags :</FilterLabel>
                        <SelectboxIsIsnot handleSelectChange={handleIsIsNOtChange} lable={"taskTags"} type={showFilterObj?.taskTags?.type || 'is'} />
                        <FilterSelector>
                            <Select
                                className='tw-w-full'
                                isMulti
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '#6366f1' : '#a5b4fc',
                                    }),
                                }}
                                value={showFilterObj?.taskTags?.values || []}
                                name="taskTags"
                                options={taskTags}
                                onChange={handleSelectChange}
                            />
                        </FilterSelector>
                    </FilterFieldBody>

                    <FilterFieldBody>
                        <FilterLabel id="SharedWith" >Shared With :</FilterLabel>
                        <SelectboxIsIsnot handleSelectChange={handleIsIsNOtChange} lable={"SharedWith"} type={showFilterObj?.SharedWith?.type || 'is'} />
                        <FilterSelector>
                            <Select
                                className='tw-w-full'
                                isMulti
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '#6366f1' : '#a5b4fc',
                                    }),
                                }}
                                value={showFilterObj?.SharedWith?.values || []}
                                name="SharedWith"
                                options={taskAssignToOptions}
                                onChange={handleSelectChange}
                            />
                        </FilterSelector>
                    </FilterFieldBody>

                    <FilterFieldBody>
                        <FilterLabel className={'!tw-max-w-[20%] !tw-min-w-[20%]'} id="SharedWith" >Unread Only :</FilterLabel>
                        <FilterSelector className={'!tw-max-w-[75%] !tw-min-w-[75%]'} >
                            <Switch checked={filters?.UnreadOnly || false} onChange={onSwitchChange} type={showFilterObj?.taskAssignByMe?.type || 'is'} />
                        </FilterSelector>
                    </FilterFieldBody>

                    <FilterFieldBody>
                        <FilterLabel id="taskCreatedBy" >Task Created by :</FilterLabel>
                        <SelectboxIsIsnot handleSelectChange={handleIsIsNOtChange} lable={"taskCreatedBy"} type={showFilterObj?.taskCreatedBy?.type || 'is'} />
                        <FilterSelector>
                            <Select
                                className='tw-w-full'
                                isMulti
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '#6366f1' : '#a5b4fc',
                                    }),
                                }}
                                value={showFilterObj?.taskCreatedBy?.values || []}
                                name="taskCreatedBy"
                                options={taskAssignToOptions}
                                onChange={handleSelectChange}
                            />
                        </FilterSelector>
                    </FilterFieldBody>

                    <FilterFieldBody>
                        <FilterLabel id="Plan" >Plan :</FilterLabel>
                        <SelectboxIsIsnot handleSelectChange={handleIsIsNOtChange} lable={"Plan"} type={showFilterObj?.Plan?.type || 'is'} />
                        <FilterSelector>
                            <Select
                                className='tw-w-full'
                                isMulti
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '#6366f1' : '#a5b4fc',
                                    }),
                                }}
                                value={showFilterObj?.Plan?.values || []}
                                name="Plan"
                                options={taskPlan}
                                onChange={handleSelectChange}
                            />
                        </FilterSelector>
                    </FilterFieldBody>

                    {/* <FilterFieldBody>
                        <FilterLabel id="task-priority" >User Groups :</FilterLabel>
                        <Select className='tw-w-full' id='taskGroups'
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#6366f1' : '#a5b4fc',
                            }),
                        }}
                        name="taskGroups" options={userGroupList} onChange={handleSelectChange} value={userGroupList?.filter((items) => (filters?.taskGroups?.values == items.value)) || []} />
                    </FilterFieldBody> */}

                    <FilterFieldBody>
                        <FilterLabel id="task-priority" >Due Date :</FilterLabel>
                        <Select className='tw-w-full' id='task-priority'
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#6366f1' : '#a5b4fc',
                            }),
                        }}
                        name="DuedateStatus" options={taskDueStatus} onChange={handleSingelSelectChange} value={taskDueStatus?.filter((items) => (filters?.DuedateStatus == items.value)) || []} />
                    </FilterFieldBody>

                    <FilterFieldBody>
                        <FilterLabel id="createdAt" >Created :</FilterLabel>
                        {/* <SelectboxIsIsnot handleSelectChange={handleIsIsNOtChange} lable={"createdAt"} type={filters?.createdAt?.type || 'is'} /> */}
   
                            <Select
                                className='tw-w-full'
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '#6366f1' : '#a5b4fc',
                                    }),
                                }}
                                value={timeDuration?.filter((items) => (filters?.createdAt?.values == items.value)) || []}
                                name="createdAt"
                                options={timeDuration}
                                onChange={handleSelectChange}
                            />

                    </FilterFieldBody>

                    <FilterFieldBody>
                        <FilterLabel id="updatedAt" >Updated :</FilterLabel>
                        {/* <SelectboxIsIsnot handleSelectChange={handleIsIsNOtChange} lable={"updatedAt"} type={filters?.updatedAt?.type || 'is'} /> */}

                            <Select
                                className='tw-w-full'
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '#6366f1' : '#a5b4fc',
                                    }),
                                }}
                                value={timeDuration?.filter((items) => (filters?.updatedAt?.values == items.value)) || []}
                                name="updatedAt"
                                options={timeDuration}
                                onChange={handleSelectChange}
                            />
    
                    </FilterFieldBody>

                    <div className='tw-grid lg:tw-grid-cols-5 md:tw-grid-cols-3 tw-grid-cols-2 tw-gap-4 ' >
                        <div className='tw-col-span-1 tw-flex tw-items-center tw-gap-2'>
                            <label className='tw-mb-0' htmlFor="overdue">Overdue Tasks:</label>
                            <input type="checkbox" name="overdue" checked={showFilterObj?.overdue || false} onChange={handleCheckboxChange} />
                        </div>

                        <div className='tw-col-span-1 tw-flex tw-items-center tw-gap-2'>
                            <label className='tw-mb-0' htmlFor="todays">Today's Tasks:</label>
                            <input type="checkbox" name="todays" checked={showFilterObj?.todays || false} onChange={handleCheckboxChange} />
                        </div>
                        <div className='tw-col-span-1 tw-flex tw-items-center tw-gap-2'>
                            <label className='tw-mb-0' htmlFor="todays">This Week's Task:</label>
                            <input type="checkbox" name="weeks" checked={showFilterObj?.weeks || false} onChange={handleCheckboxChange} />
                        </div>
                    </div>

                </div>
            </Modal>
            <div className='tw-flex tw-items-center'>
                <button className='tw-border tw-border-indigo-500 tw-bg-transparent hover:tw-bg-indigo-100 tw-rounded' onClick={onFilterModalOpen}>
                    <i class="fas fa-filter tw-text-indigo-500"></i>
                </button>
                <span className='tw-ml-1 tw-font-semibold tw-whitespace-nowrap'>FILTERS : </span>

                <FilterDisplay filter={showFilterObj} onClearFilter={onClearFilter} onClearIndividualFilter={onClearIndividualFilter} />
            </div>
        </>
    );
};

export default TaskFilter;

const FilterFieldBody = ({ className, children }) => {
    return (
        <div className={classNames(`col-md-6 !tw-px-0 tw-flex tw-gap-1 tw-justify-between tw-items-start`, className)} >
            {children}
        </div>
    )
}

const FilterLabel = ({ className, children, id }) => {
    return (
        <label htmlFor={id} className={`tw-col-form-label tw-mt-2 tw-text-sm tw-font-medium xl:tw-max-w-[24%] xl:tw-min-w-[24%] lg:tw-max-w-[22%] lg:tw-min-w-[22%] md:tw-max-w-[23%] md:tw-min-w-[23%] tw-min-w-[20%] tw-max-w-[20%] tw-mb-0 ${className && className}`} >
            {children}
        </label>
    )
}

const FilterSelector = ({ className, children }) => {
    return (
        <div className={`lg:tw-max-w-[60%] tw-mb-0 lg:tw-min-w-[60%] md:tw-max-w-[53%] md:tw-min-w-[53%] tw-max-w-[60%] tw-min-w-[60%]   ${className && className} `}>
            {children}
        </div>
    )
}