import React, { useState } from 'react'
import ButtonTabs from '../../../Commoncomponet/Tabs/ButtonTabs'
import Scriptcodeid from '../../Scriptcodeid/Scriptcodeid'
import PortableScriptDecodeId from '../../PortableDeviceScriptdcodeid/Scriptcodeid'
import ModemCredential from '../ModemCredential'
import GSMModemTables from './GSMModemTables'
import GSMMeterTables from './GSMMeterTables'
import PortableModem from './PortableModem'
import PortableMeter from './PortableMeter'
import TPMeterTable from './TPMeterTable'
import TPModemTables from './TPModemTables'
import TPScriptDecodeId from '../../TPScriptDecodeId/TPScriptDecodeId'

const ProductWiseTables = () => {
    const [DefaultTab,setDefaultTab]=useState("tab1")
    const [IsDefault,setIsDefault]=useState(false)
    return (
        <>
                <section className="section">
                    <div className="">

                        <div className='tw-mt-2 tw-w-full tw-mx-auto tw-px-0'>
                            <ButtonTabs color="indigo" className={'tw-flex-wrap'} defaultTab={'tab1'} setIsDefault={setIsDefault} IsDefault={IsDefault}>
                                <div name='pcst_eums_modem_credentials' value={'tab1'} color='#22c55e' onClick={()=>setDefaultTab('tab1')}>
                                   <ModemCredential/>
                                </div>
                                <div className='border-top' name='GSM Modem Tables' value={'tab2'} color='#6366f1' onClick={()=>setDefaultTab('tab2')}>
                                   <GSMModemTables/>
                                </div>
                                <div className='border-top' name='GSM Meter Tables' value={'tab3'} color='#0ea5e9' onClick={()=>setDefaultTab('tab3')}>
                                   <GSMMeterTables/>
                                </div>
                                <div className='border-top' name='Portable Device Modem Tables' value={'tab4'} color='#8b5cf6' onClick={()=>setDefaultTab('tab4')}>
                                   <PortableModem/>
                                </div>
                                <div className='border-top' name='Portable Device Meter Tables' value={'tab5'} color='#e3bf0e' onClick={()=>setDefaultTab('tab5')}>
                                   <PortableMeter/>
                                </div>
                                <div className='border-top' name='pcst_eums_gsm_scriptdecode_id' value={'tab6'} color='#f43f5e' onClick={()=>setDefaultTab('tab6')}>
                                   <Scriptcodeid/>
                                </div>
                                <div className='border-top' name='pcst_eums_port_scriptdecode_id' value={'tab7'} color='#0ea5e9' onClick={()=>setDefaultTab('tab7')}>
                                   <PortableScriptDecodeId/>
                                </div>
                                <div className='border-top' name='Third Party Modem Tables' value={'tab8'} color='#8b5cf6' onClick={()=>setDefaultTab('tab4')}>
                                   <TPModemTables/>
                                </div>
                                <div className='border-top' name='Third Party Meter Tables' value={'tab9'} color='#e3bf0e' onClick={()=>setDefaultTab('tab5')}>
                                   <TPMeterTable/>
                                </div>
                                <div className='border-top' name='pcst_eums_third_party_scriptdecode_id' value={'tab10'} color='#f43f5e' onClick={()=>setDefaultTab('tab6')}>
                                   <TPScriptDecodeId/>
                                </div>
                                
                            </ButtonTabs>
                        </div>
                    </div>
                </section>
        </>
    )
}

export default ProductWiseTables