import React from 'react'

export default function ScripList() {

    const Scriptjson=[{Name:'amreumsgsm_createtable',time:'consumer insert and update time'},{Name:'amreumsgsm_dailyload ',time:'Daily 12:30 AM'},{Name:'amreumsgsm_downlink',time:'Device Response Time'},{Name:'amreumsgsm_events',time:'Device Response Time'},{Name:'amreumsgsm_instantaneous_snapshot',time:'Daily 12:01 AM'},{Name:'amreumsgsm_loadsurvey',time:'every hour at 31st minute'},{Name:'amreumsgsm_month_sanpshot',time:' 6:30 AM of 1st date'},{Name:'amreumsgsm_monthlybill',time:'12:15 AM of 1st date of month'},{Name:'amreumsgsm_uplink',time:'Device Response Time'},{Name:'amreumsgsm_webdecode',time:'Device Response Time'}]
  return (
    <>
        <div className="main-content">
    <section className="section">
        <div className="section-body">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h4>Script List</h4>
									<div className="card-header-action">
										<div id="filtergrm">
											
											<a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" >
												<i class="fa fa-refresh" aria-hidden="true"></i>
											</a>
											
										</div>
									</div>
								</div>
								<div className="card-body">
									{/* {loader && (
										<div className="example">
											<Spin size="large" />
										</div>
									)} */}
										<div className="table-responsive">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												{/* <div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select
															name="table_id_length"
															aria-controls="table_id"
															class=""
															onChange={(e) => setPageSize(e.target.value)}
															value={PageSize}
														>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div> */}
												<table id="table_id" className="table border table-borderless table-striped font-10">
													<thead>
														<tr className="tw-border-b">
															<th className='text-center font-12'>No</th>
															<th className='text-center font-12'>Script Name</th>
															<th className='text-center font-12'>Start Time</th>
															<th className='text-center font-12'>Script Status</th>
														</tr>
													</thead>
													<tbody >
                                                    {Scriptjson.map((item,id)=>(<tr> 
                                                        <td className='text-center font-12'>{id+1}</td>

<td className='text-center font-12'>{item?.Name}</td>
<td className='text-center font-12'>{item?.time}</td>
<td className='text-center font-12'><div className={`badge badge-success`}>Active</div></td>
</tr>))}
														
                                                            
													</tbody>
												</table>
											</div>
										</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
            </div>
    </>
  )
}
