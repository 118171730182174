import { DatePicker, Space } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getAccount, getAreaById, getCircleById, getDivisionById, getGaById, getGSMDataFilterDataIntegration, getProfilNameByAccountId, getRolesById, GetStructureById, getSubdevisionById, Insert_consumer_data } from '../../../Commoncomponet/Utils'
import swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { style } from '@mui/system';
import { toast } from 'react-toastify';
import chroma from 'chroma-js';
import IntegrationLog from './IntegrationLog';

window.Swal = swal;
const Push = (props) => {
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']

	const [report, setReport] = useState({ start_date: '', end_date: '', Consumer_Type: '', Table: [], INTEGRATION_FILTER_TYPE: 1 })
	const [Profile, setProfile] = useState({ account: [], ga: [], circle: [], division: [], subdivision: [], area: [] });
	const [SubdevisionData, setSubdevisionData] = useState([]);
	const [DivisionData, setDivisionData] = useState([]);
	const [CircleData, setCircleData] = useState([]);
	const [GaData, setGaData] = useState([]);
	const [AccounData, setAccountData] = useState([]);
	const [AreaData, setAreaData] = useState([]);
	const [permision, setpermision] = useState({});
	const [ConsumerData, setConsumerData] = useState([])
	const [ConsumerSearchData, setConsumerSearchData] = useState([])
	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
	const [ConsumerUniqIdArray, setConsumerUniqIdArray] = useState([])
	const [FilterSerchLoader, setFilterSerchLoader] = useState(false)
	const [activeTab, setActiveTab] = useState("Setup");
	const [TableArray,setTableArray] = useState([])

	const params = useParams();
	const history = useHistory();

	const ongetGaById = async (id) => {
		const data1 = await getGaById(id);
		if (data1.success === true) {
			const options = []
			let activedata = data1?.data?.data.filter(v => v.CATEGORY1_STATUS === 1)
            const optionArray = activedata.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter((items) => (items === data.CATEGORY1_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'));
            optionArray.map((item) => (options.push({ value: item?.CATEGORY1_UNIQUE_ID, label: item?.CATEGORY1_NAME })))
			setGaData(options);
		}
	};
	const onHandaleSelection = async () => {
		const data = await getAccount();
		if (data.success === true) {
			const options = []
			let activedata = data?.data?.data.filter(v => v.STATUS === 1)
			const optionArray =activedata?.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'))
			optionArray.map((item) => (options.push({ value: item?.UNIQUE_ID, label: item?.CATEGORY0 })))
			setAccountData(options);
		} else {
			console.log("somthing Went wrong");
		}
	};
	const onHandaleRoles = async () => {
		const data = await getRolesById(userInfo?.ROLE);
		if (data.success === true) {
			const obj = data.data.data[0];
			setpermision({
				...obj,
				ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0),
				ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5),
				ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2),
				ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3),
				ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1),
				ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4),
				ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS)
			});
			onHandaleSelection()
		}
	}

	const ongetCircle = async (id) => {
		const data1 = await getCircleById(id);
		if (data1.success === true) {
			const options = []
			let activedata = data1?.data?.data.filter(v => v.CATEGORY2_STATUS === 1)
            const optionArray = activedata?.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter((items) => (items === data.CATEGORY2_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'));
            optionArray.map((item) => (options.push({ value: item?.CATEGORY2_UNIQUE_ID, label: item?.CATEGORY2_NAME })))
			setCircleData(options);
		}
	};
	const ongetSubdevisionById = async (id) => {
		const data1 = await getSubdevisionById(id);
		if (data1.success === true) {
			const options = []
			let activedata = data1?.data?.data.filter(v => v.CATEGORY4_STATUS === 1)
            const optionArray = activedata.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter((items) => (items === data.CATEGORY4_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'));
            optionArray.map((item) => (options.push({ value: item?.CATEGORY4_UNIQUE_ID, label: item?.CATEGORY4_NAME })))
			setSubdevisionData(options);
		}
	};
	const ongetDivisionById = async (id) => {
		const data3 = await getDivisionById(id);
		if (data3.success === true) {
			const options = []
			let activedata = data3?.data?.data.filter(v => v.CATEGORY3_STATUS === 1)
            const optionArray = activedata.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter((items) => (items === data.CATEGORY3_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'));
            optionArray.map((item) => (options.push({ value: item?.CATEGORY3_UNIQUE_ID, label: item?.CATEGORY3_NAME })))
			setDivisionData(options);
		}
	};
	const ongetArea = async (id) => {
		const data3 = await getAreaById(id);
		if (data3.success === true) {
			const options = []
			let activedata = data3?.data?.data.filter(v => v.STATUS === 1)
            const optionArray = activedata.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1661856938676'));
            optionArray.map((item) => (options.push({ value: item?.UNIQUE_ID, label: item?.CATEGORY5_NAME })))
			setAreaData(options);
		}
	};
	const [NameObj, setNameObj] = useState({})
	const ongetProfilNameByAccountId = async (id) => {
		const data = await getProfilNameByAccountId({ ID: id })
		if (data.success === true) {
			setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME || '{}'))
		} else {
			console.log('something Went wrong')
		}
	}
	useEffect(() => {
		setConsumerData([])
		setConsumerUniqIdArray([])
		if (Profile.account !== "") {
			ongetGaById(Profile.account);
			setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
			setCircleData([]);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([])

			ongetProfilNameByAccountId(Profile.account)

		} else {
			setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
			setGaData([]);
			setCircleData([]);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([]);
			if (Object.keys(permision).length > 0) {

			}
			setNameObj({})
		}
	}, [Profile.account]);

	useEffect(() => {
		if (Profile.ga !== "") {
			setConsumerData([])

			ongetCircle(Profile.ga);
			setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([]);

		} else {
			setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
			setCircleData([]);
			setDivisionData([]);
			setSubdevisionData([]);
			setAreaData([]);
			if (Object.keys(permision).length > 0) {

			}

		}
	}, [Profile.ga]);

	useEffect(() => {
		setConsumerData([])
		if (Profile.circle !== "") {
			ongetDivisionById(Profile.circle);
			setProfile({ ...Profile, division: "", subdivision: "", area: "" });
			setAreaData([]);
			setSubdevisionData([]);

		} else {
			setProfile({ ...Profile, division: "", subdivision: "", area: "" });
			setAreaData([]);
			setDivisionData([]);
			setSubdevisionData([]);
			if (Object.keys(permision).length > 0) {

			}

		}
	}, [Profile.circle]);

	useEffect(() => {
		setConsumerData([])

		if (Profile.division !== "") {
			ongetSubdevisionById(Profile.division);
			setAreaData([]);
			setProfile({ ...Profile, subdivision: "", area: "" });

		} else {
			setProfile({ ...Profile, subdivision: "", area: "" });
			setSubdevisionData([]);
			setAreaData([]);

		}
	}, [Profile.division]);

	useEffect(() => {
		setConsumerData([])

		if (Profile.subdivision !== "") {
			ongetArea(Profile.subdivision);
			setProfile({ ...Profile, area: "" });

		} else {
			setProfile({ ...Profile, area: "" });
			setAreaData([]);

		}
	}, [Profile.subdivision]);

	useEffect(() => {
		setConsumerData([])

		if (Profile.area !== "") {

		} else {

		}
	}, [Profile.area]);

	useEffect(() => {
		onHandaleRoles()
		const Json_obj = JSON.parse(props?.credentialdetails?.integration_filter || '{}')
		if (Object.keys(Json_obj).length > 0) {
			ongetGSMDataFilterDataIntegration(Json_obj,props?.credentialdetails?.integration_filter_type)
		}
		onGetStructureById(props?.credentialdetails?.structure_id)
	}, [])

	const onGetStructureById=async(id)=>{
		if(id){
        const data = await GetStructureById(id)
        if (data.success === true) {
			var array_obj=[]
			Object.entries(JSON.parse(data?.data?.data[0]?.data_structure||{})).map(([key,value])=>{
				Object.keys(value).map((item)=>{
					if(array_obj.filter(check=>check?.value==item).length==0){
						array_obj.push({value:item,label:item})
					}
				})
			})
			setTableArray(array_obj)
        } else {
            console.log('something Went wrong')
        }
	}
    }

	useEffect(() => {
		console.log(props);
		if (props?.credentialdetails?.integration_filter && Object.keys(JSON.parse(props?.credentialdetails?.integration_filter || '{}')).length > 0) {
			const Json_obj = JSON.parse(props?.credentialdetails?.integration_filter || '{}')
			setProfile({ account: Json_obj?.CATEGORY0 || [], ga: Json_obj?.CATEGORY1 || [], circle: Json_obj?.CATEGORY2 || [], division: Json_obj?.CATEGORY3 || [], subdivision: Json_obj?.CATEGORY4 || [], area: Json_obj?.CATEGORY5 || [] })
			setReport({ ...report, Consumer_Type: Json_obj?.CONSUMER_TYPE || [], Table: JSON.parse(props?.credentialdetails?.table_type || '[]'), INTEGRATION_FILTER_TYPE: props?.credentialdetails?.integration_filter_type })
		}
		setConsumerUniqIdArray(JSON.parse(props?.credentialdetails?.CONSUMER_LIST || '[]'))
	}, [props])





	const ongetGSMDataFilterDataIntegration = async (Json_obj,filter_type) => {
		setFilterSerchLoader(true)
		const data = await getGSMDataFilterDataIntegration({ CATEGORY0_view: permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view, CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view, CATEGORY2_view: permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view, CATEGORY3_view: permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view, CATEGORY4_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view, CATEGORY5_view: permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view, CATEGORY0: Json_obj?.CATEGORY0?.length > 0 ? Json_obj?.CATEGORY0 : Profile?.account, CATEGORY1: Json_obj?.CATEGORY1?.length > 0 ? Json_obj?.CATEGORY1 : Profile?.ga, CATEGORY2: Json_obj?.CATEGORY2?.length > 0 ? Json_obj?.CATEGORY2 : Profile?.circle, CATEGORY3: Json_obj?.CATEGORY3?.length > 0 ? Json_obj?.CATEGORY3 : Profile?.division, CATEGORY4: Json_obj?.CATEGORY4?.length > 0 ? Json_obj?.CATEGORY4 : Profile?.subdivision, CATEGORY5: Json_obj?.CATEGORY5?.length > 0 ? Json_obj?.CATEGORY5 : Profile?.area, Roles: userInfo?.ROLE, Consumer_Type: Json_obj?.CONSUMER_TYPE?.length > 0 ? Json_obj?.CONSUMER_TYPE : report?.Consumer_Type })
		if (data.success === true) {
			setConsumerData(data.data.data)
			setConsumerSearchData(data.data.data)
			if (report?.INTEGRATION_FILTER_TYPE == 1||filter_type==1) {
				setConsumerUniqIdArray(data.data.data.map((item) => (item?.UNIQUE_ID)))
			} else {
				setConsumerUniqIdArray(JSON.parse(props?.credentialdetails?.consumer_list || '[]'))
			}
			setFilterSerchLoader(false)

		} else {
			setFilterSerchLoader(false)
			setConsumerData([])
			setConsumerSearchData([])

			setConsumerUniqIdArray([])
		}
	}

	const onHandal = (e, id) => {
		var arrayCon = ConsumerUniqIdArray
		if (e.target.checked == true) {
			console.log(e.target.checked);
			arrayCon = [...arrayCon, id]
		} else {
			arrayCon = arrayCon.filter((item) => (item != id))
		}
		setConsumerUniqIdArray(arrayCon)
	}

	const onPushMssqldata = async () => {
		console.log(props)
		var Browser_Details = {}
		Browser_Details.browser = window?.navigator?.userAgent
		Browser_Details.browser_version = window?.navigator?.appVersion
		Browser_Details.screen_details = window?.screen
		// Browser_Details.screen_details=window?.navigator?.plugins

		if (Profile?.account.length > 0 && report?.Table?.length > 0 && report?.INTEGRATION_FILTER_TYPE != '') {
			const obj = {
				INTEGRATION_FILTER: {
					CATEGORY0: Profile?.account,
					CATEGORY1: Profile?.ga,
					CATEGORY2: Profile?.circle,
					CATEGORY3: Profile?.division,
					CATEGORY4: Profile?.subdivision,
					CATEGORY4: Profile?.area,
					CONSUMER_TYPE: report?.Consumer_Type
				},
				INTEGRATION_FILTER_TYPE: report?.INTEGRATION_FILTER_TYPE,
				Table: report?.Table,
				ConsumerUniqIdArray: ConsumerUniqIdArray,
				integration_type:props?.credentialdetails?.integration_type
			}

			swal.fire({
				title: report?.INTEGRATION_FILTER_TYPE==2?`Total ${ConsumerUniqIdArray.length} Consumer Selected`:`are you sure to save this filter?`,
				icon: "warning",
				showCancelButton: true,
			}).then(async (willDelete) => {
				if (willDelete.isConfirmed) {
					const data = await Insert_consumer_data(obj, params?.MSID)
					if (data.success === true) {
						props.getCredential()
						swal.fire('', 'Submit Success', 'success');
					} else {
						swal.fire('', 'Server Error', 'error');
					}
				}
			})
		} else {
			if (report?.INTEGRATION_FILTER_TYPE == '') {
				return toast.error('"Filter Type" Is Required')
			}
			if (report?.Table?.length == 0) {
				return toast.error('"Table Type" Is Required')
			}
			if (Profile?.account.length == 0) {
				return toast.error('"Account" Is Required')
			}
		}

	}

	const onHandalSearch = (e) => {
		if (e.target.value !== '') {
			const filteData = ConsumerSearchData && ConsumerSearchData.filter((item) => (JSON.parse(item?.ADDRESS || '{}').address_compony_name?.toLowerCase().includes(e.target.value.toLowerCase()) || item?.BUSINESS_PARTNER_NUMBER.includes(e.target.value.toLowerCase()) || item?.DEVICE_SERIAL_NO.includes(e.target.value.toLowerCase()) || item?.IMEI.includes(e.target.value.toLowerCase()) || item?.INSTALLATION_NUMBER.includes(e.target.value.toLowerCase())))
			setConsumerData(filteData)

		} else {
			setConsumerData(ConsumerSearchData)
		}
	}

	const consumerTypeArray = [
		{ value: "Industrial", label: 'Industrial' },
		{ value: "Residential", label: 'Residential' },
		{ value: "Commercial", label: 'Commercial' },
	]

	
	return (
		<>
			<div class="row ml-2 border-top py-2">
				<div class="d-flex tw-border-b-0 " style={{ justifyContent: "space-between" }}>
					<ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "20px", alignItems: "center" }}>
						<li>
							<button
								style={{
									backgroundColor: activeTab === "Setup" ? chroma(btnColor[0]).alpha(0.4).css() : '',
									color: activeTab === "Setup" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
									borderBottom: `1px solid ${btnColor[0]}`,
									boxShadow: activeTab === "Setup" ? `0 2px 8px -3px ${btnColor[0]}` : '',
								}}
								className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Setup" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
								data-toggle="tab"
								href="#home"
								role="tab"
								aria-controls="home"
								aria-selected="true"
								onClick={() => setActiveTab("Setup")}
							>
								Setup
							</button>
						</li>
						<li>
							<button
								style={{
									backgroundColor: activeTab === "Logs" ? chroma(btnColor[2]).alpha(0.4).css() : '',
									color: activeTab === "Logs" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
									borderBottom: `1px solid ${btnColor[2]}`,
									boxShadow: activeTab === "Logs" ? `0 2px 8px -3px ${btnColor[2]}` : '',
								}}
								className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Logs" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
								id="profile-tab"
								data-toggle="tab"
								href="#profile"
								role="tab"
								aria-controls="profile"
								aria-selected="false"
								onClick={() => setActiveTab("Logs")}
							>
								{props?.credentialdetails?.integration_type=='1'?'Forward Logs':'Receive Logs'}
							</button>
						</li>
						<li>
							<button
								style={{
									backgroundColor: activeTab === "Logs2" ? chroma(btnColor[4]).alpha(0.4).css() : '',
									color: activeTab === "Logs2" ? chroma(btnColor[4]).darken(1.5).css() : btnColor[4],
									borderBottom: `1px solid ${btnColor[4]}`,
									boxShadow: activeTab === "Logs2" ? `0 2px 8px -3px ${btnColor[4]}` : '',
								}}
								className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Logs2" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
								id="profile-tab"
								data-toggle="tab"
								href="#profile"
								role="tab"
								aria-controls="profile"
								aria-selected="false"
								onClick={() => setActiveTab("Logs2")}
							>
								Transceiver Logs
							</button>
						</li>
					</ul>
				</div>
			</div>
			{activeTab == 'Setup' && <><div className="row">

				<div className="col-12">
					<div className="">
						<div className="card-body ">
							<div className="form-row" >
								{/* <div className="form-group col-md-3" id="strdate">
                                    <label for="inputPassword4">Select Date<span class="text-danger">*</span> : </label><br></br>
									<DatePicker onChange={(e,dateString)=>setReport({ ...report, start_date: dateString})} format='DD-MM-YYYY' name='start'  style={{ width:'100%',height:'40px' }}
										disabledDate={(current) => {
											return current.isAfter(moment())
											}} />
                                 </div> */}
								<div className="form-group col-md-3" id="enddate" >
									<label for="inputPassword4">Table Type:<span className='text-danger'>*</span> </label><br></br>
									<Select name='Table' isMulti options={TableArray} onChange={(e) => {
										setReport({ ...report, Table: e.map((item) => (item.value)), label: e.map((item) => (item.label)) })
									}} value={TableArray.filter((item) => report?.Table?.includes(item.value)) || null} />
								</div>
								<div className="form-group col-md-3" id="grpeui" style={{ paddingLeft: '0px' }}>
									<label for="inputPassword4 ">Filter Type :<span className='text-danger'>*</span> </label>
									<select className="form-control form-select" name="reporttype" required="required" onChange={(e) => {
										if (e.target.value == 1) {
											setConsumerUniqIdArray(ConsumerData.map((item) => (item?.UNIQUE_ID)))
										}
										setReport({ ...report, INTEGRATION_FILTER_TYPE: e.target.value })
									}} value={report?.INTEGRATION_FILTER_TYPE} >
										{/* <option value="">Select Filter Type</option> */}
										<option value={1}>Category Wise</option>
										<option value={2}>Consumer Wise</option>
									</select>
								</div>
							</div>
							<div class="row">
								<div
									class="form-row "
									style={{
										borderColor: "#e3e6fc !important",
										position: "relative",
										marginTop: "5px",
									}}
								>
									<div class="col-md-2">
										<div class="form-group">
											<label for="inputPassword4">
												Account<span class="text-danger">*</span>
											</label>
											<Select placeholder='All account...' value={AccounData.filter((item) => (Profile?.account?.includes(item?.value)))} name='account' isMulti options={AccounData} onChange={(e) => { setProfile({ ...Profile, account: e.map((item) => (item.value)) }) }} />
										</div>
									</div>
									{Profile.account.length > 0 && (
										<div class="col-md-2">
											<div class="form-group">
												<label for="inputPassword4">{NameObj?.CATEGORY1 || 'CATEGORY1'}</label>
												<Select name='account' value={GaData.filter((item) => (Profile?.ga?.includes(item?.value)))} isMulti options={GaData} onChange={(e) => { setProfile({ ...Profile, ga: e.map((item) => (item.value)) }) }} />
											</div>
										</div>
									)}
									{Profile.ga.length > 0 && Profile.account.length > 0 && (
										<div class="col-md-2">
											<div class="form-group">
												<label for="inputPassword4">{NameObj?.CATEGORY2 || 'CATEGORY2'}</label>
												<Select name='account' value={CircleData.filter((item) => (Profile?.circle?.includes(item?.value)))} isMulti options={CircleData} onChange={(e) => { setProfile({ ...Profile, circle: e.map((item) => (item.value)) }) }} />
											</div>
										</div>
									)}
									{Profile.circle.length > 0 && Profile.ga.length > 0 && Profile.account.length > 0 && (
										<div class="col-md-2">
											<div class="form-group">
												<label for="inputPassword4">{NameObj?.CATEGORY3 || 'CATEGORY3'}</label>
												<Select name='account' value={DivisionData.filter((item) => (Profile?.division?.includes(item?.value)))} isMulti options={DivisionData} onChange={(e) => { setProfile({ ...Profile, division: e.map((item) => (item.value)) }) }} />
											</div>
										</div>
									)}
									{Profile.division.length > 0 && Profile.circle.length > 0 && Profile.ga.length > 0 && Profile.account.length > 0 && (
										<div class="col-md-2">
											<div class="form-group">
												<label for="inputPassword4">{NameObj?.CATEGORY4 || 'CATEGORY4'}</label>
												<Select name='account' value={SubdevisionData.filter((item) => (Profile?.subdivision?.includes(item?.value)))} isMulti options={SubdevisionData} onChange={(e) => { setProfile({ ...Profile, subdivision: e.map((item) => (item.value)) }) }} />
											</div>
										</div>
									)}
									{Profile.subdivision.length > 0 &&
										Profile.division.length > 0 &&
										Profile.circle.length > 0 &&
										Profile.ga.length > 0 &&
										Profile.account.length > 0 && (
											<div class="col-md-2">
												<div class="form-group">
													<label for="inputPassword4">{NameObj?.CATEGORY5 || 'CATEGORY5'}</label>
													<Select name='account' value={AreaData.filter((item) => (Profile?.area?.includes(item?.value)))} isMulti options={AreaData} onChange={(e) => { setProfile({ ...Profile, area: e.map((item) => (item.value)) }) }} />
												</div>
											</div>
										)}
								</div>
								<div class="form-group mb-0">
									<div class="col-md-2 areagrp d-flex tw-items-center tw-gap-2">
										<div class="form-group" style={{ width: '100%' }}>
											<label htmlFor="inputPassword4">Consumer Type</label>
											<Select name='Consumer_Type' value={consumerTypeArray.filter((item) => (report?.Consumer_Type?.includes(item?.value)))} isMulti options={consumerTypeArray} onChange={(e) => {
												setReport({ ...report, Consumer_Type: e.map((item) => (item.value)) })
											}} />
										</div>
										{report?.INTEGRATION_FILTER_TYPE==2?<div className={`btn btn-primary  ${FilterSerchLoader == true ? 'btn-progress disabled' : ''}`} style={{ height: '37px' }} onClick={ongetGSMDataFilterDataIntegration}><i className='fas fa-search'></i></div>:''}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
				<div className='tw-px-4'>
				{report?.INTEGRATION_FILTER_TYPE==2?<div className="card">
						<div className="card-header">
							<h4 className="modemconfigurationstatus d-none"></h4>
							<div className="card-header-action">
								<div id="filtergrm">
									<input type='text' onChange={onHandalSearch} class="form-control" placeholder={'search...'} />
								</div>
							</div>
						</div>
						<div className="card-body">
							<div className="table-responsive meterdata">
								<table className="table table-striped tw-text-xs tw-w-full">
									<thead>
										<tr>
											{report?.INTEGRATION_FILTER_TYPE == 2 ? <th><input type="checkbox" className="chk_MSSQL1" name="checkAll" checked={ConsumerUniqIdArray.length == ConsumerData.length ? true : false} onClick={(e) => (e.target.checked == true ? setConsumerUniqIdArray(ConsumerData.map((item) => (item?.UNIQUE_ID))) : setConsumerUniqIdArray([]))} disabled={report?.INTEGRATION_FILTER_TYPE == 1 ? true : false} /></th> : ''}
											<th>SRNO</th>
											<th>Company Name</th>
											<th>Modem Serial No</th>
											<th>Installation Number</th>
											<th>Panel Serial Number</th>
										</tr>
									</thead>
									<tbody>
										{ConsumerData.length > 0 && ConsumerData.map((item) => (<tr>
											{report?.INTEGRATION_FILTER_TYPE == 2 ? <th><input type="checkbox" className="chk_MSSQL1" name="checkAll" checked={ConsumerUniqIdArray.filter((info) => (info == item?.UNIQUE_ID)).length > 0 ? true : false} onChange={(e) => onHandal(e, item?.UNIQUE_ID)} disabled={report?.INTEGRATION_FILTER_TYPE == 1 ? true : false} /></th> : ''}
											<th>{item?.SRNO}</th>
											<th>{JSON.parse(item?.ADDRESS || '{}').address_compony_name}</th>
											<th>{item?.DEVICE_SERIAL_NO}</th>
											<th>{item?.INSTALLATION_NUMBER}</th>
											<th>{item?.PANEL_SERIAL_NO}</th>
										</tr>))}
									</tbody>
								</table>
							</div>
						</div>

						{ConsumerUniqIdArray.length > 0 ? <div className="form-group col-md-12 text-center">
							<input type="submit" className="btn btn-danger" value="Submit" id="sync_btn" name="sync_btn" onClick={onPushMssqldata} />
						</div> : ''}
					</div>:<div className="form-group col-md-12 text-left">
							<input type="submit" className="btn btn-danger" value="Submit" id="sync_btn" name="sync_btn" onClick={onPushMssqldata} />
						</div>}
				</div></>}
			{activeTab == 'Logs' && <IntegrationLog TYPE={props?.credentialdetails?.integration_type} credentialdetails={props?.credentialdetails} logs_table={JSON.parse(props?.credentialdetails?.integration_table_name||'{}')?.integration_forward_log} pub_topic={JSON.parse(props?.credentialdetails?.endpoint_credential || '{}')?.MQTT_RECEIVE_PUB_TOPIC} sub_topic={JSON.parse(props?.credentialdetails?.endpoint_credential || '{}')?.MQTT_RECEIVE_SUB_TOPIC} activeTab={activeTab}/>}
			{activeTab == 'Logs2' && <IntegrationLog TYPE={props?.credentialdetails?.integration_type} credentialdetails={props?.credentialdetails} logs_table={JSON.parse(props?.credentialdetails?.integration_table_name||'{}')?.integration_transceivers_log} pub_topic={JSON.parse(props?.credentialdetails?.endpoint_credential || '{}')?.MQTT_CONFIG_PUB_TOPIC} sub_topic={JSON.parse(props?.credentialdetails?.endpoint_credential || '{}')?.MQTT_CONFIG_SUB_TOPIC} activeTab={activeTab}/>}
		</>
	)
}

export default Push