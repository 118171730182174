import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import swal from 'sweetalert2';
import Pagination from '../../../../Commoncomponet/Pagination';
import { getGSMRejectedData } from '../../../../Commoncomponet/Utils';
window.Swal = swal;
export default function Rejected(props) {

  const [PageSize, setPageSize] = useState(50)
	const [numberofPage, setnumberofPage] = useState(1)
	const [currentPage, setCurrentPage] = useState(1);
    const [BufferData,setBufferData]=useState([])
	const [loader, setLoader] = useState(true)

const ongetGSMBufferData=async()=>{
    setLoader(true)
    const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
    const data=await getGSMRejectedData({ start: firstPageIndex, end: PageSize,IMEI:props.GSMdata.IMEI })
    if(data.success===true){
        setBufferData(data.data.data)
				setnumberofPage(parseInt(data.data.totalCount))
    setLoader(false)
    }else{
console.log('Something Went Wrong')
        setLoader(false)
    }
}

useEffect(() => {
    ongetGSMBufferData()
}, []);

useEffect(() => {
    ongetGSMBufferData()
}, [currentPage, PageSize])

  return (
    <>
      <section className="card-diff-section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header ">
										<h4  ></h4>
										<div className="card-header-action">
                                        
										</div>
									</div>
									<div className="card-body">
                                              
                                    {/* {BufferCountShow===true&&<div id="allbtn " className='mb-3'> <button type="button" class="btn btn-dark btn-icon icon-left ">All <span class="badge badge-transparent">{BufferCount?.total_length}</span></button>
            <button type="button" class="btn btn-success btn-icon icon-left ml-2">Success <span class="badge badge-transparent">{BufferCount?.success}</span></button>
            <button type="button" class="btn btn-warning btn-icon icon-left ml-2">Waiting For Downlink <span class="badge badge-transparent">{BufferCount?.wait_down}</span></button>
            <button type="button" class="btn btn-info btn-icon icon-left ml-2">Canceled By User <span class="badge badge-transparent">{BufferCount?.cancel}</span></button>
            <button type="button" class="btn btn-danger btn-icon icon-left ml-2">Timeout <span class="badge badge-transparent">{BufferCount?.timeout}</span></button>
            <button type="button" class="btn btn-primary btn-icon icon-left ml-2">Waiting For Uplink <span class="badge badge-transparent">{BufferCount?.wait_up}</span></button>
        </div>}  */}
										<div className="table-responsive">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select name="table_id_length" aria-controls="table_id" class="" onChange={(e) => (setPageSize(e.target.value))} value={PageSize}>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
												<table id="table_id" className="table border text-center table-borderless table-striped font-10">
													<thead>
														<tr >
															<th className="text-center" >Unique No</th>
															<th>CMD</th>
															<th>IMEI</th>
															<th>VD</th>
															<th>MSGID</th>
															<th>CMD_TYPE</th>
															<th style={{ minWidth: '100px' }}>GSMTS</th>
															<th>POTP</th>
															<th>COTP</th>
															<th>VERSTR</th>
															<th>DRXTYPE</th>
															<th>DATA</th>
															<th>DRXTYPE</th>
															<th style={{ minWidth: '100px' }}>ENTRYTS</th>
															<th>RAWDATA</th>
															<th>DATARXSTATUS</th>
															<th>DATASTATUS</th>
															
														</tr>
													</thead>
													<tbody>
														{BufferData.length > 0 && BufferData.map((item, id) => (
															<tr className="trheigth" >
																<td>{id+1}</td>
																<td>{item?.CMD}</td>
															<td>{item?.IMEI}</td>
															<td>{item?.VD}</td>
															<td>{item?.MSGID}</td>
															<td>{item?.CMD_TYPE}</td>
															<td>{moment.utc(item.GSMTS).format("DD/MM/YYYY hh:mm:ss A")}</td>
															<td>{item?.POTP}</td>
															<td>{item?.COTP}</td>
															<td>{item?.VERSTR}</td>
															<td>{item?.DRXTYPE}</td>
															<td>{item?.DATA}</td>
															<td>{item?.DRXTYPE}</td>
															<td>{moment.utc(item.ENTRYTS).format("DD/MM/YYYY hh:mm:ss A")}</td>
															<td>{item?.RAWDATA}</td>
															<td>{item?.DATARXSTATUS}</td>
															<td>{item?.DATASTATUS}</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
										<><div className='d-flex justify-content-between'>
											<div>Total Number Of Row {numberofPage}</div><Pagination
												className="pagination-bar"
												currentPage={currentPage}
												totalCount={numberofPage || 1}
												pageSize={PageSize}
												onPageChange={(page) => setCurrentPage(page)}
											/></div></>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
    </>
  )
}
