import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./AdvancedDropdown.css";

const AdvancedDropdown = ({
    options,
    onSelect,
    placeholder,
    selectedValues,
    disabled,
    isForDownload,
    selectedClassName
}) => {
    // Component logic here
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(selectedValues);
    const dropdownRef = useRef(null);
    const [position, setPosition] = useState("right");

    const handleSelect = (option) => {
        setSelected(option);
        setIsOpen(false);
        onSelect(option);
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    useEffect(() => {
        if (isOpen) {
            const rect = dropdownRef.current.getBoundingClientRect();
            const screenWidth = window.innerWidth || document.documentElement.clientWidth;
            setPosition(screenWidth - rect.right < 169 ? "left" : "right");
        }
    }, [isOpen]);

    return (
        <div className="my-dd-container" ref={dropdownRef}>
            <div className={classNames("my-dd-selected", { "my-dd-disabled": disabled }, selectedClassName)} onClick={() => !disabled && handleToggle()}>
                <div className="dropdown__header__title">
                    {
                        isForDownload ? (
                            <i className="fa fa-download" aria-hidden="true"></i>
                        ) : (
                            selected ? selected.label : placeholder
                        )}
                </div>
                <span className={`my-dd-arrow ${isOpen ? "my-dd-open" : ""}`}></span>
            </div>
            {isOpen && (
                <div className={`my-dd-options my-dd-options-${position}`}>
                    {options.map((option) => (
                        <div
                            className={classNames("my-dd-option", {
                                "my-dd-selected-option": selected && !isForDownload
                                    ? selected.value === option.value
                                    : false,
                            })}
                            key={option.value}
                            onClick={() => handleSelect(option)}>
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AdvancedDropdown;
