import { DatePicker, Space } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import './addRelease.css'


export default function AddReleaseNotes() {
    const animatedComponents = makeAnimated();

    const typeList = [
        { value: 'whats-new', label: "What's new", },
        { value: 'bug-fixes', label: 'Bug Fixes', },

    ]

    const consumerSelectStyle = {
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: (base, state) => ({
            ...base,
            border: '1px solid #e4e6fc',
            boxShadow: state.isFocused ? "0 0 0 1px #8179f0" : 0,
            '&:hover': {
                border: '1px solid #e4e6fc',
                cursor: 'text'
            }
        }),
        clearIndicator: (base, state) => ({
            ...base,
            cursor: 'pointer'
        })
    }
    return (
        <>
            <div className="main-content" style={{ minHeight: "562px", paddingRight: '30px' }}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/group-deshboard">
                                <i className="fas fa-tachometer-alt"></i> Home
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to='/release-notes'>
                                <i className='fas fa-book '></i> Release Notes
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to='/add-realese-notes'>
                                Add Release Notes
                            </Link>
                        </li>
                    </ol>
                </nav>
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className='card-header'>
                                        <h4>
                                            Add Release Notes
                                        </h4>
                                        <div className="card-header-action">
                                            <Link to="/release-notes" className="btn btn-primary">
                                                Back
                                            </Link>
                                        </div>

                                    </div>
                                    <div className='card-body'>
                                        <form method='post' name='release-notes-form' id='release-notes-form' onSubmit={{}}>
                                            <fieldset className='customLegend tw-mb-4 !tw-mt-0'>
                                                <legend>Add Details</legend>
                                                <div className='form-group row mb-4'>
                                                    <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Select Release Date : </label>
                                                    <div className='col-sm-12 col-md-7'>

                                                        <Space direction="horizontal">
                                                            <DatePicker id='release date' format='DD-MM-YYYY' name='release-date' />
                                                        </Space>
                                                    </div>

                                                </div>
                                                {/* <div className='form-group row mb-4'>
                                                    <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Title : </label>
                                                    <div className='col-sm-12 col-md-7'>

                                                        <input type={'text'} className='form-control'>
                                                        </input>
                                                    </div>

                                                </div>
                                                <div className='form-group row mb-4'>
                                                    <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Select type : </label>
                                                    <div className='col-sm-12 col-md-7'>

                                                    <Select isClearable placeholder='Select Type...' id='type' styles={consumerSelectStyle} components={animatedComponents} name='type' options={typeList} />
                                                    </div>

                                                </div> */}
                                                <div className='form-group row mb-4'>
                                                    <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">What's new Content : </label>
                                                    <div className='col-sm-12 col-md-7'>
                                                        <CKEditor editor={ClassicEditor} config={{
                                                            placeholder: "Add What's New Content here ",
                                                            toolbar: {
                                                                removeItems: ['uploadImage']
                                                            }
                                                        }
                                                        } data="" onReady={editor => {
                                                            // You can store the "editor" and use when it is needed. 
                                                            console.log('Editor is ready to use!', editor);
                                                        }} onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            console.log("Data", { event, editor, data });
                                                        }}
                                                            onBlur={(event, editor) => {
                                                                console.log('Blur.', editor);
                                                            }}
                                                            onFocus={(event, editor) => {
                                                                console.log('Focus.', editor);
                                                            }} />
                                                    </div>

                                                </div>
                                                <div className='form-group row mb-4'>
                                                    <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Bug Fixes Content : </label>
                                                    <div className='col-sm-12 col-md-7'>
                                                        <CKEditor editor={ClassicEditor} config={{
                                                            placeholder: "Add Bug Fixes content here ",
                                                            toolbar: {
                                                                removeItems: ['uploadImage']
                                                            }
                                                        }

                                                        } data="" onReady={editor => {
                                                            // You can store the "editor" and use when it is needed. 
                                                            console.log('Editor is ready to use!', editor);
                                                        }} onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            console.log("Data", { event, editor, data });
                                                        }}
                                                            onBlur={(event, editor) => {
                                                                console.log('Blur.', editor);
                                                            }}
                                                            onFocus={(event, editor) => {
                                                                console.log('Focus.', editor);
                                                            }} />
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <div class="row ">
                                                <div className='tw-flex tw-gap-2'>
                                                    <input type="submit" name="submit" class="btn btn-danger" value="Submit" id="submitvn" />
                                                    <a class="btn btn-light text-dark" href="#">Cancel</a>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </section>

            </div>
        </>
    )
}
