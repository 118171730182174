import * as React from "react"
import { cn } from "../../../lib/utils"
import { MdOutlineDragIndicator } from "react-icons/md";
import { BootstrapTooltip } from "../../../Commoncomponet/common";

const Card = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("tw-rounded-lg border-top tw-bg-card !tw-border-t-2 tw-mb-5 tw-shadow-md tw-overflow-hidden", className)}
    {...props} />
))

const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("tw-py-2 tw-px-3 tw-mb-0  tw-bg-card tw-bg-white", className)}
    {...props} />
))

const CardHeaders = React.forwardRef(({ className, children, title, tooltip, IsDrag, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("tw-flex  tw-items-center tw-justify-between tw-p-3  border border-top-0 tw-border-[#e5e7eb] ", className)}

    {...props} >
    <>
      <h4 className="tw-mb-0 tw-text-[17px] tw-leading-7" >
        <span className="mr-2">{IsDrag && <MdOutlineDragIndicator className="tw-mr-1" />}{title}</span>

        {
          tooltip && <BootstrapTooltip title={tooltip} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip>
        }
      </h4>
      {children}
    </>
  </div>
))

const CardBody = React.forwardRef(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("tw-py-2 tw-px-5 border border-top-0 tw-border-[#e5e7eb] tw-rounded-br-lg tw-rounded-bl-lg", className)}
    {...props} >
    <>
      {children}
    </>
  </div>
))

const CardFooter = React.forwardRef(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("tw-h-[45px]", className)}
    {...props} >
    <>
      {children}
    </>
  </div>
))

export { Card, CardHeader, CardBody, CardFooter,CardHeaders }
