import React from 'react'

export default function Publications({credentialdetails,setcredentialdetails,isdisable}) {
  return (
    <>
      <fieldset className="customLegend">
        <legend>To Receive Data (for user)</legend>
        <div className='row'>
          <div className="col-12">
            <div className="form-group   pl-3 pr-3">
              <label for="db_user_name">Publish Acknowledgment on this Topic:</label>
              <input type="text" className="form-control col-6" name="db_user_name" disabled={isdisable} id="db_user_name" placeholder="ccms/mmdc/{Client Id}/forward/pub" value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.MQTT_RECEIVE_PUB_TOPIC} onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), MQTT_RECEIVE_PUB_TOPIC: e.target.value }) }))} />
            </div>
          </div>

        </div>
      </fieldset>
      <fieldset className="customLegend">
        <legend>To Config Device (for user)</legend>
        <div className='row'>
          <div className="col-12 ">
            <div className="form-group pl-3 pr-3">
              <label for="db_user_name">Publish Command on this Topic:</label>
              <input type="text" className="form-control col-6" name="db_user_name" disabled={isdisable} id="db_user_name" placeholder="ccms/mmdc/{Client Id}/config/pub" value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.MQTT_CONFIG_PUB_TOPIC} onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), MQTT_CONFIG_PUB_TOPIC: e.target.value }) }))} />
            </div>
          </div>
        </div>
      </fieldset>
    </>
  )
}
