
import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Lock, Users } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import { BootstrapTooltip } from '../../Commoncomponet/common'
import * as $ from "jquery";
import * as XLSX from 'xlsx';
import dt from "datatables.net-dt";
import jsPDF from "jspdf";
import { getAllMeter, getPortablemeter_type, getmeter_type, syncDatabaseTabledata } from '../../Commoncomponet/Utils'
import { Modal } from 'antd'
import moment from 'moment'
import { toast } from 'react-toastify'
import classNames from 'classnames'
$.fn.DataTable = dt;
export default function Type() {
	const [ModemData, setModemData] = useState([])
	const [filterrolesData, setfilterRolesData] = useState([])
	const [loader, setLoader] = useState(false)
	const [roleFilter, setRoleFilter] = useState({ name: '', status: '' })
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [csvData, setCsvData] = useState([])
	const [isSideExapnded,setIsSideExapnded]=useState(false)
    const [SideDetails,setSideDetails]=useState({})
	const history = useHistory()
	const ongetRolesUserData = async () => {
		setLoader(true);
		$(".loader").fadeOut("slow");
		const data = await getPortablemeter_type()
		if (data.success === true) {
			setModemData(data.data.data)
			setfilterRolesData(data.data.data)
		} else {
			console.log('somthing Went wrong')
			setLoader(false)
		}
	}
	const csvLink = useRef();

	const downloadReport = async (isCsv) => {
		if (ModemData.length > 0) {
			const finalCsvData = await ModemData.map((item) => ({ 'SRNO': item.SRNO, 'Unique ID': item.UNIQUE_ID, 'Created Time': moment(item.CREATED_AT).format('DD-MM-YYYY'), 'Created By': item.CREATED_BY, 'Name': item.METER_TYPE_NAME, 'Status': (item.STATUS === 1 ? 'Active' : 'InActive') }))
			setCsvData(finalCsvData)
			if (isCsv === true) {
				if (finalCsvData.length > 0) {
					csvLink.current.link.click();
				}
			}
			else {
				if (finalCsvData.length > 0) {
					const worksheet = XLSX.utils.json_to_sheet(finalCsvData);
					const workbook = XLSX.utils.book_new();
					XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
					XLSX.writeFile(workbook, `Meter-type-data.xlsx`);
				}
			}
		}
	}


	useEffect(() => {
		ongetRolesUserData().then(() =>
			setTimeout(function () {
				$("#table-1").DataTable({ "pageLength": 50, order: [[0, 'desc']] });
				setLoader(false);

			}, 700)
		);
	}, []);


	const UpdateRolesUser = (item) => {
		// history.push({ pathname: `/add-type`, state: item })
		setIsSideExapnded(true)
		setSideDetails(item)

	}

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const onhandleOk = () => {
		setIsModalVisible(false)
		if (roleFilter.name === '' && roleFilter.status === '') {
			return setModemData(filterrolesData)
		}
		const filteData = filterrolesData && filterrolesData.filter((item) => (roleFilter.name !== '' ? item?.METER_TYPE_NAME?.includes(roleFilter.name) : []) && (roleFilter.status !== '' ? item?.STATUS == roleFilter.status : []))
		setModemData(filteData)
	}
	const syncList = async () => {
		setLoader(true);
		$(".loader").fadeOut("slow");
		const userInfo=JSON.parse(window.localStorage.getItem("user_info")||{})
        const data = await syncDatabaseTabledata({ createdBy: userInfo?.UNIQUE_ID,tableName:'pcmt_eums_port_meter_type',synctableName:'pcst_eums_port_meter_type' })
        if (data.success === true) {
            toast.success("Synced successfully !");
            ongetRolesUserData()
            setLoader(false)
        }
        else {
            setLoader(false)
            console.log("Error while getting all table info = ", data.err)
        }
    }
	return (
		<>
			<Modal title="Filter  Meter Type" visible={isModalVisible} footer="" onCancel={handleCancel}>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="inputState"> Meter Type :</label>
						<input type="text" class="form-control" id="" onChange={(e) => (setRoleFilter({ ...roleFilter, name: e.target.value }))} value={roleFilter.name} name="FirstName" placeholder="Enter  Meter Type" />
					</div>
					<div class="form-group col-md-6">
						<label for="inputPassword4"> Meter Type Status:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="modem_name"
							onChange={(e) => (setRoleFilter({ ...roleFilter, status: e.target.value }))}
							value={roleFilter.status}
						>
							<option value="">Select Status</option>
							<option value={1}>Active</option>
							<option value={0}>InActive</option>
						</select>
					</div>
				</div>
				<div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Filter" name="filter_customer_form" onClick={onhandleOk} />
					<Link class="btn btn-light text-dark ml-2" to={`/metetype`}>
						Reset
					</Link>
				</div>
			</Modal>
			{/* <div className="main-content"> */}
				{loader ? (
					<div className="main-content">
						<div class="loader"></div>
					</div>
				) : (
					""
				)}
				{/* <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/all-deshboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								<Lock style={{ height: "16px" }} />Admin
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								Meter Driver
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								Meter Type
							</Link>
						</li>
					</ol>
				</nav> */}
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<h4><span className='mr-2'>Meter Type</span><BootstrapTooltip title={`Table Name is meter_type`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div className="card-header-action">
										<button className="btn btn-primary mr-2" onClick={() => syncList()}>Sync Data</button>
											{/* <Link to="/add-PoratableDevice-metetype" className="btn btn-info text-white mr-2">
												<BootstrapTooltip title="Add">
													<span>
														<i
															className="fas fa-plus text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</Link> */}
											<button type="button" className="btn btn-info mr-2" onClick={() => (setIsModalVisible(true))}>
												<BootstrapTooltip title="Filter">
													<span>
														<i className="fa fa-filter text-white"></i>
													</span>
												</BootstrapTooltip>
											</button>
											<div className="dropdown d-inline mr-2">
												<button
													className="btn btn-info dropdown-toggle mr-2"
													type="button"
													id="dropdownMenuButton3"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													data-placement="bottom"
													title="Download"
												>
													<i className="fa fa-download mr-1"></i>
												</button>
												<div className="dropdown-menu">
													<a className="dropdown-item" >
														PDF
													</a>
													<a onClick={() => downloadReport(false)} className="dropdown-item" >
														Excel
													</a>
													<a onClick={() => downloadReport(true)} className="dropdown-item">
														CSV
													</a>
													<CSVLink data={csvData} filename={"Meter-type-data.csv"} ref={csvLink} />
												</div>
											</div>
										</div>
									</div>
									<div className="card-body">
									<div className="d-flex"><div className="table-responsive tw-h-screen tw-max-h-screen">
									<div id="table-1_wrapper" className="dataTables_wrapper no-footer">
									<table id="table_id" className="tableCustom table table-striped tw-w-full tw-text-sm text-center">
											<thead className="tw-sticky tw-z-50 tw-top-0 tw-text-xs tw-bg-gray-100">
													<tr>
														<th className="text-center !tw-font-semibold" style={{ width: "100px" }}>Unique No</th>
														<th className='!tw-font-semibold'>Name</th>
														<th className='!tw-font-semibold' style={{ width: "100px" }}>Status</th>
													</tr>
												</thead>
												<tbody>
													{ModemData &&
														ModemData.map((item, id) => (
															<tr style={{ height: "4rem" }} className="trheigth hover:tw-bg-[#D8D8D8]" key={id} onClick={() => (UpdateRolesUser(item))}>
																<td><div key={item?.SRNO} className={'tw-flex tw-items-center tw-text-blue-600 tw-cursor-pointer tw-group tw-gap-2'}>
																	<span className='tw-whitespace-nowrap text-center'>{item?.SRNO}</span>
																	<div className="tw-opacity-0 tw-transition-all group-hover:tw-opacity-100" >
																		<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" id="right-arrow"><g data-name="Layer 2"><g data-name="arrowhead-right"><path fill='currentColor' d="m18.78 11.37-4.78-6a1 1 0 0 0-1.41-.15 1 1 0 0 0-.15 1.41L16.71 12l-4.48 5.37a1 1 0 0 0 .13 1.41A1 1 0 0 0 13 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 .01-1.27z"></path><path fill='currentColor' d="M7 5.37a1 1 0 0 0-1.61 1.26L9.71 12l-4.48 5.36a1 1 0 0 0 .13 1.41A1 1 0 0 0 6 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 0-1.27z"></path></g></g></svg>
																	</div>
																</div></td>
																<td>{item?.METER_TYPE_NAME}</td>
																<td className="">
																	<div className={`badge ${item?.STATUS === 1 ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{item?.STATUS === 1 ? 'Active' : 'Inactive'}</div>
																</td>
															</tr>
														))}
												</tbody>
											</table>
											</div>
											</div>
											{isSideExapnded && <div className='tw-flex tw-relative  tw-w-full tw-overflow-auto tw-grow tw-bg-[#f8f8f8]'>
										<div className={classNames('!tw-border-r-gray-300 tw-h-screen', isSideExapnded ? 'animate__fadeInRight_grow border-end tw-w-[100%]' : 'animate__fadeInLeft tw-flex-grow')}>
											<div className='tw-flex tw-flex-col tw-w-full'>
												<div className='tw-w-full tw-flex tw-items-center border-bottom !tw-border-b-gray-300 lg:tw-pl-5 tw-pr-2 tw-py-3 md:tw-px-0'>
													<span className='tw-text-md '> SRNO : <b>{SideDetails?.SRNO}</b></span>
													<div class="ml-auto"></div>
													
													<span
													onClick={()=>{setIsSideExapnded(false)}}
														className='border-start !tw-border-l-gray-300 tw-float-right tw-text-[#777] tw-ml-2 tw-cursor-pointer hover:tw-text-[#212529]'>
														<svg className='tw-h-8 lg:tw-w-10 md:tw-w-7' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /> </svg>
													</span>
												</div>
											</div>
											<div className='tw-overflow-y-auto tw-pb-10 m-3' style={{ backgroundColor: 'rgb(255, 255, 255)', boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 10px 0px' }}>
												<div className='tw-float-right mr-2 mt-2'>
														
													
												</div>
												<div className='tw-px-4 '>
													<div className='tw-grid tw-grid-cols-5 tw-gap-4 tw-mt-4'>
													{Object.entries(SideDetails||{}).map(([keys,value])=>(
															<>
															<div className=''>
															{keys} :-
														</div>
														<div className='tw-col-span-4'>
															<b className='tw-text-base tw-mr-2'>{keys=='MODEM_FIRMWARE_CREATED_AT'?moment.utc(value).format('DD-MM-YYYY hh:mm:ss A'):keys=='MODEM_FIRMWARE_STATUS'?<div className={`badge ${value === 1 ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{value === 1 ? 'Active' : 'Inactive'}</div>:value} </b>
														</div>
														</>
													))}
														
														
														
													</div>
												</div>
											</div>
											
										</div>
									</div>}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			{/* </div> */}
		</>
	)
}
