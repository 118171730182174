import { Switch } from 'antd';
import React, { useState } from 'react'

export default function SecurityTLS({ credentialdetails, setcredentialdetails, isdisable,setCre_files,Cre_files }) {
    const [formData, setFormData] = useState({
        TLSSSLmode: 'Disabled',
        protocol: 'TLSv1.3'
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    return (
        <div className='tw-box-border'>
            <div className='card-body'>

                <div className='!tw-mb-2 form-group tw-flex' >
                    <label className='tw-w-[50%]  tw-flex tw-items-center'>TLS/SSL mode :- </label>
                    <select class="form-control  form-select " name="TLSSSLmode" value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.TLSSSLmode} onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), TLSSSLmode: e.target.value }) }))} disabled={isdisable}>
                        <option value={1}>Disabled</option>
                        <option value={2}>Certificates & Keys provided externally</option>
                        <option value={3}>CA certificate</option>
                        <option value={4}>CA certificate & client certificate/key</option>
                        <option value={5}>CA trust store*</option>
                        <option value={6}>CA trust store & client key store*</option>
                        <option value={7}>TLS/SSL propertoes*</option>
                    </select>
                </div>
                {JSON.parse(credentialdetails?.endpoint_credential || '{}')?.TLSSSLmode && JSON.parse(credentialdetails?.endpoint_credential || '{}')?.TLSSSLmode !== '1' && (
                    <div className='!tw-mb-2 form-group tw-flex'>
                        <label className='tw-w-[50%]  tw-flex tw-items-center'>Protocol :- </label>
                        <select className="form-control  form-select " name="protocol" value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.secureProtocol} onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), secureProtocol: e.target.value }) }))} disabled={isdisable}>
                        <option value={''}>Select Protocol</option>
                            <option value={'TLSv1_3_method'}>TLSv1.3</option>
                            <option value={'TLSv1_2_method'}>TLSv1.2</option>
                            <option value={'TLSv1_1_method'}>TLSv1.1</option>
                            <option value={'TLSv1_method'}>TLSv1</option>
                            <option value={'SSLv3_method'}>SSLv3</option>
                        </select>
                    </div>
                )}
                <div className='tw-mb-3 form-group tw-flex' >
                    <label className='tw-w-[33%] tw-flex tw-items-center'>SSL Secure :-</label>
                    <Switch  disabled={isdisable}  checked={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.rejectUnauthorized} onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), rejectUnauthorized: e })}))}></Switch>
                </div>
                {JSON.parse(credentialdetails?.endpoint_credential || '{}')?.TLSSSLmode && (JSON.parse(credentialdetails?.endpoint_credential || '{}')?.TLSSSLmode == '3' ||JSON.parse(credentialdetails?.endpoint_credential || '{}')?.TLSSSLmode == '4')&&<div class="form-group row" style={{ marginTop: "-20px" }} >
                    <label for="lastcontectname1" class="tw-w-[30%] col-form-label d-flex tw-items-center">
                    CA Certificate file :-
                    </label>
                    <div class="d-flex tw-w-[65%] border ml-4" style={{ padding: '2px' }}>
                    <div className='btn btn-secondary text-dark mr-2' style={{ width: "150px" }} onClick={() => { document.getElementById('file-uploads').click() }}>Upload File</div>
                        <input type={'text'} class="form-control" value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.ca_file} disabled></input>
                        <input type="file" class="form-control" id={"file-uploads"} hidden  onChange={(e) => {setCre_files({...Cre_files,CA:e.target.files[0]})
                        setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), ca_file: e?.target?.files[0]?.name })})
                        }}/>
                    </div>
                </div>}
                {JSON.parse(credentialdetails?.endpoint_credential || '{}')?.TLSSSLmode && JSON.parse(credentialdetails?.endpoint_credential || '{}')?.TLSSSLmode == '4' &&<div class="form-group row" style={{ marginTop: "-20px" }} >
                    <label for="lastcontectname1" class="tw-w-[30%] col-form-label">
                Client Certificate file :-
                    </label>
                    <div class="d-flex tw-w-[65%] border ml-4" style={{ padding: '2px' }}>
                    <div className='btn btn-secondary text-dark mr-2' style={{ width: "150px" }} onClick={() => { document.getElementById('file-uploadsq').click() }}>Upload File</div>
                        <input type={'text'} class="form-control" value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.client_certificate_file} disabled></input>
                        <input type="file" class="form-control" id={"file-uploadsq"} hidden  onChange={(e) => {setCre_files({...Cre_files,client_ca_file:e.target.files[0]})
                    setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), client_certificate_file: e?.target?.files[0]?.name })})
                    }}/>
                    </div>
                </div>}
                {JSON.parse(credentialdetails?.endpoint_credential || '{}')?.TLSSSLmode && JSON.parse(credentialdetails?.endpoint_credential || '{}')?.TLSSSLmode == '4' &&<div class="form-group row" style={{ marginTop: "-20px" }} >
                    <label for="lastcontectname1" class="tw-w-[30%] col-form-label">
                Client Key file :-
                    </label>
                    <div class="d-flex tw-w-[65%] border ml-4" style={{ padding: '2px' }}>
                    <div className='btn btn-secondary text-dark mr-2' style={{ width: "150px" }} onClick={() => { document.getElementById('file-uploadsw').click() }}>Upload File</div>
                        <input type={'text'} class="form-control" value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.client_key_file} disabled></input>
                        <input type="file" class="form-control" id={"file-uploadsw"} hidden  onChange={(e) => {setCre_files({...Cre_files,client_key_file:e.target.files[0]})
                    setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), client_key_file: e?.target?.files[0]?.name })})
                    }}/>
                    </div>
                </div>}
            </div>
        </div >
    )
}
