import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { GeflowEncodeRegister, GetMssqlCardDetailsById, UpdateIntegrationConfig, activeandinactive, sentTriggerCardStatusUpdate, uploadCertificatefiles, uploadsFiles } from '../../../Commoncomponet/Utils'
import { BootstrapTooltip } from '../../../Commoncomponet/common';
import { toast } from 'react-toastify';
import swal from "sweetalert2";
import { Modal } from 'antd';
import Mqttspy from '../mqttspy/Mqttspy';
window.Swal = swal;

const Credentials = () => {

    const [credentialdetails, setcredentialdetails] = useState({})
    const [letestDetails, setletestDetails] = useState({})
    const [Cre_files, setCre_files] = useState({CA:'',client_ca_file:'',client_key_file:'',psw_secret_key_file:''})
    const [isdisable, setIsDisable] = useState(false)
    const params = useParams();
    const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
    const [encodeList, setencodeList] = useState([])
    const [loader, setloader] = useState(false)
    const [ModelInfo,setModelInfo]=useState(false)

    const getCredential = async () => {
        const data = await GetMssqlCardDetailsById(params?.MSID)
        if (data.success == true) {
            setcredentialdetails(data?.data?.data)
        } else {
            console.log('something Went wrong')
        }
    }
    const ongetEncodeRegister = async () => {
        const data = await GeflowEncodeRegister()
        if (data.success == true) {
            setencodeList(data?.data?.data)
            setletestDetails(data?.data?.data)
        } else {
            console.log('Server Issue')
        }
    }
    useEffect(() => {
        setIsDisable(true)
        getCredential()
        ongetEncodeRegister()
    }, [])
    

    const onEditIntegrationProfile = async () => {
        if (credentialdetails?.NAME == '') {
            return toast.error('Enter Name')

        }
        if (credentialdetails?.endpoint_type == '') {
            return toast.error('Select Endpoint Type')

        }
        if (credentialdetails?.STRUCTURE_ID == '') {
            return toast.error('Select Structure')

        }
        const obj = { ...credentialdetails }
        const old_json = { ...letestDetails }
        var old_diffrent_json = {}
        const diffrent_json = {}
        Object.keys(old_json).map((item) => {
            if (old_json[item] != obj[item]) {
                diffrent_json[item] = obj[item]
                old_diffrent_json[item] = old_json[item]
            }
        })
        setloader(true)
        var Attachment=[]
        await new Promise(async (resolve) => Object.keys(Cre_files).length > 0 && Object.entries(Cre_files).map(async ([key,item]) => {
            console.log(item)
            if (item !== '') {
               if (typeof item !== 'string') {
                  const formData = new FormData()
                  formData.append('Images', item)
                  const uploadImges = await uploadCertificatefiles(formData,params?.MSID)
                  if (uploadImges.success === true) {
                     console.log(uploadImges)
                     Attachment.push({success:true})
                  } else {
                    Attachment.push({success:false})
                  }
               } else {
                Attachment.push({success:false})
               }
            } else {
                Attachment.push({success:false})
            }
            if (Object.keys(Cre_files).length === Attachment.length) {
               resolve()
            }
         })
        )
        const data = await UpdateIntegrationConfig({ ...credentialdetails, add_by: userInfo?.UNIQUE_ID, old_diffrent_json: old_diffrent_json, diffrent_json: diffrent_json }, params?.MSID)
        if (data.success == true) {
            getCredential()
            setIsDisable(true)
            toast.success('Update Success')
            setloader(false)
        } else {
            toast.error('Server Issue')
            setloader(false)
        }
    }

    const onactive = () => {
        swal.fire({
            title: "Are You Sure Active Integration?",
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                if (credentialdetails?.endpoint_type=='1'&&(!JSON.parse(credentialdetails?.endpoint_credential||'{}')?.URL||!JSON.parse(credentialdetails?.endpoint_credential||'{}')?.clientId)) {
                  
                    return toast.error('MQTT Credential Required')
                    
                }
                if(JSON.parse(credentialdetails?.endpoint_credential||'{}')?.user_auth){
                    if(!JSON.parse(credentialdetails?.endpoint_credential||'{}')?.Username||!JSON.parse(credentialdetails?.endpoint_credential||'{}')?.Password){
                        return toast.error('Username and Password Required')
                    }
                }
                const data = await activeandinactive({ tableName: 'integration_index', colName: 'STATUS', value: '1', matchId: 'unique_id' }, credentialdetails.unique_id)
                if (data.success == true) {
                    getCredential()
                    await sentTriggerCardStatusUpdate()
                    toast.success('Active Success')
                } else {
                    toast.success('Server Issue')
                }
            }
        })
    }

    const oninactive = () => {
        swal.fire({
            title: "Are You Sure Inactive Integration?",
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                const data = await activeandinactive({ tableName: 'integration_index', colName: 'STATUS', value: '0', matchId: 'unique_id' }, credentialdetails.unique_id)
                if (data.success == true) {
                    getCredential()
                    await sentTriggerCardStatusUpdate()
                    toast.success('Inactive Success')
                } else {
                    toast.success('Server Issue')
                }
            }
        })
    }

    const onPenddingConfguration = () => {
        swal.fire({
            title: "Are You Sure Pendding Integration?",
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                const data = await activeandinactive({ tableName: 'integration_index', colName: 'STATUS', value: '-1', matchId: 'unique_id' }, credentialdetails.unique_id)
                if (data.success == true) {
                    getCredential()
                    await sentTriggerCardStatusUpdate()
                    toast.success('Pendding Success')
                } else {
                    toast.success('Server Issue')
                }
            }
        })
    }

    const exportData = () => {
        // Create a link and download the file
        const data = JSON.stringify(JSON.parse(encodeList.filter((item)=>(item?.unique_id==credentialdetails?.structure_id))[0]?.data_structure||'{}'),null,4);
        const blob = new Blob([data], { type:'application/json' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `structure_${credentialdetails?.structure_id}.json`;
        a.click();
        window.URL.revokeObjectURL(url);
      };

    return (
        <>
        <Modal title={<>Structure<div className='btn btn-info ml-2' onClick={exportData}><i className='fas fa-download'></i></div></>} visible={ModelInfo} footer='' maskClosable={false} onCancel={() => (setModelInfo(false))} width={500}>
                <div>
                    <pre>{JSON.stringify(JSON.parse(encodeList.filter((item)=>(item?.unique_id==credentialdetails?.structure_id))[0]?.data_structure||'{}'),null,4)}</pre>
                </div>
            </Modal>
            <div className="Notcard">
                <div className="row ">
                    <div className="tw-px-6">
                   
                            <div className='d-flex tw-justify-between mt-3 mb-4'>
                            <div className='d-flex tw-items-center ml-4'><b>Connection Status :- </b> <div className={`ml-2 badge badge-${credentialdetails?.last_log?.online_offline_status === 'online' ? 'success' : credentialdetails?.last_log?.online_offline_status === 'offline' ? 'danger' : 'danger'}`}>{credentialdetails?.last_log?.online_offline_status === 'online' ? 'Online' : credentialdetails?.last_log?.online_offline_status === 'offline' ? 'offline' : 'offline'}</div></div>
                            <div className='d-flex'>
                                <div class="dropdown mr-2">
                                    <button class={`btn ${credentialdetails?.status === 1 ? 'btn-success' : credentialdetails?.status === 0 ? 'btn-danger' : credentialdetails?.status === 2 ? 'btn-info' : credentialdetails?.status === 3 ? 'btn-dark' : `btn-warning ${userInfo?.ROLE=='ROLE1661856938676'?'':'disabled'}`} dropdown-toggle`} type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" >
                                        {credentialdetails?.status === 1 ? 'Active' : credentialdetails?.status === 0 ? 'InActive' : 'Pending'}
                                    </button>
                                    <div class="dropdown-menu !tw-left-[-50px]" aria-labelledby="dropdownMenuButton1">
                                        {credentialdetails?.status != 1 ? <a class="dropdown-item text-success" onClick={() => (onactive())} >Active</a> : ''}
                                        {credentialdetails?.status != 0 ? <a class="dropdown-item text-danger" onClick={() => (oninactive())} >InActive</a> : ''}
                                        {credentialdetails?.status != -1 ? <a class="dropdown-item !tw-text-[#f59e0b]" onClick={() => (onPenddingConfguration())} >Pending</a> : ''}
                                    </div>
                                </div>
                                <div class="btn btn-info text-white btnedit mr-2 " onClick={() => (setIsDisable(false))}><BootstrapTooltip title='edit'>
                                    <span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
                                        <i class="fas fa-edit  text-white"></i>
                                    </span></BootstrapTooltip>
                                </div>
                                </div>
                            </div>
                            <fieldset className="customLegend">
                                <legend>Profile Details</legend>
                                <div className="form-row rounded p-3" style={{ marginLeft: '10px', borderColor: '#e3e6fc !important', position: 'relative' }}>
                                    <div className="form-group col-md-3">
                                        <label for="inputPassword4">Unique Id:<span className='text-danger'>*</span></label>
                                        <input className="form-control" id="profile_name" name="profile_name" type="text" disabled={true} value={credentialdetails?.unique_id} placeholder="" />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label for="inputPassword4">Profile Name:<span className='text-danger'>*</span></label>
                                        <input className="form-control" id="profile_name" name="profile_name" type="text" disabled={isdisable} value={credentialdetails?.name} placeholder="" onChange={(e) => (setcredentialdetails({ ...credentialdetails, name: e.target.value }))} />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label for="profile_description">Profile Description</label>
                                        <input className="form-control" id="profile_description" name="profile_description" disabled={isdisable} type="text" value={credentialdetails?.description} placeholder="" onChange={(e) => (setcredentialdetails({ ...credentialdetails, description: e.target.value }))} />
                                    </div>
                                    {credentialdetails?.integration_type==1?<div className="form-group col-md-3" id="grpeui">
                                        <label for="inputPassword4 ">Integration Structure :<span className='text-danger mr-2'>*</span><BootstrapTooltip title={'View Structure'} placement='top'><span onClick={()=>(setModelInfo(true))}><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip> </label>
                                        <select className="form-control form-select" name="reporttype" required="required" onChange={(e) => { setcredentialdetails({ ...credentialdetails, structure_id: e.target.value }) }} value={credentialdetails?.structure_id} disabled={isdisable}>
                                            <option value="">Select integration Structure</option>
                                            {encodeList?.map((item) => (<option value={item?.unique_id}>{item?.name}</option>))}
                                        </select>
                                        {credentialdetails?.structure_id&&<span><b>JSON Type :- </b>{encodeList.filter((item)=>(item?.unique_id==credentialdetails?.structure_id))[0]?.json_type=='1'?'Standard JSON':encodeList.filter((item)=>(item?.unique_id==credentialdetails?.structure_id))[0]?.json_type=='2'?'OPC JSON':''}</span>}
                                    </div>:''}
                                </div>
                            </fieldset>

                            <div className="col-lg-12 tw-mt-3" >
                                <ul className="nav nav-pills border" id="myTab3" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="profile-tab3" data-toggle="tab" href="#profile3" role="tab" aria-controls="profile" aria-selected="false">Credential</a>
                                    </li>
                                    {/* {credentialdetails?.endpoint_type=='0'?'':<li className="nav-item">
                                        <a className="nav-link" id="contact-tab3" data-toggle="tab" href="#contact3" role="tab" aria-controls="contact" aria-selected="false">Scheduler</a>
                                    </li>} */}

                                    {/* <li className="nav-item">
                                        <a className="nav-link" id="others-tab3" data-toggle="tab" href="#others3" role="tab" aria-controls="others" aria-selected="false" onClick={{}}>Others</a>
                                    </li> */}
                                </ul>
                                <div className="tab-content" id="myTabContent2">
                                    <div className="tab-pane fade show active" id="profile3" role="tabpanel" aria-labelledby="profile-tab3">

                                        <div className="tw-grid  tw-grid-cols-2  gdt" style={{ paddingLeft: '0px' }}>
                                            
                                            <div className="form-group  mr-3" id="grpeui" style={{ paddingLeft: '0px' }}>
                                                <label for="inputPassword4 ">Endpoint Type :<span className='text-danger'>*</span> </label>
                                                <select className="form-control form-select" name="reporttype" required="required" onChange={(e) => { setcredentialdetails({ ...credentialdetails, endpoint_type: e.target.value }) }} value={credentialdetails?.endpoint_type} disabled={isdisable}>
                                                    <option value="">Select Endpoint Type</option>
                                                    <option value={1}>MQTT</option>
                                                    <option value={2}>HTTP *</option>
                                                    <option value={3}>DATABASE *</option>
                                                </select>
                                            </div>
                                            {credentialdetails?.endpoint_type == '1'&&<div className="form-group ml-3" id="grpeui" style={{ paddingLeft: '0px' }}>
                                                <label for="inputPassword4 ">Broker:</label>
                                                <select className="form-control form-select" name="reporttype" required="required" disabled={isdisable} value={credentialdetails?.broker} onChange={(e)=>(setcredentialdetails({...credentialdetails,broker:e.target.value}))}>
                                                    <option value={1}>Memighty Broker</option>
                                                    <option value={2}>Third Party Broker</option>
                                                </select>
                                            </div>}
                                        </div>

                                        <div className="row">

                                            {credentialdetails?.endpoint_type == '2' && <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="db_host_name">API Endpoint:</label>
                                                    <input type="text" className="form-control present_home_no" name="db_host_name" disabled={isdisable} id="db_host_name" placeholder="" value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.api_endpoint} onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), api_endpoint: e.target.value }) }))} />
                                                </div>
                                            </div>}
                                            {credentialdetails?.endpoint_type == 'API' && <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="db_database_name">Database Name:</label>
                                                    <input type="text" className="form-control" name="db_database_name" id="db_database_name" disabled={isdisable} placeholder="" value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.options?.database} />
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    

                                    {credentialdetails?.endpoint_type == '1'&&<Mqttspy credentialdetails={credentialdetails} setcredentialdetails={(info)=>setcredentialdetails({...info})} isdisable={isdisable} setCre_files={setCre_files} Cre_files={Cre_files}/>}
                                </div>
                                {!isdisable ? <div class='d-flex mb-4'>
                                    <div className='btn btn-danger mr-2' onClick={onEditIntegrationProfile}>Submit</div>
                                    <div className='btn btn-light ' onClick={() => {
                                        setIsDisable(true)
                                        getCredential()
                                    }}>Cancel</div>
                                </div> : ''}
                            </div>
                        
                    </div>
                </div>
            </div>

        </>
    )
}

export default Credentials