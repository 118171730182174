import { Popover, TreeSelect } from "antd";

const SelectComponent = ({ label, id, name, value, onChange, options = [], placeholder, className }) => (
    <div className={`tw-min-w-[11rem] tw-max-w-[15rem] form-group ${className ?? null}`}>
        <label htmlFor={id}>{label}</label>
        <select
            className="form-control form-select"
            id={id}
            name={name}
            value={value}
            onChange={onChange}
        >
            <option value="">{placeholder}</option>
            {options?.map((item, idx) => (
                <option key={idx} value={item.value}>
                    {item.label}
                </option>
            ))}
        </select>
    </div>
);

const FilterButton = ({ onClick, isDisabled }) => (
    <button className={`btn btn-primary ${isDisabled ? 'disabled btn-progress' : ''}`} onClick={onClick}>
        <i className="fa fa-search"></i>
    </button>
);

const TreeSelectPopover = ({ treeData, onChangeTreeSelect, placeholder, className }) => (
    <Popover trigger="click" overlayStyle={{ width: '300px' }} content={
        <TreeSelect
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={treeData}
            placeholder={placeholder ?? "All Account"}
            treeDefaultExpandAll
            onChange={onChangeTreeSelect}
            allowClear
        />
    }>
        <div>
            <button className={`btn btn-primary ${className ?? null}`}><i className="fas fa-filter"></i></button>
        </div>
    </Popover>
);


export { SelectComponent, FilterButton, TreeSelectPopover }