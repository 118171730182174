import React, { useEffect, useState } from 'react'
import { Lock, Users } from 'react-feather'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { activeandinactive, getAllMeter, getAllModem, getCountUseToConsumerInEUI, getCountUseToConsumerInIMEI, InsertLoraModemDetails, InsertScriptdecodeId, UpdateLoraModemDetails, UpdatScriptdecodeId } from '../../Commoncomponet/Utils'
import {toast} from 'react-toastify'
import { BootstrapTooltip } from '../../Commoncomponet/common'
// import xlsx from 'xlsx'
import swal from "sweetalert2";
window.Swal = swal;

export default function AddModemDetails() {
    const [modem,setModem]=useState([])
    const [Meter,setMeter]=useState([])
const [flagBtn, setflegBtn] = useState(false);
const [scriptId,setScriptId]=useState('')
const userInfo=JSON.parse(window.localStorage.getItem("user_info")||{})
const [inputs,setInputs]=useState({modemId:'',EUI:'',DEVICE_SERIAL_NO:'',PANEL_SERIAL_NO:'',CREATED_BY:userInfo?.UNIQUE_ID,Consumer_assign:''})

const [status,setStatus]=useState(null)
const [disabled,setDisable]=useState(true)
const [Validation,setValidation]=useState({})
const history = useHistory();


const validation = (name, value) => {
    switch (name) {
        case "modemId":
            if (!value) {
                return "Please input modemId!";
            } else {
                return "";
            }
        case "EUI":
            if (!value) {
                return "Please input EUI!";
            } else {
                return "";
            }
        case "DEVICE_SERIAL_NO":
            if (!value) {
                return "Please input Last DEVICE SERIAL NO!";
            } else {
                return "";
            }
        case "FirstName":
            if (!value) {
                return "Please input First Name!";
            } else {
                return "";
            }
       
        default: {
            return null;
        }
    }
};
    const onHandaleSelection=async()=>{
        const data=await getAllModem()
        if(data.success===true){
            setModem(data.data.data)
        }else{
          console.log('somthing Went wrong')
        }
      }

      useEffect(() => {
        
        onHandaleSelection()
      }, []);

    
      const locations=useLocation()
      useEffect(() => {
          if (locations?.state) {
            setInputs({modemId:locations?.state?.C3_MODEM_TYPE,EUI:locations?.state?.EUI,DEVICE_SERIAL_NO:locations?.state?.C5_DEVICE_SERIAL_NO,PANEL_SERIAL_NO:locations?.state?.C6_PANEL_SERIAL_NO,Consumer_assign:locations?.state?.C9_CONSUMER_ASSIGN})
             setScriptId(locations?.state?.C2_UNIQUE_ID)
             setDisable(false)
             setStatus(locations?.state?.STATUS)
          }
       }, [locations]);

      const onsubmitHandale=async(e)=>{
        e.preventDefault()
        var allErrors={}
              setflegBtn(true)
              Object.keys(inputs).forEach((key) => {
                const error = validation(key, inputs[key]);
                if (error && error.length) {
                    allErrors[key] = error;
                }
            });
              if (Object.keys(allErrors).length) {
                setflegBtn(false)
                return setValidation(allErrors);
            } else {
            if(scriptId===''){
    const data=await InsertLoraModemDetails(inputs)
    if(data.success===true){
        toast.success('Success Add Modem Details')
    setflegBtn(false)
    history.push('/All-Lorawan')
    }else if (data.err.response.status === 409) {
        toast.error("Duplicat Value Not Allow");
        setflegBtn(false)

    }else{
        toast.error('Something Went Wrong')
    setflegBtn(false)
    }
}else{
    const data=await UpdateLoraModemDetails(inputs,scriptId)
    if(data.success===true){
        toast.success('Update Modem Success')
    setflegBtn(false)
    history.push('/All-Lorawan')
    }else if (data.err.response.status === 409) {
        toast.error("Duplicat Value Not Allow");
        setflegBtn(false)
    }else{
        toast.error('Something Went Wrong')
    setflegBtn(false)
    }
}
            }
      }


      const oninactive=async()=>{
        swal.fire({
              title: "Are You Sure InActive Modem?",
              icon: "warning",
              showCancelButton: true,
          }).then(async (willDelete) => {
           if (willDelete.isConfirmed) {
            const dataCount=await getCountUseToConsumerInEUI(inputs?.EUI)
			if(dataCount.success){
                if(dataCount.data.data==0){
        const data=await activeandinactive({tableName:'lora_modem_details_index',colName:'STATUS',value:'0',matchId:'UNIQUE_ID'},scriptId)
        if(data.success===true){
           toast.success('Success Inactive Modem')
           history.push('/All-Lorawan')
        }else{
           toast.error('something Went wrong')
        }
    }else{
        swal.fire({
            title: `${dataCount.data.data} Consumer Assign This Modem`,
            icon: "error",
        })
    }
    }else{

    }
     }
  })
       }
       const onactive=async()=>{
        swal.fire({
              title: "Are You Sure Modem?",
              // text: "Once deleted, you will not be able to recover this User Details!",
              icon: "warning",
              showCancelButton: true,
          }).then(async (willDelete) => {
           if (willDelete.isConfirmed) {
        const data=await activeandinactive({tableName:'lora_modem_details_index',colName:'STATUS',value:'1',matchId:'UNIQUE_ID'},scriptId)
        if(data.success===true){
           toast.success('Success Active Modem')
           history.push('/All-Lorawan')
        }else{
           toast.error('something Went wrong')
        }
     }
  })
       }
     
  return (
    <>
    <div className="main-content">
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to="/group-deshboard">
							<i className="fas fa-tachometer-alt"></i> Home
						</Link>
					</li>
					<li className="breadcrumb-item">
						<Link to='/All-Lorawan'>
							<Users style={{ height: "16px" }} /> Consumer

						</Link>
					</li>
                    <li className="breadcrumb-item">
						<Link>
                        Add Modem Details
						</Link>
					</li>
					
				</ol>
			</nav>
        <section class="section">
            <div class="section-body">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h4>{scriptId!==''?'Update Modem Details':'Add Modem Details'}</h4>
                                <div class="card-header-action">  
                                {scriptId!==''&&status==1?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2"  onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:''}
										{scriptId!==''&&status==0?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:''}
                                {scriptId!==''?<div  class="btn btn-info text-white btnedit mr-2"><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" onClick={()=>(setDisable(true))}><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:''}
										
                                    <Link to="/All-Lorawan" class="btn btn-info text-white align-left">
                                    Back
                                    </Link>
                                </div>
                            </div>
                            <div class="card-body">
                            <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" onSubmit={onsubmitHandale}>
                                    <div class="form-row" style={{marginLeft:"10px"}}>
                                        <div class="form-group col-md-3" id="grpeui">
                                            <label for="inputPassword4">Modem Profile: <span class="text-danger">*</span></label>
                                            <select class="form-control form-select modemtypename anothermodemname" required="" name="modem_profile_id" id="modem_profile_id" onChange={(e)=>(setInputs({...inputs,modemId:e.target.value}))} value={inputs.modemId} disabled={!disabled}>
                                                <option value="">Select Modem</option>
                                                {modem&&modem.map((item)=>(<option value={item?.MODEM_UNIQUE_ID}>{item?.MODEM_PROFILE_NAME}</option>))}       
                                                                                            </select>
                                                                                            <span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
															 {Validation?.modemId}
														</span>
                                        </div>
                                        <div class="form-group col-md-3">
														<label for="inputPassword4">
															EUI:<span class="text-danger">*</span>
														</label>
														<input
															class="form-control "
															id="deviceIMEI"
															name="deviceIMEI"
															required=""
															type="text"
															placeholder="EUI"
															maxlength="16"
															onChange={(e)=>(setInputs({...inputs,EUI:e.target.value}))} 
                                                            value={inputs.EUI} 
                                                            disabled={!disabled}
														/>
                                                        <div class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
															 {Validation?.EUI}
														</div>
														<span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
															* Not Allow Duplicate EUI Value
														</span>
													</div>
													<div class="form-group col-md-3">
														<label for="inputPassword4">
                                                        Device Serial No:<span class="text-danger">*</span>
														</label>
														<input
															class="form-control "
															id="consmrid"
															required=""
															name="consmrid"
															type="text"
															placeholder="Modem Serial No"
															onChange={(e)=>(setInputs({...inputs,DEVICE_SERIAL_NO:e.target.value}))} 
                                                            value={inputs.DEVICE_SERIAL_NO} 
                                                            disabled={!disabled}
														/>
                                                         <div class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
															 {Validation?.DEVICE_SERIAL_NO}
														</div>
														<span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
															* Not Allow Duplicate Modem Serial No
														</span>
													</div>
													<div class="form-group col-md-3">
														<label for="inputPassword4">Panel Serial No:</label>
														<input
															class="form-control"
															id="panelserialno"
															name="panelserialno"
															type="text"
															placeholder="Panel Serial No"
															onChange={(e)=>(setInputs({...inputs,PANEL_SERIAL_NO:e.target.value}))} 
                                                            value={inputs.PANEL_SERIAL_NO} 
                                                            disabled={!disabled}
														/>
														<span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
															* Not Allow Duplicate Panel Serial No
														</span>
													</div>
                                                    <div class="form-group col-md-3">
														<label for="inputPassword4">
															Max Consumer Assign:
														</label>
														<input
															class="form-control "
															id="deviceIMEI"
															name="deviceIMEI"
															required=""
															type="text"
															placeholder=""
															maxlength="16"
															onChange={(e)=>(setInputs({...inputs,Consumer_assign:e.target.value}))} 
                                                            value={inputs.Consumer_assign} 
                                                            disabled={!disabled}
														/>
														
													</div>
                                    </div>
                                   
                                    <p style={{marginLeft:"25px"}} class="text-danger">Note : * Indicates Mandatory Fields</p>
                                    <div class="text-left">
                                            <input type="submit" name="submit" id="submitvn" class={`btn btn-danger ${flagBtn?'btn-progress disabled':''} ml-4 mr-3`} value={scriptId===''?'Submit':'Update'}/>
                                        <Link class="btn btn-light text-dark" to="/All-Lorawan">Cancel</Link>  
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            </div>
    </>
  )
}
