import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BootstrapTooltip } from '../../../../Commoncomponet/common'
import { UpdateGsmWebConfigDetails, getAll_Active_alarm_template } from '../../../../Commoncomponet/Utils'
import { Switch } from 'antd'

export default function WebConfig(props) {

   const [config, setConfig] = useState({ BURNING_KWH: '', UNIT_RATE: '', DAILY_REQUIRED_CONS: { MIN: '', MAX: '' }, ALARMS: {}, ALERT_DISABLE: {} })
   const [disable, setDisable] = useState(true)
   const [btnFlag, setbtnFlag] = useState(false)
   const [AlarmList, setAlarmList] = useState(false)

   const [alertDisableList, setAlertDisableList] = useState(['MMD_STATUS_PWR_SOURCE']);
   const [showAlertDisableList, setShowAlertDisableList] = useState(['Battery Back-Up Available?']);
   useEffect(() => {
      setDisable(false)
      ongetalarm_template()
      const disable_alert = JSON.parse(props?.GSMdata?.ALERT_DISABLE || '{"MMD_STATUS_PWR_SOURCE":1}')

      if (disable_alert?.MMD_STATUS_PWR_SOURCE == null) {
         disable_alert["MMD_STATUS_PWR_SOURCE"] = 1
      }
      setConfig({ BURNING_KWH: props?.GSMdata?.BURNING_KWH, UNIT_RATE: props?.GSMdata?.UNIT_RATE, DAILY_REQUIRED_CONS: JSON.parse(props?.GSMdata?.DAILY_REQUIRED_CONS || '{"MIN":0,"MAX":0}')?.Daily_Req_Cons, ALARMS: JSON.parse(props?.GSMdata?.ALARMS || '{}'), ALERT_DISABLE: { ...disable_alert } })
   }, [])

   const onHandalUpdate = async () => {
      setbtnFlag(true)
      const data = await UpdateGsmWebConfigDetails(config, props?.GSMdata?.UNIQUE_ID)
      if (data.success === true) {
         toast.success('Successfully Updated Details')
         setDisable(false)
         setbtnFlag(false)
         props.onRefreshData()
      } else {
         toast.error('Something Went Wrong')
         setbtnFlag(false)
      }
   }

   const ongetalarm_template = async () => {
      const data = await getAll_Active_alarm_template()
      if (data.success == true) {
         setAlarmList(data.data.data)
         var obj = {}
         if (props?.GSMdata?.ALARMS) {

         } else {
            data?.data?.data?.map((item) => (
               obj[item?.alarm_type_id] = 0
            ))
            setConfig({ ...config, ALARMS: obj })
         }
      } else {

      }
   }

   const onHandalswitch = (e, id) => {
      if (e == true) {
         setConfig({ ...config, ALARMS: { ...config.ALARMS, [id]: 1 } });
      } else {
         setConfig({ ...config, ALARMS: { ...config.ALARMS, [id]: 0 } });

      }
   }

   const onHandalAlertDisableswitch = (e, id) => {
      if (e == true) {
         setConfig({ ...config, ALERT_DISABLE: { ...config.ALERT_DISABLE, [id]: 1 } });
      } else {
         setConfig({ ...config, ALERT_DISABLE: { ...config.ALERT_DISABLE, [id]: 0 } });

      }
   }
   return (
      <div>
         <section class="card-diff-section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                        <div class="card-header">
                           <h4>Web Configration</h4>
                           <div class="card-header-action">
                              <div class="btn btn-info text-white btnedit mr-2" onClick={() => (setDisable(true))}><BootstrapTooltip title='edit'>
                                 <span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
                                    <i class="fas fa-edit  text-white"></i>
                                 </span></BootstrapTooltip>
                              </div>
                           </div>
                        </div>
                        <div className='card-body'>
                           <div className='d-flex'>
                              <div className=' d-flex tw-flex-col tw-justify-center' style={{ fontSize: '15px', width: '30%' }}><b>1] Daily Required Consumption :- </b></div>
                              <div class="form-row pl-3 col-10" style={{ marginLeft: "10px", borderColor: "#e3e6fc !important", position: "relative", marginTop: "5px" }}>
                                 {/* <div class="form-group col-md-3">
                                       <label for="cust_lname">Burning KWH Without System (24Hrs):-</label>
                                       <input class="form-control" id="cust_lname" name="distriuter_customer_id" type="text" placeholder="KWH"  disabled={!disable} onChange={(e)=>(setConfig({...config,BURNING_KWH:e.target.value}))} value={config?.BURNING_KWH}/>
                                       
                                    </div>
                                    <div class="form-group col-md-3">
                                       <label for="installation_number">Unit Rate:-</label>
                                       <input class="form-control" id="installationo" name="installation_number" type="text" placeholder="RS"  disabled={!disable} onChange={(e)=>(setConfig({...config,UNIT_RATE:e.target.value}))} value={config?.UNIT_RATE}/>
                                       
                                    </div> */}
                                 <div class="form-group col-md-2 d-flex mb-0">
                                    <label className='d-flex tw-flex-col tw-justify-center'>Min:- </label>
                                    <input class="form-control ml-3 d-flex tw-flex-col tw-justify-center" id="installationo" name="installation_number" type="number" placeholder=" Min" disabled={!disable} onChange={(e) => (setConfig({ ...config, DAILY_REQUIRED_CONS: { ...config.DAILY_REQUIRED_CONS, MIN: e.target.value } }))} value={config?.DAILY_REQUIRED_CONS?.MIN} />

                                 </div>
                                 <div class="form-group col-md-2 d-flex mb-0">
                                    <label className='d-flex tw-flex-col tw-justify-center'>Max:- </label>
                                    <input class="form-control ml-3 d-flex tw-flex-col tw-justify-center" id="installationo" name="installation_number" type="number" placeholder="  Max" disabled={!disable} onChange={(e) => (setConfig({ ...config, DAILY_REQUIRED_CONS: { ...config.DAILY_REQUIRED_CONS, MAX: e.target.value } }))} value={config?.DAILY_REQUIRED_CONS?.MAX} />

                                 </div>
                              </div>
                           </div>
                           <div class="d-flex mt-4">
                              <div className=' ' style={{ fontSize: '15px', width: '16%' }}><b>2] Alarms :- </b></div>
                              <div >
                                 {AlarmList && AlarmList.map((item) => (
                                    <>
                                       <div class='d-flex ml-5'>
                                          <Switch size="small" checked={config?.ALARMS[item?.notification_type_id] == 1 ? true : false} onChange={(e) => (onHandalswitch(e, item?.notification_type_id))} disabled={!disable} />
                                          <h6 class='ml-2'>{item?.notification_title}</h6>
                                       </div>
                                    </>
                                 ))}
                              </div>
                           </div>
                           <div className='d-flex'>
                              <div className=' d-flex tw-flex-col tw-justify-center' style={{ fontSize: '15px', width: '30%' }}><b>3] Unit Rate:- </b></div>
                              <div class="form-row pl-3 col-10" style={{ marginLeft: "10px", borderColor: "#e3e6fc !important", position: "relative", marginTop: "5px" }}>
                                 <div class="form-group col-md-3 mb-0">
                                    <input class="form-control" id="installationo" name="installation_number" type="number" placeholder="RS" disabled={!disable} onChange={(e) => (setConfig({ ...config, UNIT_RATE: e.target.value }))} value={config?.UNIT_RATE} />
                                 </div>
                              </div>
                           </div>
                           <div className='d-flex  mt-3'>
                              <div className=' d-flex tw-flex-row ' style={{ fontSize: '15px', width: '30%' }}><b>4] </b><span>&#10240;</span><b> Elective Parameter:- </b></div>
                              <div class="form-row pl-3 col-10" style={{ marginLeft: "10px", borderColor: "#e3e6fc !important", position: "relative", marginTop: "5px" }}>
                                 <div class="form-group col-md-3 mb-0">
                                    {alertDisableList && alertDisableList.map((item, id) => (
                                       <>
                                          <div class='d-flex ' key={id}>
                                             <Switch size="small" checked={config?.ALERT_DISABLE[alertDisableList[id]] == 1 ? true : false} onChange={(e) => (onHandalAlertDisableswitch(e, alertDisableList[id]))} disabled={!disable} />
                                             <h6 class='ml-2'>{showAlertDisableList[id]}</h6>
                                          </div>
                                       </>
                                    ))}
                                 </div>
                              </div>
                           </div>
                           {disable ? <>
                              <div class="text-left mb-3 ml-3 mt-3">
                                 <input type="submit" name="Update" class={`btn btn-danger ${btnFlag ? 'disabled btn-progress' : ''} mr-4`} value="Submit" id="submitvn" onClick={onHandalUpdate} />

                                 <Link class="btn btn-light text-dark " onClick={() => setDisable(false)}>Cancel</Link>
                              </div></> : ''}
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}
