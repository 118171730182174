import React, { useEffect, useState } from 'react'
import { GeflowEncodeRegister, getMSSQLlogs } from '../../../Commoncomponet/Utils'
import moment from 'moment'
import { Modal } from 'antd'
import Pagination from '../../../Commoncomponet/Pagination'
import GoogleLoader from '../../../Commoncomponet/Loaders/GoogleLoader'
import { useParams } from 'react-router-dom'
import { exportExcelDownload } from '../../../Commoncomponet/common'
import { object } from 'prop-types'

const IntegrationLog = (props) => {
    const [logData, setLogData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isConsumerInfo, setIsConsumerInfo] = useState(false)
    const [isDuplicateInfo, setIsDuplicateInfo] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [isNullData, setIsNullData] = useState(false)
    const [consumerInfoModalData, setConsumerInfoModalData] = useState([])
    const [duplicateInfoModalData, setDuplicateInfoModalData] = useState([])
    const [API_SEND_DATAModal, setAPI_SEND_DATAModal] = useState(false)
    const [ApiSendData, setApiSendData] = useState([])
    const [InfoModalData, setInfoModalData] = useState({})
    const [nullDataInfoModalData, setNullDataInfoModalData] = useState([])
    const [numberofPage, setnumberofPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const params = useParams();

	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        setIsLoading(true)
        const firstPageIndex = (currentPage - 1) * PageSize;
        const repData = await getMSSQLlogs({Roles:userInfo?.ROLE,userId:userInfo?.UNIQUE_ID, start: firstPageIndex, end: PageSize,tableName:props?.logs_table })
        if (repData.success === true) {
            console.log(repData.data.LogDetails)
            setLogData(repData.data.LogDetails)
            setnumberofPage(repData.data.count)
            setIsLoading(false)
        }else{
            setIsLoading(false)
        }
    }

    

    const refreshClick = () => {
        setCurrentPage(1)
        fetchData()
    }

    useEffect(() => {
        fetchData()
    }, [currentPage, PageSize]);

    const onConsumerInfoClick = (info) => {
        setConsumerInfoModalData(info)
        setIsConsumerInfo(true)

    }

    const onDuplicatevalueClick = (info) => {
        setDuplicateInfoModalData(info)
        setIsDuplicateInfo(true)

    }
const onAPISendDataClick = (info) => {
        setAPI_SEND_DATAModal(true)
        setApiSendData(info)

    }
    const onNullDataFoundClick = (info) => {
        setNullDataInfoModalData(info)
        setIsNullData(true)

    }
    const onInfoClick = (info) => {
        setInfoModalData(info)
        setIsInfo(true)

    }

    const Download_Logs=()=>{
        exportExcelDownload(consumerInfoModalData.map((item)=>({UNIQUE_ID:item?.UNIQUE_ID,INSTALLATION_NUMBER:item?.INSTALLATION_NUMBER,DEVICE_SERIAL_NO:item?.DEVICE_SERIAL_NO,address_compony_name:item?.address_compony_name,SCRIPTDECODE_ID_FOUND:item?.SCRIPTDECODE_ID_FOUND,DAILY_TABLE_EXIST:item?.DAILY_TABLE_EXIST,DAILY_DATA_FOUND:item?.DAILY_DATA_FOUND,INSERT_SUCCESS:item?.INSERT_SUCCESS,RESPONSE_CODE:item?.RESPONSE_CODE})),'CONSUMER_INFO_'+(new Date().valueOf()))
    }

    return (
        <>
            <Modal title={<div><span>CONSUMER_INFO</span><span className='btn btn-primary text-white ml-2' onClick={Download_Logs}><i className='fas fa-download'></i></span></div>} visible={isConsumerInfo} footer='' onCancel={() => setIsConsumerInfo(false)} width={1500}>
                <div>
                    <table class="table table-striped tw-text-xs tw-w-full tw-text-center">
                        <thead>
                            <tr>
                                <th>UNIQUE_ID</th>
                                {props?.TYPE=='API'? <th>INSTALLATION_NUMBER</th>:''}
                                <th>DEVICE_SERIAL_NO</th>
                                <th>address_compony_name</th>
                                <th>SCRIPTDECODE_ID_FOUND</th>
                                <th>DAILY_TABLE_EXIST</th>
                                <th>DAILY_DATA_FOUND</th>
                                {/* <th>INSERT_SUCCESS</th> */}

                            </tr>
                        </thead>
                        <tbody>
                            {consumerInfoModalData.length > 0 && consumerInfoModalData.map(info => (
                                <tr>
                                    <td>{info?.UNIQUE_ID}</td>
                                    {props?.TYPE=='API'?<td>{info?.INSTALLATION_NUMBER}</td>:''}
                                    <td>{info?.DEVICE_SERIAL_NO}</td>
                                    <td>{info?.address_compony_name}</td>
                                    <td>{info?.SCRIPTDECODE_ID_FOUND !== undefined ? info?.SCRIPTDECODE_ID_FOUND === true ? <span className="badge badge-success"><i className='fas fa-check '></i></span> : <span className="badge badge-danger"><i className="fas fa-times " /></span> : '-'}</td>
                                    <td>{info?.DAILY_TABLE_EXIST !== undefined ? info?.DAILY_TABLE_EXIST === true ? <span className="badge badge-success"><i className='fas fa-check '></i></span> : <span className="badge badge-danger"><i className="fas fa-times " /></span> : '-'}</td>
                                    <td>{info?.DAILY_DATA_FOUND !== undefined ? info?.DAILY_DATA_FOUND === true ? <span className="badge badge-success"><i className='fas fa-check '></i></span> : <span className="badge badge-danger"><i className="fas fa-times " /></span> : '-'}</td>
                                    {/* <td>{info?.INSERT_SUCCESS !== undefined ? info?.INSERT_SUCCESS === true ? <span className="badge badge-success"><i className='fas fa-check '></i></span> : <span className="badge badge-danger"><i className="fas fa-times " /></span> : '-'}</td> */}
                                </tr>
                            ))}

                        </tbody>

                    </table>
                </div>
            </Modal>
            <Modal title="RESPONSE" visible={isDuplicateInfo} footer='' onCancel={() => setIsDuplicateInfo(false)} width={1500}>
              {duplicateInfoModalData}
            </Modal>
            <Modal title="SEND DATA" visible={API_SEND_DATAModal} footer='' onCancel={() => {setAPI_SEND_DATAModal(false)
            setApiSendData({})}} width={1500}>
              {ApiSendData}
            </Modal>
            <Modal title="API USER REQUEST INFO" visible={isInfo} footer='' onCancel={() => setIsInfo(false)} width={1500}>
                <div className='table-responsive'>
                    <table class="table table-striped tw-text-xs tw-w-full tw-text-center">
                        <thead>
                            <tr>
                                <th>start_date</th>
                                <th>end_date</th>
                                <th>MSID</th>
                                <th>User_ID</th>
                                <th>Browser_Details</th>
                                <th>Category0</th>
                                <th>Category1</th>
                                <th>Category2</th>
                                <th>Consumer_Unique_ID</th>
                                <th>roles_id</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{InfoModalData?.start_date}</td>
                                <td>{InfoModalData?.end_date}</td>
                                <td>{InfoModalData?.MSID}</td>
                                <td>{InfoModalData?.User_ID}</td>
                                <td>{JSON.stringify(InfoModalData?.Browser_Details)}</td>
                                <td>{InfoModalData?.Category0}</td>
                                <td>{InfoModalData?.Category1}</td>
                                <td>{InfoModalData?.Category2}</td>
                                <td className='tw-max-w-lg'>{InfoModalData?.Consumer_Unique_ID?.join(",")}</td>
                                <td>{InfoModalData?.roles_id}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </Modal>
            <Modal title="NULL_DATA_FOUND" visible={isNullData} footer='' onCancel={() => setIsNullData(false)} width={1500}>

                {nullDataInfoModalData.length === 0 ? <div className='tw-text-center'>No Data Found</div> : <div className='table-responsive'>
                    <table class="table table-striped tw-text-xs tw-w-full tw-text-center">
                        <thead>
                            <tr>
                                <th>UNIQUE_ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {nullDataInfoModalData.length > 0 && nullDataInfoModalData.map(info => (
                                <tr>
                                    <td>{info?.UNIQUE_ID}</td>
                                </tr>
                            ))}

                        </tbody>

                    </table>
                </div>}
            </Modal>
            <div class=" tw-w-full">
                <div class="card-header d-flex tw-justify-between">
                    <div class=""><b>Publish Topic :</b> {props?.pub_topic} <br/><b>Subscribe Topic : </b>{props?.sub_topic}</div>
                    <div class="card-header-action">
                        <div id="filtergrm">
                            <button class="btn btn-info text-white" onClick={refreshClick} ><i class="fa fa-refresh" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-body">

                    {isLoading ? <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                        <GoogleLoader bodyClassName={'tw-h-[70%]'} />
                    </div> :
                        <div class="table-responsive">
                            <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                                <table class="table table-striped tw-text-xs tw-w-full tw-text-center">
                                    <thead>
                                        <tr>
                                            <th style={{ width:'50px' }}>srno</th>
                                            {/* <th>INSTALLATION_NUMBER</th> */}
                                            <th style={{ width:'50px' }}>entryts</th>
                                            {/* <th>CREATED_BY</th> */}
                                            {/* {props?.TYPE=='API'?<th>API RESPONSE STATUS</th>:<th>STATUS</th>} */}
                                            {/* {props?.TYPE=='API'?<th>API RESPONSE CODE</th>:''} */}
                                            {/* <th>CONSUMER WISE INFO</th> */}
                                            {/* <th>DUPLICATE_VALUE</th> */}
                                            {/* {props?.TYPE=='API'? <th>API USER REQUEST INFO</th>:<th>INFO</th>}
                                            <th style={{ width:'100px' }}>UPDATED_TIME</th>
                                            <th>NULL_DATA_FOUND</th> */}
                                         <th style={{ width:'50px' }}>{props?.activeTab=='Logs2'?'Requested data':'sent message'}</th>
                                           {props?.activeTab=='Logs2'?  <th style={{ width:'50px' }}>Send Response</th>:''}
                                           {props?.activeTab=='Logs2'?  <th style={{ width:'50px' }}>Send Response ts</th>:''}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logData.map(row => (
                                            <tr key={row.SR_NO}>
                                                <td className='tw-px-1 tw-py-3'>{row?.u_sr_no}</td>
                                                {/* <td className='tw-px-1 tw-py-3'>{row?.INSTALLATION_NUMBER}</td> */}
                                                <td className='tw-px-1 tw-py-3'>{props.activeTab=='Logs2'?moment.utc(row?.created_at).format("DD-MM-YYYY hh:mm:ss A"):moment.utc(row?.entryts).format("DD-MM-YYYY hh:mm:ss A")}</td>
                                                {/* <td className='tw-px-1 tw-py-3'>{row?.CREATED_BY}</td> */}
                                                {/* <td className='tw-px-1 tw-py-3'>{row?.STATUS === -1 ?moment(moment.utc().add(moment().utcOffset(), "m").format()).diff(moment(moment.utc(row?.CREATED_AT).format()),'minutes') > 5?<span class="badge badge-secondary">Time Out</span>: <span class="badge badge-primary">Process Start</span> : row?.STATUS === 2 ? <span class="badge badge-danger">Failed</span> :row?.STATUS === 3 ? <span class="badge badge-secondary">Time Out</span>:<span class="badge badge-success">Success</span>}</td>
                                                {props?.TYPE=='API'?<td className='tw-px-1 tw-py-3'>{row?.RESPONSE_STATUS?row?.RESPONSE_STATUS:'-'}</td>:''}
                                                <td className='tw-px-1 tw-py-3'>{row?.STATUS === -1 ? '-' : <button className='btn btn-info btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onConsumerInfoClick(JSON.parse(row?.CONSUMER_INFO))}><i className="fa-solid fa-info"></i></button>}</td> */}
                                                {/* <td className='tw-px-1 tw-py-3'>{row?.STATUS === -1 ? '-' :<button className='btn btn-warning btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onDuplicatevalueClick(JSON.parse(row?.DUPLICATE_VALUE))}><i className="fa-solid fa-info"></i></button>}</td> */}
                                                {/* <td className='tw-px-1 tw-py-3'>{<button className='btn btn-primary btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onInfoClick(JSON.parse(row?.INFO))}><i className="fa-solid fa-info"></i></button>}</td>
                                                <td className='tw-px-1 tw-py-3'>{row?.UPDATED_TIME ? moment.utc(row?.UPDATED_TIME).format("DD-MM-YYYY hh:mm:ss A") : '-'}</td>
                                                <td className='tw-px-1 tw-py-3'>{row?.NULL_DATA_FOUND ? <button className='btn btn-danger btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onNullDataFoundClick(JSON.parse(row?.NULL_DATA_FOUND))}><i className="fa-solid fa-info"></i></button> : '-'}</td> */}
                                               {props?.activeTab=='Logs2'?<td className='tw-px-1 tw-py-3'>{row?.requested_data ? <button className='btn btn-warning  btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onAPISendDataClick(row?.requested_data)}><i className="fa-solid fa-info"></i></button> : '-'}</td>:<td className='tw-px-1 tw-py-3'>{row?.sent_message ? <button className='btn btn-warning  btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onAPISendDataClick(row?.sent_message)}><i className="fa-solid fa-info"></i></button> : '-'}</td>}
                                               {props?.activeTab=='Logs2'&& <td className='tw-px-1 tw-py-3'>{row?.send_response ? <button className='btn btn-warning  btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onDuplicatevalueClick(row?.send_response)}><i className="fa-solid fa-info"></i></button> : '-'}</td>}
                                               {props?.activeTab=='Logs2'&& <td className='tw-px-1 tw-py-3'>{moment.utc(row?.response_ts).format("DD-MM-YYYY hh:mm:ss A")}</td>}
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                                <>
                                    <div className="d-flex justify-content-between">
                                        <div>Total Number Of Row {numberofPage}</div>
                                        <div class="dataTables_length" id="table_id_length">
                                            <label>
                                                Show{" "}
                                                <select
                                                    name="table_id_length"
                                                    aria-controls="table_id"
                                                    class=""
                                                    onChange={(e) => setPageSize(e.target.value)}
                                                    value={PageSize}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={25}>25</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                </select>{" "}
                                                entries
                                            </label>
                                        </div>

                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={numberofPage || 1}
                                            pageSize={PageSize}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    </div>
                                </>
                            </div>

                        </div>}
                </div>
            </div>


        </>
    )
}

export default IntegrationLog