import React, { useState } from 'react'
import { btnColor } from '../../../Commoncomponet/common';
import chroma from 'chroma-js';

export default function ManualPush() {
	const [activeTab, setActiveTab] = useState("Push");

  return (
    <>
    <div class="row ml-2 border-top py-2">
				<div class="d-flex tw-border-b-0 " style={{ justifyContent: "space-between" }}>
					<ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "20px", alignItems: "center" }}>
						<li>
							<button
								style={{
									backgroundColor: activeTab === "Push" ? chroma(btnColor[0]).alpha(0.4).css() : '',
									color: activeTab === "Push" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
									borderBottom: `1px solid ${btnColor[0]}`,
									boxShadow: activeTab === "Push" ? `0 2px 8px -3px ${btnColor[0]}` : '',
								}}
								className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Push" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
								data-toggle="tab"
								href="#home"
								role="tab"
								aria-controls="home"
								aria-selected="true"
								onClick={() => setActiveTab("Push")}
							>
								Config
							</button>
						</li>
						<li>
							<button
								style={{
									backgroundColor: activeTab === "Logs" ? chroma(btnColor[2]).alpha(0.4).css() : '',
									color: activeTab === "Logs" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
									borderBottom: `1px solid ${btnColor[2]}`,
									boxShadow: activeTab === "Logs" ? `0 2px 8px -3px ${btnColor[2]}` : '',
								}}
								className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Logs" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
								id="profile-tab"
								data-toggle="tab"
								href="#profile"
								role="tab"
								aria-controls="profile"
								aria-selected="false"
								onClick={() => setActiveTab("Logs")}
							>
								Logs
							</button>
						</li>
					</ul>
				</div>
			</div>
    </>
  )
}
