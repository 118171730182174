import React, { useState } from "react";
import { Users } from "react-feather";
import { Link } from "react-router-dom";

export default function Wimesh() {
	const [activeTab, setActiveTab] = useState("Slave Modem");

	return (
		<>
			<div className="main-content">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb ">
						<li className="breadcrumb-item">
							<Link to="/all-deshboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link>
								<Users style={{ height: "16px" }} /> Consumers
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/wimesh">Wi-Mesh</Link>
						</li>
					</ol>
				</nav>

				<section class="section">
					<div class="section-body">
						<div class="row">
							<div class="col-12 col-sm-12 col-lg-12 tw-p-0">
								<div class="card">
									<div class="card-header d-flex tw-border-b-0 " style={{ justifyContent: "space-between" }}>
										<ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "15px", alignItems: "center" }}>
											<li>
												<button
													className={`btn btn-primary !tw-rounded !tw-p-1 ${
														activeTab === "Slave Modem" ? "!tw-p-2" : ""
													}`}
													data-toggle="tab"
													href="#home"
													role="tab"
													aria-controls="home"
													aria-selected="true"
													onClick={() => setActiveTab("Slave Modem")}
												>
													Slave Modem
												</button>
											</li>
											<li>
												<button
													className={` btn btn-danger !tw-rounded !tw-p-1 ${
														activeTab === "Master Modem" ? "!tw-p-2" : ""
													}`}
													id="profile-tab"
													data-toggle="tab"
													href="#profile"
													role="tab"
													aria-controls="profile"
													aria-selected="false"
													onClick={() => setActiveTab("Master Modem")}
												>
													Master Modem
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="card">
								
								<div className="card-body">
									<div className="table-responsive">
										<div id="table-1_wrapper" className="dataTables_wrapper no-footer">
											<div className="dataTables_length" id="table_id_length">
												<label>
													Show{" "}
													<select name="table_id_length" aria-controls="table_id" className="">
														<option value={10}>10</option>
														<option value={25}>25</option>
														<option value={50}>50</option>
														<option value={100}>100</option>
													</select>{" "}
													entries
												</label>
											</div>
											<table id="table_id" className="tableCustom table table-striped">
												<thead>
													<tr>
															<th class="GSM_Link">Unique Id</th>
															<th>
																Name
															</th>
															<th class="" data-mui-internal-clone-element="true">
																Profile
															</th>
															<th>IMEI</th>
															<th>Device serial no</th>
															<th aria-label="18-10-2022 10:42:50 AM" class="" data-mui-internal-clone-element="true">
																time
															</th>
															<th class="text-center">
																Device Status
															</th>
															<th class="text-center">
                              Status
															</th>
													</tr>
												</thead>
												<tbody>
													<tr>
															<td >1</td>
															<td>
																<div
																	class="show-read-more-name"
																	aria-label="Surat Lab Test L&amp;T HT"
																	data-mui-internal-clone-element="true"
																>
																	Surat Lab Test L&amp;T HT
																</div>
															</td>
															<td class="" data-mui-internal-clone-element="true">
																<b class="tw-font-semibold">GA:</b>
																<br />
															</td>
															<td>867542059696628</td>
															<td>13MP2940</td>
															<td aria-label="18-10-2022 10:42:50 AM" class="" data-mui-internal-clone-element="true">
																<div class="badge badge-danger badge-shadow text-white">2 months ago</div>
															</td>
															<td class="text-center">
																<div class="badge badge-danger badge-shadow text-white">Offline</div>
															</td>
															<td class="text-center">
																<div class="badge badge-success badge-shadow text-white">Active</div>
															</td>
													</tr>
                          <tr>
															<td >2</td>
															<td>
																<div
																	class="show-read-more-name"
																	aria-label="Surat Lab Test L&amp;T HT"
																	data-mui-internal-clone-element="true"
																>
																	Surat Lab Test L&amp;T HT
																</div>
															</td>
															<td class="" data-mui-internal-clone-element="true">
																<b class="tw-font-semibold">GA:</b>
																<br />
															</td>
															<td>867542059696628</td>
															<td>13MP2940</td>
															<td aria-label="18-10-2022 10:42:50 AM" class="" data-mui-internal-clone-element="true">
																<div class="badge badge-danger badge-shadow text-white">2 months ago</div>
															</td>
															<td class="text-center">
																<div class="badge badge-danger badge-shadow text-white">Offline</div>
															</td>
															<td class="text-center">
																<div class="badge badge-success badge-shadow text-white">Active</div>
															</td>
													</tr>
                          <tr>
															<td >3</td>
															<td>
																<div
																	class="show-read-more-name"
																	aria-label="Surat Lab Test L&amp;T HT"
																	data-mui-internal-clone-element="true"
																>
																	Surat Lab Test L&amp;T HT
																</div>
															</td>
															<td class="" data-mui-internal-clone-element="true">
																<b class="tw-font-semibold">GA:</b>
																<br />
															</td>
															<td>867542059696628</td>
															<td>13MP2940</td>
															<td aria-label="18-10-2022 10:42:50 AM" class="" data-mui-internal-clone-element="true">
																<div class="badge badge-danger badge-shadow text-white">2 months ago</div>
															</td>
															<td class="text-center">
																<div class="badge badge-danger badge-shadow text-white">Offline</div>
															</td>
															<td class="text-center">
																<div class="badge badge-success badge-shadow text-white">Active</div>
															</td>
													</tr>
                          <tr>
															<td >4</td>
															<td>
																<div
																	class="show-read-more-name"
																	aria-label="Surat Lab Test L&amp;T HT"
																	data-mui-internal-clone-element="true"
																>
																	Surat Lab Test L&amp;T HT
																</div>
															</td>
															<td class="" data-mui-internal-clone-element="true">
																<b class="tw-font-semibold">GA:</b>
																<br />
															</td>
															<td>867542059696628</td>
															<td>13MP2940</td>
															<td aria-label="18-10-2022 10:42:50 AM" class="" data-mui-internal-clone-element="true">
																<div class="badge badge-danger badge-shadow text-white">2 months ago</div>
															</td>
															<td class="text-center">
																<div class="badge badge-danger badge-shadow text-white">Offline</div>
															</td>
															<td class="text-center">
																<div class="badge badge-success badge-shadow text-white">Active</div>
															</td>
													</tr>
                          <tr>
															<td >5</td>
															<td>
																<div
																	class="show-read-more-name"
																	aria-label="Surat Lab Test L&amp;T HT"
																	data-mui-internal-clone-element="true"
																>
																	Surat Lab Test L&amp;T HT
																</div>
															</td>
															<td class="" data-mui-internal-clone-element="true">
																<b class="tw-font-semibold">GA:</b>
																<br />
															</td>
															<td>867542059696628</td>
															<td>13MP2940</td>
															<td aria-label="18-10-2022 10:42:50 AM" class="" data-mui-internal-clone-element="true">
																<div class="badge badge-danger badge-shadow text-white">2 months ago</div>
															</td>
															<td class="text-center">
																<div class="badge badge-danger badge-shadow text-white">Offline</div>
															</td>
															<td class="text-center">
																<div class="badge badge-success badge-shadow text-white">Active</div>
															</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
