import React, { useState } from 'react'
import ButtonTabs from '../../../Commoncomponet/Tabs/ButtonTabs'
import ProductWiseTables from './ProductWiseTables'
import UniversalTables from './UniversalTables'

const CommonTable = () => {
    const [DefaultTab,setDefaultTab]=useState("tab1")
    const [IsDefault,setIsDefault]=useState(false)
    return (
        <>
            <div className="main-content">
                <section className="section">
                    <div className="card !tw-rounded-none">

                        <div className='tw-mt-2 tw-w-full tw-mx-auto tw-px-0'>
                            <ButtonTabs color="indigo" className={''} defaultTab={'tab1'} setIsDefault={setIsDefault} IsDefault={IsDefault}>
                                <div name='Universal Tables' value={'tab1'} color='#22c55e' onClick={()=>setDefaultTab('tab1')}>
                                <UniversalTables/>
                                </div>
                                <div className='border-top' name='Product Wise Tables' value={'tab2'} color='#6366f1' onClick={()=>setDefaultTab('tab2')}>
                                   <ProductWiseTables/>
                                </div>
                                
                            </ButtonTabs>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default CommonTable