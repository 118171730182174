import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { activeandinactive, InsertsTags, UpdateTags } from '../../Commoncomponet/Utils'
import {toast} from 'react-toastify'
import swal from "sweetalert2";
import { BootstrapTooltip } from '../../Commoncomponet/common';
window.Swal = swal;

export default function Addtags() {

    const [tagsInput,setTagesInput]=useState({name:'',color:''})
    const [tagsId,settagsId]=useState('')
    const [flagBtn,setflegBtn]=useState(false)
    const [STATUS,setStatus]=useState(0)
    const [disable,setDisable]=useState(true)
    const history=useHistory()
    const userInfo=JSON.parse(window.localStorage.getItem("user_info")||{})
    const locations=useLocation()


    useEffect(() => {
        if (locations?.state) {
            setTagesInput({name:locations?.state?.TAG_NAME,color:locations?.state?.TAG_COLOR})
            settagsId(locations?.state?.UNIQUE_ID)
            setDisable(false)
            setStatus(locations?.state?.STATUS)
        }
     }, [locations]);
    const onSubmitAccountData=async(e)=>{
        e.preventDefault()
        setflegBtn(true)
     if(tagsInput?.name===''&&tagsInput?.color===''){
        toast.error('Please Fill Proper Details')
        setflegBtn(false)
     }else{
        if(tagsId===''){
            const obj={
                CREATED_BY:userInfo?.FIRST_NAME+' '+userInfo?.LAST_NAME,TAG_NAME:tagsInput?.name,TAG_COLOR:tagsInput?.color,USER_ID:userInfo?.UNIQUE_ID
            }
     const data=await InsertsTags(obj)
     if(data.success===true){
        toast.success("Tag Add Success")
        history.push('/tags')
        setflegBtn(false)
     
     }else{
        toast.error("Something Went Wrong")
        setflegBtn(false)
     
     }
        }
        else{
            const obj={
                UPDATED_BY:userInfo?.FIRST_NAME+' '+userInfo?.LAST_NAME,TAG_NAME:tagsInput?.name,TAG_COLOR:tagsInput?.color,USER_ID:userInfo?.UNIQUE_ID
            }
            const data=await UpdateTags(obj,tagsId)
            if(data.success===true){
            toast.success('Tags Successfull Update')
            history.push('/tags')
        setflegBtn(false)
            }else{
            toast.error('Something Went Wrong')
        setflegBtn(false)

            }
        }
     }
       }
     
       const oninactive=async()=>{
        swal.fire({
              title: "Are You Sure InActive Tag?",
              icon: "warning",
              showCancelButton: true,
          }).then(async (willDelete) => {
           if (willDelete.isConfirmed) {
        const data=await activeandinactive({tableName:'tag_index',colName:'STATUS',value:'0',matchId:'UNIQUE_ID'},tagsId)
        if(data.success===true){
           toast.success('Success Inactive Tag')
           history.push('/tags')
        }else{
           toast.error('Something Went Wrong')
        }
     }
  })
       }
       const onactive=async()=>{
        swal.fire({
              title: "Are You Sure Active Tag?",
              icon: "warning",
              showCancelButton: true,
          }).then(async (willDelete) => {
           if (willDelete.isConfirmed) {
        const data=await activeandinactive({tableName:'tag_index',colName:'STATUS',value:'1',matchId:'UNIQUE_ID'},tagsId)
        if(data.success===true){
           toast.success('Success Active Tag')
           history.push('/tags')
        }else{
           toast.error('Something Went Wrong')
        }
     }
  })
       }
  return (
    <>
        <div class="main-content">
      <div class="col-12 d-flex justify-content-between align-items-center pb-3 px-0">
            <nav aria-label="breadcrumb">
               <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><Link to="/"><i class="fa fa-dashboard fa-lg"></i> Home</Link></li>
                  <li class="breadcrumb-item" aria-current="page"><Link  to='/tags'><i class="fas fa-list"></i>Tags</Link></li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-list"></i>Add Tags</li>
               </ol>
            </nav>
        </div>
         <section class="section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                     <div class="card-header">
                           <h4>{tagsId===''?'Add Tags':'Update Tags'}</h4>
                           <div class="card-header-action"> 
                           {tagsId!==''?locations?.state?.permision?.ROLES_OTHER_PERMISSIONS?.Tags?.edit===true||userInfo?.ROLE=='ROLE1661856938676'?<div  class="btn btn-info text-white btnedit mr-2" onClick={()=>(setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:'':''}
										{tagsId!==''&&STATUS===1?locations?.state?.permision?.ROLES_OTHER_PERMISSIONS?.Tags?.active_inactive===true||userInfo?.ROLE=='ROLE1661856938676'?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2"  onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:'':''}
										{tagsId!==''&&STATUS===0?locations?.state?.permision?.ROLES_OTHER_PERMISSIONS?.Tags?.active_inactive===true||userInfo?.ROLE=='ROLE1661856938676'?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:'':''} 
                              <Link to="/tags" class="btn btn-info text-white">
                              Back
                              </Link>
                           </div>
                        </div>
                        <div class="card-body">
                            <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data">
                                <div class="form-row" style={{marginLeft:"10px"}}>
                                    <div class="form-group col-md-3">
                                        <label for="inputPassword4">Name:<span class="text-danger">*</span></label>
                                        <input class="form-control" name="tag" id="tag_name" type="text" placeholder="Enter Tag Name" required="" onChange={(e)=>{setTagesInput({...tagsInput,name:e.target.value})}} value={tagsInput?.name} disabled={!disable}/>  
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="inputPassword4">Tag Color:<span class="text-danger">*</span></label>
                                        <select class="form-control form-select" name="tag_color"  required="" onChange={(e)=>{setTagesInput({...tagsInput,color:e.target.value})}} value={tagsInput?.color} disabled={!disable}>
                                            <option value="">Select Color</option>
                                            <option value="#6777ef">Purple</option>
                                                        <option value="#cdd3d8">Grey</option>
                                                        <option value="#ffa426">Orange</option>
                                                        <option value="#3abaf4">Blue</option>
                                                        <option value="#fc544b">Red</option>
                                                        <option value="#54ca68">Green</option>
                                                        <option value="#191d21">Black</option>
                                                        <option value="#e3eaef">White</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="inputPassword4">Preview</label>
                                        <div id="tagpreviewdiv">
                                        {tagsInput.name!==''&&tagsInput?.color!==''?<div class={`btn btn-icon icon-left`} style={{ backgroundColor: tagsInput?.color, color: "white" }}><i class="fa fa-tag fa-lg" style={{marginRight:'4px'}}></i>{tagsInput.name}</div>:'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <p style={{marginLeft:"25px"}} class="text-danger">Note : * Indicates Mandatory Fields</p>
                                <div class="text-left" style={{marginLeft:"10px"}}>
                                   {disable?<input type="submit" name="submit" class={`btn btn-danger mr-2 ${!disable?'disabled':''}`} value={tagsId!==''?'Update':"Submit"} id="submitvn" onClick={onSubmitAccountData}/>:''} 
                                    <Link class="btn btn-light text-dark" to="/tags">Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
      </div>
             
    </>
  )
}
