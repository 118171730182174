import moment from 'moment';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Layers } from 'react-feather';
import { Link } from 'react-router-dom';
import { BootstrapTooltip } from '../../Commoncomponet/common';
import Pagination from '../../Commoncomponet/Pagination';
import { cancalGSMBuffer, getGSMBufferData, getGSMBufferDataAllCountByStatus, RetryGSMBuffer } from '../../Commoncomponet/Utils';
import swal from 'sweetalert2';
window.Swal = swal;
export default function Gsm_buffer() {

    const [PageSize, setPageSize] = useState(50)
	const [numberofPage, setnumberofPage] = useState(1)
	const [currentPage, setCurrentPage] = useState(1);
    const [BufferData,setBufferData]=useState([])
	const [loader, setLoader] = useState(true)
	const [BufferCount, setBufferCount] = useState(true)
	const [BufferCountShow, setBufferCountShow] = useState(false)

const ongetGSMBufferData=async()=>{
    setLoader(true)
    const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
    const data=await getGSMBufferData({ start: firstPageIndex, end: PageSize })
    if(data.success===true){
        setBufferData(data.data.data)
				setnumberofPage(parseInt(data.data.totalCount))
    setLoader(false)
    }else{
console.log('Something Went Wrong')
        setLoader(false)
    }
}

const onHandalRetry=async(id)=>{
const data=await RetryGSMBuffer(id)
if(data.success===true){
    swal.fire('', 'Submit Success Retry Buffer', 'success');
    ongetGSMBufferData()
}else{
    swal.fire('', 'Something Went Wrong', 'error');
}
}

const onHandalCancelBuffer=async(id)=>{
    const data=await cancalGSMBuffer(id)
    if(data.success===true){
        swal.fire('', 'Success Cancel Buffer', 'success');
        ongetGSMBufferData()
    }else{
        swal.fire('', 'Something Went Wrong', 'error');
    }
    }
useEffect(() => {
    ongetGSMBufferData()
}, []);

useEffect(() => {
    ongetGSMBufferData()

}, [currentPage, PageSize])

const ongetGSMBufferDataAllCountByStatus=async()=>{
    const data=await getGSMBufferDataAllCountByStatus()
    if(data.success===true){
        setBufferCount(data.data.data)
        setBufferCountShow(true)
    }else{
        console.log("Something Went Wrong")
    }
}
  return (
    <>
			<div className="main-content" style={{ minHeight: "562px" }}>

    <nav aria-label="breadcrumb">
					<ol className="breadcrumb ">
						<li className="breadcrumb-item">
							<Link to="/all-deshboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								<Layers style={{ height: "16px" }} /> Buffer
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link>
								 GSM Buffer
							</Link>
						</li>
					</ol>
				</nav>
<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header ">
										<h4  >GSM Buffer</h4>
										<div className="card-header-action">
                                        {BufferCountShow===false? <button type="button" class="btn btn-info" id="getcount" onClick={()=>(ongetGSMBufferDataAllCountByStatus())}>Get Count</button>: <button type="button" class="btn btn-warning" id="getcount" onClick={()=>(setBufferCountShow(false))}>Resett</button>}
										</div>
									</div>
									<div className="card-body">
                                              
                                    {BufferCountShow===true&&<div id="allbtn " className='mb-3'> <button type="button" class="btn btn-dark btn-icon icon-left ">All <span class="badge badge-transparent">{BufferCount?.total_length}</span></button>
            <button type="button" class="btn btn-success btn-icon icon-left ml-2">Success <span class="badge badge-transparent">{BufferCount?.success}</span></button>
            <button type="button" class="btn btn-warning btn-icon icon-left ml-2">Waiting For Downlink <span class="badge badge-transparent">{BufferCount?.wait_down}</span></button>
            <button type="button" class="btn btn-info btn-icon icon-left ml-2">Canceled By User <span class="badge badge-transparent">{BufferCount?.cancel}</span></button>
            <button type="button" class="btn btn-danger btn-icon icon-left ml-2">Timeout <span class="badge badge-transparent">{BufferCount?.timeout}</span></button>
            <button type="button" class="btn btn-primary btn-icon icon-left ml-2">Waiting For Uplink <span class="badge badge-transparent">{BufferCount?.wait_up}</span></button>
        </div>} 
										<div className="table-responsive">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select name="table_id_length" aria-controls="table_id" class="" onChange={(e) => (setPageSize(e.target.value))} value={PageSize}>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
												<div id="table-1_filter" class="dataTables_filter"><label>Search:<input type="search" class="mr-2" /><div className='btn btn-primary'  style={{ padding: '0.2rem 0.8rem', top: '0' }}><i class='fas fa-search'></i></div></label></div>
												<table id="table_id" className="tableCustom table table-striped">
													<thead>
														<tr >
															<th className="text-center" >Unique No</th>
															<th style={{ minWidth: '200px' }}>Consumer ID</th>
															<th style={{ minWidth: '125px' }}>Message Unique ID</th>
															<th>Retry Count</th>
															<th>MAXRETRY</th>
															<th>Consecutive Downlink</th>
															<th>Status</th>
															<th >Wait duration in Minutes</th>
															<th style={{ minWidth: '100px' }}>Timestamp</th>
															<th >Retry</th>
															<th>Topic</th>
															<th>Payload</th>
															<th>Triggered By</th>
															<th></th>
														</tr>
													</thead>
													<tbody>
														{BufferData.length > 0 && BufferData.map((item, id) => (
															<tr className="trheigth" >
																<td>{item?.SRNO}</td>
																<td>{item?.CONSUMER_ID}</td>
																<td>{item?.MSGID}</td>
																<td>{item?.RETRYCOUNT}</td>
																<td>{item?.MAXRETRY}</td>
																<td></td>
																<td>{item?.STATUS==1? <div class="badge badge-success badge-shadow">Success</div>:item?.RETRYCOUNT<0&&item?.STATUS==0?<div class="badge badge-warning badge-shadow">Waiting For Downlink</div>:item?.STATUS==2?<div class="badge badge-info badge-shadow">Canceled By User</div>:item?.STATUS==-1?<div class="badge badge-danger badge-shadow">Timeout</div>:item?.RETRYCOUNT>=0&&item?.STATUS==0?<div class="badge badge-primary text-white badge-shadow">Waiting For Uplink</div>:''}</td>
																<td>{item?.WAIT_DURATION_MINUTES}</td>
																<td>{moment.utc(item.ENTRYTS).format("DD/MM/YYYY hh:mm:ss A")}</td>
																<td></td>
																<td>{item?.TOPIC}</td>
																<td>{item?.PAYLOAD}</td>
																<td>{item?.TRIGGERSOURCE===0?<div class="badge badge-warning badge-shadow">Script</div>:<div class="badge badge-success badge-shadow">User</div>}</td>
																<td>{item?.STATUS==-1?<a onClick={()=>(onHandalRetry(item?.SRNO))} class='btn btn-icon btn-sm btn-info text-white' data-toggle='tooltip' title='Retry' data-placement='bottom'><i class='fa fa-repeat'></i></a>:item?.STATUS==0?<a class='btn btn-icon btn-sm btn-danger text-white' data-toggle='tooltip' onClick={()=>(onHandalCancelBuffer(item?.SRNO))} title='Canceled' data-placement='bottom'><i class='fa fa-times'></i></a>:''}</td>
																
															</tr>
														))}


													</tbody>
												</table>
											</div>
										</div>
										<><div className='d-flex justify-content-between'>
											<div>Total Number Of Row {numberofPage}</div><Pagination
												className="pagination-bar"
												currentPage={currentPage}
												totalCount={numberofPage || 1}
												pageSize={PageSize}
												onPageChange={(page) => setCurrentPage(page)}
											/></div></>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
                </div>
    </>
  )
}
