import { Checkbox, Input, Select } from 'antd'
import React from 'react'

export default function Connectivity({credentialdetails,setcredentialdetails,isdisable}) {
    const handleProtocolChange = (value) => {
        console.log(`selected ${value}`);
    };
    const handleServerChange = (value) => {
        console.log(`selected ${value}`);
    };
    const ProtocolOptions = [{ value: "MQTT(auto-resolve)", label: "MQTT (auto-resolve)" }, { value: "MQTT3.1.1", label: "MQTT 3.1.1" }, { value: "MQTT3.1", label: "MQTT 3.1" }];
    const ServerOptions = [{ value: "default", label: "Default" }, { value: "WebSockets", label: "WebSockets" }]
    return (
        <div className='mt-3 tw-w-[50%]'>
            <div className='tw-box-border'>
                <div className='card-body'>
                    <div className=' tw-mb-3 tw-flex'>
                        <label className='tw-w-[50%] tw-flex tw-items-center'>Protocol version :-</label>
                        <select className="form-control form-select tw-w-[50%]" name="reporttype" required="required" disabled={isdisable}>
                            <option value={2}>MQTT 3.1.1</option>
                        </select>

                        {/* <Select onChange={handleProtocolChange} options={ProtocolOptions} className='tw-w-[50%] mr-1' /> */}
                    </div>
                    <div className='tw-flex tw-gap-2 tw-h-8 tw-mb-5'>
                        <div className='tw-w-[50%] '>
                            <label className='tw-block tw-mb-0 tw-pb-0 '>Server URI(s) :- </label>
                            <label className='tw-text-xs !tw-mt-0 !tw-pt-0 tw-ml-5'>[e.g  mqtt://mybroker:1883]</label>
                        </div>
                        <input type="text" className="form-control tw-w-[35%]" name="db_database_name" id="db_database_name" value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.URL} onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), URL: e.target.value }) }))} disabled={isdisable}/>
                        <select className="form-control form-select tw-w-[15%]" name="reporttype" required="required" disabled={isdisable}>
                            <option value={1}>Default</option>
                        </select>
                    </div>
                    <div className='tw-flex tw-gap-2 tw-h-8 tw-mb-5'>
                        <div className='tw-w-[50%] '>
                            <label className='tw-block tw-mb-0 tw-pb-0'>Client ID :- </label>
                            <label className='tw-text-xs !tw-mt-0 !tw-pt-0 tw-ml-5'>[Keep it unique to avoid disconnections]</label>
                        </div>
                        <div className='tw-w-[50%] '>
                        <input type="text" className="form-control " name="db_database_name" id="db_database_name" value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.clientId} onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), clientId: e.target.value }) }))} disabled={isdisable}/>
                            {/* <label className='tw-text-xs !tw-mt-0 !tw-pt-0 tw-w-full tw-text-right '>Length = 0/23</label> */}
                        </div>
                        {/* <button className='btn btn-primary tw-w-[15%] '>Generate</button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
