import React, { useState } from 'react'
import Control from '../Events/Control';
import Current from '../Events/Current';
import Nonroll from '../Events/Nonroll';
import Other from '../Events/Other';
import Power from '../Events/Power';
import Transaction from '../Events/Transaction';
import Volt from '../Events/Volt';
import chroma from 'chroma-js';
export default function Events(props) {
    const buttonArray = ["Volt Event","Current Event","Power Event","Transection Event","Other Event","Nonrollover Event",'Control Event'];
	// ,"Voltevent","currentevent","powerevent","transectionevent","otherevent","nonrolloverevent",'controlevent'
	const [btnText, setButtontext] = useState("");
    const onHandalebtn = (text) => {
		setButtontext(text);
	};
  return (
  <>
 <div className="card-body" style={{ paddingTop: "0px",paddingLeft:'15px',paddingBottom:'5px' }}>
				<div className="d-flex justify-content-between">
					<div className="buttons">
						{buttonArray.map((item, id) => (
							<button
							style={{
								backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#54ca68').alpha(0.4).css() : '',
								color: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#54ca68').darken(1.5).css() : '#54ca68',
								borderBottom: `1px solid ${'#54ca68'}`,
								boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${'#54ca68'}`  : '',
							}}
							className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
							onClick={() => onHandalebtn(item)}>
							{item}
						</button>
						))}
					</div>
                    </div>
                    </div>
					{btnText==='Volt Event'&&<Volt GSMdata={props.GSMdata}/>}
					{btnText==='Current Event'&&<Current GSMdata={props.GSMdata}/>}
					{btnText==='Power Event'&&<Power GSMdata={props.GSMdata}/>}
					{btnText==='Transection Event'&&<Transaction GSMdata={props.GSMdata}/>}
					{btnText==='Nonrollover Event'&&<Nonroll GSMdata={props.GSMdata}/>}
					{btnText==='Control Event'&&<Control GSMdata={props.GSMdata}/>}
					{btnText==='Other Event'&&<Other GSMdata={props.GSMdata}/>}

					{/* {btnText==='Dailyload'&&<Dailyload GSMdata={props.GSMdata}/>}
					{btnText==='Loadsurvey'&&<Loadsurvey GSMdata={props.GSMdata}/>}
					{btnText==='Monthbill'&&<Monthbill GSMdata={props.GSMdata}/>} */}

  </>
  )
}
