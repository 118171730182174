import React, { useState } from 'react'
import ButtonTabs from '../../../Commoncomponet/Tabs/ButtonTabs'
import Scriptcodeid from '../../Scriptcodeid/Scriptcodeid'
import PortableScriptDecodeId from '../../PortableDeviceScriptdcodeid/Scriptcodeid'
import ModemCredential from '../ModemCredential'
import GSMModemTables from './GSMModemTables'
import GSMMeterTables from './GSMMeterTables'
import PortableModem from './PortableModem'
import PortableMeter from './PortableMeter'
import TableCity from './UnivarsalTables.js/TableCity'
import TableCountry from './UnivarsalTables.js/TableContry'
import TableState from './UnivarsalTables.js/TableState'

const UniversalTables = () => {
    const [DefaultTab,setDefaultTab]=useState("tab1")
    const [IsDefault,setIsDefault]=useState(false)
    return (
        <>
                <section className="section">
                    <div className="">

                        <div className='tw-mt-2 tw-w-full tw-mx-auto tw-px-0'>
                            <ButtonTabs color="indigo" className={'tw-flex-wrap'} defaultTab={'tab1'} setIsDefault={setIsDefault} IsDefault={IsDefault}>
                                <div name='ucmt_eums_tbl_country_master' value={'tab1'} color='#22c55e' onClick={()=>setDefaultTab('tab1')}>
                                <TableCountry/>
                                </div>
                                <div className='border-top' name='ucmt_eums_tbl_state_master' value={'tab2'} color='#6366f1' onClick={()=>setDefaultTab('tab2')}>
                                  <TableState/>
                                </div>
                                <div className='border-top' name='ucmt_eums_tbl_city_master' value={'tab3'} color='#0ea5e9' onClick={()=>setDefaultTab('tab3')}>
                                  <TableCity/>
                                </div>
                                
                            </ButtonTabs>
                        </div>
                    </div>
                </section>
        </>
    )
}

export default UniversalTables