import { Switch } from 'antd'
import React from 'react'

export default function SecurityUserAuth({credentialdetails,setcredentialdetails,isdisable,setCre_files,Cre_files}) {

    const algo=['HS256','HS384','HS512','RS256','RS384','RS512','ES256','ES384','ES512','PS256','PS384','PS512']
    console.log('credentialdetails',JSON.parse(credentialdetails.endpoint_credential))
    return (
        <div className='tw-box-border'>
            <div className='card-body'>
            <div className='tw-mb-3 form-group tw-flex' >
                    <label className='tw-w-[30%] tw-flex tw-items-center'>Enable user authentication :-</label>
                    <Switch  disabled={isdisable}  checked={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.user_auth} onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), user_auth: e,Username:!e?'':JSON.parse(credentialdetails?.endpoint_credential || '{}')?.Username,Password:!e?'':JSON.parse(credentialdetails?.endpoint_credential || '{}')?.Password,password_type:'' }) }))}></Switch>
                </div>
            <div className='!tw-mb-3 form-group tw-flex' >
                    <label className='tw-w-[30%] tw-flex tw-items-center'>User name :-</label>
                    <input className='tw-w-[70%] form-control' disabled={isdisable || !JSON.parse(credentialdetails?.endpoint_credential || '{}')?.user_auth}  value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.Username} onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), Username: e.target.value }) }))}></input>
                </div>
                <div className='!tw-mb-2 form-group tw-flex' >
                    <label className='tw-w-[30%] tw-flex tw-items-center'>Password :- </label>
                    {/* <input className='tw-w-[70%] form-control' disabled={isdisable|| !JSON.parse(credentialdetails?.endpoint_credential || '{}')?.user_auth} value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.Password} onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), Password: e.target.value }) }))}></input> */}
                    <div class="form-check tw-w-[70%] d-flex">
                            <div class="custom-control custom-radio mr-2">
                              <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input" onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), password_type: 'static' }) }))} checked={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.password_type=='static'} disabled={isdisable}/>
                              <label class="custom-control-label" for="customRadio2">static</label>
                            </div>
                            <div class="custom-control custom-radio ">
                              <input type="radio" id="customRadio3" name="customRadio" class="custom-control-input" onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), password_type: 'dynamic' }) }))} checked={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.password_type=='dynamic'}  disabled={isdisable}/>
                              <label class="custom-control-label" for="customRadio3">Dynamic</label>
                            </div>
                            
                          </div>
                </div>
               {JSON.parse(credentialdetails?.endpoint_credential || '{}')?.password_type=='static'&&<div className=' form-group tw-flex  !tw-mb-2' >
            <label className='tw-w-[30%] tw-flex tw-items-center'></label>
                    <input className='tw-w-[70%] form-control' disabled={isdisable|| !JSON.parse(credentialdetails?.endpoint_credential || '{}')?.user_auth} value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.Password} onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), Password: e.target.value }) }))}></input>
                </div>}
                {JSON.parse(credentialdetails?.endpoint_credential || '{}')?.password_type=='dynamic'&&<>
                    <div className='!tw-mb-2 form-group tw-flex'>
                        <label className='tw-w-[44%]  tw-flex tw-items-center'>Method :- </label>
                        <select className="form-control  form-select " name="protocol" value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.passsword_method} onClick={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), passsword_method: e.target.value }) }))} disabled={isdisable}>
                            <option value={'JWT_TOKEN'}>JWT Token</option>
                        </select>
                    </div>
                <div className='!tw-mb-2 form-group tw-flex' >
                    <label className='tw-w-[30%] tw-flex tw-items-center'>Token Object :- </label>
                    <textarea className='tw-w-[70%] form-control' disabled={isdisable|| !JSON.parse(credentialdetails?.endpoint_credential || '{}')?.user_auth} value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.psw_object} onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), psw_object: e.target.value }) }))}></textarea>
                </div>
                <div class="form-group row !tw-mb-2" >
                    <label for="lastcontectname1" class="tw-w-[30%] col-form-label  tw-flex tw-items-center">
                    Secret key file :-
                    </label>
                    <div class="d-flex tw-w-[68%] border mr-2" style={{ padding: '2px' }}>
                    <div className='btn btn-secondary text-dark mr-2' style={{ width: "150px" }} onClick={() => { document.getElementById('file-uploadsq').click() }}>Upload File</div>
                        <input type={'text'} class="form-control " value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.psw_secret_key_file} disabled={isdisable|| !JSON.parse(credentialdetails?.endpoint_credential || '{}')?.user_auth}></input>
                        <input type="file" class="form-control" id={"file-uploadsq"} hidden  onChange={(e) => {setCre_files({...Cre_files,psw_secret_key_file:e.target.files[0]})
                    setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), psw_secret_key_file: e?.target?.files[0]?.name })})
                    }}/>
                    </div>
                </div>
                <div className='!tw-mb-2 form-group tw-flex' >
                    <label className='tw-w-[30%] tw-flex tw-items-center'>Token Expire (minute):- </label>
                    <input type='number' className='tw-w-[70%] form-control' disabled={isdisable|| !JSON.parse(credentialdetails?.endpoint_credential || '{}')?.user_auth} value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.psw_expire_time} onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), psw_expire_time: e.target.value }) }))}></input>
                </div>
                <div className='!tw-mb-2 form-group tw-flex' >
                    <label className='tw-w-[30%] tw-flex tw-items-center'>Token Algorithm :- </label>
                    <select className="tw-w-[70%] form-control  form-select " name="protocol" disabled={isdisable|| !JSON.parse(credentialdetails?.endpoint_credential || '{}')?.user_auth} value={JSON.parse(credentialdetails?.endpoint_credential || '{}')?.psw_algorithm} onChange={(e) => (setcredentialdetails({ ...credentialdetails, endpoint_credential: JSON.stringify({ ...JSON.parse(credentialdetails?.endpoint_credential || '{}'), psw_algorithm: e.target.value }) }))}>
                        <option value={''}>Select Algorithm</option>
                          {algo.map((item)=>( <option value={item}>{item}</option>))}
                        </select>
                </div>
                </>
                }
            </div>

            
        </div>
    )
}
