import React, { useEffect, useState } from "react";
import { Users } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { BootstrapTooltip, exportCSVFile, exportExcelDownload } from "../../Commoncomponet/common";
import Pagination from "../../Commoncomponet/Pagination";
import { DownloadModemConsumerDetailsByFilter, getAllModem, getLoraModemConsumerDetails, getLoraModemConsumerDetailsByFilter, getLoraModemSearchTextConsumerDetails } from "../../Commoncomponet/Utils";
import { Modal } from "antd";
import Select from 'react-select';
import MultiInput from "../../Commoncomponet/MultiInput/MultiInput";
import GoogleLoader from "../../Commoncomponet/Loaders/GoogleLoader";
import exports from '../../assets/img/export.png'

export default function MobileDetails() {
	const [PageSize, setPageSize] = useState(50)
	const [numberofPage, setnumberofPage] = useState(1)
	const [currentPage, setCurrentPage] = useState(1);
	const history=useHistory()
	const [ModLoader,setModLoader]=useState(false)
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [Filter, setFilter] = useState({ ModemList: [], EUI: '',DSRNO:'',PASRNO:'' })
	const [ModemData,setModemData]=useState([])
	const [filterLoader,setfilterLoader]=useState(false)
	const [searchTerms, setSearchTerms] = useState([]);
const [modemDList,setmodemDList]=useState([])
const [serachLoader, setSerachLoader] = useState(false)
const [ModemFilter,setModemFilter]=useState('All')
	const ongetModemConsumerDetails=async(ModemFilter)=>{
		setModLoader(true)

		const firstPageIndex = (currentPage - 1) * PageSize;
		const data=await getLoraModemConsumerDetails({ start: firstPageIndex, end: PageSize,ModemFilter:ModemFilter })
		if(data?.success===true){
			setmodemDList(data.data.data)
		setnumberofPage(data.data.Row_Count)
		setModLoader(false)

		}else{
		setModLoader(false)

			console.log('something wait wrong')
		}
	}
	const ongetAllModem = async () => {
		const data6 = await getAllModem(true);
		if (data6.success === true) {
			const options = []
			data6?.data?.data?.map((item) => (options.push({ value: item?.MODEM_UNIQUE_ID, label: item?.MODEM_PROFILE_NAME })))
			setModemData(options);
		} else {
			console.log("somthing Went wrong");
		}
	}
	useEffect(()=>{
		ongetModemConsumerDetails(ModemFilter)
		ongetAllModem()
	},[])
	useEffect(()=>{
		ongetModemConsumerDetails(ModemFilter)
	},[currentPage,PageSize])
	const UpdateRolesUser = (item) => {
		history.push({ pathname: `/AddLoraModemDetails`, state: item })
	}

	const ongetModemConsumerDetailsByFilter=async()=>{
		setfilterLoader(true)
		const firstPageIndex = (currentPage - 1) * PageSize;
		const data=await getLoraModemConsumerDetailsByFilter({ start: firstPageIndex, end: PageSize,Filter_Obj:Filter })
		if(data?.success===true){
			setmodemDList(data.data.data)
			setfilterLoader(false)
			setIsModalVisible(false)
		setnumberofPage(data.data.Row_Count)
		}else{
			setfilterLoader(false)
			console.log('something Went wrong')
		}
	}

	useEffect(()=>{
		ongetModemConsumerDetails(ModemFilter)
    },[ModemFilter])
	const onDownloadModemConsumerDetailsByFilter=async(type)=>{
		const data=await DownloadModemConsumerDetailsByFilter({ Filter_Obj:Filter })
		if(data?.success===true){
			var headers={'SRNO':'SRNO','UNIQUE_ID':'UNIQUE_ID','MODEM_TYPE':'MODEM_TYPE','EUI':'EUI','DEVICE_SERIAL_NO':'DEVICE_SERIAL_NO','PANEL_SERIAL_NO':'PANEL_SERIAL_NO','MODEM_PROFILE_NAME':'MODEM_PROFILE_NAME'}
			if(type=='csv'){
				exportCSVFile(headers,data.data.data,`Modem_Details`)
			}else{
				exportExcelDownload(data.data.data,`Modem_Details`)
			}
		}else{
			console.log('something Went wrong')
		}
	}

	const onHandalSearchMulti = (newSearchTerms) => {
		setSearchTerms(newSearchTerms);
		
	};
	
	const onItemCleared = (clearedIndex) => {
		// Create a new array without the cleared item
		const newSearchTerms = searchTerms.filter((_, index) => index !== clearedIndex);
		// Update the search results based on the new search terms
		onHandalSearchMulti(newSearchTerms);
	};
	const onClearAllItems = () => {
		// Reset the search results to the original data
		onHandalSearchMulti([]);
	
	};

	const onHandalSerch = async () => {
		console.log("OnMultiSearch = ", searchTerms)
		setSerachLoader(true)
		if(searchTerms.length>0){
		const firstPageIndex = (currentPage - 1) * PageSize;

		const data=await getLoraModemSearchTextConsumerDetails({text:searchTerms, start: firstPageIndex, end: PageSize,Filter_Obj:Filter })
		if(data?.success===true){
			setmodemDList(data.data.data)
			setSerachLoader(false)

		setnumberofPage(data.data.Row_Count)
		}else{
			setSerachLoader(false)

			console.log('something Went wrong')
		}
	}else{
		ongetModemConsumerDetails(ModemFilter)
		setSerachLoader(false)
	}
	}
	return (
		<>
		<Modal title="Filter" visible={isModalVisible} footer="" onCancel={()=>setIsModalVisible(false)} width={800}>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="inputPassword4">Modem Name:</label>
						<Select name='Modem_Profile' options={ModemData} isMulti onChange={(e) => {
										setFilter({ ...Filter, ModemList: e.map((item) => (item.value)) })}}/>
					</div>
					<div class="form-group col-md-6">
						<label for="inputPassword4">EUI:</label>
						<input type="text" className="form-control" name="EUI" id="checktext7" placeholder="Enter imei"  onChange={(e) => {
										setFilter({ ...Filter, EUI: e.target.value })}} />
					</div>
					<div class="form-group col-md-6">
						<label for="inputPassword4">Device Serial No:</label>
						<input type="text" className="form-control" name="EUI" id="checktext7" placeholder="Enter Device Serial No"  onChange={(e) => {
										setFilter({ ...Filter, DSRNO: e.target.value })}} />
					</div>
					<div class="form-group col-md-6">
						<label for="inputPassword4">Panel Serial No:</label>
						<input type="text" className="form-control" name="EUI" id="checktext7" placeholder="Enter Panel Serial No"  onChange={(e) => {
										setFilter({ ...Filter, PASRNO: e.target.value })}} />
					</div>
				</div>
				<div class="text-left mt-2">
					<input type="submit" class={`btn btn-danger ${filterLoader==true?'disabled btn-progress':''}`} value="Filter" name="filter_customer_form"  onClick={ongetModemConsumerDetailsByFilter}/>
					<Link class="btn btn-light text-dark ml-2" onClick={()=>{setIsModalVisible(false)
					setFilter({ ModemList: [], EUI: '',DSRNO:'',PASRNO:'' })
					}}>
						Reset
					</Link>
				</div>
			</Modal>
				<section class="section">
				
					<div className="row">
						<div className="col-12">
							<div className="card">
							<div className="card-header ">
										<h4 className="d-flex"><span className='mr-2'>Modem Details</span><BootstrapTooltip title='Table Name is group_modem_details_index' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip>
										<div class="form-group ml-2 mb-0 mr-2">
											<select
												id="inputState"
												class="form-select form-control"
												name="modem_name"
												onChange={(e) => setModemFilter(e.target.value)}
												value={ModemFilter}
												style={{ width: '300px', height: '35px', padding: '0px 15px' }}
											>
												<option value={'All'}>All</option>
													{ModemData?.map((item)=>(<option value={item?.value}>{item?.label}</option>))}
											</select>
										</div></h4>
										<div className="card-header-action">
											<Link to='/AddLoraModemDetails' className="btn btn-info text-white mr-2">
												<BootstrapTooltip title="Add">
													<span>
														<i
															className="fas fa-plus  text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</Link>
											<button type="button" className="btn btn-info mr-2" onClick={()=>setIsModalVisible(true)}>
												<BootstrapTooltip title="Filter">
													<span>
														<i className="fa fa-filter text-white"></i>
													</span>
												</BootstrapTooltip>
											</button>
											<div className="dropdown d-inline mr-2">
												<button
													className="btn btn-info dropdown-toggle mr-2"
													type="button"
													id="dropdownMenuButton3"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													data-placement="bottom"
													title="Download"
												>
													<i className="fa fa-download mr-1"></i>
												</button>
												<div className="dropdown-menu">
												
													<a onClick={() => onDownloadModemConsumerDetailsByFilter('xlsx')} className="dropdown-item" >
														Excel
													</a>
													<a onClick={() => onDownloadModemConsumerDetailsByFilter('csv')} className="dropdown-item">
														CSV
													</a>
												</div>
											</div>
											
										</div>
									</div>
								<div className="card-body">
								{ModLoader ? <> <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
				<GoogleLoader bodyClassName={'tw-h-[70%]'} />
			</div></> :	<div className="table-responsive tw-h-screen tw-max-h-screen">
										<div id="table-1_wrapper" className="dataTables_wrapper no-footer">
											<div className="dataTables_length" id="table_id_length">
												<label>
													Show{" "}
													<select name="table_id_length" aria-controls="table_id" className="" onChange={(e) => (setPageSize(e.target.value))} value={PageSize}>
														<option value={10}>10</option>
														<option value={25}>25</option>
														<option value={50}>50</option>
														<option value={100}>100</option>
													</select>{" "}
													entries
												</label>
											</div>
											<div id="table-1_filter" className="dataTables_filter"><label className="mr-2">Search:</label><MultiInput
									placeholder="search and enter..."
									onChange={onHandalSearchMulti}
									maxItems={null} // Allow any number of items
									onClear={onItemCleared}
									onClearAll={onClearAllItems}
									onHandalSerch={onHandalSerch}
								/>
								{/* <input type="search" className="mr-2" value={InputText} onChange={(e) => { setInputText(e.target.value.trim()) }} onKeyDown={(e) => (e.code === "Enter" ? onHandalSerch(e) : '')} /> */}
								<div className={`ml-2 btn btn-primary ${serachLoader === true ? 'btn-progress disabled' : ''}`} onClick={onHandalSerch} style={{ padding: '0.2rem 0.8rem', top: '0' }}><i className={`fas fa-search `}></i></div></div>
											<table id="table_id" className="tableCustom table table-striped tw-w-full tw-text-sm text-center">
												<thead className="tw-sticky tw-z-50 tw-top-0 tw-text-xs tw-bg-gray-100">
													<tr>
															<th class="GSM_Link">Unique Id</th>
															<th>
															Modem Name
															</th>
															<th>EUI</th>
															<th>Device Serial No</th>
															<th>Panel Serial No</th>
															<th>Status</th>

													</tr>
												</thead>
												<tbody>
												{modemDList.length>0&&modemDList.map((item)=>(
													<tr onMouseEnter={() => {
														var element = document.getElementById(item?.C1_SRNO + 'GSM');
														element.classList.add('within');
														element.classList.remove('display-icon');
													}} onMouseLeave={() => {
														var element = document.getElementById(item?.C1_SRNO + 'GSM');
														element.classList.add('display-icon');
														element.classList.remove('within');
													}} style={{cursor:'pointer'}}>
															<td className="GSM_Link"><a onClick={()=>(UpdateRolesUser(item))} style={{color:'#1890ff'}}>{item?.C1_SRNO}</a>
															<a id={item?.C1_SRNO + 'GSM'} className='display-icon'  onClick={()=>(UpdateRolesUser(item))} ><img src={exports} style={{ height: "10px" }} /></a></td>
															<td>
																	{item?.MODEM_PROFILE_NAME}
															</td>
															<td>{item?.EUI}</td>
															<td>{item?.C5_DEVICE_SERIAL_NO}</td>
															<td>{item?.C6_PANEL_SERIAL_NO}</td>
															<td className="text-center">
																	<div className={`badge ${item.STATUS === 1 ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{item.STATUS === 1 ? 'Active' : 'Inactive'}</div>
																</td>
													</tr>
												))}
												</tbody>
											</table>
										</div>
									</div>}
									{ModLoader==false&&modemDList.length==0?<h4 className="text-center">No Data Found</h4>:''}
									<><div className='d-flex justify-content-between'>
											<div>Total Number Of Row {numberofPage}</div><Pagination
												className="pagination-bar"
												currentPage={currentPage}
												totalCount={numberofPage || 1}
												pageSize={PageSize}
												onPageChange={(page) => setCurrentPage(page)}
											/></div></>
								</div>
							</div>
						</div>
					</div>
				</section>
		</>
	);
}
