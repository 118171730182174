import React, { useState,useRef,useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import DropdownItem from './DropdownItem';
import DropdownSearch from './DropdownSearch';
import './styles.css';

const DropdownMenu = ({
    options,
    placeHolder,
    value,
    onChange,
    searchable,
    searchPlaceholder,
    className,
    style,
    valueClassname,
    disabled,
    isClearable,
    menuClassName,
    costomStyle
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (option) => {
        setIsOpen(false);
        onChange(option);
    };

    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        const filtered = options.filter((option) =>
            option.label.toLowerCase().includes(searchTerm)
        );
        setFilteredOptions(filtered);
    };

    const dropdownClasses = classNames('my-dropdown__menu', className, {
        'my-dropdown__menu--open': isOpen,
    });

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div className={classNames("my-dropdown", menuClassName)}>
            <div
                className={classNames('my-dropdown__toggle tw-py-1 tw-px-2 tw-whitespace-nowrap tw-inline-block '+costomStyle, {
                    'tw-cursor-not-allowed': disabled,
                    'tw-text-white': value?.color,
                })}
                onClick={handleToggle}
                disabled={disabled}
                style={{ backgroundColor: value?.color }}
            >
                <span className={classNames(valueClassname)}>{value ? value.label : placeHolder ? placeHolder : 'Select an option'}</span>
                {value && isClearable && (<div className="tw-transition-all tw-text-stone-500 tw-cursor-pointer tw-mx-1 border-right hover:tw-text-red-500"
                    onClick={(e) => {
                        e.stopPropagation();
                        onChange(null)
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /> </svg>
                </div>)}
                <span className={classNames('my-dropdown__arrow ', { '!tw-border-t-white': value?.color })} />
            </div>
            <CSSTransition
                in={isOpen}
                timeout={300}
                classNames="my-dropdown__menu--transition"
                unmountOnExit
                onEnter={() => setFilteredOptions(options)}
                
            >
                <div className={dropdownClasses} ref={ref}  style={style}>
                    {searchable && (
                        <DropdownSearch
                            searchPlaceholder={searchPlaceholder}
                            onSearch={handleSearch}
                        />
                    )}
                    <div className="my-dropdown__options">
                        {filteredOptions.map((option) => (
                            <DropdownItem
                                key={option.value}
                                option={option}
                                onSelect={handleSelect}
                                isSelected={option.value === value?.value}
                            />
                        ))}
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
};

DropdownMenu.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired,
        })
    ).isRequired,
    value: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    searchable: PropTypes.bool,
    searchPlaceholder: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    isClearable: PropTypes.bool,
};

DropdownMenu.defaultProps = {
    searchable: false,
    searchPlaceholder: 'Search...',
    disabled: false,
    isClearable: true,
};

export default DropdownMenu;
